import { language } from "./18nStore";
import Regex from "../utils/Regex";
import StoreType from "../interfaces/StoreType";
import { Action, Case } from "../interfaces/ReducerCase";

const AUTH: StoreType["AuthReducer"] = {
  authIsLoading: false,
  newsletter: false,
  signinMode: false,
  signUpValue: {
    email: "",
    emailconfirmation: "",
    password: "",
  },
  authSignUpInputs: [
    {
      htmlFor: "email",
      type: "email",
      label: language("email"),
      autocomplete: "email",
      errorStyle: false,
    },
    {
      htmlFor: "emailconfirmation",
      type: "email",
      label: language("confirmEmail"),
      autocomplete: "off",
      errorStyle: false,
    },
    {
      htmlFor: "password",
      type: "password",
      label: language("password"),
      autocomplete: "new-password",
      errorStyle: false,
    },
  ],
  authSignInInputs: [
    {
      htmlFor: "email",
      type: "email",
      label: language("email"),
      autocomplete: "email",
      errorStyle: false,
    },
    {
      htmlFor: "password",
      type: "password",
      label: language("password"),
      autocomplete: "password",
      errorStyle: false,
    },
  ],
  authforgotedPasswordInputs: {
    htmlFor: "email",
    type: "email",
    label: language("email"),
    autocomplete: "off",
    errorStyle: false,
  },
  signInValue: {
    email: "",
    password: "",
  },
  authForgotedPasswordValue: {
    email: "",
  },

  recoverPasswordValue: {
    password: "",
    confirmpassword: "",
  },
  authRecoverPasswordInputs: [
    {
      htmlFor: "password",
      type: "password",
      label: language("newPassword"),
      autocomplete: "off",
      errorStyle: false,
    },
    {
      htmlFor: "confirmpassword",
      type: "password",
      label: language("confirmNewPassword"),
      autocomplete: "off",
      errorStyle: false,
    },
  ],
  facebookSdkIsLoaded: false
};

export default function AuthReducer(state = AUTH, action: Action) {
  switch (action.type) {
    case Case.SIGNIN: {
      return { ...state, signinMode: true };
    }
    case Case.SIGNUP: {
      return { ...state, signinMode: false };
    }
    case Case.FB_SDK_IS_LOADED: {
      return { ...state, facebookSdkIsLoaded: true };
    }
    case Case.FB_SDK_ERROR: {
      return { ...state, facebookSdkIsLoaded: false };
    }
    case Case.AUTH_IS_LOADING: {
      return { ...state, authIsLoading: true };
    }
    case Case.AUTH_IS_NOT_LOADING: {
      return { ...state, authIsLoading: false };
    }
    case Case.ALLOW_NEWSLETTER:
      return { ...state, newsletter: !state.newsletter };
    case Case.SET_SIGNUP_VALUE:
      return {
        ...state,
        signUpValue: {
          ...state.signUpValue,
          [action.payload.htmlFor]: action.payload.value,
        },
      };
    case Case.SET_SIGNUP_ERROR:
      return {
        ...state,
        authSignUpInputs: [
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "email",
            errorStyle:
              state.signUpValue.email !== state.signUpValue.emailconfirmation ||
              state.signUpValue.email === "" ||
              !Regex.emailSchema.test(state.signUpValue.email)
                ? true
                : false,
          },
          {
            htmlFor: "emailconfirmation",
            type: "email",
            label: language("confirmEmail"),
            autocomplete: "off",
            errorStyle:
              state.signUpValue.email !== state.signUpValue.emailconfirmation ||
              state.signUpValue.emailconfirmation === "" ||
              !Regex.emailSchema.test(state.signUpValue.emailconfirmation)
                ? true
                : false,
          },
          {
            htmlFor: "password",
            type: "password",
            label: language("password"),
            autocomplete: "new-password",
            errorStyle:
              state.signUpValue.password.length < 6 ||
              state.signUpValue.password === ""
                ? true
                : false,
          },
        ],
      };
    case Case.SET_DEFAULT_SINUP:
      return {
        ...state,
        authSignUpInputs: [
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "email",
            errorStyle: false,
          },
          {
            htmlFor: "emailconfirmation",
            type: "email",
            label: language("confirmEmail"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "password",
            type: "password",
            label: language("password"),
            autocomplete: "new-password",
            errorStyle: false,
          },
        ],
      };

    case Case.SET_SIGNIN_VALUE:
      return {
        ...state,
        signInValue: {
          ...state.signInValue,
          [action.payload.htmlFor]: action.payload.value,
        },
      };
    case Case.SET_SIGNIN_ERROR:
      return {
        ...state,
        authSignInInputs: [
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "email",
            errorStyle:
              state.signInValue.email === "" ||
              !Regex.emailSchema.test(state.signInValue.email)
                ? true
                : false,
          },
          {
            htmlFor: "password",
            type: "password",
            label: language("password"),
            autocomplete: "password",
            errorStyle:
              state.signInValue.password.length < 6 ||
              state.signInValue.password === ""
                ? true
                : false,
          },
        ],
      };
    case Case.SET_DEFAULT_SIGNIN:
      return {
        ...state,
        authSignInInputs: [
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "email",
            errorStyle: false,
          },
          {
            htmlFor: "password",
            type: "password",
            label: language("password"),
            autocomplete: "password",
            errorStyle: false,
          },
        ],
      };
    case Case.SET_FORGOTED_PASSWORD_VALUE:
      return {
        ...state,
        authForgotedPasswordValue: {
          ...state.authForgotedPasswordValue,
          [action.payload.htmlFor]: action.payload.value,
        },
      };
    case Case.SET_FORGOTED_PASSWORD_ERROR:
      return {
        ...state,
        authforgotedPasswordInputs: {
          htmlFor: "email",
          type: "email",
          label: language("email"),
          autocomplete: "email",
          errorStyle:
            state.authForgotedPasswordValue.email === "" ||
            !Regex.emailSchema.test(state.authForgotedPasswordValue.email)
              ? true
              : false,
        },
      };
    case Case.SET_DEFAULT_FORGOTED_PASSWORD:
      return {
        ...state,
        authforgotedPasswordInputs: {
          htmlFor: "email",
          type: "email",
          label: language("email"),
          autocomplete: "email",
          errorStyle: false,
        },
      };
    case Case.SET_RECOVER_PASSWORD_ERROR:
      return {
        ...state,
        authRecoverPasswordInputs: [
          {
            htmlFor: "password",
            type: "password",
            label: language("newPassword"),
            autocomplete: "off",
            errorStyle:
              state.recoverPasswordValue.password !==
                state.recoverPasswordValue.confirmpassword ||
              state.recoverPasswordValue.password.length < 6
                ? true
                : false,
          },
          {
            htmlFor: "confirmpassword",
            type: "password",
            label: language("confirmNewPassword"),
            autocomplete: "off",
            errorStyle:
              state.recoverPasswordValue.password !==
                state.recoverPasswordValue.confirmpassword ||
              state.recoverPasswordValue.confirmpassword.length < 6
                ? true
                : false,
          },
        ],
      };
    case Case.SET_DEFAULT_RECOVER_PASSWORD:
      return {
        ...state,
        authRecoverPasswordInputs: [
          {
            htmlFor: "password",
            type: "password",
            label: language("newPassword"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "confirmpassword",
            type: "password",
            label: language("confirmNewPassword"),
            autocomplete: "off",
            errorStyle: false,
          },
        ],
      };
    case Case.SET_RECOVER_PASSWORD_VALUE:
      return {
        ...state,
        recoverPasswordValue: {
          ...state.recoverPasswordValue,
          [action.payload.htmlFor]: action.payload.value,
        },
      };
    case Case.SIGNOUT:
      return {
        authIsLoading: false,
        newsletter: false,
        signinMode: false,
        signUpValue: {
          email: "",
          emailconfirmation: "",
          password: "",
        },
        authSignUpInputs: [
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "email",
            errorStyle: false,
          },
          {
            htmlFor: "emailconfirmation",
            type: "email",
            label: language("confirmEmail"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "password",
            type: "password",
            label: language("password"),
            autocomplete: "new-password",
            errorStyle: false,
          },
        ],
        authSignInInputs: [
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "email",
            errorStyle: false,
          },
          {
            htmlFor: "password",
            type: "password",
            label: language("password"),
            autocomplete: "password",
            errorStyle: false,
          },
        ],
        authforgotedPasswordInputs: {
          htmlFor: "email",
          type: "email",
          label: language("email"),
          autocomplete: "off",
          errorStyle: false,
        },
        signInValue: {
          email: "",
          password: "",
        },
        authForgotedPasswordValue: {
          email: "",
        },
      
        recoverPasswordValue: {
          password: "",
          confirmpassword: "",
        },
        authRecoverPasswordInputs: [
          {
            htmlFor: "password",
            type: "password",
            label: language("newPassword"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "confirmpassword",
            type: "password",
            label: language("confirmNewPassword"),
            autocomplete: "off",
            errorStyle: false,
          },
        ],
        facebookSdkIsLoaded: false
      };
    default:
      return state;
  }
}
