import React, { ReactElement, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import StoreType from "../interfaces/StoreType";
import "../styles/series.scss";
import SeriesEpisodes from "./SeriesEpisodes";
import SeriesHeader from "./SeriesHeader";
import SeriesDetails from "./SeriesDetails";
import SeriesSimilar from "./SeriesSimilar";
import Modal from "react-responsive-modal";
import SeriesNavigation from "./SeriesNavigation";
import { UseSeriesMain } from "../ressources/UseSeriesMain";
import SeriesHeaderPlaceHolder from "./SeriesHeaderPlaceHolder";
import SeriesEpisodesPlaceHolder from "./SeriesEpisodesPlaceHolder";
import { UseEpisodesBySeasons } from "../ressources/UseEpisodesBySeasons";
import EventStore from "../stores/AmplitudeStore";
import { Action, Case } from "../interfaces/ReducerCase";
import { Dispatch } from "redux";
import SeriesLocationTypes from "../interfaces/SeriesLocationTypes";
import ReactPixel from "react-facebook-pixel";

export default function Series(): ReactElement {
  const {
    series,
    universes,
    currentEpisode,
    user,
    userContentLanguage,
    isLoading,
  } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
    ...state.ProfileReducer,
    ...state.PlayerReducer,
    ...state.UserReducer,
    ...state.RouterReducer,
  }));
  const { openSeries } = EventStore();
  let { idparams } = useParams<Record<string, string | undefined>>();
  const location = useLocation<SeriesLocationTypes>();
  const history = useHistory();
  const {
    seasonIndex,
    isEpisodeView,
    setIsEpisodeView,
    isSimilarView,
    setIsSimilarView,
    isLiked,
    seriesLanguage,
    navigation,
    setIsLiked,
    toastId,
    setIsDetailView,
    setSeasonIndex,
    isDetailView,
    isSerieLoaded,
    fetchedSeries,
    lastNavigation,
  } = UseSeriesMain(idparams);
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  useEffect(() => {
    if (!idparams) {
      stableDispatch({ type: Case.CLOSE_SERIES });
      history.push("/home");
    }
  }, [history, idparams, stableDispatch]);
  const closingModal = () => {
    dispatch({ type: Case.CLOSE_SERIES });
    history.push("/home");
  };
  useEffect(() => {
    stableDispatch({ type: Case.OPEN_SERIES });
  }, [stableDispatch]);



  useEffect(() => {
    if (fetchedSeries && seriesLanguage && idparams) {
      ReactPixel.track("ViewContent", {
        content_type: "product",
        content_category:
          location && location.state && location.state.contentEventDetails
            ? location.state.contentEventDetails.contentCategory
            : null,
        content_ids: idparams,
        content_name: fetchedSeries?.info[seriesLanguage].title,
        univers_affichage:
          location && location.state && location.state.contentEventDetails
            ? location.state.contentEventDetails.contentUniverse
            : "Main Homepage",
        page_type: "Fiche produit",
      });
    }
  }, [idparams, location, fetchedSeries, seriesLanguage]);

  useEffect(() => {
    if (
      JSON.parse(sessionStorage.getItem("profile")!) &&
      fetchedSeries &&
      seriesLanguage &&
      idparams &&
      idparams === fetchedSeries._id
    ) {
      if (
        location &&
        location.state &&
        location.state.isSimilar &&
        location.state.isSimilar.id === fetchedSeries._id
      ) {
        openSeries(
          fetchedSeries.isChild,
          "Series Similar Titles",
          fetchedSeries.emotion ? fetchedSeries.emotion!.join() : "",
          fetchedSeries._id,
          fetchedSeries.info[seriesLanguage].title,
          JSON.parse(sessionStorage.getItem("profile")!).isChild,
          undefined,
          undefined,
          location.state.isSimilar.index,
          undefined,
          undefined,
          fetchedSeries.mainImageId,
          undefined,
          undefined,
          location.state.isSimilar.lastId,
          location.state.isSimilar.title,
          undefined,
          undefined,
          fetchedSeries.channel ? fetchedSeries.channel : undefined,
          fetchedSeries &&
            fetchedSeries.info &&
            fetchedSeries.info[seriesLanguage].rssFeed
            ? true
            : false,
            fetchedSeries.scripted ? fetchedSeries.scripted : undefined,
            fetchedSeries.genre ? fetchedSeries.genre : undefined,
            fetchedSeries.format ? fetchedSeries.format : undefined,
            fetchedSeries.length ? fetchedSeries.length : undefined,
            fetchedSeries.chronological ? fetchedSeries.chronological : undefined,
            fetchedSeries.classification ? fetchedSeries.classification : undefined,
            fetchedSeries.publicationDate ? fetchedSeries.publicationDate : undefined,
            fetchedSeries.seasonsList ? fetchedSeries.seasonsList : undefined,
            fetchedSeries.info[seriesLanguage] ?  fetchedSeries.info[seriesLanguage].headerMention : undefined,
            fetchedSeries.authors ?  fetchedSeries.authors : undefined,
            fetchedSeries.owner_id?  fetchedSeries.owner_id : undefined,
        );
      } else if (
        (location && !location.state) ||
        (location &&
          location.state &&
          !location.state.isSimilar &&
          !location.state.contentEventDetails)
      ) {
        openSeries(
          fetchedSeries.isChild,
          "Series Deeplink",
          fetchedSeries.emotion ? fetchedSeries.emotion!.join() : "",
          fetchedSeries._id,
          fetchedSeries.info[seriesLanguage].title,
          JSON.parse(sessionStorage.getItem("profile")!).isChild,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          fetchedSeries.mainImageId,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          fetchedSeries.channel ? fetchedSeries.channel : undefined,
          fetchedSeries && fetchedSeries.info && fetchedSeries.info[seriesLanguage].rssFeed ? true : false,
          fetchedSeries.scripted ? fetchedSeries.scripted : undefined,
          fetchedSeries.genre ? fetchedSeries.genre : undefined,
          fetchedSeries.format ? fetchedSeries.format : undefined,
          fetchedSeries.length ? fetchedSeries.length : undefined,
          fetchedSeries.chronological ? fetchedSeries.chronological : undefined,
          fetchedSeries.classification ? fetchedSeries.classification : undefined,
          fetchedSeries.publicationDate ? fetchedSeries.publicationDate : undefined,
          fetchedSeries.seasonsList ? fetchedSeries.seasonsList : undefined,
          fetchedSeries.info[seriesLanguage] ?  fetchedSeries.info[seriesLanguage].headerMention : undefined,
          fetchedSeries.authors ?  fetchedSeries.authors : undefined,
          fetchedSeries.owner_id?  fetchedSeries.owner_id : undefined,
        );
      } else if (
        location &&
        location.state &&
        location.state.contentEventDetails
      ) {
        openSeries(
          fetchedSeries.isChild,
          location.state.contentEventDetails.contentContext,
          fetchedSeries.emotion ? fetchedSeries.emotion!.join() : "",
          idparams,
          fetchedSeries.info[seriesLanguage!].title,
          JSON.parse(sessionStorage.getItem("profile")!).isChild,
          location.state.contentEventDetails.contentCategory,
          location.state.contentEventDetails.categoryType,
          location.state.contentEventDetails.categoryPosition,
          location.state.contentEventDetails.contentUniverse,
          location.state.contentEventDetails.universeId,
          fetchedSeries.mainImageId,
          location.state.contentEventDetails.contentPosition,
          location.state.contentEventDetails.catalog,
          undefined,
          undefined,
          location.state.contentEventDetails.searchedText,
          universes && location.state.contentEventDetails.universeId
            ? universes[
                universes?.findIndex(
                  (each) =>
                    each.headerCategoryId ===
                    location.state.contentEventDetails!.universeId
                )
              ].title
            : undefined,
          fetchedSeries.channel ? fetchedSeries.channel : undefined,
          fetchedSeries && fetchedSeries.info && fetchedSeries.info[seriesLanguage].rssFeed ? true : false,
          fetchedSeries.scripted ? fetchedSeries.scripted : undefined,
          fetchedSeries.genre ? fetchedSeries.genre : undefined,
          fetchedSeries.format ? fetchedSeries.format : undefined,
          fetchedSeries.length ? fetchedSeries.length : undefined,
          fetchedSeries.chronological ? fetchedSeries.chronological : undefined,
          fetchedSeries.classification ? fetchedSeries.classification : undefined,
          fetchedSeries.publicationDate ? fetchedSeries.publicationDate : undefined,
          fetchedSeries.seasonsList ? fetchedSeries.seasonsList : undefined,
          fetchedSeries.info[seriesLanguage] ?  fetchedSeries.info[seriesLanguage].headerMention : undefined,
          fetchedSeries.authors ?  fetchedSeries.authors : undefined,
          fetchedSeries.owner_id?  fetchedSeries.owner_id : undefined,
          location && location.state && location.state.contentEventDetails?.recommId && location.state.contentEventDetails && location.state.contentEventDetails?.recommId
        );
      }
    }
    // eslint-disable-next-line
  }, [location, fetchedSeries, seriesLanguage, idparams, universes]);

  const { isEpisodesLoaded } = UseEpisodesBySeasons(idparams, seasonIndex);
  return (
    <Modal
      open={true}
      onClose={closingModal}
      classNames={{
        modalContainer: "series-background-modale",
        modal: "series-container",
        closeIcon: "exit",
        closeButton: "exit",
        overlay: "background",
      }}
      center
      styles={{
        modal: {
          marginBottom:
            currentEpisode && user && userContentLanguage !== null && !isLoading
              ? "80px"
              : "0",
        },
      }}
      closeIcon={<img src="/image/exit.svg" alt="exit series" />}
    >
      {idparams &&
      series &&
      series[idparams] &&
      seriesLanguage &&
      isSerieLoaded &&
      isEpisodesLoaded &&
      seasonIndex ? (
        <>
          <SeriesHeader
            id={idparams}
            toastId={toastId}
            isLiked={isLiked}
            setIsLiked={setIsLiked}
            seasonIndex={seasonIndex}
          />
          <SeriesNavigation
            isEpisodeView={isEpisodeView}
            isSimilarView={isSimilarView}
            navigation={navigation}
            isDetailView={isDetailView}
            setIsSimilarView={setIsSimilarView}
            setIsEpisodeView={setIsEpisodeView}
            setIsDetailView={setIsDetailView}
            id={idparams}
          />
          {isEpisodeView && (
            <SeriesEpisodes
              id={idparams}
              seasonIndex={seasonIndex}
              setSeasonIndex={setSeasonIndex}
              seriesLanguage={seriesLanguage}
              isEpisodesLoaded={isEpisodesLoaded}
              fetchedSeries={fetchedSeries}
              lastNavigation={lastNavigation}
            />
          )}
          {isSimilarView && (
            <SeriesSimilar
              id={idparams}
              seriesLanguage={seriesLanguage}
              setIsSimilarView={setIsSimilarView}
              setIsEpisodeView={setIsEpisodeView}
              setIsDetailView={setIsDetailView}
              navigation={navigation}
              setSeasonIndex={setSeasonIndex}
              toastId={toastId}
              fetchedSeries={fetchedSeries}
              lastNavigation={lastNavigation}
            />
          )}
          {isDetailView && (
            <SeriesDetails
              id={idparams}
              seriesLanguage={seriesLanguage}
              fetchedSeries={fetchedSeries}
              lastNavigation={lastNavigation}
            />
          )}
        </>
      ) : (
        <>
          <SeriesHeaderPlaceHolder />
          <SeriesEpisodesPlaceHolder />
        </>
      )}
    </Modal>
  );
}
