import React, { ReactElement, useRef } from "react";
import { useSelector } from "react-redux";
import EpisodeType from "../interfaces/EpisodeType";
import SeriesHistoryType from "../interfaces/SeriesHistoryType";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";
import SeriesEpisodesProgressBar from "./SeriesEpisodesProgressBar";
import ReactPixel from "react-facebook-pixel";
import { Link } from "react-router-dom";


interface Props {
  each?: string;
  seriesHistory?: {
    [seriesId: string]: SeriesHistoryType;
  };
  play?: (
    id: string,
    episode: EpisodeType,
    context: string,
    contentUniverse?: string,
    universeId?: string,
    categoryType?: string,
    categoryPosition?: number,
    contentCategory?: string,
    timestampe?: number,
    ratio?: number
  ) => void;
  index: number;
  universToShow?: string | undefined;
}

export default function HomeResumeCard({
  each,
  seriesHistory,
  play,
  index,
  universToShow,
}: Props): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const { universes } = useSelector((state: StoreType) => ({
    ...state.ProfileReducer,
  }));

  return (
    <div className="each-resume-card" ref={ref}>
      <div
        style={{
          background:
            seriesHistory &&
            each &&
            seriesHistory[each] &&
            seriesHistory[each].series &&
            seriesHistory[each].series.mainImage
              ? `top center / cover url(${seriesHistory[each].series.mainImage})`
              : "#343434",
        }}
        className="each-resume-card-cover-container"
        onClick={() =>
          play && seriesHistory && each && seriesHistory[each]
            ? play(
                seriesHistory[each].series._id,
                {
                  ...seriesHistory[each].episode,
                  seriesId: seriesHistory[each].series._id,
                  progress: seriesHistory[each].progress,
                  publicationDate: new Date(),
                  image: seriesHistory[each].series.mainImage,
                  info: {},
                },
                "Explorer",
                universes && universToShow
                  ? universes.filter(
                      (each) => each.headerCategoryId === universToShow
                    )[0].title
                  : undefined,
                universToShow,
                "Home Resume Listening Category",
                1,
                language("resumeListening"),
                seriesHistory[each].progress,
                Math.round(
                  (seriesHistory[each].progress /
                    seriesHistory[each].episode.length +
                    Number.EPSILON) *
                    1000
                ) / 1000
              )
           : null
        }
      >
        <img className="play-epidode-button" src="/image/playLogo.svg" alt="" />
        <SeriesEpisodesProgressBar
          progressBarSize={
            seriesHistory && each && seriesHistory[each]
              ? (seriesHistory[each].progress * 100) /
                seriesHistory[each].episode.length
              : 0
          }
        />
      </div>
      <Link
        to={{
          pathname: each ? `/series/${each}` : undefined,
          state: {
            contentEventDetails: each
              ? {
                  id: each,
                  contentContext: "Explorer",
                  categoryType: "Main Homepage",
                  contentCategory: undefined,
                  categoryPosition: undefined,
                  universeId: undefined,
                  contentUniverse: "Main Homepage",
                  contentPosition: index,
                  catalog: undefined,
                }
              : undefined,
          },
        }}
        className="each-resume-card-imformation"
        onClick={() => {
          ReactPixel.track("ViewCategory", {
            content_type: "product",
            content_category: "Resume Category",
            content_ids: each ? each : undefined,
            content_name: null,
            univers_affichage: "Main Homepage",
            page_type: "Main Homepage",
          });
        }}
      >
        <img src="/image/informationLogo.svg" alt="" />
      </Link>
    </div>
  );
}
