import React, {
  Dispatch,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import { language } from "../stores/18nStore";
import EventStore from "../stores/AmplitudeStore";
import sleepModeArray from "../utils/sleepModeArray";

interface Props {
  sleepModeInfo:
    | {
        time: number | null;
        second: number | null;
        wording: string;
        isActivated: boolean | undefined;
      }
    | undefined;
  setSleepModeInfo: Function;
  setEpisodeEndSleep: Function;
  episodeEndSleep: boolean;
}

export default function PlayerSleepMode({
  sleepModeInfo,
  setSleepModeInfo,
  setEpisodeEndSleep,
  episodeEndSleep,
}: Props): ReactElement {
  const dispatch: Dispatch<Action> = useDispatch();
  const { currentEpisode, uuid, volume } = useSelector((state: StoreType) => ({
    ...state.PlayerReducer,
    ...state.UserReducer,
  }));
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const { seriesLanguage } = UseSeriesLanguage();
  const [isLogEvent, setIsLogEvent] = useState<boolean>(false);
  const { pause, sleepMode, sleepModeOff } = EventStore();
  useEffect(() => {
    if (isLogEvent && JSON.parse(localStorage.getItem("profile")!)) {
      pause(
        currentEpisode!.series.isChild,
        "Auto From SleepMode",
        currentEpisode!.series.emotion
          ? currentEpisode!.series.emotion!.join()
          : undefined,
        currentEpisode!.episode!.info[seriesLanguage].title,
        currentEpisode!.episode!._id,
        currentEpisode!.episode!.season,
        currentEpisode!.series._id,
        currentEpisode!.series.info[seriesLanguage].title,
        JSON.parse(localStorage.getItem("profile")!).isChild,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        currentEpisode!.series.mainImageId,
        currentEpisode!.episode?.length,
        1,
        currentEpisode!.episode?.length,
        currentEpisode!.series?.length
      );
    }
  }, [isLogEvent, currentEpisode, seriesLanguage, pause]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        sleepModeInfo &&
        sleepModeInfo.second &&
        sleepModeInfo.isActivated &&
        currentEpisode
      ) {
        if (sleepModeInfo!.second! > 1) {
          setSleepModeInfo((sleepModeInfo) => ({
            ...sleepModeInfo!,
            second: sleepModeInfo!.second! - 1,
          }));
        } else {
          window.analytics.track("Audio Content Interrupted", {
            subscription: !!localStorage.getItem("user")
              ? JSON.parse(localStorage.getItem("user")!).subscription
              : 0,
            context: "sleepmode",
            platform: "Web",
            session_id: uuid,
            sound: volume,
            signup_type: localStorage.getItem("sy_signup_type")
              ? localStorage.getItem("sy_signup_type")
              : undefined,
            content_type: currentEpisode.sample ? "Trailer" : "Episode",
            episode_id: currentEpisode.episode
              ? currentEpisode.episode._id
              : undefined,
            content_length: currentEpisode.series.length,
            series_id: currentEpisode.series._id,
            episode_name:
              currentEpisode && currentEpisode.episode
                ? currentEpisode.episode.info[seriesLanguage].title
                : undefined,
            channel_id:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel._id
                : undefined,
            channel_name:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel.name
                : undefined,
            channel_name_code:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel.nameCode
                : undefined,
            channel_umbrella_id:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel.umbrella._id
                : undefined,
            channel_umbrella_name:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel.umbrella.name
                : undefined,
            channel_umbrella_name_code:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel.umbrella.nameCode
                : undefined,
                has_rss_feed:
                currentEpisode &&
                currentEpisode.series &&
                currentEpisode.series.info[seriesLanguage] &&
                currentEpisode.series.info[seriesLanguage].rssFeed
                  ? true
                  : false,
                  child_content:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.isChild
                    : undefined,
                chronological:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.chronological
                    : undefined,
                classification:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.classification
                    : undefined,
                emotion:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.emotion
                    : undefined,
                episode_index: currentEpisode && currentEpisode.episode
                  ? currentEpisode.episode.index
                  : undefined,
                format:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.format
                    : undefined,
                genre:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.genre
                    : undefined,
                total_length: currentEpisode && currentEpisode.episode
                  ? currentEpisode.episode.length
                  : undefined,
                  is_unit:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.isUnit
                    : undefined,
          });
          stableDispatch({
            type: Case.SET_CURRENT_EPISODE_UNDEFINED,
          });
          clearInterval(interval);
          setIsLogEvent(true);
          return setSleepModeInfo(undefined);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [
    sleepModeInfo,
    stableDispatch,
    setSleepModeInfo,
    currentEpisode,
    seriesLanguage,
    uuid,
    volume,
  ]);
  const triggerSleepMode = (
    each:
      | {
          time: null;
          second: null;
          wording: string;
        }
      | {
          time: number;
          second: number;
          wording: string;
        }
  ) => {
    if (
      (sleepModeInfo &&
        !sleepModeInfo.isActivated === true &&
        sleepModeInfo.time === each.time) ||
      (sleepModeInfo &&
        !sleepModeInfo.isActivated === false &&
        sleepModeInfo.time !== each.time) ||
      (sleepModeInfo &&
        !sleepModeInfo.isActivated === true &&
        sleepModeInfo.time !== each.time) ||
      sleepModeInfo === undefined
    ) {
      sleepMode(
        each.time === null
          ? "episode"
          : each.time === 1
          ? "60min"
          : `${each.time}min`
      );
    } else {
      sleepModeOff();
    }
    each.second === null
      ? currentEpisodeSleepmodeTrigger(each)
      : setSleepModeInfo({
          ...each,
          isActivated:
            (sleepModeInfo &&
              !sleepModeInfo.isActivated === true &&
              sleepModeInfo.time === each.time) ||
            (sleepModeInfo &&
              !sleepModeInfo.isActivated === false &&
              sleepModeInfo.time !== each.time) ||
            (sleepModeInfo &&
              !sleepModeInfo.isActivated === true &&
              sleepModeInfo.time !== each.time) ||
            sleepModeInfo === undefined
              ? true
              : false,
        });
  };
  const currentEpisodeSleepmodeTrigger = (each: {
    time: null;
    second: null;
    wording: string;
  }) => {
    setSleepModeInfo({
      ...each,
      second: null,
      isActivated:
        (sleepModeInfo &&
          !sleepModeInfo.isActivated === true &&
          sleepModeInfo.time === each.time) ||
        (sleepModeInfo &&
          !sleepModeInfo.isActivated === false &&
          sleepModeInfo.time !== each.time) ||
        (sleepModeInfo &&
          !sleepModeInfo.isActivated === true &&
          sleepModeInfo.time !== each.time) ||
        sleepModeInfo === undefined
          ? true
          : false,
    });
    setEpisodeEndSleep(!episodeEndSleep);
  };
  return (
    <div
      className="sleep-mode"
    >
      <div className="sleep-hover">
        <p className={`season-episode-hover`}>{language("sleepAfter")}</p>
        <div>
          {sleepModeArray.map((each, index) => {
            return (
              <div
                key={index}
                style={{
                  color:
                    sleepModeInfo &&
                    each.time === sleepModeInfo.time &&
                    sleepModeInfo.isActivated === true
                      ? "#f23568"
                      : "#fff",
                  fontWeight:
                    sleepModeInfo &&
                    each.time === sleepModeInfo.time &&
                    sleepModeInfo.isActivated === true
                      ? "bold"
                      : "normal",
                }}
                className={"sleep-hover-each"}
                onClick={() => {
                  triggerSleepMode(each);
                }}
              >
                <p>
                  {each.time} {language(each.wording)}
                </p>
              </div>
            );
          })}
          {sleepModeInfo && sleepModeInfo.isActivated && (
            <div
              className={"sleep-hover-each"}
              style={{ color: "#fff", fontWeight: "bold" }}
              onClick={() => {
                sleepModeOff();
                setEpisodeEndSleep(false);
                setSleepModeInfo(undefined);
              }}
            >
              <p>{language("disableSleep")}</p>
            </div>
          )}
        </div>
      </div>
      {sleepModeInfo && sleepModeInfo.isActivated ? (
        <img src="/image/sleepModeActivated.svg" alt="" />
      ) : (
        <img src="/image/sleepMode.svg" alt="" />
      )}
    </div>
  );
}
