import React, { ReactElement } from "react";
import HomeMainCategoriesCard from "./HomeMainCategoriesCard";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation, Mousewheel, Controller } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import {
  LazyLoadComponent,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import swiperMainOptions from "../utils/SwiperOptions";
import HomeMainCategorieType from "../interfaces/components/HomeMainCategorieType";

SwiperCore.use([Navigation, Mousewheel, Controller]);

function HomeMainCategories({
  scrollPosition,
  each,
  universToShow,
}: HomeMainCategorieType): ReactElement {
  return (
    <LazyLoadComponent
      threshold={0}
      scrollPosition={scrollPosition}
      placeholder={
        <div className="home-resume">
          <p className="resume-title">{each.title}</p>
          <Swiper {...swiperMainOptions}>
            {Array.from(Array(9), (e, i) => {
              return (
                <SwiperSlide key={i}>
                  <HomeMainCategoriesCard index={i} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      }
    >
      <div className="home-resume">
        <p className="resume-title">{each.title}</p>
        <Swiper loop centeredSlides {...swiperMainOptions}>
          {each.seriesList.length < 9
            ? [...each.seriesList, ...each.seriesList, ...each.seriesList].map(
                (eachSeries, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <HomeMainCategoriesCard
                        each={eachSeries}
                        category={each}
                        universeToShow={universToShow}
                        index={index}
                      />
                    </SwiperSlide>
                  );
                }
              )
            : each.seriesList.map((eachSeries, index) => {
                return (
                  <SwiperSlide key={index}>
                    <HomeMainCategoriesCard
                      each={eachSeries}
                      category={each}
                      universeToShow={universToShow}
                      index={index}
                    />
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </div>
    </LazyLoadComponent>
  );
}

export default trackWindowScroll(HomeMainCategories);
