import Phrases from "./Phrases";

const phrases: Phrases = {
  inputPlaceholder: "Type here", //TODO
  alreadyHasAnAccount: "Do you already have an account? ",
  alreadyHasAnAccountLink: "Sign in",
  notAlreadyAnAccount: "No account yet? ",
  notAlreadyAnAccountLink: "Sign up",
  forgotedPassword: "Did you forget your password? ",
  forgotedPasswordLink: "Click here",
  or: "OR",
  cgu: "Terms and Conditions",
  authCgu: "By clicking here, you confirm that you accept our ",
  signup: "Sign up",
  signin: "Sign in",
  signout: "Sign out",
  email: "Email address",
  confirmEmail: "Confirm email address",
  emailSuccess: "Your email has been successfully updated",
  password: "Password",
  wrongOldPassword: "Please verify your old password",
  newPassword: "New password",
  confirmNewPassword: "Confirm new password",
  newPasswordSuccess: "Your password has been successfully updated",
  newPasswordTitle: "Create a new password",
  newPasswordParagraphe: "Your password must be at least 6-characters long",
  confirmButton: "CONFIRM",
  facebookLinking: "Link you Facebook account",
  facebookUnlinking: "Unlink your Facebook account",
  facebookUnlinkingUnauthorized:
    "Add an email to your account to unlink Facebook",
  facebookSignup: "Continue with Facebook",
  facebookLogin: "Continue with Facebook",
  appleContinue: "Continue with Apple",
  googleContinue: "Continue with Google",
  emailSignup: "Join us by email",
  emailLogin: "Sign in by email",
  wellcome: "Welcome to Sybel!",
  wellcomeSubtitle: "Who are you going to share your account with?",
  chooseProfileTitle: "Who's listening?",
  chooseProfileSubTitle: "Choose a profile to begin",
  finishing: "Finish",
  isChild: "Kid profile?",
  searchPlaceholder: "Title, keyword, themes...",
  profile: "Profile",
  likeList: "My list",
  backToHome: "Back to homepage",
  shortHome: "Homepage",
  back: "Back",
  settingsScreenTitle: "Account settings",
  subscription: "My subscription",
  subscriptionWord: "Subscription ",
  cancelSubscription: "Cancel subscription",
  cancelSubscriptionQuestion:
    "Do you really want to cancel your subscription ?",
  paymentMethod: "Payment method",
  billingDetails: "See billing details",
  billingDetailsTitle: "Billing details",
  annualWord: "yearly ",
  monthWord: "monthly",
  aWithAccent: " à ", //TODO
  subscribe: "subscribe",
  stripeSubscriptionTitle: "Enter your payment information",
  sfrSubscription: "You subscribed to a Sybel+ option with our partner",
  sfrSubscriptionTwo: "SFR",
  sfrSubscriptionThree: "go to your",
  sfrSubscriptionFour: "SFR client account",
  subscriptionStore:
    "You subscribed with your application store on the mobile app, go to your Store (Apple App Store or Google Play Store) on your phone to manage your subscription.",
  subscriptionCoupon:
    "You've activated a coupon that grant you unlimited access to Sybel until ",
  subscriptionStripe: "You're subscribed until ",
  subscriptionLife: "You've purchased a lifetime access to Sybel library ❤️",
  signinInformation: "SIGN IN INFORMATION",
  addEmailSentence:
    "Add an email address to share your account easily with your friends or family",
  addEmail: "Add a sign in email",
  modifyEmail: "Edit sign in email",
  modifyPassword: "Edit password",
  loginMethod: "Add another sign in method to connect in one click:",
  parentalCodeSettings: "EXPLICIT CONTENT AND PARENTAL CONTROLS ",
  dontHaveParentalCodeSettings:
    "We advise your to set a parental controls PIN if you share your account with people that may be offended by explicit content unadapted to his/her age.",
  haveParentalCodeSettings:
    "Your parental controls PIN has been set! Contents that are for +16yo and above will be restricted by this PIN code.",
  addProfile: "Add another profile",
  modifyProfile: "Edit profile",
  firstName: "First name",
  gender: "Gender",
  wontSay: "Rather not to say",
  male: "Masculine",
  female: "Feminine",
  other: "Other",
  birthday: "Birthdate",
  birthdayFormat: "(dd/mm/yyyy)",
  childMode: "Kid mode",
  anyQuestions: "Any question?",
  helpCenter: "Help center",
  contactUs: "Contact us by email",
  contactUsOn: "Contact us on",
  tc: "Terms and Conditions",
  listen: "Listen",
  episodes: "episodes",
  similar: "Similar titles",
  details: "Details",
  season: "Season",
  sample: "Trailer",
  seemore: "See more...",
  seeless: "See less",
  resumeListening: "Resume listening",
  resume: "Resume",
  avatarPickerTitle: "Pick an avatar",
  info: "Info",
  yes: "Yes",
  no: "No",
  cancel: "Cancel",
  search: "Search",
  noLikeList:
    "Add your favorite content or the ones you want to listen to soon to your list to find them back easily!",
  emailVerificationSuccess:
    "Your email address has been successfully verified! Enjoy Sybel experience",
  emailVerificationTitle: "Verify your email to continue",
  emailVerificationSubtitle:
    "We need this information to secure your account and your experience!",
  emailVerificationClickLink: "Click on the link that has been sent to",
  emailVerificationResend: "Resend an verification email",
  newEpisodes: "New episodes",
  preferences: "Preferences",
  newsletterSybel: "Subscribe to Sybel Newsletter",
  discover: "DISCOVER NOW",
  signupFailIdenticalMail: "Uhoh... the email addresses are not identical",
  signupFailMail: "The email address do not seem correct, please try again",
  failBirthday: "Your birhtdate do not seem correct, please try again",
  empty: "At least one of the fields is empty",
  signupFailPasswordLength: "Hmm... your password must be 6-characters long",
  signupFail:
    "An account already uses this email address. Please try to sign in instead.",
  recoverFailPasswordDifferent: "The two passwords do not match",
  forgotPasswordAlert:
    "We've sent and email to {{email}}. If an account exists with this address, you'll receive a link to reset your password.",
  emailAlert:
    // eslint-disable-next-line
    "We've sent an email to {{email}}.",
  signinFail:
    "Your signin information are wrong, please try again or reset your password",
  forgotPassword: "Forgotten password",
  forgotPasswordTitle: "Reset your password",
  forgotPasswordParagraph:
    "Enter your email address, we'll send you a link to reset your password if our email exists in our database",
  discoverTitle: "Imagination now on-demand",
  newsletter: "Receive our news, offers, and recommendations by email",
  cgu1: "By clicking on the button below you agree to accept our",
  cgu2: "Terms and Conditions",
  cgu3: `. Your subscription starts at the time you choose an offer and set a payment method. Your subscription will automatically be renewed unless you previously deactivated this option from your account settings at least 24h before the renewing date.`,
  cgu3Culture: `. Your subscription starts at the time you choose an offer and set a payment method. While subscribing and at the end of any potential trial period, you acknowledge that you renunciate to your right of withdrawal as specified in the Directive 2011/83/EU of the European Parliament and of the council of 25 October 2011.`,
  changeOffer: "SWITCH",
  continue: "Continue",
  then: "then",
  per: "pour", //TODO
  addProfileHomeTitle: "Add another profile",
  addProfileHomeButton: "Add an adult",
  days: "jours", //TODO
  month: "mois", //TODO
  year: "année", //TODO
  remaining: "Il reste", //TODO
  addKidsProfile: "Add a KiD profile",
  comingSoon: "Available soon...",
  comingSoonEpisode: "Available on",
  popUpTitle: "Hundred of audio series to discover",
  popUpTitleFree: ", for free during",
  seriesContextPopUpTitleNoTrial: "Subscribe now to listen to",
  sampleFrom: "Trailers of ",
  comingNext: "Coming next ",
  mainUserTitle: "Pseudo*",
  emptyDate: "DD/MM/YYYY",
  sleepAfter: "SLEEP MODE",
  endOfEpisode: "The end of the episode",
  oneHour: "hour",
  minutes: "minutes",
  disableSleep: "Deactivate sleep mode",
  episodesList: "Episodes",
  interfaceLanguage: "Interface language",
  contentLanguage: "Content Language",
  seriesContextPopUpTitle: "Activate your free trial now to listen to", //TODO COMMENT: ATTENTION ICI SI PAS DE FREE TRIAL QUOI AFFICHER
  trialPremium: "DAY OF FREE TRIAL", //TODO
  trialPremium_plural: "DAYS OF FREE TRIAL", //TODO

  explicitEmailSubtitle:
    "Please add an email first to your account to retrieve you parental controls PIN in case you forget it",
  explicitCodeEditionSubtitle:
    "To edit your parental controls PIN, type your current code, and then the new one",
  explicitCodeDeleteSubtitle:
    "Your current parental controls PIN is required to remove the code from your account.",
  explicitCodeCreationSubtitle: "Create parental controls PIN for more safety",
  chooseNumbers: "(Pick 4 digits)",
  typeNumbers: "(Type 4 digits)",
  wrongCode: "Wrong code, please try again",
  forgotCode: "Forgot your code?",
  codeSended: "We've sent you an email email with your parental controls PIN",
  createCode: "CREATE A PARENTAL CONTROLS PIN",
  modifyCode: "Edit parental controls PIN",
  codeParental: "Parental controls PIN",
  currentCode: "Current parental controls PIN:",
  newCode: "New parental controls PIN:",
  parentalCode: "Parental controls PIN",
  promoCodeSubTitle: "Activate a promo coupon",
  deleteCode: "Delete parental controls PIN",
  codeCreated: "Your parental controls PIN have been created",
  codeDeleted:
    "Your parental controls PIN has been removed. +16yo and +18yo are now accessible from all adult profiles.",
  codeModified: "Your parental controls PIN has been updated",
  codeError:
    "An error occurred, contact support team if if this issue persists", //TODO
  yearly: "Yearly",
  monthly: "Monthly",
  yearUnit: "year", //TODO
  noSubscription: "Uhoh... you're currently not subscribed.",
  discoverOffers: "Discover our offers",
  letsGo: "Let's go!",
  operatorActivate: "ACTIVATE SYBEL+ OPTION WITH YOUR PHONE OPERATOR",
  operatorActivateWording:
    "Choose the phone operator you want to activate a Sybel+ option with :",
  redBySfr: "RED by SFR",
  sfr: "SFR",
  deleteAccount: "Delete your account",
  delete: "DELETE",
  deleteLabel: 'Type "DELETE" to continue',
  deleteAccountSuccess: "We miss you already 😢",
  deleteAccountSubscribed:
    "You cannot delete your account because your subscription is still running",
  changeLanguage: "Switch language",
  fr: "Français",
  es: "Español",
  en: "English",
  notSubscribedSettingsOffers:
    "All features and an unlimited access from only ",
  notSubscribedSettingsOffersEmphase: "3,99€/month!",
  notSubscribedSettingsCoupon:
    "Gift card or partner coupon, activate your access to ",
  notSubscribedSettingsCouponEmphase: "Sybel+ now!",
  notSubscribedSettingsCouponCta: "ACTIVATE A COUPON",
  notSubscribedSettingsSfr: "An access to Sybel+ from only ",
  notSubscribedSettingsSfrEmphase: "2€/month",
  notSubscribedSettingsSfrPostEmphase: " with your SFR option!",
  notSubscribedSettingsSfrCta: "ACTIVATE the option",
  coupon: "ACTIVATE A COUPON",
  couponWording:
    "Enter and activate you gift card or partner coupon, we will credit your account with months of access after verification.",
  couponWordingBefore:
    "Validez le code puis créez un compte pour profiter de l’offre",
  couponFail: "This coupon doesn't exist, try again 🤷‍♀️",
  couponSuccess: "The coupon has been successfully applied! 🤗",
  couponClick: "Applied",
  couponAlreadySubscribed:
    "You are already subscribed, you cannot benefit from this coupon",
  couponNotCorrect:
    "Wrong coupon, please verify the entry or copy/paste the coupon you've received to avoid errors",
  couponConflict:
    "This coupon doesn't apply to this offer, switch offers to pursue",
  couponConflictToast: "Promotion not valid for this offer ",
  couponExpired: "This coupon has expired, try with another coupon to continue",
  couponUsed:
    "This coupon has already been applied, try with another coupon to continue",
  nextBill: "Next billing period starts on",
  total: "Total billed",
  period: "Period",
  billingDate: "Billing date",
  from: "from",
  to: "to",
  editProfile: "Edit profiles",
  editProfileSubtitle: "Click on the profile you want to edit.",
  chooseAvatar: "Pick an avatar",
  chooseAvatarSubTitle: "Click on your favorite avatar",
  seriesCopy: "Copied to clipboard",
  seriesShareMobile: "Share",
  seriesPagePlay: "Sample",
  seriesPagePause: "Pause",
  min: "min",
  home: "Homepage",
  premium: "Sybel+",
  profileManagment: "Manage profiles",
  openSybel: "Open Sybel",
  bonus: "bonus",
  shortSeason: "S",
  shortEpisode: "E",
  cardNumberPlaceholder: "Card number",
  expirationCardPlaceholder: "Expiration date",
  cvcCardPlaceholder: "CVV",
  discountPaymentPlaceholder: "Discount coupon?",
  ok: "OK",
  sybelPlusWelcome: "Welcome to Sybel+,",
  sybelPlusSeries: "profitez dès maintenant de votre abonnement en écoutant ", //TODO
  sybelPlusNoContext:
    "You're about to discover audio content as you never listened to it before. These are not podcasts or audiobooks, but audioseries with immersive sound design and acting with which you'll uncover unexplored areas of your imagination, ready? Listen carefully.",
  billboardDiscover: "Discover the series",
  searched: "Résultat de recherche pour ", // TODO
  searched_plural: "Résultats de recherche pour ", // TODO
  noResult: "Uhoh... No results found for ", //TODO
  life: "Lifelong subscription",
  lifeLong: "Lifelong unlimited access to Sybel+",
  lifePayment: "Lifelong subscription",
  cultureCta: "(50% donated to the culture)",
  cultureTitle: "SPECIAL OFFER TO SUPPORT THE CULTURE",
  paymentHasAnAccountTitle: "Hmmm...",
  paymentHasAnAccountParagraphe:
    "You're already subscribed to our services, you cannot add another subscription to your acccount",
  durring: "for",
  firstMonth: "the 1rst month, then",
  firstYear: "the first year",
  changeCreditCardCTA: "Edit payment method",
  changeCreditCardGood: "Your payment method has been successfully updated",
  cookiesTitle: "WE NEED YOUR CONSENT 🍪",
  cookiesSubTitle1: "Cookies and other trackers used by Sybel and their",
  cookiesSubTitle2: "partners",
  cookiesSubTitle3:
    "are used to:  mesure and analyze Sybel audience and our ads, personalize content and the service according to your interests and allow you to sign in with your social media and share content on them.",
  cookiesSubTitle4: "To read more about it, visit our",
  cookiesSubTitle5: "cookies policy.",
  cookiesSubTitle6:
    "You can express or withdraw your consent at any moment by clicking on the link “Manage cookies” on the bottom of your app Settings.",
  cookiesAllowAll: "Accept all",
  cookiesDisableAll: "Deny all",
  cookiesSettingsCTA: "Manage cookies",
  cookiesSettingsTitle: "Manage cookies 🍪",
  cookiesSettingsSubTitle: "We need your consent for the cookies use below:",
  cookiesSettingsTitleCantDisable: "Mandatory cookies for Sybel operation",
  cookiesSettingsTitleCantDisableParagraph:
    "These cookies are mandatory for Sybel services to function, they cannot be unswitched as you would not be able to login to Sybel and use the app without them.",
  cookiesSettingsTitleCantDisableMoreInformations:
    "Read more about mandatory cookies",
  cookiesSettingsTitleCantDisableMoreInformationsListTitle:
    "The cookies we use enable us to:",
  cookiesSettingsTitleCantDisableMoreInformationsList1:
    "Recognize you when you log in Sybel app.",
  cookiesSettingsTitleCantDisableMoreInformationsList2:
    "Display you some of our features, content or services you might be interested and propose you ads related to our content.",
  cookiesSettingsTitleCantDisableMoreInformationsList3:
    "Recommend you content according to what you listened and liked before.",
  cookiesSettingsTitleCantDisableMoreInformationsList4:
    "Prevent fraudulent behaviors.",
  cookiesSettingsTitleCantDisableMoreInformationsList5:
    "Enhance our service security, including secured payment.",
  cookiesSettingsTitleCantDisableMoreInformationsList6:
    "Ensure the to keep your browsing preferences between two sessions like interface and content language.",
  cookiesSettingsTitleCantDisableMoreInformationsListFooter:
    "We also use cookies to understand how you use Sybel to improve your experience. For example, we use cookies to make anonymous search and statistics and diagnose behaviors on our app to improve the content, service and measure Sybel performances.",
  cookiesAddsTitle: "Ads management cookies",
  cookiesAddsParagraphe:
    "These cookies help us record actions (e.g. purchase a subscription) after you clicked, seen or interact with one of Sybel ads on other tier platform to measure their efficiency and performances.",
  cookiesSocialNetworkTitle: "Social networks cookies",
  cookiesSocialNetworkParagraphe:
    "Thanks to these cookies you'll be able to interact with your social network directly from ou application, signin on Sybel with your social networks and share content with your community by clicking on the dedicated share buttons.",
  cookiesSettingsValidation: "Confirm setup",
  signupSuccessMail: "The email address seems correct",
  signupFailIdenticalMailOnChange: "The email addresses are not identical",
  manageCookies: "Manage cookies",
  defaultYearPrice: "{{price}} / year",
  defaultMonthPrice: "{{price}} / month",
  temporaryYearReduction:
    "{{reducedPrice}} for {{duration}} ans then {{price}} / year",
  temporaryMonthReduction:
    "{{reducedPrice}} for {{duration}} months then {{price}} / month",
  temporaryOneYearReduction:
    "{{reducedPrice}} the first year then {{price}} / year",
  temporaryOneMonthReduction:
    "{{reducedPrice}} the first month {{price}} / month",
  YearlyOfferUnitaryPrice: "billed {{price}}€ annually",
  summerOffer: "SUNNY DAYS SPECIAL OFFER",
  beginTrialPremium: "Activer mon {{count}} jour gratuits", //TODO
  beginTrialPremium_plural: "Activer mes {{count}} jours gratuits", //TODO

  YearlyPaymentUnitaryPrice: "{{price}} / month, billed annually",
  permanentYearPaymentPrice: "{{newPrice}} / an au lieu de {{price}}", //TODO

  permanentMonthPaymentPrice: "{{newPrice}} / mois au lieu de {{price}}", //TODO
  maillinglist:
    "We unsubscribed the email <strong><em>{{email}}</em></strong> from Sybel newsletter mailing list, you will not receive any other offer or recommendation on this address.",
  maillinglistChoice:
    "If you want to subscribe again later, you can still change your preferences from your app settings.",
  discoverApp: "Découvrir L’app mobile", //TODO
  freeInSettings: "Associer mon compte Sybel à ma Freebox", //TODO
  freeUnlinkInSettings: "Dissocier mon compte Sybel de ma Freebox", //TODO
  freeUnlinkInSettingsSuccess: "Votre compte a été dissocié avec succès", //TODO
  assiociateFreeSuccess:
    "Bravo, vous pouvez désormais profiter de Sybel sur tous vos appareils compatibles : mobile, web, assistants vocaux ou tv !", //TODO
  freeSignin: "UTILISEZ VOTRE MéTHODE DE CONNEXION HABITUELLE", //TODO
  associateFreeDescription:
    "Associez un compte Sybel pour profiter de votre abonnement sur la FreeBox et toutes nos autres applications. Entrez le code d’association qui s’affiche sur votre TV pour lier un compte à votre application FreeBox :", //TODO
  activateFreeLinkEmailDescription:
    "Associer un compte Sybel existant à ma Freebox", //TODO
  activateFreeAddEmailDescription:
    "Ajouter un email pour profiter de mon abonnement Free sur toutes les plateformes", //TODO
  activateFreeLinkEmailCta: "ASSOCIER MON COMPTE", //TODO
  activateFreeAddEmailCta: "AJOUTER UN EMAIL", //TODO
  FreeErrorBothAccountsAreSubscribed:
    "Nous ne pouvons associer ces deux comptes car ils sont tous deux abonnés, veuillez vous déconnecter et choisir un autre compte sans abonnement.", //TODO
  FreeErrorNoFreeAccount:
    "Le code est invalide ou vous avez quitté le mode association de votre application Freebox. Veuillez vérifier l’écran de votre TV.", //TODO
  freeSubscription: "Vous vous êtes abonné via notre partenaire",
  freeSubscriptionTwo: "Free",
  freeSubscriptionThree: "rendez-vous dans votre",
  freeSubscriptionFour: "Freebox",
  subscriptionInsert: "ECOUTER SYBEL +", //TODO
  userCanaccessToAFreeTrial: "ESSAYER DURANT {{count}} JOUR", //TODO
  userCanaccessToAFreeTrial_plural: "ESSAYER DURANT {{count}} JOURS", //TODO
  popupNewSession: "ESSAYER SYBEL+", //TODO
  popupNewSessionDays: "{{count}} jours gratuits*", //TODO
  popupNewSessionPrice:
    "*{{count}} jours d’essai gratuit, sans engagemet, résiliation à tout moment, puis {{price}}€ / an.", //TODO
  popUpNewSessionCta: "essayer Sybel +", //TODO
  categoryInsert: "Essayez gratuitement Sybel+ pendant {{count}} jours* !", //TODO
  categoryInsertPoint1:
    "Découvrez + de 1000 heures d'écoute de livres audio et séries exclusives.", //TODO
  categoryInsertPoint2: "Chaque semaine des nouveautés vous attendent.", //TODO
  categoryInsertPoint3:
    "Créer jusqu'à 5 profils, et profitez du mode enfant pour un espace dédié et sécurisé.", //TODO
  categoryInsertPoint4:
    "Profitez de votre abonnement partout: sur mobiles, ordinateurs, tablettes, enceintes connectées, FreeBox...", //TODO
  categoryInsertPoint5:
    "Et tout le temps, téléchargez vos séries favorites pour une écoute hors-ligne", //TODO
  categoryInsertButton: "démarrer mon ESSAI GRATUIT", //TODO
  radioTitle: "Découvrez gratuitement les  séries Sybel", //TODO
  radioSubTitle: "Cliquez pour démarrer la radio de votre choix", //TODO
  radioKids: "SYBEL RADIO POUR ENFANT", //TODO
  radioAdult: "SYBEL RADIO", //TODO
  popupPomote1: "Marre des vidéos sur les réseaux sociaux ?", //TODO
  popupPomote2: "Marre de perdre son temps ?", //TODO
  popupPomote3:
    "Découvrez des heures de documentaires et de fictions audio originales et exclusives pour se cultiver, apprendre et se divertir", //TODO
  popupPomote4: "Vivez une expérience immersive en illimité et sans publicité", //TODO
  redeemSignup: "Sign up to take advantage of the offer",

  redeemArgTitle1: "Thousands of hours of listening",
  redeemArgParagraph1:
    "Premiere and exclusive episodes of your podcasts, documentaries & audiobooks",
  redeemArgTitle2: "Contents for children",
  redeemArgParagraph2: "Stories sorted by age on a secured profile",
  redeemArgTitle3: "+100 original creations",
  redeemArgParagraph3: "With 3D sound & exceptional castings",
  redeemArgTitle4: "Support your favorite podcasters",
  redeemArgParagraph4:
    "70% of your subscription is donated to content creators",
  buyashare: "INVEST ON THIS CONTENT",
  learnMore: "More…",
};

export default phrases;
