import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";

export const UseChildRestriction = (id: string | undefined) => {
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const history = useHistory();
  const { profile, series } = useSelector((state: StoreType) => ({
    ...state.ProfileReducer,
    ...state.SeriesReducer,
  }));
  useEffect(() => {
    if (id && series[id]) {
      if (
        series[id!].isChild === false &&
        profile &&
        profile.isChild === true
      ) {
        stableDispatch({ type: Case.CLOSE_SERIES });
        history.push("/home");
  }}}, [profile, history, id, series, stableDispatch]);
};
