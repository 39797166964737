import React, {
  Dispatch,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "./services/UserService";
import { language } from "./stores/18nStore";
import { notificationsParam } from "./utils/ReactNotificationStaticOptions";
import Auth from "./views/Auth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import { Action, Case } from "./interfaces/ReducerCase";
import ReactPixel from "react-facebook-pixel";
import { getProfiles } from "./services/ProfileService";
import ProfileType from "./interfaces/ProfileType";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StoreType from "./interfaces/StoreType";
import { isMobile } from "react-device-detect";
import Cookies from "./components/Cookies";
import PartnerConnect from "./components/PartnerConnect";
import Player from "./components/Player";
import { Beforeunload } from "react-beforeunload";
import TagManager from "react-gtm-module";
import amplitude from "amplitude-js";
import IdleTimer from "react-idle-timer";
import LoaderFullScreen from "./components/LoaderFullScreen";
import Home from "./views/Home";
import Series from "./components/Series";
import Liste from "./views/Liste";
import { v4 as uuidv4 } from "uuid";
import {
  getEpisodesHistoryService,
  getSeriesHistoryService,
} from "./services/SeriesService";
import EpisodeHistoryType from "./interfaces/EpisodeHistoryType";
import SeriesHistoryType from "./interfaces/SeriesHistoryType";
import Offers from "./views/Offers";
import Payment from "./views/Payment";
import { UseSeriesLanguage } from "./ressources/UseSeriesLanguage";
import SsoSfr from "./views/SsoSfr";
import Sso from "./views/Sso";

declare global {
  interface Window {
    fbAsyncInit: Function;
  }
}

const tagManagerArgs = {
  gtmId: "GTM-N74PBBV",
};
TagManager.initialize(tagManagerArgs);
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init("1660224797364416", undefined, options);

const ForgotedPassword = React.lazy(() => import("./views/ForgotedPassword"));
const RecoverPassword = React.lazy(() => import("./views/RecoverPassword"));
const SetupProfile = React.lazy(() => import("./views/SetupProfile"));
const Settings = React.lazy(() => import("./views/Settings"));
const ChangePassword = React.lazy(() => import("./views/ChangePassword"));
const ChangeEmail = React.lazy(() => import("./views/ChangeEmail"));
const AddEmail = React.lazy(() => import("./views/AddEmail"));
const EmailVerification = React.lazy(() => import("./views/EmailVerification"));
const AddPinCode = React.lazy(() => import("./views/AddPinCode"));
const DeletePinCode = React.lazy(() => import("./views/DeletePinCode"));
const ChangePinCode = React.lazy(() => import("./views/ChangePinCode"));
const BillingHistory = React.lazy(() => import("./views/BillingHistory"));
const ChooseProfile = React.lazy(() => import("./views/ChooseProfile"));
const EditProfile = React.lazy(() => import("./views/EditProfile"));
const Redeem = React.lazy(() => import("./views/Redeem"));
const RedeemSuccess = React.lazy(() => import("./views/RedeemSuccess"));
const PaymentSuccess = React.lazy(() => import("./views/PaymentSuccess"));
const OffersCulture = React.lazy(() => import("./views/OffersCulture"));
const PaymentCulture = React.lazy(() => import("./views/PaymentCulture"));
const HasAnAccount = React.lazy(() => import("./views/HasAnAccount"));
const VerifyEmail = React.lazy(() => import("./views/VerifyEmail"));
const VerifiedEmail = React.lazy(() => import("./views/VerifiedEmail"));
const ChangeCreditCard = React.lazy(() => import("./views/ChangeCreditCard"));
const UnsubRedirect = React.lazy(() => import("./views/UnsubRedirect"));
const Unsub = React.lazy(() => import("./views/Unsub"));
const ActivateFree = React.lazy(() => import("./views/ActivateFree"));
const ActivateFreeAssociate = React.lazy(
  () => import("./views/ActivateFreeAssociate")
);
const ActivateFreeSuccess = React.lazy(
  () => import("./views/ActivateFreeSuccess")
);
const ActivateFreeSignin = React.lazy(
  () => import("./views/ActivateFreeSignin")
);
const ActivateFreeSignup = React.lazy(
  () => import("./views/ActivateFreeSignup")
);
const Radio = React.lazy(() => import("./views/Radio"));
/* const Week = React.lazy(() => import("./views/Week")); */

function App() {
  const [stipePromise] = useState(() =>
    loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`)
  );
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const {
    user,
    userContentLanguage,
    isLoading,
    currentEpisode,
    isTCValidated,
    authIsLoading,
    isSeriesOpen,
    playerIsHidded,
    isPlaying,
    uuid,
    volume,
    playerIsLoading,
  } = useSelector((state: StoreType) => ({
    ...state.UserReducer,
    ...state.RouterReducer,
    ...state.PlayerReducer,
    ...state.AuthReducer,
  }));
  useEffect(() => {
    if (isTCValidated && isTCValidated.adds) {
      ReactPixel.grantConsent();
    }
  }, [isTCValidated]);
  useEffect(() => {
    if (!uuid) {
      if (localStorage && !localStorage.getItem("uuid")) {
        const newUuid = uuidv4();
        localStorage.setItem("uuid", newUuid);
        stableDispatch({ type: Case.SET_UUID, payload: { uuid: newUuid } });
      } else {
        stableDispatch({
          type: Case.SET_UUID,
          payload: { uuid: localStorage.getItem("uuid") },
        });
      }
    } else {
      return;
    }
  }, [stableDispatch, uuid]);

  useEffect(() => {
    if (!uuid) {
      if (localStorage && !localStorage.getItem("uuid")) {
        const newUuid = uuidv4();
        localStorage.setItem("uuid", newUuid);
        stableDispatch({ type: Case.SET_UUID, payload: { uuid: newUuid } });
      } else {
        stableDispatch({
          type: Case.SET_UUID,
          payload: { uuid: localStorage.getItem("uuid") },
        });
      }
    } else {
      return;
    }
  }, [stableDispatch, uuid]);
  useEffect(() => {
    /**
     * GET User if token exsiste on app mount
     */
    const initalFetch = async () => {
      stableDispatch({ type: Case.IS_GLOBAL_LOADING });
      try {
        const res = await getUser(
          JSON.parse(localStorage.getItem("user")!) &&
            JSON.parse(localStorage.getItem("user")!)._id,
          JSON.parse(localStorage.getItem("user")!).token
        );
        if (res.status === 403) {
          localStorage.removeItem("user");
          localStorage.removeItem("popup");
          sessionStorage.removeItem("profile");
          sessionStorage.removeItem("nextUrl");
          amplitude.getInstance().clearUserProperties();
          stableDispatch({ type: Case.SIGNOUT });
        }
        const userData: any = await res.json();
        stableDispatch({ type: Case.UPDATE_USER, payload: { user: userData } });
        const profilePending = await getProfiles({
          token: JSON.parse(localStorage.getItem("user")!).token,
          ...userData,
        });
        const profileRes: ProfileType[] = await profilePending.json();
        stableDispatch({
          type: Case.SET_PROFILES,
          payload: { profiles: profileRes },
        });
        stableDispatch({ type: Case.IS_GLOBAL_NOT_LOADING });
      } catch (error: any) {
        stableDispatch({ type: Case.IS_GLOBAL_NOT_LOADING });
        return toast(language("codeError"), notificationsParam);
      }
    };
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")!)._id &&
      JSON.parse(localStorage.getItem("user")!).token
    ) {
      initalFetch();
    }
  }, [stableDispatch]);

  useEffect(() => {
    const getHistory = async () => {
      const ac = new AbortController();
      const { signal } = ac;
      try {
        const episodehistoryPending = await getEpisodesHistoryService(
          JSON.parse(localStorage.getItem("user")!),
          JSON.parse(sessionStorage.getItem("profile")!),
          userContentLanguage,
          signal
        );
        const episodehistoryData: EpisodeHistoryType[] =
          await episodehistoryPending.json();
        let episodereorganizedHistory: any = {};
        episodehistoryData.map((each) => {
          return (episodereorganizedHistory = {
            ...episodereorganizedHistory,
            [each.seriesId]: {
              ...episodereorganizedHistory[each.seriesId],
              [each.episodeId]: each,
            },
          });
        });
        const historyPending = await getSeriesHistoryService(
          JSON.parse(localStorage.getItem("user")!),
          JSON.parse(sessionStorage.getItem("profile")!),
          userContentLanguage,
          signal
        );
        const historyData: SeriesHistoryType[] = await historyPending.json();
        let reorganizedHistory: any = {};
        historyData.map((each) => {
          return (reorganizedHistory = {
            ...reorganizedHistory,
            [each.series._id]: each,
            _id: each.series._id,
          });
        });
        stableDispatch({
          type: Case.PUSH_SERIES_HISTORY,
          payload: {
            seriesHistory: reorganizedHistory,
          },
        });
        stableDispatch({
          type: Case.PUSH_EPISODES_HISTORY,
          payload: {
            seriesHistory: episodereorganizedHistory,
          },
        });
      } catch (e) {
        console.log(e);
      }
    };
    if (
      !!sessionStorage.getItem("profile") &&
      !!JSON.parse(sessionStorage.getItem("profile")!) &&
      !!localStorage.getItem("user") &&
      !!JSON.parse(localStorage.getItem("user")!)
    ) {
      getHistory();
    }
  }, [stableDispatch, userContentLanguage]);
  useEffect(() => {
    if (!!user && !!user.token && !!user._id) {
      amplitude.getInstance().logEvent("Start Session");
    } else return;
  }, [user]);
  const { seriesLanguage } = UseSeriesLanguage();

  useEffect(() => {
    const reloadOnError = (e) => {
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        window.location.reload();
      }
    };
    window.addEventListener("error", reloadOnError);
    return () => {
      window.removeEventListener("error", reloadOnError);
    };
  }, []);

  return (
    <Elements stripe={stipePromise}>
      <Router>
        <div
          className="main-warpper"
          style={{
            marginBottom:
              currentEpisode &&
              user &&
              userContentLanguage !== null &&
              !isLoading &&
              !playerIsHidded
                ? "80px"
                : "0",
            display: isSeriesOpen && isMobile ? "none" : "flex",
            overflowY: isTCValidated ? "scroll" : "hidden",
          }}
        >
          {/*  {!isTCValidated && <Cookies />} */}
          <IdleTimer
            timeout={1000 * 60 * 5}
            onIdle={(e) => {
              if (isPlaying) return;
              else {
                amplitude.getInstance().logEvent("End Session");
              }
            }}
            onActive={() => {
              if (isPlaying) return;
              else if (user.token) {
                amplitude.getInstance().logEvent("Start Session");
              }
            }}
          />
          <Switch>
            <Route exact path={"/activate-free"}>
              <Suspense fallback={<LoaderFullScreen />}>
                <ActivateFree />
              </Suspense>
            </Route>
            <Route exact path={"/activate-free/signup"}>
              <Suspense fallback={<LoaderFullScreen />}>
                <ActivateFreeSignup />
              </Suspense>
            </Route>
            <Route exact path={"/activate-free/signin"}>
              <Suspense fallback={<LoaderFullScreen />}>
                <ActivateFreeSignin />
              </Suspense>
            </Route>
            <Route exact path={"/activate-free/associate"}>
              <Suspense fallback={<LoaderFullScreen />}>
                <ActivateFreeAssociate />
              </Suspense>
            </Route>
            <Route exact path={"/activate-free/success"}>
              <Suspense fallback={<LoaderFullScreen />}>
                <ActivateFreeSuccess />
              </Suspense>
            </Route>
            <Route path={"/unsubscribe-mailling-list"}>
              <Suspense fallback={<LoaderFullScreen />}>
                <Unsub />
              </Suspense>
            </Route>
            <Route path={"/unsub/:email/:id"}>
              <Suspense fallback={<LoaderFullScreen />}>
                <UnsubRedirect />
              </Suspense>
            </Route>
            <Route
              path={["/signup", "/signin"]}
              render={(params) => {
                return user.token &&
                  !authIsLoading &&
                  !new URLSearchParams(params.location.search).get("renault") &&
                  !sessionStorage.getItem("renault") ? (
                  <Redirect to="/home" />
                ) : (
                  <Suspense fallback={<LoaderFullScreen />}>
                    <Auth
                      renault={
                        new URLSearchParams(params.location.search).get(
                          "renault"
                        ) || sessionStorage.getItem("renault")
                          ? true
                          : false
                      }
                    />
                  </Suspense>
                );
              }}
            />
            <Route path="/partner-connect">
              <Suspense fallback={<LoaderFullScreen />}>
                <PartnerConnect />
              </Suspense>
            </Route>
            <Route path="/user/verify">
              <Suspense fallback={<LoaderFullScreen />}>
                <VerifyEmail />
              </Suspense>
            </Route>
            <Route path="/email-verified">
              <Suspense fallback={<LoaderFullScreen />}>
                <VerifiedEmail />
              </Suspense>
            </Route>
            <Route
              render={({ location }) => {
                return (
                  <Suspense fallback={<LoaderFullScreen />}>
                    <Auth
                      renault={
                        new URLSearchParams(location.search).get("renault") ||
                        sessionStorage.getItem("renault")
                          ? true
                          : false
                      }
                    />
                  </Suspense>
                );
              }}
              path="/auth/sign-in"
            ></Route>
            <Route path="/auth/recover-password">
              <Suspense fallback={<LoaderFullScreen />}>
                <RecoverPassword />
              </Suspense>
            </Route>
            <Route
              path="/auth/apple"
              render={({ location }) => (
                <Suspense fallback={<LoaderFullScreen />}>
                  <Auth
                    renault={
                      new URLSearchParams(location.search).get("renault") ||
                      sessionStorage.getItem("renault")
                        ? true
                        : false
                    }
                  />
                </Suspense>
              )}
            />

            <Route path="/auth">
              {user.token ? <Redirect to="/home" /> : <Redirect to="/signup" />}
            </Route>
            <Route path="/forgot-password">
              {user.token ? (
                <Redirect to="/settings" />
              ) : (
                <Suspense fallback={<LoaderFullScreen />}>
                  <ForgotedPassword />
                </Suspense>
              )}
            </Route>
            {/*             <Route path="/gift">
              <Gift />
            </Route> */}

            <Route exact path="/choose-profile">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <ChooseProfile />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/choose-profile" },
                  }}
                />
              )}
            </Route>
            <Route path="/choose-profile/edit">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <EditProfile />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/choose-profile" },
                  }}
                />
              )}
            </Route>
            <Route path="/setup-profile">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <SetupProfile />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/setup-profile" },
                  }}
                />
              )}
            </Route>
            <Route
              path={"/offers/culture"}
              render={(props) =>
                user.token ? (
                  <Suspense fallback={<LoaderFullScreen />}>
                    <OffersCulture />
                  </Suspense>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/signin",
                      state: {
                        nextUrl: props.match.params.idparams
                          ? `/offers/${props.match.params.idparams}`
                          : "/offers",
                      },
                    }}
                  />
                )
              }
            />
            <Route
              path={"/offers"}
              render={(props) =>
                user.token ? (
                  <Suspense fallback={<LoaderFullScreen />}>
                    <Offers />
                  </Suspense>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/signin",
                      state: {
                        nextUrl: props.match.params.idparams
                          ? `/offers/${props.match.params.idparams}`
                          : "/offers",
                      },
                    }}
                  />
                )
              }
            />
            <Route
              path={"/payment/culture/:idparams"}
              render={(props) =>
                user.token ? (
                  <Suspense fallback={<LoaderFullScreen />}>
                    <PaymentCulture />
                  </Suspense>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/signup",
                      state: {
                        nextUrl: props.match.params.idparams
                          ? `/payment/culture/${props.match.params.idparams}`
                          : "/offers",
                      },
                    }}
                  />
                )
              }
            />
            <Route path="/payment/failed">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <HasAnAccount />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/offers" },
                  }}
                />
              )}
            </Route>
            <Route path="/payment/success">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <PaymentSuccess />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/offers" },
                  }}
                />
              )}
            </Route>
            <Route
              path="/payment/:id"
              render={(props) => {
                return user.token ? (
                  <Suspense fallback={<LoaderFullScreen />}>
                    <Payment />
                  </Suspense>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/signup",
                      state: {
                        nextUrl: props.location.search
                          ? `/payment/${props.match.params.id}${props.location.search}`
                          : `/payment/${props.match.params.id}`,
                      },
                    }}
                  />
                );
              }}
            />
            <Route path="/home">
              {user.token ? (
                <Home />
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                  }}
                />
              )}
            </Route>
            <Route path="/my-list">
              {user.token ? (
                <Liste />
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/my-list" },
                  }}
                />
              )}
            </Route>
            {/*  <Route path="/search">
              {user.token ? (
                <Search />
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                  }}
                />
              )}
            </Route> */}
            <Route
              path="/redeem"
              render={(props) => {
                return user.token ||
                  (new URLSearchParams(props.location.search).get("campain") &&
                    new URLSearchParams(props.location.search).get("code")) ? (
                  <Suspense fallback={<LoaderFullScreen />}>
                    <Redeem />
                  </Suspense>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/signup",
                      state: {
                        nextUrl:
                          new URLSearchParams(props.location.search).get(
                            "campain"
                          ) &&
                          new URLSearchParams(props.location.search).get("code")
                            ? "/redeem?code=" +
                              new URLSearchParams(props.location.search).get(
                                "code"
                              ) +
                              "&campain=" +
                              new URLSearchParams(props.location.search).get(
                                "campain"
                              )
                            : "/redeem",
                        campain: new URLSearchParams(props.location.search).get(
                          "campain"
                        )
                          ? new URLSearchParams(props.location.search).get(
                              "campain"
                            )
                          : undefined,
                        code: new URLSearchParams(props.location.search).get(
                          "code"
                        )
                          ? new URLSearchParams(props.location.search).get(
                              "code"
                            )
                          : undefined,
                      },
                      search: new URLSearchParams(props.location.search).get(
                        "campain"
                      )
                        ? "?campain=" +
                          new URLSearchParams(props.location.search).get(
                            "campain"
                          )
                        : "",
                    }}
                  />
                );
              }}
            />

            <Route path="/redeem-success">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <RedeemSuccess />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/redeem" },
                  }}
                />
              )}
            </Route>
            <Route path="/promos">
              <Redirect
                to={{
                  pathname: "/redeem",
                  state: { nextUrl: "/redeem" },
                }}
              />
            </Route>
            <Route
              path="/series/:idparams"
              render={({ match, location }: any) =>
                user.token ? (
                  <>
                    {location && location.state && location.state.likeList ? (
                      <Liste />
                    ) : (
                      <Home isFromSeries />
                    )}
                    <Series />
                  </>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/signin",
                      state: {
                        nextUrl: `/series/${match.params.idparams}`,
                      },
                    }}
                  />
                )
              }
            />

            <Route exact path="/settings">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <Settings />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    state: { nextUrl: "/settings" },
                    pathname: "/signup",
                  }}
                />
              )}
            </Route>
            <Route path="/settings/change-password">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <ChangePassword />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/settings" },
                  }}
                />
              )}
            </Route>
            <Route path="/settings/change-email">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <ChangeEmail />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/settings" },
                  }}
                />
              )}
            </Route>
            <Route path="/settings/change-credit-card">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <ChangeCreditCard />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/settings/change-credit-card" },
                  }}
                />
              )}
            </Route>
            <Route path="/settings/add-email">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <AddEmail />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/settings" },
                  }}
                />
              )}
            </Route>
            <Route path="/settings/billing-history">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <BillingHistory />
                </Suspense>
              ) : (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { nextUrl: "/settings/billing-history" },
                  }}
                />
              )}
            </Route>
            <Route path="/settings/verify-email">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <EmailVerification />
                </Suspense>
              ) : (
                <Redirect to="/signup" />
              )}
            </Route>
            <Route path="/settings/add-pin-code">
              {user.token ? <AddPinCode /> : <Redirect to="/signup" />}
            </Route>
            <Route path="/settings/delete-pin-code">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <DeletePinCode />
                </Suspense>
              ) : (
                <Redirect to="/signup" />
              )}
            </Route>
            <Route path="/radio">
              <Suspense fallback={<LoaderFullScreen />}>
                <Radio />
              </Suspense>
            </Route>
            <Route
              path="/renault-activated"
              render={() => {
                sessionStorage.removeItem("renault");
                return (
                  <div
                    style={{
                      minHeight: "640px",
                      backgroundImage:
                        "url('/image/main-renault-landing-background.png')",
                      padding: "0 30px",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      height: "100vh",
                    }}
                    className="sign-up-sign-in-container"
                  >
                    <div
                      className="sign-up-container"
                      style={{
                        fontSize: "20px",
                        marginTop: "40px",
                        padding: "60px 20px 60px 20px",
                        borderRadius: "22px",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: " blur(10px)",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        WebkitBackdropFilter: "blur(10px)",
                        boxShadow: "0 0 10px #000",
                      }}
                    >
                      <img
                        className="sign-up-logo"
                        src="/image/logo/whitelogo.svg"
                        alt="Sybel logo"
                      />
                      <p
                        style={{
                          color: "#fff",
                          marginTop: "40px",
                          fontWeight: 700,
                        }}
                      >
                        Bienvenue à bord !
                      </p>
                      <h2
                        style={{
                          color: "#fff",
                          marginTop: "20px",
                          fontWeight: 500,
                        }}
                      >
                        Profitez dès maintenant de votre abonnement en vous
                        connectant dans votre voiture et sur tous vos autres
                        appareils.
                      </h2>
                    </div>
                    <p style={{ color: "#fff", margin: "30px 0" }}>OU</p>
                    <a
                      href={
                        "https://play.google.com/store/apps/details?id=co.sybel.android"
                      }
                      className="sign-up-btn"
                      style={{
                        color: "#fff",
                        minWidth: "250px",
                        maxWidth: "350px",
                        padding: "7px 20px",
                        height: "auto",
                        marginBottom: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: " blur(0px)",
                        background: "rgba(0, 0, 0, 0.0)",
                        WebkitBackdropFilter: "blur(0px)",
                        boxShadow: "none",
                        textDecoration: "underline",
                      }}
                    >
                      <svg
                        width="45"
                        height="51"
                        viewBox="0 0 45 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.889716 0.767867C0.325743 1.3756 0 2.30421 0 3.51967V46.6296C0 47.8451 0.325742 48.7737 0.909163 49.3571L1.05988 49.4884L25.2135 25.3348V24.7951L1.04043 0.636597L0.889716 0.767867Z"
                          fill="url(#paint0_linear_1893_6803)"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M33.2504 33.4102L25.1943 25.3542V24.7902L33.2504 16.7341L33.4254 16.8411L42.9546 22.2572C45.6821 23.7935 45.6821 26.3314 42.9546 27.8872L33.4254 33.3033L33.2504 33.4102Z"
                          fill="url(#paint1_linear_1893_6803)"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M33.4251 33.3034L25.194 25.0723L0.889648 49.3766C1.77937 50.3295 3.27195 50.4365 4.93956 49.5079L33.4251 33.3034Z"
                          fill="url(#paint2_linear_1893_6803)"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M33.4251 16.8412L4.93956 0.66098C3.27195 -0.291941 1.7745 -0.16067 0.889648 0.792251L25.194 25.0723L33.4251 16.8412Z"
                          fill="url(#paint3_linear_1893_6803)"
                        />
                        <path
                          opacity="0.25"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.93963 0.942915L42.9544 22.5392C44.1893 23.2345 44.8845 24.1436 44.9915 25.0722C44.9915 24.0561 44.3205 23.0351 42.9544 22.2572L4.93963 0.660928C2.21214 -0.899723 0 0.403251 0 3.51969V3.80168C0 0.680376 2.21214 -0.598288 4.93963 0.942915Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1893_6803"
                            x1="23.047"
                            y1="3.05456"
                            x2="-15.643"
                            y2="13.3635"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00A0FF" />
                            <stop offset="0.00657" stop-color="#00A1FF" />
                            <stop offset="0.2601" stop-color="#00BEFF" />
                            <stop offset="0.5122" stop-color="#00D2FF" />
                            <stop offset="0.7604" stop-color="#00DFFF" />
                            <stop offset="1" stop-color="#00E3FF" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_1893_6803"
                            x1="46.5115"
                            y1="25.0723"
                            x2="-0.662639"
                            y2="25.0723"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#FFE000" />
                            <stop offset="0.4087" stop-color="#FFBD00" />
                            <stop offset="0.7754" stop-color="#FFA500" />
                            <stop offset="1" stop-color="#FF9C00" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_1893_6803"
                            x1="28.9493"
                            y1="29.5512"
                            x2="-2.11075"
                            y2="81.8279"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#FF3A44" />
                            <stop offset="1" stop-color="#C31162" />
                          </linearGradient>
                          <linearGradient
                            id="paint3_linear_1893_6803"
                            x1="-5.23045"
                            y1="-13.5711"
                            x2="8.6267"
                            y2="9.77648"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#32A071" />
                            <stop offset="0.0685" stop-color="#2DA771" />
                            <stop offset="0.4762" stop-color="#15CF74" />
                            <stop offset="0.8009" stop-color="#06E775" />
                            <stop offset="1" stop-color="#00F076" />
                          </linearGradient>
                        </defs>
                      </svg>
                      Telecharger l'App dans votre voiture
                    </a>
                    <Link
                      to={"/home"}
                      className="sign-up-btn"
                      style={{
                        color: "#fff",
                        minWidth: "250px",
                        maxWidth: "350px",
                        padding: "7px 20px",
                        height: "auto",
                        marginBottom: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: " blur(0px)",
                        background: "rgba(0, 0, 0, 0.0)",
                        WebkitBackdropFilter: "blur(0px)",
                        boxShadow: "none",
                        textDecoration: "underline",
                      }}
                    >
                      Continuer Sur Le Web
                    </Link>
                    <a
                      href="https://app.adjust.com/9j9tjng?deep_link=sybel%3A%2F%2Fopen&redirect_macos=https%3A%2F%2Fapp.sybel.co%2F&redirect_windows=https%3A%2F%2Fapp.sybel.co%2F"
                      className="sign-up-btn only-on-mobile"
                      style={{
                        color: "#fff",
                        minWidth: "250px",
                        maxWidth: "350px",
                        padding: "7px 20px",
                        height: "auto",
                        marginBottom: "50px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Telecharger l'app mobile
                    </a>
                  </div>
                );
              }}
            />
            <Route path="/settings/change-pin-code">
              {user.token ? (
                <Suspense fallback={<LoaderFullScreen />}>
                  <ChangePinCode />
                </Suspense>
              ) : (
                <Redirect to="/signup" />
              )}
            </Route>
            <Route path="/sso-sfr">
              <SsoSfr />
            </Route>
            <Route
              exact
              path="/sso/sybel"
              render={(props) => {
                return user.token ? (
                  <Suspense fallback={<LoaderFullScreen />}>
                    <Sso />
                  </Suspense>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/signin",
                      state: {
                        nextUrl: new URLSearchParams(props.location.search).get(
                          "code_challenge"
                        )
                          ? "/sso/sybel?code_challenge=" +
                            new URLSearchParams(props.location.search).get(
                              "code_challenge"
                            ) +
                            "&redirect_uri=" +
                            new URLSearchParams(props.location.search).get(
                              "redirect_uri"
                            ) +
                            "&client_id=" +
                            new URLSearchParams(props.location.search).get(
                              "client_id"
                            )
                          : "/home",
                        search: new URLSearchParams(props.location.search).get(
                          "code_challenge"
                        )
                          ? "?code_challenge=" +
                            new URLSearchParams(props.location.search).get(
                              "code_challenge"
                            ) +
                            "&redirect_uri=" +
                            new URLSearchParams(props.location.search).get(
                              "redirect_uri"
                            ) +
                            "&client_id=" +
                            new URLSearchParams(props.location.search).get(
                              "client_id"
                            )
                          : "",
                      },
                    }}
                  />
                );
              }}
            />
            <Route path="/*">
              <Redirect to="/signup" />
            </Route>
          </Switch>
        </div>
        <Beforeunload
          onBeforeunload={() =>
            currentEpisode
              ? window.analytics.track("Audio Content Interrupted", {
                  subscription: !!localStorage.getItem("user")
                    ? JSON.parse(localStorage.getItem("user")!).subscription
                    : 0,
                  context: "app_closed",
                  platform: "Web",
                  content_type: currentEpisode.sample ? "Trailer" : "Episode",
                  episode_id: currentEpisode.episode
                    ? currentEpisode.episode._id
                    : undefined,
                  content_length: currentEpisode.series.length,
                  series_id: currentEpisode.series._id,
                  session_id: uuid,
                  sound: volume,
                  signup_type: localStorage.getItem("sy_signup_type")
                    ? localStorage.getItem("sy_signup_type")
                    : undefined,
                  content_name:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.info[seriesLanguage].title
                      : undefined,
                  episode_name:
                    currentEpisode && currentEpisode.episode
                      ? currentEpisode.episode.info[seriesLanguage].title
                      : undefined,
                  channel_id:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel._id
                      : undefined,
                  channel_name:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.name
                      : undefined,
                  channel_name_code:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.nameCode
                      : undefined,
                  channel_umbrella_id:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.umbrella._id
                      : undefined,
                  channel_umbrella_name:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.umbrella.name
                      : undefined,
                  channel_umbrella_name_code:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.umbrella.nameCode
                      : undefined,
                  has_rss_feed:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.info[seriesLanguage] &&
                    currentEpisode.series.info[seriesLanguage].rssFeed
                      ? true
                      : false,
                  child_content:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.isChild
                      : undefined,
                  chronological:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.chronological
                      : undefined,
                  classification:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.classification
                      : undefined,
                  emotion:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.emotion
                      : undefined,
                  episode_index: currentEpisode.episode
                    ? currentEpisode.episode.index
                    : undefined,
                  episode_length: currentEpisode.episode
                    ? currentEpisode.episode.length
                    : undefined,
                  format:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.format
                      : undefined,
                  genre:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.genre
                      : undefined,
                  season: currentEpisode.episode
                    ? currentEpisode.episode.season
                    : undefined,
                  total_length: currentEpisode.episode
                    ? currentEpisode.episode.length
                    : undefined,
                  is_unit:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.isUnit
                      : undefined,
                })
              : undefined
          }
        >
          {((!isLoading &&
            user &&
            !!user.token &&
            userContentLanguage !== null) ||
            playerIsLoading) && <Player />}
        </Beforeunload>
      </Router>
      <ToastContainer
        newestOnTop
        draggablePercent={35}
        transition={Bounce}
        autoClose={5000}
      />
    </Elements>
  );
}
export default App;
