import Phrases from "./Phrases";

const phrases: Phrases = {
  inputPlaceholder: "Écrivez ici", //TODO
  alreadyHasAnAccount: "Vous avez déja un compte ? ",
  alreadyHasAnAccountLink: "Connectez-vous",
  notAlreadyAnAccount: "Pas encore de compte ? ",
  notAlreadyAnAccountLink: "Inscrivez-vous",
  forgotedPassword: "Un petit oubli de mot de passe ? ",
  forgotedPasswordLink: "Cliquez ici",
  or: "OU",
  cgu: "CGU",
  authCgu: "En cliquant sur s’incrire, vous acceptez nos ",
  signup: "Créer un compte",
  signin: "Connexion",
  signout: "Se déconnecter",
  email: "Adresse email",
  confirmEmail: "Confirmez l’adresse email",
  emailSuccess: "Votre email a été changé avec succès",
  password: "Mot de passe",
  wrongOldPassword: "Veuillez verifier votre ancien mot de passe",
  newPassword: "Nouveau mot de passe",
  confirmNewPassword: "Confirmer le nouveau mot de passe",
  newPasswordSuccess: "Votre mot de passe a été changé avec succès",
  newPasswordTitle: "Créer votre nouveau mot de passe",
  newPasswordParagraphe:
    "Votre mot de passe doit comporter au moins 6 caractères",
  confirmButton: "CONFIRMER",
  facebookLinking: "Lier votre compte Facebook",
  facebookUnlinking: "Dissocier le compte facebook",
  facebookUnlinkingUnauthorized:
    "Ajoutez un email pour dissocier votre compte facebook",
  facebookSignup: "Inscription par Facebook",
  facebookLogin: "Continuer avec Facebook",
  appleContinue: "Continuer avec Apple",
  googleContinue: "Continuer avec Google",
  emailSignup: "Rejoignez-nous par email",
  emailLogin: "Connectez-vous par mail",
  wellcome: "Bienvenue sur Sybel !",
  wellcomeSubtitle: "Qui va utiliser votre compte ?",
  chooseProfileTitle: "Qui est-ce ?",
  chooseProfileSubTitle: "Choissisez votre profil pour démarrer",
  finishing: "terminer",
  isChild: "Enfant ?",
  searchPlaceholder: "Titre, mot-clés, thèmes...",
  profile: "Profil",
  likeList: "Ma liste",
  backToHome: "Retour à l’accueil",
  shortHome: "Accueil",
  back: "Retour",
  settingsScreenTitle: "Paramètres de compte",
  subscription: "mon abonnement",
  subscriptionWord: "Abonnement ",
  cancelSubscription: "Annuler l’abonnement",
  cancelSubscriptionQuestion: "Annuler l’abonnement?",
  paymentMethod: "Moyen de paiement",
  billingDetails: "Voir le détail de facturation",
  billingDetailsTitle: "Détail de facturation",
  annualWord: "annuel ",
  monthWord: "mensuel",
  aWithAccent: " à ",
  subscribe: "s'abonner",
  stripeSubscriptionTitle: "Saisissez vos informations de paiement",
  sfrSubscription: "Vous vous êtes abonné via notre partenaire",
  sfrSubscriptionTwo: "SFR",
  sfrSubscriptionThree: "rendez-vous dans votre",
  sfrSubscriptionFour: "Espace client SFR",
  subscriptionStore:
    "Vous vous êtes abonné via l’application mobile, rendez-vous sur votre store d’applications (Google Play Store ou App Store) pour gérer votre abonnement Sybel.",
  subscriptionCoupon:
    "Vous bénéficiez d’un coupon vous offrant un accès illimité au catalogue Sybel jusqu’au ",
  subscriptionStripe: "Vous êtes abonné jusqu’au ",
  subscriptionLife: "Vous disposez d’un accès à vie au catalogue Sybel ❤️",
  signinInformation: "INFORMATIONS DE CONNEXION",
  addEmailSentence:
    "Ajoutez une adresse email pour partager votre compte plus facilement",
  addEmail: "Ajouter un email de connexion",
  modifyEmail: "Modifier l’email de connexion",
  modifyPassword: "Modifier le mot de passe",
  loginMethod:
    "Pour vous connecter en un clic sur tous vos appareils ajoutez une méthode de connexion :",
  parentalCodeSettings: "CONTENU EXPLICITE ET CODE PARENTAL ",
  dontHaveParentalCodeSettings:
    "Nous vous conseillons de paramétrer un code parental si vous partagez votre compte avec une personne susceptible d'être heurtée par un contenu explicite inadapté à son âge.",
  haveParentalCodeSettings:
    "Vous avez paramétré un code parental, la lecture des contenus +16 et supérieurs sera controlée par ce code d’accès.",
  addProfile: "Ajouter un autre profil",
  modifyProfile: "Modifier le profil",
  firstName: "Prénom",
  gender: "Genre",
  wontSay: "Ne se prononce pas",
  male: "Masculin",
  female: "Féminin",
  other: "Autre",
  birthday: "Date d'anniversaire",
  birthdayFormat: "(jj/mm/aaaa)", //TODO
  childMode: "Mode enfant",
  anyQuestions: "Une question ?",
  helpCenter: "Accédez au centre d’aide",
  contactUs: "Contactez-nous par email",
  contactUsOn: "Contactez-nous sur",
  tc: "Conditions d’utilisation",
  listen: "Écouter",
  episodes: "épisodes",
  similar: "titres similaires",
  details: "détails",
  season: "Saison",
  sample: "extrait",
  seemore: "Voir plus...",
  seeless: "Voir moins",
  resumeListening: "Reprendre la lecture",
  resume: "Reprendre",
  avatarPickerTitle: "Choisir un avatar",
  info: "Infos",
  yes: "Oui",
  no: "Non",
  cancel: "Annuler",
  search: "Recherche",
  noLikeList:
    "Ajoutez vos contenus préférés ou ceux que vous avez envie d'écouter prochainement à votre liste pour les retrouver plus facilement !",
  emailVerificationSuccess:
    "Votre adresse email est bien vérifiée ! On vous souhaite une bonne écoute sur Sybel",
  emailVerificationTitle: "Vérifier votre email pour continuer",
  emailVerificationSubtitle:
    "Nous avons besoin de cette information pour vous recommander du contenu et sécuriser votre expérience !",
  emailVerificationClickLink: "Cliquez sur le lien dans l’email envoyé à",
  emailVerificationResend: "Renvoyer un email",
  newEpisodes: "Nouveaux épisodes",
  preferences: "Préférences",
  newsletterSybel: "Inscription à la Newsletter Sybel",
  discover: "DÉCOUVRIR",
  signupFailIdenticalMail:
    "Oh Oh... les  adresses email ne sont pas identiques, veuillez retenter",
  signupFailMail: "L'adresse email n'a pas l'air correct",
  failBirthday: "Votre anniversaire n'a pas l'air correcte",
  empty: "L'un des champs est vide",
  signupFailPasswordLength:
    "Hmm... votre mot de passe doit faire au moins 6 caractères",
  signupFail:
    "Un compte utilise déjà cette adresse email. Essayez de vous connecter avec cet email.",
  recoverFailPasswordDifferent: "Les deux mots de passe ne correspondent pas",
  forgotPasswordAlert:
    "Nous avons envoyé un email à {{email}}. Si un compte existe à cette adresse vous recevrez un lien par email pour réinitialiser votre mot de passe.",
  emailAlert: "Nous avons envoyé un email à {{email}}",
  signinFail:
    "Informations de connexion erronées, veuillez réessayer ou réinitialisez votre mot de passe",
  forgotPassword: "Mot de passe oublié",
  forgotPasswordTitle: "Réinitialiser votre mot de passe",
  forgotPasswordParagraph:
    "Saisissez votre adresse mail, nous vous enverrons un lien si l’adresse existe dans notre base",
  discoverTitle: "Le podcast... en mieux",
  newsletter: "Recevoir nos offres & recommandations d’écoute par email",
  cgu1: "En cliquant sur le bouton ci-dessous, vous acceptez nos",
  cgu2: "Conditions d'utilisation",
  cgu3: `. Votre abonnement débute dès que vous choisissez une offre et définissez un mode de paiement . Votre abonnement à Sybel est automatiquement renouvelé à moins que l'option "renouvellement automatique" n'ait été désactivée dans vos réglages/paramètres de  votre compte 24h minimum avant la fin de la période d'abonnement en cours`,
  cgu3Culture: `. Votre abonnement débute dès que vous choisissez une offre et définissez un mode de paiement. En vous abonnant vous reconnaissez expressément renoncer à votre droit de rétractation, en application de l’article L121-21-8 du Code de la consommation.`,
  changeOffer: "CHANGER",
  continue: "continuer",
  then: "puis",
  per: "pour",
  addProfileHomeTitle: "Ajouter un profil",
  addProfileHomeButton: "Ajouter un profil adulte",
  days: "jours",
  month: "mois",
  year: "année",
  remaining: "Il reste",
  addKidsProfile: "Ajouter un profil KiD",
  comingSoon: "Bientôt disponible...",
  comingSoonEpisode: "Disponible le :",
  popUpTitle: "Débloquez l’ensemble des 700 séries du catalogue Sybel",
  popUpTitleFree: ", gratuitement pendant",
  seriesContextPopUpTitleNoTrial: "Abonnez-vous maintenant pour écouter",
  sampleFrom: "Extrait de ",
  comingNext: "À suivre ",
  mainUserTitle: "Pseudo*",
  emptyDate: "JJ/MM/AAAA",
  sleepAfter: "MODE VEILLE",
  endOfEpisode: "La fin de l’épisode",
  oneHour: "heure",
  minutes: "minutes",
  disableSleep: "Désactiver le mode veille",
  episodesList: "Épisodes",
  interfaceLanguage: "Langue de l’interface",
  contentLanguage: "Langue du contenu",
  seriesContextPopUpTitle: "Activez votre essai gratuit pour écouter", //TODO ATTENTION ICI SI PAS DE FREE TRIAL QUOI AFFICHER
  trialPremium: "JOUR D’ESSAI GRATUIT", //TODO
  trialPremium_plural: "JOURS D’ESSAI GRATUITS", //TODO
  explicitEmailSubtitle:
    "Pour bénéficier d’un code parental, veuillez saisir votre email afin de récupérer le mot de passe en cas de perte",
  explicitCodeEditionSubtitle:
    "Pour modifier votre code parental, saississez le code actuel puis le nouveau",
  explicitCodeDeleteSubtitle:
    "Vous devez renseigner le code parental actuel pour pouvoir le supprimer.",
  explicitCodeCreationSubtitle:
    "Créez votre code parental pour plus de sécurité",
  chooseNumbers: "(Choisissez 4 chiffres)",
  typeNumbers: "(Saisissez les 4 chiffres)",
  wrongCode: "Code erroné, essayez à nouveau",
  forgotCode: "Code oublié ?",
  codeSended: "Un email contenant votre code parental vous a été renvoyé",
  createCode: "CRÉER UN CODE PARENTAL",
  modifyCode: "Modifier le code",
  codeParental: "Code parental",
  currentCode: "Code actuel :",
  newCode: "Nouveau code :",
  parentalCode: "Code parental",
  promoCodeSubTitle: "Activez votre code Sybel+",
  deleteCode: "Supprimer le code",
  codeCreated: "Code créé",
  codeDeleted:
    "Code supprimé. Les contenus +16 et +18 sont désormais accessibles sans code pour tous les profils.",
  codeModified: "Le code parental a été modifié",
  codeError: "Une erreur est survenue",
  yearly: "Annuel",
  monthly: "Mensuel",
  yearUnit: "an",
  noSubscription:
    "Oh oh... Il semblerait que vous n’ayez aucun abonnement Sybel en cours.",
  discoverOffers: "Découvrir nos offres",
  letsGo: "C'est parti !",
  operatorActivate: "ACTIVER MON OFFRE PARTENAIRE",
  operatorActivateWording:
    "Choisissez l’opérateur avec lequel vous souhaitez activer votre option Sybel+  :",
  redBySfr: "RED by SFR",
  sfr: "SFR",
  deleteAccount: "Supprimer le compte",
  delete: "SUPPRIMER",
  deleteLabel: 'Écrire "SUPPRIMER" pour poursuivre',
  deleteAccountSuccess: "Vous nous manquez déjà 😢",
  deleteAccountSubscribed:
    "Vous ne pouvez pas supprimer votre compte car vous êtes encore abonné",
  changeLanguage: "Modifier la langue",
  fr: "Français",
  es: "Español",
  en: "English",
  notSubscribedSettingsOffers:
    "Toutes les fonctionnalités et un accès illimité à tout le catalogue à partir de ",
  notSubscribedSettingsOffersEmphase: "3,99€/mois !",
  notSubscribedSettingsCoupon:
    "Vous avez un code cadeau ou code partenaire? activez dès maintenant votre accès à ",
  notSubscribedSettingsCouponEmphase: "Sybel+",
  notSubscribedSettingsCouponCta: "ACTIVER UN CODE",
  notSubscribedSettingsSfr: "Tous les avantages de Sybel Premium sont à ",
  notSubscribedSettingsSfrEmphase: "2€/mois",
  notSubscribedSettingsSfrPostEmphase: " avec l’option SFR !",
  notSubscribedSettingsSfrCta: "ACTIVER l’option",
  coupon: "Activer votre code",
  couponWording:
    "Renseignez votre code cadeau ou partenaire, nous créditerons votre compte après validation.",
  couponWordingBefore:
    "Validez le code puis créez un compte pour profiter de l’offre",
  couponFail: "Ce coupon n'existe pas ! 🤷‍♀️",
  couponSuccess: "Le coupon a bien été appliqué ! 🤗",
  couponClick: "Appliqué",
  couponAlreadySubscribed:
    "Vous êtes déjà abonné, vous ne pouvez pas bénéficier de ce code",
  couponNotCorrect: "Code non valide, veuillez vérifier la saisie",
  couponConflict:
    "Ce code n’est pas valable sur cet abonnement, veuillez changer pour poursuivre",
  couponConflictToast: "Réduction non valable pour cet abonnement ",
  couponExpired:
    "Ce code a expiré et n'est plus valide, essayez un nouveau code",
  couponUsed: "Ce code a déjà été activé, essayez un nouveau code",
  nextBill: "Prochaine facturation le",
  total: "Total facturé",
  period: "Période",
  billingDate: "Date de facturation",
  from: "du",
  to: "au",
  editProfile: "éditer les profils",
  editProfileSubtitle: "Cliquez sur le profil que vous souhaitez modifier.",
  chooseAvatar: "Choisissez votre avatar",
  chooseAvatarSubTitle: "Cliquez sur l’avatar qui vous plaît le plus !",
  seriesCopy: "Copié dans le presse-papier",
  seriesShareMobile: "Partager",
  seriesPagePlay: "Extrait",
  seriesPagePause: "Pause",
  min: "min",
  home: "Accueil",
  premium: "Premium",
  profileManagment: "Gestion des profils",
  openSybel: "Ouvrir Sybel",
  bonus: "bonus",
  shortSeason: "S",
  shortEpisode: "E",
  cardNumberPlaceholder: "Numéro de carte",
  expirationCardPlaceholder: "Date d’expiration",
  cvcCardPlaceholder: "CVV",
  discountPaymentPlaceholder: "Un code de réduction ?",
  ok: "OK",
  sybelPlusWelcome: "Bienvenue sur Sybel+,",
  sybelPlusSeries: "profitez dès maintenant de votre abonnement en écoutant ",
  sybelPlusNoContext:
    "Vous vous apprétez à découvrir des contenus d'un nouveau genre. Ce ne sont ni des podcasts, ni des livres audio, nos créations originales au sound design immersif vous feront découvrir des recoins inexplorés de votre imaginaire.... Prêt ? Écoutez attentivement.",
  billboardDiscover: "découvrir la série",
  searched: "Résultat de recherche pour ", //TODO
  searched_plural: "Résultats de recherche pour ", //TODO
  noResult: "Oups... Aucun résultat pour ", //TODO
  life: "À vie !",
  lifeLong: "Sybel+ en illimité, à vie !",
  lifePayment: "Sybel à Vie",
  cultureCta: "(50% reversé à la culture)",
  cultureTitle: 'OFFRES SPECIALES "VIVE LA CULTURE"',
  paymentHasAnAccountTitle: "Hmmm...",
  paymentHasAnAccountParagraphe:
    "Vous bénéficiez déjà d’un abonnement en cours à Sybel+, vous ne pouvez pas souscrire de nouvelles offres.",
  durring: "pendant",
  firstMonth: "le premier mois puis",
  firstYear: "la première année",
  changeCreditCardCTA: "Modifier le moyen de paiement",
  changeCreditCardGood: "Le moyen de paiement a correctement été mis à jour",
  cookiesTitle: "Nous respectons vos choix 🍪",
  cookiesSubTitle1: "Les cookies et autres traceurs utilisés par Sybel et ses",
  cookiesSubTitle2: "partenaires",
  cookiesSubTitle3:
    " permettent de : mesurer et d’analyser l’audience de Sybel et de ses publicités, de personnaliser le contenu et les services qui vous sont proposés en fonction de vos centres d’intérêt ainsi que le partage sur les réseaux sociaux.",
  cookiesSubTitle4: "Pour en savoir plus, rendez-vous sur notre",
  cookiesSubTitle5: "politique cookies.",
  cookiesSubTitle6:
    " Vous pouvez exprimer ou retirer votre consentement à tout moment en cliquant sur le lien “Gestion des cookies” en bas de page dans les Paramètres",
  cookiesAllowAll: "tout accepter",
  cookiesDisableAll: "tout refuser",
  cookiesSettingsCTA: "parametrer",
  cookiesSettingsTitle: "Nous respectons vos choix 🍪",
  cookiesSettingsSubTitle:
    "Pour les utilisations ci-dessous, votre consentement est nécessaire:",
  cookiesSettingsTitleCantDisable: "Cookies nécessaires opérationnels",
  cookiesSettingsTitleCantDisableParagraph:
    "Les cookies opérationnels sont les cookies de  fonctionnalité et de performance utilisés pour fournir la Plateforme Sybel qui ne peuvent pas être désactivés pour ces finalités.",
  cookiesSettingsTitleCantDisableMoreInformations:
    "En savoir plus sur les cookies opérationnels",
  cookiesSettingsTitleCantDisableMoreInformationsListTitle:
    "Nous utilisons des cookies pour vous fournir Sybel notamment pour :",
  cookiesSettingsTitleCantDisableMoreInformationsList1:
    "Vous reconnaître lorsque vous vous connectez pour utiliser la Plateforme.",
  cookiesSettingsTitleCantDisableMoreInformationsList2:
    "Afficher des fonctionnalités, du contenu et des services qui pourraient vous intéresser et qui comprennent des publicités relatives aux contenus.",
  cookiesSettingsTitleCantDisableMoreInformationsList3:
    "Conserver le suivi de vos préférences concernant vos écoutes.",
  cookiesSettingsTitleCantDisableMoreInformationsList4:
    "Prévenir les activités frauduleuses.",
  cookiesSettingsTitleCantDisableMoreInformationsList5:
    "Améliorer la sécurité dont le paiement sécurisé.",
  cookiesSettingsTitleCantDisableMoreInformationsList6:
    "Assurer le suivi de vos préférences, comme celle de la langue.",
  cookiesSettingsTitleCantDisableMoreInformationsListFooter:
    "Nous utilisons également des cookies pour comprendre comment vous utilisez la Plateforme afin de pouvoir apporter des améliorations. Par exemple, nous utilisons des Cookies pour faire des recherches, statistiques anonymisées et établir des diagnostics permettant d’améliorer le contenu qui vous est proposé, les services et pour mesurer et comprendre les performances de Sybel.",
  cookiesAddsTitle: "Cookies publicitaires",
  cookiesAddsParagraphe:
    "L’objectif est d’enregistrer vos actions (ex : souscription à un abonnement) après que vous avez vu ou avez interagi sur les publicités Sybel diffusées sur des supports tiers afin d’en mesurer l’efficacité et la performance.",
  cookiesSocialNetworkTitle: "Cookies réseaux sociaux",
  cookiesSocialNetworkParagraphe:
    "L’objectif est de vous permettre d’interagir avec vos réseaux sociaux, directement depuis notre site, ainsi que de partager nos contenus avec vos communautés en cliquant sur les boutons de partage dédiés.",
  cookiesSettingsValidation: "Valider le parametrage",
  signupSuccessMail: "L'adresse email a l'air correcte",
  signupFailIdenticalMailOnChange: "Les adresses email ne sont pas identiques",
  manageCookies: "Gérer les cookies",
  defaultYearPrice: "{{price}} / an",
  defaultMonthPrice: "{{price}} / mois",
  temporaryYearReduction:
    "{{reducedPrice}} pendant {{duration}} ans puis {{price}} / an",
  temporaryMonthReduction:
    "{{reducedPrice}} pendant {{duration}} mois puis {{price}} / mois",
  temporaryOneYearReduction:
    "{{reducedPrice}} la premiere année puis {{price}} / an",
  temporaryOneMonthReduction:
    "{{reducedPrice}} le premier mois puis {{price}} / mois",
  YearlyOfferUnitaryPrice: "facturés {{price}}€ / an en une fois",
  summerOffer: "OFFRE SPÉCIALE BEAUX JOURS",
  beginTrialPremium: "Activer mon {{count}} jour gratuits", //TODO
  beginTrialPremium_plural: "Activer mes {{count}} jours gratuits", //TODO
  YearlyPaymentUnitaryPrice: "{{price}} / mois en une fois",
  permanentYearPaymentPrice: "{{newPrice}} / an au lieu de {{price}}", //TODO
  permanentMonthPaymentPrice: "{{newPrice}} / mois au lieu de {{price}}", //TODO
  maillinglist:
    "Nous avons désinscrit l’email <strong><em>{{email}}</em></strong> de la newsletter Sybel, vous ne recevrez plus nos offres et recommendations à cette adresse.",
  maillinglistChoice:
    "Vous pouvez modifier ce choix à tout moment en vous rendant dans les paramètres du compte de l'application.",
  discoverApp: "Découvrir L’app mobile", //TODO
  freeInSettings: "Associer mon compte Sybel à ma Freebox", //TODO
  freeUnlinkInSettings: "Dissocier mon compte Sybel de ma Freebox", //TODO
  freeUnlinkInSettingsSuccess: "Votre compte a été dissocié avec succès", //TODO
  assiociateFreeSuccess:
    "Bravo, vous pouvez désormais profiter de Sybel sur tous vos appareils compatibles : mobile, web, assistants vocaux ou tv !", //TODO
  freeSignin: "UTILISEZ VOTRE MéTHODE DE CONNEXION HABITUELLE", //TODO
  associateFreeDescription:
    "Associez un compte Sybel pour profiter de votre abonnement sur la FreeBox et toutes nos autres applications. Entrez le code d’association qui s’affiche sur votre TV pour lier un compte à votre application FreeBox :", //TODO
  activateFreeLinkEmailDescription:
    "Associer un compte Sybel existant à ma Freebox", //TODO
  activateFreeAddEmailDescription:
    "Ajouter un email pour profiter de mon abonnement Free sur toutes les plateformes", //TODO
  activateFreeLinkEmailCta: "ASSOCIER MON COMPTE", //TODO
  activateFreeAddEmailCta: "AJOUTER UN EMAIL", //TODO
  FreeErrorBothAccountsAreSubscribed:
    "Nous ne pouvons associer ces deux comptes car ils sont tous deux abonnés, veuillez vous déconnecter et choisir un autre compte sans abonnement.", //TODO
  FreeErrorNoFreeAccount:
    "Le code est invalide ou vous avez quitté le mode association de votre application Freebox. Veuillez vérifier l’écran de votre TV.", //TODO
  freeSubscription: "Vous vous êtes abonné via notre partenaire",
  freeSubscriptionTwo: "Free",
  freeSubscriptionThree: "rendez-vous dans votre",
  freeSubscriptionFour: "Freebox",
  subscriptionInsert: "ECOUTER SYBEL +", //TODO
  userCanaccessToAFreeTrial: "ESSAYER PENDANT {{count}} JOUR", //TODO
  userCanaccessToAFreeTrial_plural: "ESSAYER PENDANT {{count}} JOURS", //TODO
  popupNewSession: "ESSAYER SYBEL+", //TODO
  popupNewSessionDays: "{{count}} jours gratuits*", //TODO
  popupNewSessionPrice:
    "*{{count}} jours d’essai gratuit, sans engagement, résiliation à tout moment, puis {{price}}€ / an.", //TODO
  popUpNewSessionCta: "essayer Sybel +", //TODO
  categoryInsert: "Essayez gratuitement Sybel+<br />pendant {{count}} jours* !", //TODO
  categoryInsertPoint1:
    "Découvrez + de 1000 heures d'écoute de livres audio et séries exclusives.", //TODO
  categoryInsertPoint2: "Chaque semaine des nouveautés vous attendent.", //TODO
  categoryInsertPoint3:
    "Créer jusqu'à 5 profils, et profitez du mode enfant pour un espace dédié et sécurisé.", //TODO
  categoryInsertPoint4:
    "Profitez de votre abonnement partout: sur mobiles, ordinateurs, tablettes, enceintes connectées, FreeBox...", //TODO
  categoryInsertPoint5:
    "Et tout le temps, téléchargez vos séries favorites pour une écoute hors-ligne", //TODO
  categoryInsertButton: "démarrer mon ESSAI GRATUIT", //TODO
  radioTitle:
    "Cliquez pour écouter en exclusivité la radio Sybel de votre choix",
  radioSubTitle:
    "Passez à l’offre d’essai de Sybel+ pour découvrir 17 000 contenus à la demande",
  radioKids: "SYBEL RADIO POUR ENFANTS",
  radioAdult: "SYBEL RADIO",

  popupPomote1: "Marre des vidéos sur les réseaux sociaux ?", //TODO
  popupPomote2: "Marre de perdre son temps ?", //TODO
  popupPomote3:
    "Découvrez des heures de documentaires et de fictions audio originales et exclusives pour se cultiver, apprendre et se divertir", //TODO
  popupPomote4: "Vivez une expérience immersive en illimité et sans publicité", //TODO
  redeemSignup: "Inscrivez-vous pour profiter de l'offre",

  redeemArgTitle1: "Des milliers d'heures d'écoute",
  redeemArgParagraph1:
    "Épisodes inédits de vos podcasts, documentaires & livres audio",
  redeemArgTitle2: "Des contenus jeunesse",
  redeemArgParagraph2:
    "Histoires adaptées aux enfants, classées par âge sur un profil sécurisé",
  redeemArgTitle3: "Plus de 100 créations originales",
  redeemArgParagraph3: "Avec un son en 3D & des castings d’exception",
  redeemArgTitle4: "Soutenez vos podcasters préférés",
  redeemArgParagraph4:
    "70% de votre abonnement est reversé aux créateurs de contenus",
  buyashare: "INVESTIR SUR CE CONTENU",
  learnMore: "En savoir plus…",
};

export default phrases;
