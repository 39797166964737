import { useEffect, useState } from "react";
import authFormValidator from "../utils/AuthFormValidator";

export default function UseEmailVerification(email: string): {
  emailError: {
    error?: string | undefined;
  };
} {
  const [emailError, setEmailError] = useState<{ error?: string }>({
    error: undefined,
  });
  useEffect(() => {
    if (email.indexOf("@") === -1) {
      return;
    } else {
      setEmailError(authFormValidator({email}));
      
    }
  }, [email]);
  return { emailError };
}
