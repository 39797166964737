import UserType from "../interfaces/UserType";

 const searchService = async (
    user: UserType, s: string, isChild: boolean , lng: string
  ) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: user.token,
      },
    };
  
    return await fetch(
      `${process.env.REACT_APP_API}/${lng}/search?text=${s}&isChild=${isChild ? 1 : 0}`,
      requestOptions
    );
  }; 

   export { searchService }; 
