import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import { UseSeriesLanguage } from "./UseSeriesLanguage";

export default function UseDismissPopUp(
  setIsLocked: React.Dispatch<React.SetStateAction<boolean | undefined>>
): { dismissPopUp: (isSubscribed?: boolean | undefined) => any } {
  const { currentEpisode, uuid, volume } = useSelector((state: StoreType) => ({
    ...state.PlayerReducer,
    ...state.UserReducer,
  }));
  const {seriesLanguage} = UseSeriesLanguage()
  const dispatch: Dispatch<Action> = useDispatch();
  const dismissPopUp = (isSubscribed?: boolean) => {
    if (isSubscribed) {
      setIsLocked(false);
      dispatch({
        type: Case.SET_CURRENT_EPISODE,
        payload: { ...currentEpisode, uuid },
      });
      return dispatch({
        type: Case.IS_PLAYING,
      });
    } else {
      setIsLocked(false);
      window.analytics.track("Audio Content Interrupted", {
        subscription: !!localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!).subscription : 0,
        context: "dismiss_pop_up",
        platform: "Web",
        session_id: uuid,
        sound: volume,
        content_type:
          currentEpisode && currentEpisode.sample ? "Trailer" : "Episode",
        series_id:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series._id
            : undefined,
        episode_id:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode._id
            : undefined,
        content_length: currentEpisode && currentEpisode.series.length,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
          episode_name: currentEpisode && currentEpisode.episode ? currentEpisode.episode.info[seriesLanguage].title :undefined,
          channel_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel._id
            : undefined,
        channel_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.name
            : undefined,
        channel_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.nameCode
            : undefined,
        channel_umbrella_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella._id
            : undefined,
        channel_umbrella_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.name
            : undefined,
        channel_umbrella_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.nameCode
            : undefined,
            has_rss_feed:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.info[seriesLanguage] &&
            currentEpisode.series.info[seriesLanguage].rssFeed
              ? true
              : false,
              child_content:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.isChild
                : undefined,
            chronological:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.chronological
                : undefined,
            classification:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.classification
                : undefined,
            emotion:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.emotion
                : undefined,
            episode_index: currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.index
              : undefined,
            format:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.format
                : undefined,
            genre:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.genre
                : undefined,
            total_length: currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.length
              : undefined,
              is_unit:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.isUnit
                : undefined,
      });
      
      return dispatch({
        type: Case.SET_CURRENT_EPISODE_UNDEFINED,
      });
    }
  };
  return { dismissPopUp };
}
