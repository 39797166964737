import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import StoreType from "../interfaces/StoreType";
import { ProductStripeType } from "../interfaces/UserType";
import { language } from "../stores/18nStore";
import amplitude from "amplitude-js";
import "../styles/home.scss";

export default function HomeCategoryMarketingInsert(): ReactElement {
  const { user } = useSelector((state: StoreType) => ({
    ...state.UserReducer,
  }));
  return (
    <div className="marketing-category-insert">
      <div className="marketing-category-insert-left">
        <h3
          dangerouslySetInnerHTML={{
            __html: `${language("categoryInsert", {
              count: user.subscriptionData
                ? Math.max.apply(
                    Math,
                    user.subscriptionData.productsStripe.map(
                      (o) => o.trial_period_days
                    )
                  )
                : null
            })}`,
          }}
        ></h3>
        <p>
          {language("popupNewSessionPrice", {
            count: user.subscriptionData
              ? Math.max.apply(
                  Math,
                  user.subscriptionData.productsStripe.map(
                    (o) => o.trial_period_days
                  )
                )
              : null,
            price:
              user.subscriptionData && user.subscriptionData.productsStripe
                ? (
                    user.subscriptionData.productsStripe[
                      user.subscriptionData.productsStripe.findIndex(
                        (eachProduct) =>
                          eachProduct.trial_period_days ===
                          Math.max.apply(
                            Math,
                            user.subscriptionData!.productsStripe.map(
                              (o) => o.trial_period_days
                            )
                          )
                      )
                    ] as ProductStripeType
                  ).amount
                : null,
          })}
        </p>
        {user.subscription === 0 &&
          user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) < new Date() && (
            <p>{language("subscriptionInsert")}</p>
          )}
        {user.subscription === 0 &&
          user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) < new Date() && (
            <p>{language("subscriptionInsert")}</p>
          )}
        {user.subscription === 0 &&
          user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) < new Date() && (
            <p>{language("subscriptionInsert")}</p>
          )}
      </div>
      <div className="marketing-category-insert-right">
        <div className="marketing-category-insert-right-point">
          {Array.from(Array(5), (e, i) => {
            return (
              <div key={i} className="marketing-category-insert-right-point-each">
                <svg
                  width="24"
                  height="17"
                  viewBox="0 0 24 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.19024 16.9925C8.72195 16.9925 8.25366 16.7658 7.90244 16.4256L0.526829 9.16854C-0.17561 8.48819 -0.17561 7.46767 0.526829 6.78733C1.22927 6.10698 2.28293 6.10698 2.98537 6.78733L9.07317 12.7971L21.0146 1.00437C21.7171 0.324023 22.7707 0.324023 23.4732 1.00437C24.1756 1.68472 24.1756 2.70524 23.4732 3.38559L10.478 16.539C10.1268 16.7658 9.65854 16.9925 9.19024 16.9925Z"
                    fill="white"
                  />
                </svg>
                <p>{language(`categoryInsertPoint${i + 1}`)}</p>
              </div>
            );
          })}
        </div>
        <Link
         onClick={() => 
            amplitude.getInstance().logEvent("Click Premium Button", {"Context": "Subscribe Insert"}) }
          to={`/payment/${
            (
              user.subscriptionData!.productsStripe[
                user.subscriptionData!.productsStripe.findIndex(
                  (eachProduct) =>
                    eachProduct.trial_period_days ===
                    Math.max.apply(
                      Math,
                      user.subscriptionData!.productsStripe.map(
                        (o) => o.trial_period_days
                      )
                    )
                )
              ] as ProductStripeType
            ).id
          }`}
        >
          {language("categoryInsertButton")}
        </Link>
      </div>
    </div>
  );
}
