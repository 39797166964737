import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HomeNavBar from "../components/HomeNavBar";
import LoaderFullScreen from "../components/LoaderFullScreen";
import StoreType from "../interfaces/StoreType";
import "../styles/home.scss";
import HomeHeaderSlider from "../components/HomeHeaderSlider";
import { UseHome } from "../ressources/UseHome";
import HomeResumeListe from "../components/HomeResumeListe";
import { UseUnivers } from "../ressources/UseUnivers";
import HomeMainCategories from "../components/HomeMainCategories";
import HomeBillboard from "../components/HomeBillboard";
import UseCategory from "../ressources/UseCategory";
import PopUpMarketing from "../components/PopUpMarketing";
import HomeHighlightCategory from "../components/HomeHighlightCategory";
import HomeCategoryMarketingInsert from "../components/HomeCategoryMarketingInsert";
import SwiperCore, { Navigation, Mousewheel, Controller } from "swiper";
/* import { Swiper, SwiperSlide } from "swiper/react"; */
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/navigation/navigation.scss";
/* import {
  getEpisodesService,
  getSeriesService,
} from "../services/SeriesService";
import { useHistory } from "react-router";
import SeriesType from "../interfaces/SeriesType";
import EpisodeType from "../interfaces/EpisodeType";
import { Case } from "../interfaces/ReducerCase";
import swiperMainOptions from "../utils/SwiperOptions"; */
SwiperCore.use([Navigation, Mousewheel, Controller]);

interface Props {
  isFromSeries?: true;
}

export default function Home({ isFromSeries }: Props): ReactElement {
  const {
    isLoading,
    home,
    profile,
    seriesHistory,
    universes,
    user,
    /*     userContentLanguage, */
  } = useSelector((state: StoreType) => ({
    ...state.RouterReducer,
    ...state.ProfileReducer,
    ...state.SeriesReducer,
    ...state.UserReducer,
  }));
  const [search, setSearch] = useState<string>("");
  const { isUniverseLoading, categoriesData, setUniversToShow, universToShow } =
    UseCategory();
  UseHome();
  UseUnivers();
  const [isLocked, setIsLocked] = useState(false);
  const [isUnsubscibed, setIsUnsubscibed] = useState(false);

  useEffect(() => {
    if (
      user &&
      !!user.token &&
      user.subscription !== undefined &&
      (user.subscription === 0 ||
        user.subscription === null ||
        (user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) < new Date())) &&
      user.subscriptionData &&
      user.subscriptionData.productsStripe &&
      Math.max.apply(
        Math,
        user.subscriptionData.productsStripe.map((o) => o.trial_period_days)
      ) !== null &&
      Math.max.apply(
        Math,
        user.subscriptionData.productsStripe.map((o) => o.trial_period_days)
      ) !== 0
    ) {
      setIsUnsubscibed(true);
      if (!localStorage.getItem("popup")) {
        localStorage.setItem(
          "popup",
          JSON.stringify({
            date: new Date()
              .toISOString()
              .replace("-", "/")
              .split("T")[0]
              .replace("-", "/"),
            session: 1,
          })
        );
      }
      if (
        localStorage.getItem("popup") &&
        JSON.parse(localStorage.getItem("popup")!) &&
        JSON.parse(localStorage.getItem("popup")!).session >= 2 &&
        JSON.parse(localStorage.getItem("popup")!).date &&
        new Date(
          JSON.parse(localStorage.getItem("popup")!).date
        ).toISOString() !==
          new Date(
            new Date()
              .toISOString()
              .replace("-", "/")
              .split("T")[0]
              .replace("-", "/")
          ).toISOString()
      ) {
        setIsLocked(true);
      }
      if (
        localStorage.getItem("popup") &&
        JSON.parse(localStorage.getItem("popup")!) &&
        JSON.parse(localStorage.getItem("popup")!).date &&
        JSON.parse(localStorage.getItem("popup")!).date !==
          new Date()
            .toISOString()
            .replace("-", "/")
            .split("T")[0]
            .replace("-", "/")
      ) {
        localStorage.setItem(
          "popup",
          JSON.stringify({
            date: new Date()
              .toISOString()
              .replace("-", "/")
              .split("T")[0]
              .replace("-", "/"),
            session: JSON.parse(localStorage.getItem("popup")!).session + 1,
          })
        );
      }
    }
  }, [user]);
  const dismissPopUp = () => {
    setIsLocked(false);
  };
  /*   const history = useHistory();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]); */

  /*   useEffect(() => {
    const fetchShuffle = async (id) => {
      try {
        const seriesPending = await getSeriesService(
          id!,
          JSON.parse(localStorage.getItem("user")!),
          userContentLanguage
        );
        const seriesData: SeriesType = await seriesPending.json();
        getEpisodes(
          seriesData._id,
          seriesData.chronological
            ? 1
            : seriesData.seasonsList[seriesData.seasonsList.length - 1],
          seriesData
        );
      } catch (e) {
        console.log(e);
      }
    };
    const getEpisodes = async (
      id: string,
      season: number,
      seriesRes: SeriesType
    ) => {
      const ac = new AbortController();
      const { signal } = ac;
      try {
        const episodesPending = await getEpisodesService(
          user.token,
          id,
          seriesRes.chronological,
          season,
          userContentLanguage,
          signal
        );
        const episodesData: EpisodeType[] = await episodesPending.json();
        stableDispatch({
          type: Case.IS_SHUFFULABLE,
          payload: {
            isShuffle: {
              series: seriesRes,
              episodes: episodesData,
              title: home!.recommendation
                .filter((each) => each.seriesList.length > 0)
                .find((each) => each.type === "surprise")?.title,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    };
    if (
      user &&
      user.subscription !== null &&
      user.subscription !== undefined &&
      user.subscription !== 0 &&
      home &&
      !!home.recommendation &&
      home.recommendation.length > 0 &&
      typeof home.recommendation !== "string" &&
      home.recommendation
        .filter((each) => each.seriesList.length > 0)
        .find((each) => each.type === "surprise")
    ) {
      fetchShuffle(
        home.recommendation
          .filter((each) => each.seriesList.length > 0)
          .find((each) => each.type === "surprise")?.seriesList[0]._id
      );
    }
  }, [user, home, stableDispatch, userContentLanguage]); */

  return isLoading || !home ? (
    <LoaderFullScreen />
  ) : (
    <>
      {isLocked &&
        user.subscriptionData &&
        user.subscriptionData.productsStripe &&
        isUnsubscibed && (
          <PopUpMarketing
            isLocked={true}
            dismissPopUp={dismissPopUp}
            isNewSession
            setIsUnsubscibed={setIsUnsubscibed}
          />
        )}
      <HomeNavBar
        universToShow={universToShow}
        setUniversToShow={setUniversToShow}
        search={search}
        setSearch={setSearch}
      />
      <HomeHeaderSlider
        universToShow={universToShow}
        isFromSeries={isFromSeries}
      />
      {!universToShow ? (
        <>
          {/*           {user &&
            user.subscription !== null &&
            user.subscription !== undefined &&
            user.subscription !== 0 &&
            home &&
            !!home.recommendation &&
            home.recommendation.length > 0 &&
            typeof home.recommendation !== "string" &&
            home.recommendation
              .filter((each) => each.seriesList.length > 0)
              .map(
                (each, indexCateg) =>
                  each.type !== "surprise" && (
                    <div key={indexCateg + 1000} className="home-resume">
                      <p
                        className="resume-title"
                        style={{
                          color:
                            each.type === "categories_by_scripted"
                              ? "#fdfdfe"
                              : "#fff",
                        }}
                      >
                        {each.title}
                      </p>
                      <Swiper {...swiperMainOptions}>
                        {each.seriesList.map((eachSeries, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div
                                className="each-resume-card"
                                onClick={() => {
                                  history.push(`/series/${eachSeries._id}`, {
                                    contentEventDetails: each
                                      ? {
                                          recommId: each.recommId,
                                          contentContext:
                                            "Click Resume Category Info Icon",
                                          categoryType: "Main Homepage",
                                          contentCategory: undefined,
                                          categoryPosition: undefined,
                                          universeId: undefined,
                                          contentUniverse: "Main Homepage",
                                          contentPosition: index,
                                          catalog: undefined,
                                        }
                                      : undefined,
                                  });
                                }}
                              >
                                <div
                                  style={{
                                    background:
                                      eachSeries && eachSeries.url
                                        ? `top center / cover url(${eachSeries.url})`
                                        : "#343434",
                                  }}
                                  className="each-resume-card-cover-container"
                                >
                                  <img
                                    className="play-epidode-button"
                                    src="/image/playLogo.svg"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  )
              )} */}
          {home.categories &&
            home.categories
              .filter((each) => each.active)
              .map((eachCategories, index) => {
                return eachCategories.billboard ? (
                  <Fragment key={index}>
                    <HomeBillboard
                      universToShow={universToShow}
                      categoryPosition={index}
                      each={eachCategories}
                    />
                    {index === 0 &&
                      profile &&
                      seriesHistory &&
                      Object.keys(seriesHistory).filter(
                        (each) => seriesHistory[each].status === false
                      ).length > 0 && (
                        <HomeResumeListe universToShow={universToShow} />
                      )}
                    {index === 1 &&
                      user &&
                      user.subscriptionData &&
                      user.subscriptionData.productsStripe &&
                      Math.max.apply(
                        Math,
                        user.subscriptionData.productsStripe.map(
                          (o) => o.trial_period_days
                        )
                      ) !== null &&
                      Math.max.apply(
                        Math,
                        user.subscriptionData.productsStripe.map(
                          (o) => o.trial_period_days
                        )
                      ) !== 0 &&
                      isUnsubscibed && <HomeCategoryMarketingInsert />}
                  </Fragment>
                ) : eachCategories.highlight ? (
                  <Fragment key={index}>
                    <HomeHighlightCategory each={eachCategories} />
                    {index === 0 &&
                      profile &&
                      seriesHistory &&
                      Object.keys(seriesHistory).filter(
                        (each) => seriesHistory[each].status === false
                      ).length > 0 && (
                        <HomeResumeListe universToShow={universToShow} />
                      )}
                    {index === 1 &&
                      user &&
                      user.subscriptionData &&
                      user.subscriptionData.productsStripe &&
                      Math.max.apply(
                        Math,
                        user.subscriptionData.productsStripe.map(
                          (o) => o.trial_period_days
                        )
                      ) !== null &&
                      Math.max.apply(
                        Math,
                        user.subscriptionData.productsStripe.map(
                          (o) => o.trial_period_days
                        )
                      ) !== 0 &&
                      isUnsubscibed && <HomeCategoryMarketingInsert />}
                  </Fragment>
                ) : (
                  <Fragment key={index}>
                    <HomeMainCategories each={eachCategories} />
                    {index === 0 &&
                      profile &&
                      seriesHistory &&
                      Object.keys(seriesHistory).filter(
                        (each) => seriesHistory[each].status === false
                      ).length > 0 && (
                        <HomeResumeListe universToShow={universToShow} />
                      )}
                    {index === 1 &&
                      user &&
                      user.subscriptionData &&
                      user.subscriptionData.productsStripe &&
                      (Math.max.apply(
                        Math,
                        user.subscriptionData.productsStripe.map(
                          (o) => o.trial_period_days
                        )
                      ) !== null ||
                        Math.max.apply(
                          Math,
                          user.subscriptionData.productsStripe.map(
                            (o) => o.trial_period_days
                          )
                        ) !== 0) &&
                      isUnsubscibed && <HomeCategoryMarketingInsert />}
                  </Fragment>
                );
              })}
        </>
      ) : universes && universToShow && categoriesData ? (
        <>
          {isUniverseLoading && <LoaderFullScreen />}
          {categoriesData
            .sort((a, b) => a!.index - b!.index)
            .filter((each) => each!.active)
            .map((each, index: number) =>
              each!.billboard ? (
                <>
                  <HomeBillboard
                    universToShow={universToShow}
                    key={index}
                    each={each!}
                    categoryPosition={index}
                  />
                  {index === 1 &&
                    user &&
                    user.subscriptionData &&
                    user.subscriptionData.productsStripe &&
                    Math.max.apply(
                      Math,
                      user.subscriptionData.productsStripe.map(
                        (o) => o.trial_period_days
                      )
                    ) !== null &&
                    Math.max.apply(
                      Math,
                      user.subscriptionData.productsStripe.map(
                        (o) => o.trial_period_days
                      )
                    ) !== 0 &&
                    isUnsubscibed && <HomeCategoryMarketingInsert />}
                </>
              ) : each && !each.billboard && !each.highlight ? (
                <HomeMainCategories
                  key={each._id}
                  each={each}
                  universToShow={universToShow}
                />
              ) : null
            )}
        </>
      ) : (
        <LoaderFullScreen />
      )}
    </>
  );
}
