import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import "../styles/player.scss";
import ReactPlayer from "react-player";
import "rc-slider/assets/index.css";
import PlayerSleepMode from "./PlayerSleepMode";
import PlayerPlaylist from "./PlayerPlaylist";
import PlayerNextEpisode from "./PlayerNextEpisode";
import PlayerForward from "./PlayerForward";
import PlayerVolume from "./PlayerVolume";
import PlayerEpisodeTitle from "./PlayerEpisodeTitle";
import PlayerPlay from "./PlayerPlay";
import PlayerRewind from "./PlayerRewind";
import PlayerCurrentTime from "./PlayerCurrentTime";
import { UseEpisodeUrl } from "../ressources/UseEpisodeUrl";
import { UseNextEpisode } from "../ressources/UseNextEpisode";
import PopUp from "./PopUp";
import useComponentVisible from "../utils/OnClickElsewhere";
import UseDismissPopUp from "../ressources/UseDismissPopUp";
import UseProgress from "../ressources/UseProgress";
import UseControlePlayerWithKeyBoard from "../ressources/UseControlePlayerWithKeyBoard";
import UseSample from "../ressources/UseSample";
import { language } from "../stores/18nStore";
import amplitude from "amplitude-js";
import { Link } from "react-router-dom";
import { ProductStripeType } from "../interfaces/UserType";
import { Action, Case } from "../interfaces/ReducerCase";
import { Dispatch } from "redux";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";

export default function Player(): ReactElement {
  const {
    currentEpisode,
    user,
    isPlaying,
    playerIsHidded,
    uuid,
    seriesHistory,
    playerIsLoading,
  } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
    ...state.PlayerReducer,
    ...state.UserReducer,
  }));
  const playerRef = useRef<ReactPlayer>(null);
  const airPlayButton = useRef<HTMLButtonElement>(null);
  const playerButtonRewind = useRef(null);
  const playerButtonPlay = useRef(null);
  const playerButtonForward = useRef(null);

  const [sleepModeInfo, setSleepModeInfo] = useState<
    | {
        time: number | null;
        second: number | null;
        wording: string;
        isActivated: boolean | undefined;
      }
    | undefined
  >(undefined);
  const { url, isLocked, setIsLocked, isPlayerLoad } = UseEpisodeUrl(playerRef);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
  const { nextEpisode, setEpisodeEndSleep, episodeEndSleep } = UseNextEpisode(
    setSleepModeInfo,
    playerRef
  );
  const { setCurrentTimeInformation } = UseProgress(playerRef, isPlayerLoad);
  const { dismissPopUp } = UseDismissPopUp(setIsLocked);
  const [volume, setVolume] = useState(1);
  const [isStarted, setIsStarted] = useState(false);
  const [isBuffuring, setIsBuffuring] = useState(false);
  const [speedUp, setSpeedUp] = useState(false);
  UseControlePlayerWithKeyBoard(
    playerRef,
    isComponentVisible,
    playerButtonRewind,
    playerButtonForward,
    playerButtonPlay
  );

  UseSample(true);

  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);

  useEffect(() => {
    if (
      isLocked &&
      currentEpisode &&
      currentEpisode.episode &&
      user &&
      !!user.token &&
      (user.subscription === 0 ||
        user.subscription === null ||
        (user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) < new Date()))
    ) {
      stableDispatch({
        type: Case.IS_NOT_PLAYING,
      });
    }
  }, [user, currentEpisode, isLocked, stableDispatch]);

  useEffect(() => {
    const cleanupPlayerRef = playerRef.current;
    const cleanupButtonRef = airPlayButton.current;

    const handleAirplayClick = () => {
      playerRef.current!.getInternalPlayer().webkitShowPlaybackTargetPicker();
    };
    const handleAirplayAvalaibility = (event) => {
      if (airPlayButton.current) {
        switch (event.availability) {
          case "available":
            airPlayButton.current!.hidden = false;
            airPlayButton.current!.disabled = false;
            break;
          case "not-available":
            airPlayButton.current!.hidden = true;
            airPlayButton.current!.disabled = true;
            break;
        }
      }
    };
    if (
      (window as any).WebKitPlaybackTargetAvailabilityEvent &&
      !!airPlayButton &&
      !!airPlayButton.current!! &&
      !!playerRef &&
      !!playerRef.current &&
      !!playerRef.current.getInternalPlayer()
    ) {
      playerRef
        .current!.getInternalPlayer()
        .addEventListener("webkitplaybacktargetavailabilitychanged", (e) =>
          handleAirplayAvalaibility(e)
        );
    }
    if (!(window as any).WebKitPlaybackTargetAvailabilityEvent) return;
    if (
      !!airPlayButton.current &&
      !!playerRef &&
      !!playerRef.current &&
      !!playerRef.current.getInternalPlayer()
    ) {
      airPlayButton.current!.addEventListener("click", handleAirplayClick);
    }
    return () => {
      if (cleanupButtonRef && cleanupPlayerRef) {
        cleanupButtonRef.removeEventListener("click", handleAirplayClick);
        cleanupButtonRef.removeEventListener(
          "webkitplaybacktargetavailabilitychanged",
          handleAirplayAvalaibility
        );
      }
    };
  }, [playerRef, airPlayButton]);

  const { seriesLanguage } = UseSeriesLanguage();

  useEffect(() => {
    var playerTimer = setInterval(function (e) {
      if (
        isPlaying &&
        isStarted &&
        !isBuffuring &&
        !isPlayerLoad &&
        playerRef &&
        playerRef.current &&
        playerRef.current.getCurrentTime() &&
        Math.round(playerRef.current.getCurrentTime()) !== 0 &&
        Math.round(playerRef.current.getCurrentTime()) % 60 === 0
      ) {
        window.analytics.track("Audio Content Playing", {
          production:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.production
              : undefined,
          scripted:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.scripted
              : undefined,
          catalog: seriesLanguage,
          subscription: !!localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")!).subscription
            : 0,
          platform: "Web",
          session_id: uuid,
          series_id:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series._id
              : undefined,
          episode_index:
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.index
              : undefined,
          episode_id:
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode._id
              : "trailer",
          format:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.format
              : undefined,
          genre:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.genre
              : undefined,
          content_type:
            currentEpisode && currentEpisode.series ? "Series" : "Extract",
          content_name:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.info[seriesLanguage].title
              : undefined,
          episode_length:
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.length
              : undefined,
          content_length:
            currentEpisode && currentEpisode.series
              ? Math.round(currentEpisode.series.length)
              : undefined,
          season:
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.season
              : undefined,
          position:
            playerRef && playerRef.current
              ? Math.round(playerRef.current.getCurrentTime())
              : undefined,
          signup_type: localStorage.getItem("sy_signup_type")
            ? localStorage.getItem("sy_signup_type")
            : undefined,
          chronological:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.chronological
              : undefined,
          classification:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.classification
              : undefined,
          emotion:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.emotion
              : undefined,
          child_content:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.isChild
              : undefined,
          is_unit:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.isUnit
              : undefined,
          publication_date:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.publicationDate
              : undefined,
          seasons_list:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.seasonsList
              : undefined,
          header_mention:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.info[seriesLanguage].headerMention
              : undefined,
          episode_name:
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.info[seriesLanguage].title
              : undefined,
          channel_id:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel._id
              : undefined,
          channel_name:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.name
              : undefined,
          channel_name_code:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.nameCode
              : undefined,
          channel_umbrella_id:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.umbrella._id
              : undefined,
          channel_umbrella_name:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.umbrella.name
              : undefined,
          channel_umbrella_name_code:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.umbrella.nameCode
              : undefined,
          has_rss_feed:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.info[seriesLanguage] &&
            currentEpisode.series.info[seriesLanguage].rssFeed
              ? true
              : false,
          authors:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.authors
              ? currentEpisode.series.authors
              : undefined,
          owner_id:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.owner_id
              ? currentEpisode.series.owner_id
              : undefined,
              recomm_id : sessionStorage.getItem("recommId") ? sessionStorage.getItem("recommId") : undefined
        });
      }
    }, 1000);
    return () => clearInterval(playerTimer);
  }, [
    isPlaying,
    uuid,
    currentEpisode,
    seriesLanguage,
    playerRef,
    isStarted,
    isPlayerLoad,
    isBuffuring,
  ]);

  return (
    <>
      {isLocked &&
        currentEpisode &&
        currentEpisode.episode &&
        user &&
        !!user.token &&
        (user.subscription === 0 ||
          user.subscription === null ||
          (user.subscriptionExpirationDate &&
            new Date(user.subscriptionExpirationDate) < new Date())) && (
          <PopUp
            seriesContext={currentEpisode?.series}
            isLocked={isLocked}
            dismissPopUp={dismissPopUp}
          />
        )}
      <div
        ref={ref}
        onClick={() => setIsComponentVisible(true)}
        className="player-container"
        style={{ bottom: (currentEpisode && !playerIsHidded ) || playerIsLoading? 0 : "-80px" }}
      >
        <ReactPlayer
          config={{
            file: {
              forceAudio: true,
            },
          }}
          onDuration={() => {
            if (
              playerRef &&
              playerRef.current &&
              currentEpisode &&
              currentEpisode.episode &&
              !!seriesHistory &&
              seriesHistory[currentEpisode.series._id] &&
              seriesHistory[currentEpisode.series._id].episode &&
              seriesHistory[currentEpisode!.series._id].progress &&
              seriesHistory[currentEpisode.series._id].episode._id &&
              seriesHistory[currentEpisode.series._id].episode._id ===
                currentEpisode.episode._id
            ) {
              playerRef.current!.seekTo(
                Math.round(seriesHistory[currentEpisode!.series._id].progress),
                "seconds"
              );
            } else {
              setCurrentTimeInformation({
                played: 0,
                playedSeconds: 0,
                loaded: 0,
                loadedSeconds: 0,
              });
            }
          }}
          onBuffer={() => setIsBuffuring(true)}
          onBufferEnd={() => setIsBuffuring(false)}
          onStart={() => setIsStarted(true)}
          playing={isPlaying}
          width={0}
          height={0}
          url={
            currentEpisode && currentEpisode.sample
              ? currentEpisode.sample
              : url
          }
          volume={volume}
          onEnded={() => {
            setIsStarted(false);
            return nextEpisode();
          }}
          ref={playerRef}
          playbackRate={speedUp ? 1.3 : 1}
          progressInterval={10000}
          onProgress={(progress) =>
            (currentEpisode && currentEpisode.sample) || isPlayerLoad
              ? null
              : setCurrentTimeInformation(progress)
          }
        />
        {currentEpisode &&
          currentEpisode.episode &&
          !isPlayerLoad &&
          !playerIsLoading &&
          playerRef.current && <PlayerCurrentTime playerRef={playerRef} />}
        <div className="controls">
          <div className="player-left">
            {currentEpisode && currentEpisode.episode && (
              <PlayerRewind playerRef={playerRef} />
            )}
            <PlayerPlay isPlayerLoad={isPlayerLoad} playerRef={playerRef} />
            {currentEpisode && currentEpisode.episode && (
              <PlayerForward playerRef={playerRef} />
            )}
            <PlayerVolume setVolume={setVolume} volume={volume} />
            <p
              className="play-controle"
              style={{
                color: speedUp ? "#f23568" : "#fff",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => setSpeedUp(!speedUp)}
            >
              X1.3
            </p>
            {currentEpisode && currentEpisode.series && <PlayerEpisodeTitle />}
          </div>

          <div className="player-right">
            {user &&
              !!user.subscriptionData &&
              !!user.subscriptionData.productsStripe &&
              (user.subscription === null || user.subscription === 0) && (
                <Link
                  className="nav-profile-premium"
                  to={
                    user.subscription === 0 &&
                    user.subscriptionExpirationDate &&
                    new Date(user.subscriptionExpirationDate) < new Date()
                      ? "/offers"
                      : `/payment/${
                          (
                            user.subscriptionData!.productsStripe[
                              user.subscriptionData!.productsStripe.findIndex(
                                (eachProduct) =>
                                  eachProduct.trial_period_days ===
                                  Math.max.apply(
                                    Math,
                                    user.subscriptionData!.productsStripe.map(
                                      (o) => o.trial_period_days
                                    )
                                  )
                              )
                            ] as ProductStripeType
                          ).id
                        }`
                  }
                  onClick={() =>
                    amplitude
                      .getInstance()
                      .logEvent("Click Premium Button", { Context: "Player" })
                  }
                >
                  {user.subscription === 0 &&
                  user.subscriptionExpirationDate &&
                  new Date(user.subscriptionExpirationDate) < new Date()
                    ? language("subscriptionInsert").toUpperCase()
                    : language("popUpNewSessionCta").toUpperCase()}
                </Link>
              )}
            {currentEpisode &&
              currentEpisode.episodes &&
              currentEpisode.episodes.length > 1 && (
                <PlayerNextEpisode nextEpisode={nextEpisode} />
              )}
            {currentEpisode &&
              currentEpisode.episodes &&
              currentEpisode.episodes.length > 1 && (
                <PlayerPlaylist playerRef={playerRef} />
              )}
            <button
              className="next-episode"
              ref={airPlayButton}
              hidden
              disabled
            >
              <img
                src="/image/logo/airplay_logo.svg"
                alt="airplay by Apple logo"
              />
            </button>
            {currentEpisode &&
              currentEpisode.episodes &&
              currentEpisode.episode && (
                <PlayerSleepMode
                  sleepModeInfo={sleepModeInfo}
                  setSleepModeInfo={setSleepModeInfo}
                  setEpisodeEndSleep={setEpisodeEndSleep}
                  episodeEndSleep={episodeEndSleep}
                />
              )}
          </div>
        </div>
      </div>
    </>
  );
}
