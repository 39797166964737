import React, { ReactElement } from "react";
import {
  LazyLoadComponent,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import HomeBillboardType from "../interfaces/components/HomeBillboardType";
import StoreType from "../interfaces/StoreType";
import UseBillboard from "../ressources/UseBillboard";
import { UsePlay } from "../ressources/UsePlay";
import UseSample from "../ressources/UseSample";
import { UseSeries } from "../ressources/UseSeries";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import HomeBillboardLeftSide from "./HomeBillboardLeftSide";
import HomeBillboardLoader from "./HomeBillboardLoader";
import HomeBillboardRightSide from "./HomeBillboardRightSide";

function HomeBillboard({
  each,
  categoryPosition,
  universToShow,
  scrollPosition,
}: HomeBillboardType): ReactElement {
  const { universes } = useSelector((state: StoreType) => ({
    ...state.ProfileReducer,
  }));
  const { isSerieLoaded } = UseSeries(each.seriesList[0]._id);
  const { play } = UsePlay();
  const { seriesLanguage } = UseSeriesLanguage();
  const { playExtract, isExtractLoading } = UseSample();
  const { episodes } = UseBillboard(each.seriesList[0]);

  return episodes && isSerieLoaded ? (
    <LazyLoadComponent
      threshold={0}
      scrollPosition={scrollPosition}
      placeholder={<HomeBillboardLoader title={each.title} />}
    >
      <div className="billboard">
        <p className="resume-title">{each.title}</p>
        <div className="billboard-container">
          <HomeBillboardLeftSide
            play={play}
            each={each}
            episodes={episodes}
            categoryPosition={categoryPosition}
            universToShow={universToShow}
            universes={universes}
          />
          <HomeBillboardRightSide
            episodes={episodes}
            seriesLanguage={seriesLanguage}
            isExtractLoading={isExtractLoading}
            each={each}
            playExtract={playExtract}
            categoryPosition={categoryPosition}
            universToShow={universToShow}
            universes={universes}
          />
        </div>
      </div>
    </LazyLoadComponent>
  ) : (
    <HomeBillboardLoader title={each.title} />
  );
}

export default trackWindowScroll(HomeBillboard);
