import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";

export const UseSeriesLanguage = (): { seriesLanguage: string } => {
  const { userContentLanguage } = useSelector((state: StoreType) => ({
    ...state.UserReducer,
  }));
  const [seriesLanguage, setSeriesLanguage] = useState<string>(
    (localStorage.getItem("contentLanguage") &&
      localStorage.getItem("contentLanguage") === "esp") ||
      userContentLanguage === "esp"
      ? "es"
      : (localStorage.getItem("contentLanguage") &&
          localStorage.getItem("contentLanguage") === "usa") ||
        userContentLanguage === "usa"
      ? "en"
      : "fr"
  );

  useEffect(() => {
    switch (userContentLanguage) {
      case "fra": {
        return setSeriesLanguage("fr");
      }
      case "esp": {
        return setSeriesLanguage("es");
      }
      case "usa": {
        return setSeriesLanguage("en");
      }
    }
  }, [userContentLanguage]);
  return { seriesLanguage };
};
