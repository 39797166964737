import React, { ReactElement, useRef } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";
import resetScrollEffect from "../utils/ScrollEffect";
import PlayerEpisodesCard from "./PlayerEpisodesCard";

interface Props {
  playerRef: React.RefObject<ReactPlayer>;
}

export default function PlayerPlaylist({ playerRef }: Props): ReactElement {
  const { currentEpisode } = useSelector((state: StoreType) => ({
    ...state.PlayerReducer,
    ...state.UserReducer,
    ...state.ProfileReducer,
  }));

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className="playlist">
      <div className="playlist-hover" ref={ref}>
        <p className={`season-episode-hover`}>
          {language("season")} {currentEpisode!.episodes[0]!.season}
        </p>
        {currentEpisode &&
          currentEpisode.episodes &&
          currentEpisode.episodes.map((each, index) => {
            return (
              <PlayerEpisodesCard
                resetScrollEffect={resetScrollEffect}
                refOfParent={ref}
                playerRef={playerRef}
                each={each}
                key={index}
              />
            );
          })}
      </div>
      <img src="/image/episodes.svg" alt="" />
    </div>
  );
}
