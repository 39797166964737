import React, { ReactElement } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";

interface Props {
  isEpisodeView: boolean;
  isSimilarView: boolean;
  isDetailView: boolean;
  navigation: Function;
  setIsSimilarView: Function;
  setIsEpisodeView: Function;
  setIsDetailView: Function;
  id: string;
}

export default function SeriesNavigation({
  isEpisodeView,
  isSimilarView,
  isDetailView,
  navigation,
  setIsSimilarView,
  setIsEpisodeView,
  setIsDetailView,
  id,
}: Props): ReactElement {
  const { series } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
  }));
  const useStyles = createUseStyles({
    selected: {
      width: "100%",
      height: "2px",
      position: "absolute",
      top: "-2px",
      left: "0",
      zIndex: 10000,
      background: "linear-gradient(90.91deg, #f23568 0%, #ff8475 100%)",
    },
  });
  const Selected = () => {
    const classes = useStyles();
    return <div className={classes.selected}></div>;
  };
  return (
    <div className="series-navigation">
      <h2
        style={{
          color: isEpisodeView ? "#fff" : "#A5A5A5",
        }}
        onClick={() =>
          navigation(setIsEpisodeView, setIsSimilarView, setIsDetailView)
        }
      >
        {isEpisodeView && <Selected />}
        {language("episodesList")}
      </h2>
      {series && series[id].similar && series[id].similar.length > 0 && (
        <h2
          style={{ color: isSimilarView ? "#fff" : "#A5A5A5" }}
          onClick={() =>
            navigation(setIsSimilarView, setIsEpisodeView, setIsDetailView)
          }
        >
          {isSimilarView && <Selected />}
          {language("similar")}
        </h2>
      )}
      <h2
        style={{ color: isDetailView ? "#fff" : "#A5A5A5" }}
        onClick={() =>
          navigation(setIsDetailView, setIsSimilarView, setIsEpisodeView)
        }
      >
        {isDetailView && <Selected />}
        {language("details")}
      </h2>
    </div>
  );
}
