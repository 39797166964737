import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import {
  oauthSignInService,
  signInService,
  signUpService,
} from "../services/AuthService";
import { language } from "../stores/18nStore";
import EventStore from "../stores/AmplitudeStore";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";
import ReactPixel from "react-facebook-pixel";
import { addPromoService, getUser } from "../services/UserService";
import authFormValidator from "../utils/AuthFormValidator";
import UserType from "../interfaces/UserType";
declare global {
  interface Window {
    analytics: any;
  }
}

export default function UseAuth(renault?: boolean): {
  isAppleLoading: boolean;
  signIn: () => Promise<void | React.ReactText | NodeJS.Timeout>;
  signUp: () => Promise<void | React.ReactText | NodeJS.Timeout>;
} {
  const {
    userSignUp,
    userSignIn,
    userCreation,
    redeemDiscoverCouponError,
    enterDiscoverCoupon,
  } = EventStore();

  const [isAppleLoading, setIsAppleLoading] = useState<boolean>(false);
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const location = useLocation<{ nextUrl: string }>();
  const history = useHistory();
  const toastId = "toastUnique";

  const checkCoupon = async (user: UserType) => {
    enterDiscoverCoupon(JSON.parse(sessionStorage.getItem("redeemCode")!));
    const { error } = authFormValidator(
      JSON.parse(sessionStorage.getItem("redeemCode")!)
    );
    if (!error) {
      try {
        const parsedCode = JSON.parse(sessionStorage.getItem("redeemCode")!);
        const couponPending = await addPromoService(parsedCode, user);
        if (couponPending.status === 409) {
          redeemDiscoverCouponError(
            JSON.parse(sessionStorage.getItem("redeemCode")!),
            language("couponAlreadySubscribed")
          );
          setIsAppleLoading(false);
          dispatch({ type: Case.AUTH_IS_NOT_LOADING });
          toast(language("couponAlreadySubscribed"), {
            ...notificationsParam,
            toastId,
          });
          return sessionStorage.removeItem("couponRedeem");
        } else if (couponPending.status === 400) {
          redeemDiscoverCouponError(
            JSON.parse(sessionStorage.getItem("redeemCode")!),
            language("couponFail")
          );
          setIsAppleLoading(false);
          dispatch({ type: Case.AUTH_IS_NOT_LOADING });
          sessionStorage.removeItem("couponRedeem");
          return toast(language("couponFail"), {
            ...notificationsParam,
            toastId,
          });
        } else {
          const couponData: {
            user: UserType;
            token: string;
          } = await couponPending.json();
          dispatch({
            type: Case.UPDATE_USER,
            payload: {
              user: { ...user, ...couponData.user, token: couponData.token },
            },
          });
          localStorage.setItem(
            "user",
            JSON.stringify({ ...user, ...couponData.user, token: couponData.token })
          );
          window.scrollTo(0, 0);
          return history.push("/redeem-success", {
            fromRedeem: true,
            noNav: true,
          });
        }
      } catch (e) {
        setIsAppleLoading(false);
        dispatch({ type: Case.AUTH_IS_NOT_LOADING });
        console.log(e);
        redeemDiscoverCouponError(
          JSON.parse(sessionStorage.getItem("redeemCode")!),
          JSON.stringify(e)
        );
        return toast(language("codeError"), { ...notificationsParam, toastId });
      }
    } else {
      redeemDiscoverCouponError(sessionStorage.getItem("redeemCode")!, error);
      return toast(language(error), { ...notificationsParam, toastId });
    }
  };
  useEffect(() => {
    if (renault) {
      sessionStorage.setItem("renault", "true");
    }
  }, [renault]);

  const { signInValue, signUpValue, newsletter, nextUrl } = useSelector(
    (state: StoreType) => ({
      ...state.AuthReducer,
      ...state.RouterReducer,
      ...state.UserReducer,
    })
  );
  useEffect(() => {
    const signWithApple = async () => {
      setIsAppleLoading(true);
      const appleCode: string = new URLSearchParams(location.search).get(
        "code"
      )!;
      const appleToken: string = new URLSearchParams(location.search).get(
        "id_token"
      )!;
      const applePending = await signUpService(
        {
          appleCode,
          appleToken,
          origin: "https://app.sybel.co",
        },
        renault ? true : undefined
      );
      const appleData = await applePending.json();
      if (appleData.user) {
        localStorage.setItem("user", JSON.stringify(appleData.user));
      }
      if (
        localStorage.getItem("redirectUri") &&
        localStorage.getItem("state") &&
        localStorage.getItem("clientId")
      ) {
        const redirectUri = localStorage.getItem("redirectUri")!.slice();
        const state = localStorage.getItem("state")!.slice();
        const clientId = localStorage.getItem("clientId")!.slice();
        try {
          const tokenPending = await oauthSignInService(
            appleData.user,
            clientId
          );
          const { token }: { token: string } = await tokenPending.json();
          localStorage.clear();
          sessionStorage.clear();
          return (window.location.href = `${redirectUri}#access_token=${token}&token_type=Bearer&state=${state}`);
        } catch (e) {
          console.log(e);
        }
      } else {
        if (appleData.alreadySubscribed) {
          setIsAppleLoading(false);
          sessionStorage.removeItem("renault");
          sessionStorage.removeItem("redeem");
          return toast(
            language("paymentHasAnAccountParagraphe"),
            notificationsParam
          );
        } else if (
          appleData.user.existed &&
          !sessionStorage.getItem("redeemCode")
        ) {
          stableDispatch({
            type: Case.UPDATE_USER,
            payload: { user: appleData.user },
          });
          const applfrredrct = localStorage.getItem("applfrredrct");
          localStorage.setItem("sy_signup_type", "Apple");
          window.analytics.group(appleData.user._id, {
            email: appleData.user.email,
          });
          window.analytics.track("Signed In", {
            subscription:
              !!localStorage.getItem("user") &&
              !!JSON.parse(localStorage.getItem("user")!).subscription
                ? JSON.parse(localStorage.getItem("user")!).subscription
                : 0,
            signup_type: "Apple",
            email: appleData.user.email,
            platform: "Web",
          });
          userSignIn(
            "Apple",
            applfrredrct ? applfrredrct : nextUrl ? nextUrl : "Default"
          );
          if (applfrredrct) {
            localStorage.removeItem("applfrredrct");
          } else {
            setIsAppleLoading(false);
            return history.push(
              applfrredrct
                ? applfrredrct
                : renault
                ? "/renault-activated"
                : nextUrl
                ? nextUrl
                : "/choose-profile"
            );
          }
        } else if (
          !!sessionStorage.getItem("redeemCode") &&
          appleData.user.existed
        ) {
          stableDispatch({
            type: Case.UPDATE_USER,
            payload: { user: appleData.user },
          });
          const applfrredrct = localStorage.getItem("applfrredrct");
          localStorage.setItem("sy_signup_type", "Apple");
          window.analytics.group(appleData.user._id, {
            email: appleData.user.email,
          });
          window.analytics.track("Signed In", {
            subscription: !!localStorage.getItem("user") && !!JSON.parse(localStorage.getItem("user")!).subscription
              ? JSON.parse(localStorage.getItem("user")!).subscription
              : 0,
            signup_type: "Apple",
            email: appleData.user.email,
            platform: "Web",
          });
          userSignIn(
            "Apple",
            applfrredrct ? applfrredrct : nextUrl ? nextUrl : "Default"
          );
          if (applfrredrct) {
            localStorage.removeItem("applfrredrct");
          }
            return checkCoupon({
              ...appleData.user,
              token: appleData.user.token,
            });
        } else if (
          !sessionStorage.getItem("redeemCode") &&
          !appleData.user.existed
        ) {
          stableDispatch({
            type: Case.UPDATE_USER,
            payload: { user: appleData.user },
          });
          userSignUp("Apple", nextUrl ? nextUrl : "Default");
          localStorage.setItem("sy_signup_type", "Apple");
          window.analytics.group(appleData.user._id, {
            email: appleData.user.email,
          });
          window.analytics.track("Signed Up", {
            subscription:
              !!localStorage.getItem("user") &&
              !!JSON.parse(localStorage.getItem("user")!).subscription
                ? JSON.parse(localStorage.getItem("user")!).subscription
                : 0,
            signup_type: "Apple",
            email: appleData.user.email,
            platform: "Web",
          });
            setIsAppleLoading(false);
            return history.push(
              renault ? "/renault-activated" : "/setup-profile",
              {
                nextUrl,
                isLegit: true,
              }
            );
          
        }
        else if (
          !!sessionStorage.getItem("redeemCode") &&
          !appleData.user.existed
        ) {
          stableDispatch({
            type: Case.UPDATE_USER,
            payload: { user: appleData.user },
          });
          userSignUp("Apple", nextUrl ? nextUrl : "Default");
          localStorage.setItem("sy_signup_type", "Apple");
          window.analytics.group(appleData.user._id, {
            email: appleData.user.email,
          });
          window.analytics.track("Signed Up", {
            subscription:
              !!localStorage.getItem("user") &&
              !!JSON.parse(localStorage.getItem("user")!).subscription
                ? JSON.parse(localStorage.getItem("user")!).subscription
                : 0,
            signup_type: "Apple",
            email: appleData.user.email,
            platform: "Web",
          });
            return checkCoupon({
              ...appleData.user,
              token: appleData.user.token,
            });
        }
      }
    };
    const alexaLogin = async () => {
      if (
        !new URLSearchParams(location.search).get("redirect_uri") ||
        !new URLSearchParams(location.search).get("state") ||
        !new URLSearchParams(location.search).get("client_id")
      ) {
        history.push(renault ? "/signin?renault=true" : "/signin");
      } else {
        const redirectUri: string = new URLSearchParams(location.search)
          .get("redirect_uri")!
          .slice();
        const state: string = new URLSearchParams(location.search)
          .get("state")!
          .slice();
        const clientId: string = new URLSearchParams(location.search)
          .get("client_id")!
          .slice();
        const tokenPending = await oauthSignInService(JSON.parse(localStorage.getItem("user")!), clientId);
        const { token }: { token: string } = await tokenPending.json();
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = `${redirectUri}#access_token=${token}&token_type=Bearer&state=${state}`;
      }
    };
    if (location && location.state && location.state.nextUrl) {
      stableDispatch({
        type: Case.SET_NEXT_URL,
        payload: { url: location.state.nextUrl },
      });
      sessionStorage.setItem("nextUrl", location.state.nextUrl);
    }
    if (location.pathname.substr(0, 11) === "/auth/apple" && !localStorage.getItem("user")) {
      signWithApple();
    } else if (
      (location.pathname === "/auth/sign-in" ||
        location.pathname === "/auth/sign-in?renault=true") &&
      new URLSearchParams(location.search).get("client_id") &&
      localStorage.getItem("user")
    ) {
      alexaLogin();
    } else if (
      (location.pathname === "/auth/sign-in" ||
        location.pathname === "/auth/sign-in?renault=true") &&
      !localStorage.getItem("user") &&
      new URLSearchParams(location.search).get("client_id") &&
      new URLSearchParams(location.search).get("redirect_uri") &&
      new URLSearchParams(location.search).get("state")
    ) {
      localStorage.setItem(
        "redirectUri",
        new URLSearchParams(location.search).get("redirect_uri")!
      );
      localStorage.setItem(
        "clientId",
        new URLSearchParams(location.search).get("client_id")!
      );
      localStorage.setItem(
        "state",
        new URLSearchParams(location.search).get("state")!
      );
      stableDispatch({
        type: Case.SET_NEXT_URL,
        payload: { url: `${location.pathname}${location.search}` },
      });
      localStorage.setItem("nextUrl", `${location.pathname}${location.search}`);
      history.push(renault ? "/signin?renault=true" : "/signin");
    }
    // eslint-disable-next-line
  }, [
    location,
    stableDispatch,
    history,
    nextUrl,
    renault,
  ]);
  const signIn = async () => {
    dispatch({ type: Case.AUTH_IS_LOADING });
    const res = authFormValidator(signInValue);
    if (!res.error) {
      try {
        const resPending = await signInService(
          signInValue,
          renault ? true : undefined
        );
        if (resPending.status === 409) {
          dispatch({ type: Case.AUTH_IS_NOT_LOADING });
          sessionStorage.removeItem("renault");
          sessionStorage.removeItem("redeem");
          return toast(
            language("paymentHasAnAccountParagraphe"),
            notificationsParam
          );
        }
        const res = await resPending.json();
        localStorage.setItem("user", JSON.stringify(res.user));
        const usePending = await getUser(
          JSON.parse(localStorage.getItem("user")!)._id,
          JSON.parse(localStorage.getItem("user")!).token
        );
        const userData: any = await usePending.json();
        stableDispatch({
          type: Case.UPDATE_USER,
          payload: { user: { ...userData, token: res.user.token } },
        });
        localStorage.setItem(
          "user",
          JSON.stringify({ ...userData, token: res.user.token })
        );
        userSignIn("Email", nextUrl ? nextUrl : "Default");
        if (nextUrl && nextUrl.substr(0, 13) === "/auth/sign-in") {
          localStorage.setItem("sy_signup_type", "Alexa");
          window.analytics.group(userData._id, {
            email: userData.email,
            email_verified: userData.emailVerified,
            offers_email: userData.offersEmail,
            subscription: userData.subscription,
            subscription_expiration_date: userData.subscriptionExpirationDate,
          });
          window.analytics.track("Signed In", {
            subscription:
              !!localStorage.getItem("user") &&
              !!JSON.parse(localStorage.getItem("user")!).subscription
                ? JSON.parse(localStorage.getItem("user")!).subscription
                : 0,
            signup_type: "Alexa",
            email: userData.email,
            platform: "Web",
          });
          userSignIn("Alexa", nextUrl ? nextUrl : "Default");
          history.push(nextUrl);
        } else {
          localStorage.setItem("sy_signup_type", "Email");
          window.analytics.group(userData._id, {
            email: userData.email,
            email_verified: userData.emailVerified,
            offers_email: userData.offersEmail,
            subscription: userData.subscription,
            subscription_expiration_date: userData.subscriptionExpirationDate,
          });
          window.analytics.track("Signed In", {
            subscription:
              !!localStorage.getItem("user") &&
              !!JSON.parse(localStorage.getItem("user")!).subscription
                ? JSON.parse(localStorage.getItem("user")!).subscription
                : 0,
            signup_type: "Email",
            email: userData.email,
            platform: "Web",
          });
          if (!!sessionStorage.getItem("redeemCode")) {
            return checkCoupon({ ...userData, token: res.user.token });
          }
          return history.push(
            renault
              ? "/renault-activated"
              : nextUrl
              ? nextUrl
              : "/choose-profile"
          );
        }
      } catch (error) {
        console.log({ error });
        dispatch({ type: Case.AUTH_IS_NOT_LOADING });
        return toast(language("signinFail"), { ...notificationsParam });
      }
    } else {
      toast(language(res.error), { ...notificationsParam });
      dispatch({ type: Case.SET_SIGNIN_ERROR });
      dispatch({ type: Case.AUTH_IS_NOT_LOADING });
      return setTimeout(() => {
        dispatch({ type: Case.SET_DEFAULT_SIGNIN });
      }, 5000);
    }
  };

  /**
   * POST User to SignUp
   */
  const signUp = async () => {
    dispatch({ type: Case.AUTH_IS_LOADING });
    const res = authFormValidator(signUpValue);
    if (!res.error) {
      try {
        const res = await signUpService(
          { ...signUpValue, newsletter },
          renault ? true : undefined
        );
        if (res.status === 409) {
          dispatch({ type: Case.AUTH_IS_NOT_LOADING });
          sessionStorage.removeItem("renault");
          return toast(
            language("paymentHasAnAccountParagraphe"),
            notificationsParam
          );
        }
        const userResponse = await res.json();
        localStorage.setItem("user", JSON.stringify(userResponse.user));
        dispatch({
          type: Case.UPDATE_USER,
          payload: { user: userResponse.user },
        });
        ReactPixel.track("Lead");
        if (renault) {
          userCreation();
          localStorage.setItem("sy_signup_type", "Email");
          window.analytics.group(userResponse.user._id, {
            email: userResponse.user.email,
            email_verified: userResponse.user.emailVerified,
            offers_email: userResponse.user.offersEmail,
            subscription: userResponse.user.subscription,
            subscription_expiration_date:
              userResponse.user.subscriptionExpirationDate,
          });
          window.analytics.track("Signed Up", {
            subscription:
              !!localStorage.getItem("user") &&
              !!JSON.parse(localStorage.getItem("user")!).subscription
                ? JSON.parse(localStorage.getItem("user")!).subscription
                : 0,
            signup_type: "Email",
            email: userResponse.user.email,
            platform: "Web",
          });
          userSignUp("Email", "/renault-activated");
          if (!!sessionStorage.getItem("redeemCode")) {
            return checkCoupon(userResponse.user);
          }
          return history.push("/renault-activated", { fromSignUp: true });
        } else if (nextUrl && nextUrl.substr(0, 13) === "/auth/sign-in") {
          userCreation();
          localStorage.setItem("sy_signup_type", "Alexa");
          window.analytics.group(userResponse.user._id, {
            email: userResponse.user.email,
            email_verified: userResponse.user.emailVerified,
            offers_email: userResponse.user.offersEmail,
            subscription: userResponse.user.subscription,
            subscription_expiration_date:
              userResponse.user.subscriptionExpirationDate,
          });
          window.analytics.track("Signed Up", {
            subscription:
              !!localStorage.getItem("user") &&
              !!JSON.parse(localStorage.getItem("user")!).subscription
                ? JSON.parse(localStorage.getItem("user")!).subscription
                : 0,
            signup_type: "Alexa",
            email: userResponse.user.email,
            platform: "Web",
          });
          userSignUp("Alexa", nextUrl ? nextUrl : "Default");
          if (!!sessionStorage.getItem("redeemCode")) {
            return checkCoupon(userResponse.user);
          }
          history.push(nextUrl);
        } else if (nextUrl) {
          userCreation();
          localStorage.setItem("sy_signup_type", "Email");
          window.analytics.group(userResponse.user._id, {
            email: userResponse.user.email,
            email_verified: userResponse.user.emailVerified,
            offers_email: userResponse.user.offersEmail,
            subscription: userResponse.user.subscription,
            subscription_expiration_date:
              userResponse.user.subscriptionExpirationDate,
          });
          window.analytics.track("Signed Up", {
            subscription:
              !!localStorage.getItem("user") &&
              !!JSON.parse(localStorage.getItem("user")!).subscription
                ? JSON.parse(localStorage.getItem("user")!).subscription
                : 0,
            signup_type: "Email",
            email: userResponse.user.email,
            platform: "Web",
          });
          userSignUp("Email", nextUrl);
          if (!!sessionStorage.getItem("redeemCode")) {
            return checkCoupon(userResponse.user);
          }
          return history.push(nextUrl, { fromSignUp: true });
        } else {
          localStorage.setItem("sy_signup_type", "Email");
          window.analytics.group(userResponse.user._id, {
            email: userResponse.user.email,
            email_verified: userResponse.user.emailVerified,
            offers_email: userResponse.user.offersEmail,
            subscription: userResponse.user.subscription,
            subscription_expiration_date:
              userResponse.user.subscriptionExpirationDate,
          });
          window.analytics.track("Signed Up", {
            subscription:
              !!localStorage.getItem("user") &&
              !!JSON.parse(localStorage.getItem("user")!).subscription
                ? JSON.parse(localStorage.getItem("user")!).subscription
                : 0,
            signup_type: "Email",
            email: userResponse.user.email,
            platform: "Web",
          });
          userCreation();
          userSignUp("Email", "Default");
          if (!!sessionStorage.getItem("redeemCode")) {
            return checkCoupon(userResponse.user);
          }
          return history.push("/setup-profile", { isLegit: true });
        }
      } catch (error) {
        console.log(error);
        dispatch({ type: Case.AUTH_IS_NOT_LOADING });
        return toast(language("signupFail"), notificationsParam);
      }
    } else {
      toast(language(res.error), notificationsParam);
      dispatch({ type: Case.SET_SIGNUP_ERROR });
      dispatch({ type: Case.AUTH_IS_NOT_LOADING });
      return setTimeout(() => {
        dispatch({ type: Case.SET_DEFAULT_SINUP });
      }, 5000);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("fm_sttgs") !== null) {
      stableDispatch({ type: Case.SET_NEXT_URL_DEFAULT });
      sessionStorage.clear();
      localStorage.removeItem("fm_sttgs");
    }
  }, [stableDispatch]);

  return { isAppleLoading, signIn, signUp };
}
