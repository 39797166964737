import { useEffect } from "react";
import playerWave from "../utils/Animations/player_wave.json";

import lottie from "lottie-web";

export const UseLottie = (
  container: React.RefObject<HTMLDivElement>,
  width: string,
  height: string
) => {
  useEffect(() => {
    lottie.loadAnimation({
      container: container.current!,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: playerWave,
      rendererSettings: {
        filterSize: {
          width,
          height,
          x: "50%",
          y: "50%",
        },
      },
    });
  }, [container, height, width]);
};
