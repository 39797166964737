import i18next from "i18next";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import StoreType from "../interfaces/StoreType";
import { ProductStripeType } from "../interfaces/UserType";
import { UsePopUp } from "../ressources/UsePopUp";
import { UsePopUpPayment } from "../ressources/UsePopUpPayment";
import { language } from "../stores/18nStore";
import ReactPixel from "react-facebook-pixel";
import {
  seriesHeaderStyle, StripeBaseEllementsStyle,
} from "../utils/DynamicStyle";
import { Action, Case } from "../interfaces/ReducerCase";
import { Dispatch } from "redux";
import EventStore from "../stores/AmplitudeStore";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import LoaderFullScreen from "../components/LoaderFullScreen";
import { CardElement } from "@stripe/react-stripe-js";

export default function Payment(): ReactElement {
  let { id } = useParams<Record<string, string | undefined>>();
  const location = useLocation();
  const [couponFromUrl, setCouponFromUrl] = useState<string | null>(null);
  const { user, isLoading, context, currentEpisode, uuid } = useSelector(
    (state: StoreType) => ({
      ...state.UserReducer,
      ...state.RouterReducer,
      ...state.PlayerReducer,
    })
  );
  const { stripeViewPaymentScreen } = EventStore();
  const { seriesLanguage } = UseSeriesLanguage();
  const [isMultipleOffers, setIsMultipleOffers] = useState<boolean>(false);
  const ref = useRef<{ isInit: boolean }>({ isInit: false });
  const urlApplayed = useRef<{ isDone: boolean }>({ isDone: false });
  const refdone = useRef<{ isDone: boolean }>({ isDone: false });
  const [offer, setOffer] = useState<ProductStripeType | undefined>();
  const history = useHistory();
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const closingModal = (isSubscribed?: boolean) => {
    window.scrollTo(0, 0);
    history.push("/home");
  };
  const { isSuccess, setIsSuccess, setUpdatedUser } = UsePopUp(
    closingModal,
    undefined
  );
  const {
    isPaymentLoading,
    handleSubmit,
    applyCoupon,
    isCouponLoading,
    couponTextResponse,
    couponValue,
    setCouponValue,
    couponErrorText,
    isCouponValidated,
    priceText,
  } = UsePopUpPayment(
    { index: offer?.interval === "month" ? 1 : 0, offer: offer! },
    isMultipleOffers,
    setUpdatedUser,
    setIsSuccess,
    id!,
    CardElement
  );
  useEffect(() => {
    if (user && user._id && user.token) {
      if (
        user.subscriptionData &&
        user.subscriptionData.productsStripe &&
        user.subscriptionData.productsStripe.length > 1
      ) {
        setIsMultipleOffers(true);
      } else {
        setIsMultipleOffers(false);
      }
    }
  }, [user]);
  useEffect(() => {
    if (user && user._id && id && user.subscription !== undefined) {
      if (user.subscription !== 0 && user.subscription !== null) {
        window.scrollTo(0, 0);
        history.push("/payment/failed");
      } else if (
        !!user.subscriptionData &&
        !!user.subscriptionData.productsStripe &&
        user.subscriptionData.productsStripe.length > 1 &&
        !!user &&
        user.subscriptionData
      ) {
        for (let i = 0; i < Object.keys(user.subscriptionData).length; i++) {
          for (
            let j = 0;
            j <
            user.subscriptionData[Object.keys(user.subscriptionData)[i]].length;
            j++
          ) {
            if (
              user.subscriptionData[Object.keys(user.subscriptionData)[i]][j]
                .id === id
            ) {
              setOffer(
                user.subscriptionData[Object.keys(user.subscriptionData)[i]][j]
              );
              ref.current.isInit = true;
            }
          }
        }
      }
    } else if (user && !!user.subscriptionData) {
      ref.current.isInit = true;
      setOffer(user.subscriptionData!.productsStripe[0] as ProductStripeType);
    }
  }, [id, user, history]);
  useEffect(() => {
    if (isSuccess) {
      if (offer) {
        ReactPixel.track("Purchase", {
          order_id: offer.id,
          subscription_type: `${offer.amount} ${offer.interval} ${offer.currency}`,
          Trial:
            offer.trial_period_days && offer.trial_period_days > 0
              ? true
              : false,
          numero_trial:
            offer.trial_period_days && offer.trial_period_days > 0
              ? offer.trial_period_days
              : null,
          value: offer.currency,
        });
      }
      window.scrollTo(0, 0);
      return history.push("/payment/success", { isOk: true });
    }
  }, [isSuccess, history, offer]);
  useEffect(() => {
    if (user && id && user.subscriptionData) {
      for (let i = 0; i < Object.keys(user.subscriptionData).length; i++) {
        for (
          let j = 0;
          j <
          user.subscriptionData[Object.keys(user.subscriptionData)[i]].length;
          j++
        ) {
          if (
            user.subscriptionData[Object.keys(user.subscriptionData)[i]][j]
              .id === id
          ) {
            const thisOffer =
              user.subscriptionData[Object.keys(user.subscriptionData)[i]][j];
            ReactPixel.track("InitiateCheckout", {
              order_id: thisOffer!.id,
              subscription_type: `${thisOffer!.amount} ${thisOffer!.interval} ${
                thisOffer!.currency
              }`,
              Trial:
                thisOffer!.trial_period_days && thisOffer!.trial_period_days > 0
                  ? true
                  : false,
              numero_trial:
                thisOffer!.trial_period_days && thisOffer!.trial_period_days > 0
                  ? thisOffer!.trial_period_days
                  : null,
            });
          }
        }
      }
    }
  }, [id, user]);
  useEffect(() => {
    if (
      !!new URLSearchParams(location.search).get("promo") &&
      !!offer &&
      !urlApplayed.current.isDone
    ) {
      urlApplayed.current.isDone = true;
      setCouponFromUrl(new URLSearchParams(location.search).get("promo")!);
      applyCoupon(new URLSearchParams(location.search).get("promo"));
    }
  }, [location, offer, applyCoupon]);

  const useStyles = createUseStyles({
    "pop-up-dynamic-header": {
      position: "relative",
      "&::before": {
        background: "none",
        ...seriesHeaderStyle["pop-up-dynamic-header-before"],
      },
      "&::after": {
        backgroundImage: "none",
        filter: "none",
        ...seriesHeaderStyle["pop-up-dynamic-header-after"],
      },
    },
  });
  useEffect(() => {
    if (offer && id && seriesLanguage && offer.id === id) {
      stripeViewPaymentScreen(
        "Default offers",
        offer.id,
        offer.amount,
        offer.interval,
        undefined,
        context ? context : undefined,
        currentEpisode && currentEpisode.episode && currentEpisode.episode.info
          ? currentEpisode.episode.info[seriesLanguage].title
          : undefined,
        currentEpisode && currentEpisode.episode && currentEpisode.episode
          ? currentEpisode.episode._id
          : undefined,
        currentEpisode && currentEpisode.episode && currentEpisode.episode
          ? currentEpisode.episode.season
          : undefined,
        currentEpisode && currentEpisode.series
          ? currentEpisode.series._id
          : undefined,
        currentEpisode && currentEpisode.series && currentEpisode.series.info
          ? currentEpisode.series.info[seriesLanguage].title
          : undefined
      );
    }
  }, [
    offer,
    currentEpisode,
    seriesLanguage,
    context,
    id,
    stripeViewPaymentScreen,
  ]);

  useEffect(() => {
    if (
      !!offer &&
      !isLoading &&
      !!id &&
      !!user &&
      !isPaymentLoading &&
      ref &&
      ref.current &&
      !!ref.current.isInit &&
      !refdone.current.isDone
    ) {
      refdone.current.isDone = true;
      window.analytics.page("Viewed Stripe Payment Page", undefined, {
        context: context ? context : undefined,
        plan_id: id,
        plan_period: offer.interval,
        plan_price: offer.amount,
        platform: "Web",
        presented_offers: "Default offers",
        session_id: uuid,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
      });
    }
  }, [ref, offer, context, uuid, id, isLoading, user, isPaymentLoading]);

  const classes = useStyles();

  return isLoading ? (
    <LoaderFullScreen />
  ) : (
    <>
      <div
        className={`${classes["pop-up-dynamic-header"]} pop-up`}
        id="payment"
      >
        <img
          onClick={() => {
            stableDispatch({
              type: Case.SET_POPUP_CONTEXT,
              payload: { context: "Back From Payment Screen" },
            });
            history.goBack();
          }}
          className="payment-back"
          src="/image/pop-up-back.svg"
          alt="arrow back offers"
        />
        <div className="pop-up-payment-container">
          <p className="title-payment">{language("stripeSubscriptionTitle")}</p>
          <div className="pop-up-payment">
            <div className="stripe-englobe">
            <CardElement options={{style:{ base: StripeBaseEllementsStyle}, hidePostalCode: true}}/>
            </div>
            <div className="coupon">
              <input
                placeholder={language("discountPaymentPlaceholder")}
                className="stripe-englobe"
                type="text"
                disabled={!!couponFromUrl}
                onChange={(e) =>
                  setCouponValue(e.target.value.toUpperCase().trim())
                }
                value={!!couponFromUrl ? couponFromUrl : couponValue}
              />
              {!!couponFromUrl ? (
                <button
                  type="button"
                  style={{
                    background: "none",
                  }}
                  onClick={() => {
                    history.push(location.pathname);
                    return window.location.reload();
                  }}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle opacity="0.6" cx="20" cy="20" r="20" fill="black" />
                    <g clip-path="url(#clip0)">
                      <path
                        d="M19.7992 17.6389C19.6401 17.6389 19.4875 17.7021 19.375 17.8144C19.2624 17.9268 19.1992 18.0793 19.1992 18.2382V26.1966C19.1992 26.3556 19.2624 26.508 19.375 26.6204C19.4875 26.7328 19.6401 26.7959 19.7992 26.7959C19.9583 26.7959 20.111 26.7328 20.2235 26.6204C20.336 26.508 20.3992 26.3556 20.3992 26.1966V18.2382C20.3992 18.0793 20.336 17.9268 20.2235 17.8144C20.111 17.7021 19.9583 17.6389 19.7992 17.6389Z"
                        fill="#A5A5A5"
                      />
                      <path
                        d="M16.6004 17.6189C16.4419 17.6301 16.2943 17.7038 16.19 17.8236C16.0858 17.9435 16.0334 18.0997 16.0444 18.2581L16.6004 26.1886C16.6115 26.3391 16.679 26.4799 16.7895 26.5828C16.9 26.6858 17.0453 26.7433 17.1964 26.7439H17.2404C17.399 26.7326 17.5465 26.659 17.6508 26.5391C17.755 26.4193 17.8074 26.263 17.7964 26.1047L17.2404 18.1742C17.2291 18.0159 17.1554 17.8684 17.0354 17.7643C16.9154 17.6602 16.759 17.6079 16.6004 17.6189Z"
                        fill="#A5A5A5"
                      />
                      <path
                        d="M23.0003 17.6547C22.8419 17.6448 22.6861 17.6975 22.5663 17.8014C22.4466 17.9053 22.3726 18.0521 22.3603 18.2101L21.8003 26.1405C21.7893 26.2989 21.8416 26.4552 21.9459 26.575C22.0501 26.6949 22.1977 26.7685 22.3563 26.7798H22.4003C22.5521 26.7802 22.6984 26.7231 22.8097 26.6201C22.921 26.517 22.9891 26.3757 23.0003 26.2244L23.5643 18.294C23.5697 18.2149 23.5594 18.1356 23.5339 18.0605C23.5084 17.9855 23.4683 17.9162 23.4158 17.8568C23.3634 17.7974 23.2996 17.7489 23.2283 17.7142C23.157 17.6795 23.0795 17.6593 23.0003 17.6547Z"
                        fill="#A5A5A5"
                      />
                      <path
                        d="M27.3996 12.0376H24.4476V10.5993C24.4476 10.4403 24.3844 10.2879 24.2718 10.1755C24.1593 10.0632 24.0067 10 23.8476 10H15.7476C15.5885 10 15.4359 10.0632 15.3233 10.1755C15.2108 10.2879 15.1476 10.4403 15.1476 10.5993V12.0376H12.1996C12.0405 12.0376 11.8879 12.1007 11.7753 12.2131C11.6628 12.3255 11.5996 12.4779 11.5996 12.6368V15.3616C11.5996 15.5205 11.6628 15.6729 11.7753 15.7853C11.8879 15.8977 12.0405 15.9608 12.1996 15.9608H12.9596C12.8684 16.2168 12.8343 16.4894 12.8596 16.7599L13.9356 28.0024C13.9966 28.5441 14.2524 29.0453 14.6556 29.4127C15.0588 29.7802 15.5819 29.9888 16.1276 30H23.4676C24.0133 29.9888 24.5364 29.7802 24.9395 29.4127C25.3427 29.0453 25.5986 28.5441 25.6596 28.0024L26.7356 16.7599C26.7609 16.4894 26.7267 16.2168 26.6356 15.9608H27.3996C27.5587 15.9608 27.7113 15.8977 27.8238 15.7853C27.9364 15.6729 27.9996 15.5205 27.9996 15.3616V12.6368C27.9996 12.4779 27.9364 12.3255 27.8238 12.2131C27.7113 12.1007 27.5587 12.0376 27.3996 12.0376ZM24.4636 27.8905C24.43 28.1338 24.3118 28.3575 24.1297 28.5226C23.9477 28.6877 23.7133 28.7835 23.4676 28.7934H16.1276C15.8819 28.7835 15.6475 28.6877 15.4654 28.5226C15.2834 28.3575 15.1652 28.1338 15.1316 27.8905L14.0556 16.648C14.0423 16.5614 14.0486 16.473 14.074 16.3892C14.0993 16.3054 14.1431 16.2283 14.2022 16.1635C14.2613 16.0988 14.3341 16.0481 14.4153 16.0151C14.4965 15.9821 14.5841 15.9676 14.6716 15.9728H24.9276C25.0151 15.9676 25.1027 15.9821 25.1839 16.0151C25.2651 16.0481 25.3379 16.0988 25.397 16.1635C25.456 16.2283 25.4998 16.3054 25.5252 16.3892C25.5506 16.473 25.5569 16.5614 25.5436 16.648L24.4636 27.8905ZM16.3476 11.1986H23.2476V12.0376H16.3476V11.1986ZM26.8036 14.7623H12.8036V13.2361H26.8036V14.7623Z"
                        fill="#A5A5A5"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(10 10)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              ) : (
                <button
                  type="button"
                  disabled={
                    couponValue === "" ||
                    (isCouponValidated && couponValue === "")
                  }
                  onClick={applyCoupon}
                  style={{
                    fontSize:
                      isCouponValidated && couponValue === "" ? "10px" : "18px",
                  }}
                >
                  {isCouponLoading ? (
                    <Loader />
                  ) : isCouponValidated && couponValue === "" ? (
                    language("couponClick")
                  ) : (
                    language("ok")
                  )}
                </button>
              )}
            </div>
            {couponTextResponse && (
              <p className="offer-payment-summup-details-coupons-res">
                {couponTextResponse}
              </p>
            )}
            {couponErrorText && (
              <p className="offer-payment-summup-error-coupons">
                {couponErrorText}
              </p>
            )}
          </div>
          <div className="bills-summup">
            {offer && (
              <div className="offer-payment-summup-left">
                <p className="offer-payment-summup-title">
                  {offer.interval === "month"
                    ? language("monthly")
                    : language("yearly")}
                </p>
              </div>
            )}
            {priceText && offer && (
              <p className="offer-payment-summup-details">
                {offer.interval === "month" ||
                (isCouponValidated && offer.interval === "year")
                  ? priceText
                  : language("defaultYearPrice", {
                      price: `${offer.amount.toFixed(2)} ${offer.currency}`,
                    })}
              </p>
            )}
          </div>
          <button
            className="payment-continue"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            {isPaymentLoading ? (
              <Loader />
            ) : offer &&
              offer.trial_period_days &&
              offer.trial_period_days !== 0 ? (
              language("beginTrialPremium", { count: offer.trial_period_days })
            ) : (
              language("continue")
            )}
          </button>
          <i>
            {language("cgu1")}{" "}
            <a
              href={`https://www.sybel.co/${i18next.language.substr(
                0,
                2
              )}/cgu#subscription-conditions`}
            >
              {language("cgu2")}
            </a>
            {language("cgu3")}
          </i>
        </div>
      </div>
    </>
  );
}
