import React, { ReactElement } from "react";

interface Props {
  customeClassName?: string;
  fix?: true
}

export default function Loader({ customeClassName, fix}: Props): ReactElement {
  return (
    <div className={customeClassName ? `loader ${customeClassName}` : "loader"}>
      {Array.from(Array(6), (e, i) => {
        return <div style={{animation: fix ? "none" : undefined}} key={i} className={`bar${i + 1}`}></div>;
      })}
    </div>
  );
}
