import React, { ReactElement } from "react";
import AppleLogin from "react-apple-login";
import GoogleLogin from "react-google-login";
import Loader from "./Loader";
import UseSocialConnect from "../ressources/UseSocialConnect";

interface Props {
  isAppleLoading: boolean;
  renault?: boolean;
}

export default function SignUpSocialConnect({
  isAppleLoading,
  renault,
}: Props): ReactElement {
  const {
    isFacebookLoading,
    isGoogleLoading,
    signWithGoogle,
    signWithFacebook,
  } = UseSocialConnect(renault);

  return (
    <div className="social-connect-container">
      <AppleLogin
        clientId="com.sybel.client"
        scope="email name"
        redirectURI={"https://api.sybel.com/auth/oauth/redirect-web"}
        responseMode="form_post"
        render={(props) => (
          <button className="apple-connect" onClick={props.onClick}>
            {isAppleLoading ? (
              <Loader customeClassName="player-load-loader-black" />
            ) : (
              <img src="/image/logo/apple_logo.svg" alt="apple logo" />
            )}
          </button>
        )}
      />
      <button className="facebook-connect" onClick={signWithFacebook}>
        {!isFacebookLoading ? (
          <img src="/image/logo/facebook_logo.svg" alt="facebook logo" />
        ) : (
          <Loader customeClassName="player-load-loader-white" />
        )}
      </button>
      <GoogleLogin
        tag="div"
        icon={false}
        className="apple-connect"
        clientId={process.env.REACT_APP_GOOGLE_SECRET!}
        onSuccess={(successRes) => signWithGoogle(successRes)}
      >
        {isGoogleLoading ? (
          <Loader customeClassName="player-load-loader" />
        ) : (
          <img src="/image/logo/google_logo.svg" alt="google logo" />
        )}
      </GoogleLogin>
    </div>
  );
}
