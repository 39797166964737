import React, { Dispatch, ReactElement, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { language } from "../stores/18nStore";
import { Action } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import DatePicker from "react-date-picker";
interface Props {
  htmlFor: string;
  type: string;
  label: string;
  autocomplete?: string;
  dispatchType: Action["type"];
  errorStyle?: boolean;
  index?: number;
  choice?: string[];
}

export default function Input({
  htmlFor,
  type,
  label,
  autocomplete,
  errorStyle,
  dispatchType,
  index,
  choice,
}: Props): ReactElement {
  const [showPassword, setShowPassword] = useState(false);
  const input = useRef<HTMLInputElement>(null);
  const dispatch: Dispatch<Action> = useDispatch();
  const { editProfilValue, multipleProfileInput, couponValue } = useSelector(
    (state: StoreType) => ({
      ...state.ProfileReducer,
      ...state.UserReducer,
    })
  );
  const showPasswordEvent = (
    e:
      | React.MouseEvent<HTMLImageElement, MouseEvent>
      | React.TouchEvent<HTMLImageElement>
  ) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  return (
    <div className="input-form-container">
      {!choice && htmlFor !== "birthday" ? (
        <input
          ref={input}
          style={{
            border: errorStyle
              ? "1px solid #FF9D00"
              : "1px solid rgba(0, 0, 0, 0)",
            paddingRight: type === "password" ? "30px" : 0,
          }}
          placeholder={type === "date" ? language("emptyDate") : label}
          onChange={(e) =>
            index
              ? dispatch({
                  type: dispatchType,
                  payload: { value: e.target.value, htmlFor, index },
                })
              : dispatch({
                  type: dispatchType,
                  payload: { value: e.target.value, htmlFor },
                })
          }
          type={showPassword ? "text" : type}
          id={htmlFor}
          name={htmlFor}
          autoComplete={autocomplete}
          value={
            dispatchType === "SET_CHOOSE_PROFILE_VALUE"
              ? editProfilValue[htmlFor]
              : dispatchType === "SET_PROFILE_VALUE"
              ? multipleProfileInput[index!].userName:
              dispatchType === "SET_COUPON_VALUE" ? couponValue.coupon
              : undefined
          }
        />
      ) : choice ? (
        <select
          defaultValue={
            editProfilValue.gender ? editProfilValue.gender : undefined
          }
          onChange={(e) =>
            dispatch({
              type: dispatchType,
              payload: { value: e.target.value, htmlFor },
            })
          }
        >
          {choice.map((eachOpt, indexOpt) => {
            return (
              <option key={indexOpt} value={indexOpt}>
                {eachOpt}
              </option>
            );
          })}
        </select>
      ) : (
        <DatePicker
          onChange={(value) =>
            dispatch({
              type: dispatchType,
              payload: { value, htmlFor },
            })
          }
          value={editProfilValue.birthday}
          maxDate={new Date()}
          minDate={
            new Date(
              new Date().getFullYear() - 100,
              new Date().getMonth(),
              new Date().getDate()
            )
          }
          className="birthday"
          calendarIcon={null}
          clearIcon={null}
        />
      )}
      {type === "password" && input.current?.value.length !== 0 && (
        <img
          draggable="false"
          onClick={(e) => showPasswordEvent(e)}
          src="/image/eyePassword.svg"
          alt="eye showing password"
        />
      )}
    </div>
  );
}
