import ProfileType from "../interfaces/ProfileType";
import UserType from "../interfaces/UserType";
export interface Progress {
  userId: string;
  profileId: string;
  seriesId: string;
  episodeId: string;
  start: number;
  progress: number;
}

const getSeriesService = async (
  id: string,
  user: UserType,
  local: string,
  signal?: AbortSignal
) => {
  const requestOptions = {
    signal,
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/${local}/series/${id}`,
    requestOptions
  );
};

const getEpisodesService = async (
  token: string,
  seriesId: string,
  chronological: boolean,
  season = 1,
  lng: string,
  signal?: AbortSignal,
  offset?: number,
  limit?: number,
) => {
  const requestOptions = {
    signal,
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/${lng}/series/${seriesId}/episodes?chronological=${chronological}&season=${season}&published=false&limit=${limit ? limit : 0}&offset=${offset}`,
    requestOptions
  );
};

const getEpisodesForSlotService = async (
  token: string,
  lng: string,
  dateFrom: string,
  dateTo: string,
  signal?: AbortSignal,
  withFranceBleu?: boolean
) => {
  const requestOptions = {
    signal,
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    }),
    body: JSON.stringify({
      criteria: {
        publicationDate: { $gte: dateFrom, $lt: dateTo },
        status: 2
      },
      criteriaSeries: !!withFranceBleu ? null : {
        authors: { $ne: "France Bleu" }
      }
    })
  };
  return await fetch(
    `${process.env.REACT_APP_API}/${lng}/episodes/loaded`,
    requestOptions
  );
};

const getEpisodesUrlService = async (
  user: UserType | { token: string },
  seriesId: string,
  episodeId: string,
  profile: ProfileType,
  lng: string,
  signal?: AbortSignal
) => {
  let local: string | undefined = undefined;
  switch (lng) {
    case "fra":
      local = "fr";
      break;
    case "usa":
      local = "en";
      break;
    case "esp":
      local = "es";
      break;
    default:
      local = "fr";
  }
  const requestOptions = {
    signal,
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/${lng}/series/${seriesId}/episode/${episodeId}/${local}?profileId=${profile._id}`,
    requestOptions
  );
};



const getSeriesHistoryService = async (
  user: UserType,
  profile: ProfileType,
  lng: string,
  signal?: AbortSignal
) => {
  const requestOptions = {
    signal,
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/${lng}/user/${user._id}/profile/${profile._id}/history/series?limit=50`,
    requestOptions
  );
};
const getEpisodesHistoryService = async (
  user: UserType,
  profile: ProfileType,
  lng: string,
  signal?: AbortSignal
) => {
  const requestOptions = {
    signal,
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
  };

  return await fetch(
    `${process.env.REACT_APP_API}/${lng}/user/${user._id}/profile/${profile._id}/history/episode`,
    requestOptions
  );
};

const sendProgressService = async (
  progress: Progress,
  user: UserType,
  profile: ProfileType,
  signal: AbortSignal
) => {
  const requestOptions = {
    signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
    body: JSON.stringify(progress),
  };

  return await fetch(
    `${process.env.REACT_APP_API}/user/${user._id}/profile/${profile._id}/history/progress`,
    requestOptions
  );
};
export {
  getSeriesService,
  getEpisodesService,
  getSeriesHistoryService,
  getEpisodesHistoryService,
  getEpisodesUrlService,
  sendProgressService,
  getEpisodesForSlotService
};
