import { PaymentMethod } from "@stripe/stripe-js";
import UserType from "../interfaces/UserType";
import axios from 'axios'

const getUser = async (user_id: string, token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user_id}`,
    requestOptions
  );
};

const getSubscription = async (user_id: string, token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user_id}/stripe/subscription`,
    requestOptions
  );
};
const getInvoices = async (user_id: string, token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user_id}/stripe/invoices`,
    requestOptions
  );
};

const cancelSubscription = async (user_id: string, token: string) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user_id}/stripe/subscription`,
    requestOptions
  );
};
const updatePasswordService = async (
  oldPassword: string,
  newPassword: string,
  user: UserType
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
    body: JSON.stringify({
      email: user.email,
      oldPassword,
      newPassword,
    }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/auth/changePassword`,
    requestOptions
  );
};

const updateEmailService = async (
  email: string,
  token: string,
  user_id: string
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    },
    body: JSON.stringify({
      email,
      /*       emailVerified: false, */
    }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user_id}`,
    requestOptions
  );
};
const updateUserService = async (body: any, token: string, user_id: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    },
    body: JSON.stringify(body),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user_id}`,
    requestOptions
  );
};
const sendVerificationMail = async (token: string, user_id: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user_id}/resendVerificationEmail`,
    requestOptions
  );
};
const resendPinCodenMail = async (token: string, user_id: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user_id}/resendPinCode`,
    requestOptions
  );
};
const subscribe = async (
  plan: string,
  user: {token: "string", _id: "string"},
  currency?: string,
  amount?: number,
  couponId?: string,
  offer?: string
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
    body: JSON.stringify({
      couponId: couponId,
      plan,
      currency,
      amount,
      offer
    }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user._id}/stripe/subscription`,
    requestOptions
  );
};
const changeCreditCardService = async (
  paymentMethod: PaymentMethod | undefined,
  user: UserType,
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
    body: JSON.stringify({
      paymentMethod: paymentMethod!.id ,
    }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user._id}/stripe/card`,
    requestOptions
  );
};
const  getCoupon = (couponId: string, user: UserType, plan: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.request({
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: user.token,
        },
        url: `${process.env.REACT_APP_API}/store/stripe/coupon`,
        data: {
          couponId: couponId,
          plan
        },
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

const applyCouponEffectively = (couponId: string, user: UserType, plan: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.request({
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: user.token,
        },
        url: `${process.env.REACT_APP_API}v`,
        data: {
          couponId: couponId,
          plan
        },
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

const deleteUserService = async (token: string, user_id: string) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user_id}`,
    requestOptions
  );
};

const addPromoService = async (coupon: string, user: UserType) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
    body: JSON.stringify({
      coupon: coupon,
    }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user._id}/coupon`,
    requestOptions
  );
};

const getExternalAuthService = async (client_id: string, code_challenge: string, user: UserType) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    }
  };
  return await fetch(
    `${process.env.REACT_APP_API}/auth/external_login?client_id=${client_id}&code_challenge=${code_challenge}&redirect_uri=a`,
    requestOptions
  );
};

export {
  getUser,
  getSubscription,
  cancelSubscription,
  getInvoices,
  updatePasswordService,
  updateEmailService,
  sendVerificationMail,
  deleteUserService,
  updateUserService,
  resendPinCodenMail,
  subscribe,
  getCoupon,
  applyCouponEffectively,
  addPromoService,
  changeCreditCardService,
  getExternalAuthService
};
