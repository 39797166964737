import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HeaderSettings from "../components/HeaderSettings";
import { getExternalAuthService } from "../services/UserService";
import Loader from "../components/Loader";
import "../styles/sso.scss";
import { language } from "../stores/18nStore";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { UseSignout } from "../ressources/UseSignout";

export default function Sso() {
  const location = useLocation();

  useEffect(() => {
    i18next.changeLanguage("en");
    setCodeChallenge(
      new URLSearchParams(location.search).get("code_challenge")
    );
    setRedirectUri(new URLSearchParams(location.search).get("redirect_uri"));
    setClientId(new URLSearchParams(location.search).get("client_id"));
    setType(new URLSearchParams(location.search).get("type"));
  }, [location.search]);

  const { user } = useSelector((state: StoreType) => ({
    ...state.UserReducer,
    ...state.ProfileReducer,
  }));

  const [isAllowLoading, setIsAllowLoading] = useState<boolean>(false);
  const [isRejectLoading, setIsRejectLoading] = useState<boolean>(false);
  const [codeChallenge, setCodeChallenge] = useState<string | null>();
  const [redirectUri, setRedirectUri] = useState<string | null>();
  const [clientId, setClientId] = useState<string | null>();
  const [type, setType] = useState<string | null>();

  const allow = async () => {
    setIsAllowLoading(true);
    if (clientId && codeChallenge && user && redirectUri) {
      const codeReq = await getExternalAuthService(
        clientId,
        codeChallenge,
        user
      );
      const { code } = await codeReq.json();
      if (code) {
        window.location.replace(
          redirectUri +
            (redirectUri.indexOf("?") > -1 ? "&" : "?") +
            "code=" +
            code
        );
      } else {
        // Raise an error TODO @BorisJerrar
      }
    }
  };

  const reject = () => {
    setIsRejectLoading(true);
    alert("REJECTED");
  };
  const { signout } = UseSignout();

  return (
    <div className="sso-container">
      <header className="header-settings">
        <p style={{ color: "rgb(195, 195, 195)", fontSize: "14px" }}>
          You're curently signed in as: {user.email}
        </p>
      </header>
      <header
        style={{ marginTop: "30px", background: "none" }}
        className="header-settings"
        onClick={() => signout()}
      >
        <p style={{ textDecoration: "underline" }}>{language("signout")}</p>
      </header>
      <div className="sso-card">
        <div className="sso-card-logos">
          <img src="/image/logo/crypto-sybl.svg" alt="SYBL Coin" />
          <img src="/image/logo/sybel_classic.svg" alt="Sybel Logo" />
        </div>
        {type === "creator" ? (
            <p>
              Frak needs your permission to receive your listen history and import your uploaded content{" "}
            </p>
          ) : (
            <p>
              <li>Frak wants to access Sybel to sync your listening history and enable $FRK earnings.</li>
              <li>Frak wants to access your Sybel Wallet and convert your Sybel Coins into $FRK token</li>
            </p>
        )}
        <div className="sso-buttons">
          <button onClick={reject} disabled={isRejectLoading || isAllowLoading}>
            {isRejectLoading ? <Loader /> : "REJECT"}
          </button>
          <button onClick={allow} disabled={isRejectLoading || isAllowLoading}>
            {isAllowLoading ? <Loader /> : "ALLOW"}
          </button>
        </div>
      </div>
    </div>
  );
}
