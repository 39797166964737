import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import HomeCategoryType from "../interfaces/HomeCategoriyType";
import StoreType from "../interfaces/StoreType";
import { getUniversServiceById } from "../services/DashboardService";

export default function UseCategory(): {
  universToShow: string | undefined;
  isUniverseLoading: boolean;
  categoriesData: (HomeCategoryType | undefined)[] | undefined;
  setUniversToShow: React.Dispatch<React.SetStateAction<string | undefined>>;
} {
  const { universes, user, userContentLanguage } = useSelector(
    (state: StoreType) => ({
      ...state.RouterReducer,
      ...state.ProfileReducer,
      ...state.SeriesReducer,
      ...state.UserReducer,
    })
  );
  const location =
    useLocation<{
      headerCategoryId: string | undefined;
      fromSearch: boolean | undefined;
    }>();
  const [universToShow, setUniversToShow] = useState<string | undefined>(
    location && location.state && location.state.headerCategoryId
      ? location.state.headerCategoryId
      : undefined
  );
  const [isUniverseLoading, setIsUniverseLoading] = useState<boolean>(false);

  const [categoriesData, setCategoriesData] =
    useState<(HomeCategoryType | undefined)[]>();

  useEffect(() => {
    const fetchUniverseCategories = async () => {
      setIsUniverseLoading(true);
      Promise.all(
        universes![
          universes!.findIndex(
            (each) => each.headerCategoryId === universToShow
          )
        ].categories.map(async (each) => {
          try {
            const categoryPending = await getUniversServiceById(
              user,
              userContentLanguage,
              each
            );
            const categoryDatas: HomeCategoryType =
              await categoryPending.json();
            return categoryDatas;
          } catch (e) {
            return undefined;
          }
        })
      ).then((categoriesData) => {
        const filteredArray = categoriesData.filter(
          (each) => each !== undefined
        );
        setIsUniverseLoading(false);
        return setCategoriesData(filteredArray);
      });
    };
    if (user && userContentLanguage && universToShow && universes) {
      fetchUniverseCategories();
    }
  }, [user, userContentLanguage, universToShow, universes]);
  return { categoriesData, isUniverseLoading, setUniversToShow, universToShow };
}
