import React, {
  ReactElement,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import { language } from "../stores/18nStore";
import ReactPixel from "react-facebook-pixel";
import { Link } from "react-router-dom";

interface Props {
  each?:
    | {
      _id: string;
      id?: string;
      bannerImage?: string;
        newEpisodes?: boolean;
        locked: boolean;
        mainImage: string;
        mainImageId?: string;
        coverImage?: string;
        titleImage?: string;
        imageUrl?: string;
      }
    | SeriesType;
  category?: {
    active? : boolean
    _id: string;
    index: number;
    coverImage: string;
    image: string;
    highlight?: boolean;
    billboard?: boolean;
    title: string;
    titleFr: string;
    seriesList: SeriesType[];
  };
  universeToShow?: string;
  index: number;
  likeListe?: boolean;
}

export default function HomeHighlightCategoryCard({
  each,
  category,
  universeToShow,
  index,
  likeListe,
}: Props): ReactElement {
  const { universes } = useSelector(
    (state: StoreType) => ({
      ...state.ProfileReducer,
    })
  );
  const ref = useRef<HTMLDivElement>(null);
  const { seriesLanguage } = UseSeriesLanguage();

  return (
    <div
      ref={ref}
      className="each-resume-card highlight"
      style={{
        background:
        each && each.bannerImage
            ? `center center / cover url(${each.bannerImage})`
            : "#343434",
      }}
    >
      <Link
        to={{
          pathname: each ? `/series/${each._id}` : undefined,
          state: {
            likeList: likeListe,
            contentEventDetails: each
              ? {
                  id: each._id,
                  contentContext: "Explorer",
                  categoryType: "Default Category",
                  contentCategory: category?.title,
                  categoryPosition: category?.index,
                  universeId: universeToShow ? universeToShow : undefined,
                  contentUniverse:
                    universes && universeToShow
                      ? universes.filter(
                          (each) => each.headerCategoryId === universeToShow
                        )[0].title
                      : "Main Homepage",
                  contentPosition: index,
                  catalog: seriesLanguage,
                }
              : undefined,
          },
        }}
        className="each-resume-card-cover-container highlight-container"
        onClick={() => {
          if (each) {
            ReactPixel.trackSingle("2507418622671662", "ViewCategory", {
              content_type: "product",
              content_category: category?.title,
              content_ids: each._id,
              content_name: null,
              univers_affichage:
                universes && universeToShow
                  ? universes.filter(
                      (each) => each.headerCategoryId === universeToShow
                    )[0].title
                  : "Main Homepage",
              page_type: "Main Homepage",
            });
          }
        }}
      >
        {each && each.newEpisodes && (
          <p className="new-episode-categories">{language("newEpisodes")}</p>
        )}
      </Link>
    </div>
  );
}
