import React, { ReactElement, useRef } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import EpisodeType from "../interfaces/EpisodeType";
import StoreType from "../interfaces/StoreType";
import SeriesEpisodesProgressBar from "./SeriesEpisodesProgressBar";
import { UseEpisodesBySeasons } from "../ressources/UseEpisodesBySeasons";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import { UseLottie } from "../ressources/UseLottie";

interface Props {
  isInProgress: boolean;
  isListened: boolean;
  progressBarSize: number;
  episode: EpisodeType;
  id: string;
  seasonIndex: number;
}

export default function SeriesEpidodesCover({
  episode,
  isInProgress,
  isListened,
  progressBarSize,
  id,
  seasonIndex,
}: Props): ReactElement {
  const { series, currentEpisode, user, isPlaying } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
    ...state.PlayerReducer,
    ...state.UserReducer,
  }));
  const uA = navigator.userAgent;
  const vendor = navigator.vendor;
  const lottieRef = useRef<HTMLDivElement>(null);
  UseLottie(lottieRef, "20", "20");
  const { isEpisodesLoaded } = UseEpisodesBySeasons(id, seasonIndex);
  const { seriesLanguage } = UseSeriesLanguage();
  const useStyles = createUseStyles({
    bgImage: {
      background: (props: {
        url: string | undefined;
        seriesImage: string | undefined;
      }) =>
        props.url
          ? `center center / cover url(${props.url})`
          : props.seriesImage
          ? `center center / cover  url(${props.seriesImage})`
          : "#343434",
    },
  });
  const classes = useStyles({
    url:
      isEpisodesLoaded && episode && episode.image ? episode.image : undefined,
    seriesImage:
      series[id] &&
      series[id].info &&
      series[id] &&
      series[id].info[seriesLanguage] &&
      series[id] &&
      series[id].info[seriesLanguage].coverImage
        ? series[id].info[seriesLanguage].coverImage
        : undefined,
  });
  return (
    <div className={`imgCover ${classes.bgImage}`}>
      {/Safari/i.test(uA) &&
      /Apple Computer/.test(vendor) &&
      !/Mobi|Android/i.test(uA) ? null : (
        <div
          style={{
            display:
              currentEpisode &&
              currentEpisode.episode &&
              currentEpisode.episode._id === episode._id && isPlaying
                ? "block"
                : "none",
          }}
          className="lottie-episode"
          ref={lottieRef}
        ></div>
      )}
      {episode.locked &&
        (user.subscription === 0 ||
          user.subscription === null ||
          (user.subscriptionExpirationDate &&
            new Date(user.subscriptionExpirationDate) < new Date())) && (
          <img
            className="locked-episode-button"
            src="/image/locked.svg"
            alt=""
          />
        )}

      {isInProgress && (
        <SeriesEpisodesProgressBar progressBarSize={progressBarSize} />
      )}
      {isListened && (
        <img
          className="listened-episode-button"
          src="/image/listened.svg"
          alt=""
        />
      )}
      {(!episode.locked ||
        (episode.locked &&
          user.subscription !== 0 &&
          user.subscription !== null &&
          user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) >= new Date())) &&
      (
        episode.info[seriesLanguage].title ||
        (episode.publicationDate &&
          new Date(episode.publicationDate) < new Date())) ? (
        <img className="play-epidode-button" src="/image/playLogo.svg" alt="" />
      ) : null}
    </div>
  );
}
