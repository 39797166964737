import { Dispatch, useCallback } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import EpisodeType from "../interfaces/EpisodeType";
import { Action, Case } from "../interfaces/ReducerCase";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import {
  getEpisodesService,
  getSeriesService,
  sendProgressService,
} from "../services/SeriesService";
import { language } from "../stores/18nStore";
import EventStore from "../stores/AmplitudeStore";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";
import { UseSeriesLanguage } from "./UseSeriesLanguage";

export const UsePlay = (): {
  play: (
    id: string,
    episode: EpisodeType,
    context: string,
    contentUniverse?: string,
    universeId?: string,
    categoryType?: string,
    categoryPosition?: number,
    contentCategory?: string,
    timestampe?: number,
    ratio?: number, 
    recomm_id?: string
  ) => void;
  playInPlayer: (
    playerRef: React.RefObject<ReactPlayer>,
    context: string,
    contentUniverse?: string,
    universeId?: string,
    categoryType?: string,
    categoryPosition?: number,
    contentCategory?: string,
    timestampe?: number,
    ratio?: number
  ) => Promise<void>;
} => {
  const {
    series,
    episodes,
    user,
    profile,
    isPlaying,
    currentEpisode,
    userContentLanguage,
    episodesHistory,
    uuid,
    volume,
  } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
    ...state.UserReducer,
    ...state.ProfileReducer,
    ...state.PlayerReducer,
  }));
  const history = useHistory();
  const toastId = "unique";
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const { seriesLanguage } = UseSeriesLanguage();
  const { pause } = EventStore();
  const playEvent = EventStore().play;
  const getSeries = async (id: string) => {
    const ac = new AbortController();
    const { signal } = ac;
    try {
      const seriesPending = await getSeriesService(
        id,
        user,
        userContentLanguage,
        signal
      );
      const seriesData: SeriesType = await seriesPending.json();
      if (
        !seriesData ||
        !seriesData.info[seriesLanguage!] ||
        (seriesData.isChild === false && profile && profile.isChild === true)
      ) {
        history.push("/home");
        return;
      } else {
        stableDispatch({
          type: Case.PUSH_SERIES,
          payload: { series: seriesData },
        });
        return seriesData;
      }
    } catch (e) {
      history.push("/home");
      return;
    }
  };
  const getEpisodes = async (
    id: string,
    season: number,
    seriesRes: SeriesType
  ) => {
    const ac = new AbortController();
    const { signal } = ac;
    try {
      const episodesPending = await getEpisodesService(
        user.token,
        id,
        seriesRes.chronological,
        season,
        userContentLanguage,
        signal
      );
      const episodesData: EpisodeType[] = await episodesPending.json();
      stableDispatch({
        type: Case.PUSH_EPISODES,
        payload: {
          series: seriesRes,
          episodes: episodesData,
          season: season,
        },
      });
      return episodesData;
    } catch (e) {
      toast(language("codeError"), { ...notificationsParam, toastId });
    }
  };
  const play = async (
    id: string,
    episode: EpisodeType,
    context: string,
    contentUniverse?: string,
    universeId?: string,
    categoryType?: string,
    categoryPosition?: number,
    contentCategory?: string,
    timestampe?: number,
    ratio?: number, recomm_id?: string
  ) => {
    dispatch({
      type: Case.IS_NOT_PLAYING,
    });
    if (!episodes || !episodes[id] || !episodes[id][episode.season]) {
      dispatch({
        type: Case.SET_CONTEXT,
        payload: { context },
      });
      dispatch({ type: Case.REINIT_IS_SEEKED_TO_HISTORY });

      const seriesRes = await getSeries(id);
      const episodeRes = await getEpisodes(id, episode.season, seriesRes!);
      if (
        currentEpisode &&
        currentEpisode.episode &&
        !currentEpisode.sample &&
        isPlaying
      ) {
        pause(
          currentEpisode.series.isChild,
          "Play Other Content",
          currentEpisode.series.emotion
            ? currentEpisode.series.emotion.join()
            : undefined,
          currentEpisode.episode!.info[seriesLanguage].title,
          currentEpisode.episode._id,
          currentEpisode.episode.season,
          currentEpisode.series._id,
          currentEpisode.series.info[seriesLanguage].title,
          profile!.isChild,
          contentCategory,
          categoryType,
          categoryPosition,
          contentUniverse,
          universeId,
          currentEpisode.series.mainImageId,
          episodesHistory &&
            episodesHistory[currentEpisode.series._id] &&
            episodesHistory[currentEpisode.series._id][
              currentEpisode.episode._id
            ]
            ? Math.round(
                episodesHistory[currentEpisode.series._id][
                  currentEpisode.episode._id
                ].progress
              )
            : undefined,
          episodesHistory &&
            episodesHistory[currentEpisode.series._id] &&
            episodesHistory[currentEpisode.series._id][
              currentEpisode.episode._id
            ]
            ? Math.round(
                (episodesHistory[currentEpisode.series._id][
                  currentEpisode.episode._id
                ].progress /
                  currentEpisode.episode.length +
                  Number.EPSILON) *
                  1000
              ) / 1000
            : undefined,
          currentEpisode.episode.length,
          currentEpisode.series.length,
          episodeRes!.filter((each) => each._id === episode._id)[0].info[
            seriesLanguage
          ].title,
          seriesRes!._id
        );
      }
      dispatch({
        type: Case.SET_CURRENT_EPISODE,
        payload: {
          series: seriesRes!,
          episodes: seriesRes!.chronological
            ? episodeRes!.filter(
                (each) => new Date(each.publicationDate) <= new Date()
              )
            : episodeRes!.filter(
                (each) => new Date(each.publicationDate) <= new Date()
              ),
          episode: episodeRes!.filter((each) => each._id === episode._id)[0],
          lng: seriesLanguage,
        },
      });
      if(recomm_id){
        sessionStorage.setItem('recommId', recomm_id)
      } else {
        sessionStorage.removeItem('recommId')
      }
      window.analytics.track("Audio Content Started", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        platform: "Web",
        session_id: uuid,
        series_id: seriesRes!._id,
        episode_id: episodeRes!.filter((each) => each._id === episode._id)[0]
          ._id,
        content_name: seriesRes
          ? seriesRes.info[seriesLanguage].title
          : undefined,
        episode_length: episodeRes!.filter(
          (each) => each._id === episode._id
        )[0].length,
        content_length: Math.round(seriesRes!.length),
        season: episodeRes!.filter((each) => each._id === episode._id)[0]
          .season,
        position: timestampe ? Math.round(timestampe) : undefined,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        chronological: seriesRes!.chronological,
        classification: seriesRes!.classification,
        emotion: seriesRes!.emotion,
        format: seriesRes!.format,
        is_unit: seriesRes!.isUnit,
        publication_date: seriesRes!.publicationDate,
        seasons_list: seriesRes!.seasonsList,
        header_mention: seriesRes!.info[seriesLanguage].headerMention,
        context: context ? context : "none",
        episode_name: episodeRes!.filter((each) => each._id === episode._id)[0]
          .info[seriesLanguage].title,
        channel_id:
          seriesRes && seriesRes.channel ? seriesRes.channel._id : undefined,
        channel_name:
          seriesRes && seriesRes.channel ? seriesRes.channel.name : undefined,
        channel_name_code:
          seriesRes && seriesRes.channel
            ? seriesRes.channel.nameCode
            : undefined,
        channel_umbrella_id:
          seriesRes && seriesRes.channel
            ? seriesRes.channel.umbrella._id
            : undefined,
        channel_umbrella_name:
          seriesRes && seriesRes.channel
            ? seriesRes.channel.umbrella.name
            : undefined,
        channel_umbrella_name_code:
          seriesRes && seriesRes.channel
            ? seriesRes.channel.umbrella.nameCode
            : undefined,
        has_rss_feed:
          seriesRes &&
          seriesRes.info &&
          seriesRes.info[seriesLanguage] &&
          seriesRes.info[seriesLanguage].rssFeed
            ? true
            : false,
        child_content:
          seriesRes && seriesRes.isChild ? seriesRes.isChild : undefined,
        episode_index:
          episodeRes &&
          episodeRes!.filter((each) => each._id === episode._id)[0]
            ? episodeRes!.filter((each) => each._id === episode._id)[0].index
            : undefined,

        genre: seriesRes && seriesRes?.genre ? seriesRes.genre : undefined,
        total_length:
          episodeRes &&
          episodeRes!.filter((each) => each._id === episode._id)[0]
            ? episodeRes!.filter((each) => each._id === episode._id)[0].length
            : undefined,
            recomm_id
      });
      return playEvent(
        seriesRes!.isChild,
        context,
        seriesRes!.emotion ? seriesRes!.emotion.join() : undefined,
        episodeRes!.filter((each) => each._id === episode._id)[0].info[
          seriesLanguage
        ].title,
        episodeRes!.filter((each) => each._id === episode._id)[0]._id,
        episodeRes!.filter((each) => each._id === episode._id)[0].season,
        seriesRes!._id,
        seriesRes!.info[seriesLanguage].title,
        profile!.isChild,
        contentCategory,
        categoryType,
        categoryPosition,
        contentUniverse,
        universeId,
        seriesRes!.mainImageId,
        timestampe,
        ratio,
        Math.round(
          episodeRes!.filter((each) => each._id === episode._id)[0].length
        ),
        Math.round(seriesRes!.length)
      );
    } else {
      dispatch({ type: Case.REINIT_IS_SEEKED_TO_HISTORY });
      dispatch({
        type: Case.SET_CONTEXT,
        payload: { context },
      });
      if (currentEpisode && currentEpisode.episode && !currentEpisode.sample) {
        pause(
          currentEpisode.series.isChild,
          "Play Other Content",
          currentEpisode.series.emotion
            ? currentEpisode.series.emotion.join()
            : undefined,
          currentEpisode.episode!.info[seriesLanguage].title,
          currentEpisode.episode._id,
          currentEpisode.episode.season,
          currentEpisode.series._id,
          currentEpisode.series.info[seriesLanguage].title,
          profile!.isChild,
          contentCategory,
          categoryType,
          categoryPosition,
          contentUniverse,
          universeId,
          currentEpisode.series.mainImageId,
          episodesHistory &&
            episodesHistory[currentEpisode.series._id] &&
            episodesHistory[currentEpisode.series._id][
              currentEpisode.episode._id
            ]
            ? Math.round(
                episodesHistory[currentEpisode.series._id][
                  currentEpisode.episode._id
                ].progress
              )
            : undefined,
          episodesHistory &&
            episodesHistory[currentEpisode.series._id] &&
            episodesHistory[currentEpisode.series._id][
              currentEpisode.episode._id
            ]
            ? Math.round(
                (episodesHistory[currentEpisode.series._id][
                  currentEpisode.episode._id
                ].progress /
                  currentEpisode.episode.length +
                  Number.EPSILON) *
                  1000
              ) / 1000
            : undefined,
          currentEpisode.episode.length,
          currentEpisode.series.length,
          series[id].info[seriesLanguage].title,
          series[id]._id
        );
      }
      if(recomm_id){
        sessionStorage.setItem('recommId', recomm_id)
      } else {
        sessionStorage.removeItem('recommId')
      }
      dispatch({
        type: Case.SET_CURRENT_EPISODE,
        payload: {
          series: series[id],
          episodes: series[id].chronological
            ? episodes[id][episode.season].filter(
                (each) => new Date(each.publicationDate) <= new Date()
              )
            : episodes[id][episode.season].filter(
                (each) => new Date(each.publicationDate) <= new Date()
              ),
          episode: episodes[id][episode.season].filter(
            (each) => each._id === episode._id
          )[0],
          lng: seriesLanguage,
        },
      });
      window.analytics.track("Audio Content Started", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        platform: "Web",
        session_id: uuid,
        series_id: id,
        episode_id: episodes[id][episode.season].filter(
          (each) => each._id === episode._id
        )[0]._id,
        content_name:
          series && series[id]
            ? series[id].info[seriesLanguage].title
            : undefined,
        episode_length: episodes[id][episode.season].filter(
          (each) => each._id === episode._id
        )[0].length,
        content_length: Math.round(series[id].length),
        season: episodes[id][episode.season].filter(
          (each) => each._id === episode._id
        )[0].season,
        position: timestampe ? Math.round(timestampe) : undefined,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        chronological:
          series && series[id] ? series[id].chronological : undefined,
        classification:
          series && series[id] ? series[id].classification : undefined,
        emotion: series && series[id] ? series[id].emotion : undefined,
        format: series && series[id] ? series[id].format : undefined,
        is_unit: series && series[id] ? series[id].isUnit : undefined,
        publication_date:
          series && series[id] ? series[id].publicationDate : undefined,
        seasons_list: series && series[id] ? series[id].seasonsList : undefined,
        header_mention:
          series && series[id]
            ? series[id].info[seriesLanguage].headerMention
            : undefined,
        context: context ? context : "none",
        episode_name: episodes[id][episode.season].filter(
          (each) => each._id === episode._id
        )[0]
          ? episodes[id][episode.season].filter(
              (each) => each._id === episode._id
            )[0].info[seriesLanguage].title
          : undefined,
        channel_id:
          series && series[id] && series[id].channel
            ? series[id].channel!._id
            : undefined,
        channel_name:
          series && series[id] && series[id].channel
            ? series[id].channel!.name
            : undefined,
        channel_name_code:
          series && series[id] && series[id].channel
            ? series[id].channel!.nameCode
            : undefined,
        channel_umbrella_id:
          series && series[id] && series[id].channel
            ? series[id].channel!.umbrella._id
            : undefined,
        channel_umbrella_name:
          series && series[id] && series[id].channel
            ? series[id].channel!.umbrella.name
            : undefined,
        channel_umbrella_name_code:
          series && series[id] && series[id].channel
            ? series[id].channel!.umbrella.nameCode
            : undefined,
        has_rss_feed:
          series &&
          series[id] &&
          series[id].info &&
          series[id].info[seriesLanguage] &&
          series[id].info[seriesLanguage].rssFeed
            ? true
            : false,
        child_content:
          series[id] && series[id].isChild ? series[id].isChild : undefined,
        episode_index:
          episodes &&
          episodes[id][episode.season].filter(
            (each) => each._id === episode._id
          )[0]
            ? episodes[id][episode.season].filter(
                (each) => each._id === episode._id
              )[0].index
            : undefined,

        genre: series[id] && series[id].genre ? series[id].genre : undefined,
        total_length:
          episodes &&
          episodes[id][episode.season].filter(
            (each) => each._id === episode._id
          )[0]
            ? episodes[id][episode.season].filter(
                (each) => each._id === episode._id
              )[0].length
            : undefined,
            recomm_id
      });
      return playEvent(
        series[id].isChild,
        context,
        series[id].emotion ? series[id].emotion!.join() : undefined,
        episodes[id][episode.season].filter(
          (each) => each._id === episode._id
        )[0].info[seriesLanguage].title,
        episodes[id][episode.season].filter(
          (each) => each._id === episode._id
        )[0]._id,
        episodes[id][episode.season].filter(
          (each) => each._id === episode._id
        )[0].season,
        series[id]._id,
        series[id].info[seriesLanguage].title,
        profile!.isChild,
        contentCategory,
        categoryType,
        categoryPosition,
        contentUniverse,
        universeId,
        series[id].mainImageId,
        timestampe,
        ratio,
        Math.round(
          episodes[id][episode.season].filter(
            (each) => each._id === episode._id
          )[0].length
        ),
        Math.round(series[id].length)
      );
    }
  };
  const playInPlayer = async (
    playerRef: React.RefObject<ReactPlayer>,
    context: string,
    contentUniverse?: string,
    universeId?: string,
    categoryType?: string,
    categoryPosition?: number,
    contentCategory?: string,
    timestampe?: number,
    ratio?: number
  ) => {
    const ac = new AbortController();
    const { signal } = ac;
    if (isPlaying) {
      if (currentEpisode && currentEpisode.episode && !currentEpisode.sample) {
        pause(
          currentEpisode!.series.isChild,
          context,
          currentEpisode!.series.emotion
            ? currentEpisode!.series.emotion.join()
            : undefined,
          currentEpisode!.episode!.info[seriesLanguage].title,
          currentEpisode!.episode._id,
          currentEpisode.episode.season,
          currentEpisode.series._id,
          currentEpisode.series.info[seriesLanguage].title,
          profile!.isChild,
          contentCategory,
          categoryType,
          categoryPosition,
          contentUniverse,
          universeId,
          currentEpisode!.series.mainImageId,
          timestampe ? Math.round(timestampe) : undefined,
          timestampe
            ? Math.round(
                (timestampe / currentEpisode!.episode.length + Number.EPSILON) *
                  1000
              ) / 1000
            : undefined,
          Math.round(currentEpisode!.episode.length),
          Math.round(currentEpisode!.series.length)
        );
      }
      dispatch({
        type: Case.IS_NOT_PLAYING,
      });
      window.analytics.track("Audio Content Paused", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        platform: "Web",
        session_id: uuid,
        sound: volume,
        content_type:
          currentEpisode && currentEpisode.sample ? "Trailer" : "Episode",
        series_id:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series._id
            : undefined,
        episode_id:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode._id
            : undefined,
        content_length: currentEpisode && currentEpisode.series.length,
        position:
          playerRef && playerRef.current
            ? Math.round(playerRef.current.getCurrentTime())
            : undefined,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        episode_name:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode.info[seriesLanguage].title
            : undefined,
        channel_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel._id
            : undefined,
        channel_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.name
            : undefined,
        channel_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.nameCode
            : undefined,
        channel_umbrella_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella._id
            : undefined,
        channel_umbrella_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.name
            : undefined,
        channel_umbrella_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.nameCode
            : undefined,
        has_rss_feed:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.info &&
          currentEpisode.series.info[seriesLanguage] &&
          currentEpisode.series.info[seriesLanguage].rssFeed
            ? true
            : false,
        child_content:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.isChild
            : undefined,
        episode_index:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode.index
            : undefined,

        genre:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.genre
            : undefined,
        total_length:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode.length
            : undefined,
      });
      if (currentEpisode && currentEpisode!.episode) {
        try {
          const historyPending = await sendProgressService(
            {
              userId: user._id,
              profileId: profile!._id!,
              seriesId: currentEpisode!.series._id,
              episodeId: currentEpisode!.episode!._id,
              start: playerRef.current!.getCurrentTime(),
              progress: playerRef.current!.getCurrentTime(),
            },
            user,
            profile!,
            signal
          );
          await historyPending.json();
        } catch (e) {}
      }
    } else {
      if (currentEpisode && currentEpisode!.episode) {
        playEvent(
          currentEpisode!.series.isChild,
          context,
          currentEpisode!.series.emotion
            ? currentEpisode!.series.emotion!.join()
            : undefined,
          currentEpisode!.episode.info[seriesLanguage].title,
          currentEpisode!.episode._id,
          currentEpisode!.episode.season,
          currentEpisode!.series._id,
          currentEpisode!.series.info[seriesLanguage].title,
          profile!.isChild,
          contentCategory,
          categoryType,
          categoryPosition,
          contentUniverse,
          universeId,
          currentEpisode!.series.mainImageId,
          timestampe,
          ratio,
          Math.round(currentEpisode!.episode.length),
          Math.round(currentEpisode!.series.length)
        );
      }
      window.analytics.track("Audio Content Resumed", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        platform: "Web",
        session_id: uuid,
        sound: volume,
        content_type:
          currentEpisode && currentEpisode.sample ? "Trailer" : "Episode",
        series_id:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series._id
            : undefined,
        episode_id:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode._id
            : undefined,
        content_length: currentEpisode && currentEpisode.series.length,
        position:
          playerRef && playerRef.current
            ? Math.round(playerRef.current.getCurrentTime())
            : undefined,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        episode_name:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode.info[seriesLanguage].title
            : undefined,
        channel_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel._id
            : undefined,
        channel_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.name
            : undefined,
        channel_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.nameCode
            : undefined,
        channel_umbrella_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella._id
            : undefined,
        channel_umbrella_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.name
            : undefined,
        channel_umbrella_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.nameCode
            : undefined,
        has_rss_feed:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.info &&
          currentEpisode.series.info[seriesLanguage] &&
          currentEpisode.series.info[seriesLanguage].rssFeed
            ? true
            : false,
            child_content:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.isChild
              : undefined,
          episode_index:
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.index
              : undefined,
  
          genre:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.genre
              : undefined,
          total_length:
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.length
              : undefined,
      });
      dispatch({
        type: Case.IS_PLAYING,
      });
    }
  };
  return { play, playInPlayer };
};
