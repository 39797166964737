import React, { ReactElement } from "react";
import SeriesType from "../interfaces/SeriesType";

import UseHomeHeaderCard from "../ressources/UseHomeHeaderCard";

interface Props {
  shuffledHeader?: any[];
  shuffledUniverse?: any[];
  each?: SeriesType;
  actualIndex: number;
  universToShow: string | undefined;
  seriesLanguage: string;
  isCurrent?: boolean;
  isFromClick: boolean;
}
export default function HomeHeaderCard({
  shuffledHeader,
  shuffledUniverse,
  each,
  actualIndex,
  universToShow,
  seriesLanguage,
  isCurrent,
  isFromClick,
}: Props): ReactElement {
  const { isLiked, addToLikeList, openSeries } = UseHomeHeaderCard({
    each,
    universToShow,
    seriesLanguage,
    shuffledHeader,
    shuffledUniverse,
    actualIndex,
    isFromClick,
    isCurrent,
  });

  return (
    <div
      onClick={() => openSeries()}
      className="home-extract-slider-element"
      style={{
        background:
          each && each.coverImage
            ? `center center / cover url(${each.coverImage})`
            : "#343434",
      }}
    >
      <svg
        width="320"
        height="320"
        viewBox="0 0 320 320"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="320" height="320" rx="8" fill="#343434" />
      </svg>
      <img className="play-epidode-button" src="/image/playLogo.svg" alt="" />

      <img className="title-header" src={each && each.titleImage} alt="" />
      <div onClick={(e) => addToLikeList(e)} className="like-header">
        <img
          src={
            isLiked
              ? "/image/heartHeader.svg"
              : "/image/heartHeaderNotLiked.svg"
          }
          alt=""
        />
      </div>
    </div>
  );
}
