import { shuffle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { isSafari } from "react-device-detect";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import EventStore from "../stores/AmplitudeStore";
import { UseSeriesLanguage } from "./UseSeriesLanguage";

interface Props {
  universToShow: string | undefined;
  isFromSeries?: true;
}

export default function UseHomeHeaderSlider({
  universToShow,
  isFromSeries,
}: Props): {
  nextArrowRef: React.RefObject<HTMLImageElement>;
  prevArrowRef: React.RefObject<HTMLImageElement>;
  classes: any;
  fromClick: boolean;
  actualIndex: number;
  setActualIndex: React.Dispatch<React.SetStateAction<number>>;
  shuffledHeader: any[] | undefined;
  seriesLanguage: string;
  shuffledUniverse: any[] | undefined;
} {
  const nextArrowRef = useRef<HTMLImageElement>(null);
  const prevArrowRef = useRef<HTMLImageElement>(null);
  const [actualIndex, setActualIndex] = useState<number>(0);
  const [shuffledHeader, setShuffledHeader] = useState<any[] | undefined>();
  const [shuffledUniverse, setShuffledUniverse] = useState<any[] | undefined>(
    []
  );
  const [fromClick, setFromClick] = useState<boolean>(false);
  const { viewHome, viewUniverse } = EventStore();

  const { home, universes, universesCategories, profile } = useSelector(
    (state: StoreType) => ({
      ...state.ProfileReducer,
      ...state.SeriesReducer,
      ...state.UserReducer,
      ...state.PlayerReducer,
    })
  );

  const useStyles = createUseStyles({
    bg: {
      position: "relative",
      zIndex: "10",
      "&::before": {
        filter: isSafari ? "none" : "blur(64px)",
        zIndex: "-2",
        content: '""',
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: ({ url }) =>
          url ? `url(${url})` : 'url("/image/coverImagePlaceholder.svg")',
      },
      "&::after": {
        "-webkit-backdrop-filter": isSafari ? "blur(64px)" : "none",
        bottom: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        content: "''",
        zIndex: "-1",
        position: "absolute",
        background:
          "linear-gradient(rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 0) 51.04%, rgb(25, 25, 25) 92.04%) repeat scroll 0% 0%",
      },
    },
  });
  const classes = useStyles({
    url:
      home &&
      home.headers &&
      shuffledHeader &&
      [...shuffledHeader, ...shuffledHeader][actualIndex] &&
      shuffledHeader.length > 0 &&
      !universToShow
        ? [...shuffledHeader, ...shuffledHeader][actualIndex].coverImage
        : universesCategories &&
          universes &&
          universToShow &&
          shuffledUniverse &&
          [...shuffledUniverse, ...shuffledUniverse][actualIndex]
        ? [...shuffledUniverse, ...shuffledUniverse][actualIndex].coverImage
        : "none",
  });
  const { seriesLanguage } = UseSeriesLanguage();

  useEffect(() => {
    if (home && home.headers && home.headers.length > 0 && !universToShow) {
      const shuffled = shuffle(home.headers);
      setShuffledHeader(shuffled);
    }
    return () => setShuffledHeader(undefined)
  }, [home, universToShow]);

  useEffect(() => {
    if (
      universesCategories &&
      universToShow &&
      universesCategories[
        universesCategories.findIndex((each) => each._id === universToShow)
      ].seriesList
    ) {
      const shuffled = shuffle(
        universesCategories[
          universesCategories.findIndex((each) => each._id === universToShow)
        ].seriesList
      );
      setShuffledUniverse(shuffled);
      setActualIndex(0);
      viewUniverse(
        shuffled[0]._id,
        universes
          ? universes[
              universes.findIndex(
                (each) => each.headerCategoryId === universToShow
              )
            ].title
          : "Main Home",
        seriesLanguage,
        universToShow
      );
    }
    return () => setShuffledUniverse(undefined);
  }, [
    universToShow,
    universesCategories,
    seriesLanguage,
    viewUniverse,
    universes,
  ]);

  useEffect(() => {
    if (
      profile &&
      shuffledHeader &&
      shuffledHeader[0] &&
      shuffledHeader[0]._id &&
      !universToShow &&
      seriesLanguage
    ) {
      viewHome(
        shuffledHeader[0]._id,
        profile.isChild ? "Kids Main Home" : "Main Home",
        seriesLanguage,
        profile.isChild ? "Kids Main Home" : "Main Home"
      );
    }
  }, [shuffledHeader, universToShow, seriesLanguage, viewHome, profile]);

  useEffect(() => {        
    if (
      !isFromSeries && !shuffledUniverse &&
      !universToShow &&
      sessionStorage.getItem("profile")! && JSON.parse(sessionStorage.getItem("profile")!) &&
      shuffledHeader &&
      shuffledHeader[0] &&
      shuffledHeader[0]._id 
    ) {
      window.analytics.page(`Viewed Home Page`, undefined, {
        "content_focused_on_header": shuffledHeader[0]._id,
        "universe_name": JSON.parse(sessionStorage.getItem("profile")!).isChild ? "Kids Main Home" : "Main Home",
        catalog: localStorage.getItem("contentLanguage"),
        "universe_id": JSON.parse(sessionStorage.getItem("profile")!).isChild ? "Kids Main Home" : "Main Home",
      });
    }
  }, [shuffledHeader, universToShow, isFromSeries, shuffledUniverse]);

  useEffect(() => {
    if (fromClick) {
      setTimeout(() => {
        setFromClick(false);
      }, 100);
    }
  }, [fromClick]);

  return {
    nextArrowRef,
    prevArrowRef,
    classes,
    fromClick,
    actualIndex,
    setActualIndex,
    shuffledHeader,
    seriesLanguage,
    shuffledUniverse,
  };
}
