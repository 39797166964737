import React, { ReactElement, useState } from "react";
import EpisodeType from "../interfaces/EpisodeType";
import { language } from "../stores/18nStore";
import EventStore from "../stores/AmplitudeStore";

interface Props {
  episode: EpisodeType;
  seriesLanguage: string;
}

export default function SeriesEpisodesDetails({
  episode,
  seriesLanguage,
}: Props): ReactElement {
  const { clickSeeMoreSeriesEpisodeDescription } = EventStore();
  const [isShowMoreState, setIsShowMoreState] = useState<boolean>(false);
  const showingMore = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    isShowMore: boolean
  ) => {
    e.stopPropagation();
    clickSeeMoreSeriesEpisodeDescription();
    setIsShowMoreState(isShowMore);
  };
  return (
    <>
      {episode.info[seriesLanguage].details &&
      episode.info[seriesLanguage].details.length <= 265 ? (
        <h3>{episode.info[seriesLanguage].details.replace(/<[^>]*>/g, "")}</h3>
      ) : episode.info[seriesLanguage].details &&
        episode.info[seriesLanguage].details.length > 265 ? (
        <h3>
          {isShowMoreState ? (
            <>
              {episode.info[seriesLanguage].details.replace(/<[^>]*>/g, "")}
              <strong onClick={(e) => showingMore(e, false)}>
                {language("seeless")}
              </strong>
            </>
          ) : (
            <>
              {episode.info[seriesLanguage].details.substr(0, 265).replace(/<[^>]*>/g, "")}
              {episode.info[seriesLanguage].details.length > 265 && (
                <strong onClick={(e) => showingMore(e, true)}>
                  {language("seemore")}
                </strong>
              )}
            </>
          )}
        </h3>
      ) : null}
    </>
  );
}
