import StoreType from "../interfaces/StoreType";
import { Action, Case } from "../interfaces/ReducerCase";

const SeriesStore: StoreType["SeriesReducer"] = {
  series: {},
  episodes: {},
  seriesHistory: undefined,
  episodesHistory: undefined,
};
export default function SeriesReducer(state = SeriesStore, action: Action) {
  switch (action.type) {
    case Case.PUSH_SERIES: {
      return {
        ...state,
        series: {
          ...state.series,
          [action.payload.series._id]: action.payload.series,
        },
      };
    }
    case Case.PUSH_EPISODES: {
      return {
        ...state,
        episodes: {
          ...state.episodes,
          [action.payload.series._id]: {
            ...state.episodes[action.payload.series._id],
            [action.payload.season]: action.payload.episodes,
          },
        },
      };
    }
    case Case.PUSH_SERIES_HISTORY: {
      sessionStorage.setItem(
        "syb_s_h",
        JSON.stringify({ seriesHistory: action.payload.seriesHistory })
      );
      return {
        ...state,
        seriesHistory: action.payload.seriesHistory,
      };
    }
    case Case.UPDATE_SERIES_EPISODE_HISTORY: {
      sessionStorage.setItem(
        "syb_s_h",
        JSON.stringify({ seriesHistory: action.payload.seriesHistory })
      );
      return {
        ...state,
        seriesHistory: {
          ...state.seriesHistory,
          [action.payload.id]: {
            ...state.seriesHistory![action.payload.id],
            episode: action.payload.episodeHistory,
          },
        },
      };
    }
    case Case.PUSH_EPISODES_HISTORY: {
      return {
        ...state,
        episodesHistory: action.payload.seriesHistory,
      };
    }
    case Case.UPDATE_EPISODE_HISTORY: {
      return {
        ...state,
        episodesHistory: {
          ...state.episodesHistory,
          [action.payload.seriesId]: {
            ...state.episodesHistory![action.payload.seriesId],
            [action.payload.episodeId]: {
              ...action.payload.seriesHistory,
            },
          },
        },
      };
    }
    case Case.CREATE_EPISODE_HISTORY: {
      return {
        ...state,
        episodesHistory: {
          ...state.episodesHistory,
          [action.payload.seriesId]: {
            [action.payload.episodeId]: {
              ...action.payload.seriesHistory,
            },
          },
        },
      };
    }
    case Case.UPDATE_SERIES_HISTORY: {
      const correctobject = state.seriesHistory;
      if (correctobject && correctobject[action.payload.seriesId]) {
        delete correctobject[action.payload.seriesId];
      }
      sessionStorage.setItem(
        "syb_s_h",
        JSON.stringify({
          seriesHistory: {
            [action.payload.seriesId]: {
              ...action.payload.seriesHistory,
            },
          },
        })
      );
      return {
        ...state,
        seriesHistory: {
          [action.payload.seriesId]: {
            ...action.payload.seriesHistory,
          },
          ...correctobject,
        },
      };
    }
    case Case.REINIT_EPISODE_HISTORY: {
      return {
        ...state,
        episodesHistory: undefined,
      };
    }
    case Case.REINIT_SERIES_HISTORY: {
      sessionStorage.removeItem("syb_s_h");
      return {
        ...state,
        seriesHistory: undefined,
      };
    }
    case Case.SIGNOUT: {
      sessionStorage.removeItem("syb_s_h");
      return {
        series: {},
        episodes: {},
        seriesHistory: undefined,
        episodesHistory: undefined,
      };
    }
    default:
      return state;
  }
}
