import { Dispatch, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";

export const UseUrlNext = () => {
  const location = useLocation();
  const history = useHistory();
  const { profile } = useSelector((state: StoreType) => ({
    ...state.ProfileReducer,
  }));
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  useEffect(() => {
    if (!profile) {
      stableDispatch({
        type: Case.SET_NEXT_URL,
        payload: { url: location.pathname },
      });
      history.push("/choose-profile");
    }
  }, [profile, history, stableDispatch, location]);
};
