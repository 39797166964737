import React, { ReactElement } from "react";
import Loader from "../components/Loader";

export default function LoaderFullScreen(): ReactElement {
  return (
    <div className="loading-settings">
      <Loader customeClassName="splash-loader" />
      <img src="/image/logo/brand.svg" alt="Sybel Compagny Name" />
    </div>
  );
}
