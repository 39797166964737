import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import HomeMainCategoriesCard from "../components/HomeMainCategoriesCard";
import HomeNavBar from "../components/HomeNavBar";
import LoaderFullScreen from "../components/LoaderFullScreen";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import { getSeriesService } from "../services/SeriesService";
import { language } from "../stores/18nStore";
import amplitude from "amplitude-js";

export default function Liste(): ReactElement {
  const { isLoading, profile, user, userContentLanguage, profiles } =
    useSelector((state: StoreType) => ({
      ...state.RouterReducer,
      ...state.ProfileReducer,
      ...state.SeriesReducer,
      ...state.UserReducer,
    }));
  const history = useHistory();
  const [list, setList] = useState<SeriesType[]>();
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (!profile || !profiles) {
      window.scrollTo(0, 0);
      history.push("/choose-profile");
    } else if (
      profiles &&
      profile &&
      profiles[profiles.findIndex((each) => each._id === profile._id)] &&
      profiles[profiles.findIndex((each) => each._id === profile._id)].likeList
    ) {
      Promise.all(
        Array.from(
          new Set(
            profiles[
              profiles.findIndex((each) => each._id === profile._id)
            ].likeList
          )
        ).map(async (each) => {
          const dataPending = await getSeriesService(
            each,
            user,
            userContentLanguage
          );
          const dataRes: SeriesType = await dataPending.json();
          return dataRes;
        })
      ).then((result) => setList(result));
    }
  }, [profile, user, userContentLanguage, profiles, history]);
  const [universToShow, setUniversToShow] =
    useState<string | undefined>(undefined);
  useEffect(() => {
    amplitude.getInstance().logEvent("View My List Screen");
  }, []);
  useEffect(() => {
    window.analytics.page('Viewed My List Page')
  }, []);

  const { seriesLanguage } = UseSeriesLanguage();
  return isLoading ? (
    <LoaderFullScreen />
  ) : list && list.length > 0 ? (
    <div className="list-container">
      <p className="search-summup">{language("likeList")}</p>
      <HomeNavBar
        universToShow={universToShow}
        setUniversToShow={setUniversToShow}
        search={search}
        setSearch={setSearch}
      />
      <div className="search-covers">
        {list &&
          seriesLanguage &&
          list
            .filter((each) => each.info[seriesLanguage])
            .map((each, index) => {
              return (
                <HomeMainCategoriesCard
                  likeListe={true}
                  index={index}
                  key={index}
                  each={each}
                />
              );
            })}
      </div>
    </div>
  ) : list && list.length === 0 ? (
    <div className="list-container">
      <p className="search-summup">{language("likeList")}</p>
      <HomeNavBar
        universToShow={universToShow}
        setUniversToShow={setUniversToShow}
        search={search}
        setSearch={setSearch}
      />
      <div className="like-not-yet-covers">
        <p className="list-not-yet">{language("noLikeList")}</p>
      </div>
    </div>
  ) : (
    <LoaderFullScreen />
  );
}
