import { useEffect, useState } from "react";

export default function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const upHandler = ({ keyCode }) => {
      if (keyCode === targetKey) {
        setKeyPressed(false);
      }
    };
    function downHandler(e) {
      if (e.keyCode === targetKey) {
        if (e.keyCode === 32 && e.target === document.body) {
          e.preventDefault();
        }
        setKeyPressed(true);
      }
    }

    window.addEventListener("keydown", downHandler);

    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);

      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
}
