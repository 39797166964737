import i18next from "i18next";
import React, { ReactElement, useCallback, useEffect } from "react";
import SeriesType from "../interfaces/SeriesType";
import { ProductStripeType } from "../interfaces/UserType";
import { UsePopUpPayment } from "../ressources/UsePopUpPayment";
import { language } from "../stores/18nStore";
import Loader from "./Loader";
import ReactPixel from "react-facebook-pixel";
import EventStore from "../stores/AmplitudeStore";
import StoreType from "../interfaces/StoreType";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { Action, Case } from "../interfaces/ReducerCase";
import { CardElement } from "@stripe/react-stripe-js";
import { StripeBaseEllementsStyle } from "../utils/DynamicStyle";

interface Props {
  selectedOffer: {
    index: number;
    offer: ProductStripeType;
  };
  isMultipleOffers: boolean;
  setIsPayment: React.Dispatch<React.SetStateAction<boolean>>;
  seriesContext?: SeriesType;
  isSuccess: boolean;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  resumeOnSubscribed: (button: string) => void;
  setUpdatedUser: React.Dispatch<any>;
  seriesLanguage: string;
  isNewSession?: true;
  dismissPopUp: (isSubscribed?: boolean | undefined) => void;
  setIsUnsubscibed: (value: React.SetStateAction<boolean>) => void
}

export default function PopUpPaymentMarketing({
  selectedOffer,
  isMultipleOffers,
  setIsPayment,
  seriesContext,
  isSuccess,
  setIsSuccess,
  resumeOnSubscribed,
  setUpdatedUser,
  seriesLanguage,
  isNewSession,
  dismissPopUp,
  setIsUnsubscibed
}: Props): ReactElement {
  const {
    priceText,
    isPaymentLoading,
    couponTextResponse,
    isCouponLoading,
    couponValue,
    setCouponValue,
    applyCoupon,
    handleSubmit,
    couponErrorText,
    isCouponValidated,
    stripe,
    elements,
  } = UsePopUpPayment(
    selectedOffer,
    isMultipleOffers,
    setUpdatedUser,
    setIsSuccess,
    selectedOffer.offer.id,
    CardElement
  );
  const goBackToOffers = () => {
    return setIsPayment(false);
  };
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const { currentEpisode, context, uuid } = useSelector((state: StoreType) => ({
    ...state.PlayerReducer,
    ...state.UserReducer,
  }));
  const { stripeViewPaymentScreen } = EventStore();
  useEffect(() => {
    if (selectedOffer && selectedOffer.offer && isSuccess) {
      ReactPixel.track("Purchase", {
        order_id: selectedOffer.offer.id,
        subscription_type: `${selectedOffer.offer.amount} ${selectedOffer.offer.interval} ${selectedOffer.offer.currency}`,
        Trial:
          selectedOffer.offer.trial_period_days &&
          selectedOffer.offer.trial_period_days > 0
            ? true
            : false,
        numero_trial:
          selectedOffer.offer.trial_period_days &&
          selectedOffer.offer.trial_period_days > 0
            ? selectedOffer.offer.trial_period_days
            : null,
        value: selectedOffer.offer.currency,
      });
    }
  }, [selectedOffer, isSuccess]);
  useEffect(() => {
    if (selectedOffer && selectedOffer.offer) {
      ReactPixel.track("InitiateCheckout", {
        order_id: selectedOffer.offer.id,
        subscription_type: `${selectedOffer.offer.amount} ${selectedOffer.offer.interval} ${selectedOffer.offer.currency}`,
        Trial:
          selectedOffer.offer.trial_period_days &&
          selectedOffer.offer.trial_period_days > 0
            ? true
            : false,
        numero_trial:
          selectedOffer.offer.trial_period_days &&
          selectedOffer.offer.trial_period_days > 0
            ? selectedOffer.offer.trial_period_days
            : null,
      });
    }
  }, [selectedOffer]);

  useEffect(() => {
    stripeViewPaymentScreen(
      "Default offers",
      selectedOffer.offer.id,
      selectedOffer.offer.amount,
      selectedOffer.offer.interval,
      undefined,
      context ? context : undefined,
      currentEpisode && currentEpisode.episode && currentEpisode.episode.info
        ? currentEpisode.episode.info[seriesLanguage].title
        : undefined,
      currentEpisode && currentEpisode.episode && currentEpisode.episode
        ? currentEpisode.episode._id
        : undefined,
      currentEpisode && currentEpisode.episode && currentEpisode.episode
        ? currentEpisode.episode.season
        : undefined,
      currentEpisode && currentEpisode.series
        ? currentEpisode.series._id
        : undefined,
      currentEpisode && currentEpisode.series && currentEpisode.series.info
        ? currentEpisode.series.info[seriesLanguage].title
        : undefined
    );
  }, [
    currentEpisode,
    seriesLanguage,
    context,
    selectedOffer,
    stripeViewPaymentScreen,
  ]);
  useEffect(() => {
    if (!!selectedOffer && selectedOffer.offer) {
      window.analytics.page("Viewed Stripe Payment Page", undefined, {
        context: context ? context : undefined,
        plan_id: selectedOffer.offer.id,
        plan_period: selectedOffer.offer.interval,
        plan_price: selectedOffer.offer.amount,
        platform: "Web",
        presented_offers: "Default offers",
        session_id: uuid,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
      });
    }
  }, [selectedOffer, uuid, context]);
  useEffect(() => {
    if (isSuccess){
      setIsUnsubscibed(false)
    }
  }, [isSuccess, setIsUnsubscibed]);

  return (
    <>
      {isSuccess ? (
        <div className="pop-up-payment-container" id="paymentSuccess">
          <img src="/image/logo/sybelplus_logo.svg" alt="" />
          {seriesContext && !isNewSession ? (
            <>
              <p className="pop-up-payment-container-success-paragrapgh">
                {language("sybelPlusWelcome")}
              </p>
              <p>
                {language("sybelPlusSeries")}{" "}
                {seriesContext.info[seriesLanguage].title}
              </p>
            </>
          ) : (
            <>
              <p className="pop-up-payment-container-success-paragrapgh-no-context">
                {language("sybelPlusWelcome")}
              </p>
              <p className="sybel-plus-no-context-success">
                {language("sybelPlusNoContext")}
              </p>
            </>
          )}
          {seriesContext && !isNewSession ? (
            <div className="cover-series-context">
              <img
                className={seriesContext ? "header-image-pop-up" : "logo"}
                src={
                  seriesContext
                    ? seriesContext.titleImage
                    : "/image/logo/whitelogo.svg"
                }
                alt=""
              />
            </div>
          ) : null}
          {seriesContext && !isNewSession ? (
            <button
              className="series-context-play-series"
              onClick={() => resumeOnSubscribed("popupSuccessListen")}
            >
              <img src="/image/playExtract.svg" alt="" /> {language("listen")}{" "}
              {seriesContext.info[seriesLanguage].title}
            </button>
          ) : (
            <button
              className="series-context-play-series"
              onClick={() => dismissPopUp()}
            >
              {language("letsGo")}
            </button>
          )}
        </div>
      ) : (
        <>
          <img
            onClick={() => {
              stableDispatch({
                type: Case.SET_POPUP_CONTEXT,
                payload: { context: "Back From Payment Screen" },
              });
              goBackToOffers();
            }}
            className="pop-up-back"
            src="/image/pop-up-back.svg"
            alt="arrow back offers"
          />
          <div className="pop-up-payment-container" id="payment">
            <p className="title-payment">
              {language("stripeSubscriptionTitle")}
            </p>
            <div className="pop-up-payment">
              <div className="stripe-englobe">
                <CardElement
                  options={{
                    style: { base: StripeBaseEllementsStyle },
                    hidePostalCode: true,
                  }}
                />
              </div>
              <div className="coupon">
                <input
                  placeholder={language("discountPaymentPlaceholder")}
                  className="stripe-englobe"
                  type="text"
                  onChange={(e) =>
                    setCouponValue(e.target.value.toUpperCase().trim())
                  }
                  value={couponValue}
                />
                <button
                  type="button"
                  disabled={
                    couponValue === "" ||
                    (isCouponValidated && couponValue === "")
                  }
                  onClick={applyCoupon}
                  style={{
                    fontSize:
                      isCouponValidated && couponValue === "" ? "10px" : "18px",
                  }}
                >
                  {isCouponLoading ? (
                    <Loader />
                  ) : isCouponValidated && couponValue === "" ? (
                    language("couponClick")
                  ) : (
                    language("ok")
                  )}
                </button>
              </div>
              {couponTextResponse && (
                <p className="offer-payment-summup-details-coupons-res">
                  {couponTextResponse}
                </p>
              )}
              {couponErrorText && (
                <p className="offer-payment-summup-error-coupons">
                  {couponErrorText}
                </p>
              )}
            </div>
            <div className="bills-summup">
              <div className="offer-payment-summup-left">
                <p className="offer-payment-summup-title">
                  {selectedOffer.offer.interval === "month"
                    ? language("monthly")
                    : language("yearly")}
                </p>
              </div>
              {priceText && (
                <p className="offer-payment-summup-details">
                  {selectedOffer.offer.interval === "month" ||
                  (isCouponValidated && selectedOffer.offer.interval === "year")
                    ? priceText
                    : language("defaultYearPrice", {
                        price: `${selectedOffer.offer.amount.toFixed(2)} ${
                          selectedOffer.offer.currency
                        }`,
                      })}
                </p>
              )}
            </div>
            <button
              className="payment-continue"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              {isPaymentLoading ? (
                <Loader />
              ) : selectedOffer &&
                selectedOffer.offer &&
                selectedOffer.offer.trial_period_days &&
                selectedOffer.offer.trial_period_days !== 0 ? (
                language("beginTrialPremium", {
                  count: selectedOffer.offer.trial_period_days,
                })
              ) : (
                language("continue")
              )}
            </button>
            <i>
              {language("cgu1")}{" "}
              <a
                href={`https://www.sybel.co/${i18next.language.substr(
                  0,
                  2
                )}/cgu#subscription-conditions`}
              >
                {language("cgu2")}
              </a>
              {language("cgu3")}
            </i>
            {(
              !selectedOffer ||
              !stripe ||
              !elements) && (
              <div
                className="loading-settings"
                style={{ height: "100%", width: "100%" }}
              >
                <Loader customeClassName="splash-loader" />
                <img src="/image/logo/brand.svg" alt="Sybel Compagny Name" />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
