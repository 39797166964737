import React, { ReactElement, useEffect, useRef, useState } from "react";
import Slider from "rc-slider";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import ReactPlayer from "react-player";
import { sendProgressService } from "../services/SeriesService";
import EventStore from "../stores/AmplitudeStore";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";

interface Props {
  playerRef: React.RefObject<ReactPlayer>;
}

export default function PlayerCurrentTime({ playerRef }: Props): ReactElement {
  const { currentEpisode, profile, user, uuid, volume } = useSelector(
    (state: StoreType) => ({
      ...state.SeriesReducer,
      ...state.PlayerReducer,
      ...state.ProfileReducer,
      ...state.UserReducer,
    })
  );
  const { play, pause } = EventStore();
  const [isCurrentTimeReversed, setIsCurrentTimeReversed] =
    useState<boolean>(true);
  const { seriesLanguage } = UseSeriesLanguage();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [actual, setActual] = useState<number>();
  const [beforeChange, setBeforeChange] = useState<number>(0);
  const ref = useRef<{ isSeeking: boolean }>({ isSeeking: false });
  useEffect(() => {
    const curentInterval = setInterval(() => {
      if (!isDragging) {
        setActual(playerRef.current?.getCurrentTime());
      }
    }, 500);
    return () => clearInterval(curentInterval);
  }, [playerRef, isDragging]);
  useEffect(() => {
    const ac = new AbortController();
    const { signal } = ac;
    const updateHistory = async () => {
      try {
        const historyPending = await sendProgressService(
          {
            userId: JSON.parse(localStorage.getItem("user")!)._id,
            profileId: JSON.parse(localStorage.getItem("profile")!)._id!,
            seriesId: currentEpisode!.series._id,
            episodeId: currentEpisode!.episode!._id,
            start: playerRef.current!.getCurrentTime(),
            progress: playerRef.current!.getCurrentTime(),
          },
          JSON.parse(localStorage.getItem("user")!),
          JSON.parse(localStorage.getItem("profile")!),
          signal
        );
        await historyPending.json();
      } catch (e) {}
    };
    const interval = setInterval(() => {
      if (
        currentEpisode &&
        currentEpisode.episode &&
        JSON.parse(localStorage.getItem("user")!) &&
        JSON.parse(localStorage.getItem("user")!).token &&
        JSON.parse(localStorage.getItem("user")!).id &&
        JSON.parse(localStorage.getItem("profile")!)
      ) {
        updateHistory();
      } else {
        clearInterval(interval);
      }
    }, 5000);
    return () => {
      clearInterval(interval);
      ac.abort();
    };
  }, [currentEpisode, playerRef]);

  const setCurrentTime = async (e: number) => {
    playerRef.current!.seekTo(e, "seconds");
    pause(
      currentEpisode!.series.isChild,
      "Player Progress Bar",
      currentEpisode!.series.emotion
        ? currentEpisode!.series.emotion.join()
        : undefined,
      currentEpisode!.episode!.info[seriesLanguage].title,
      currentEpisode!.episode!._id,
      currentEpisode!.episode!.season,
      currentEpisode!.series._id,
      currentEpisode!.series.info[seriesLanguage].title,
      profile!.isChild,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      currentEpisode!.series.mainImageId,
      Math.round(beforeChange),
      Math.round(
        (beforeChange / currentEpisode!.episode!.length + Number.EPSILON) * 1000
      ) / 1000,
      currentEpisode!.episode!.length,
      currentEpisode!.series.length
    );

    const ac = new AbortController();
    const { signal } = ac;
    try {
      const historyPending = await sendProgressService(
        {
          userId: user._id,
          profileId: profile!._id!,
          seriesId: currentEpisode!.series._id,
          episodeId: currentEpisode!.episode!._id,
          start: e,
          progress: e,
        },
        user,
        profile!,
        signal
      );
      await historyPending.json();
      play(
        currentEpisode!.series.isChild,
        "Player Progress Bar",
        currentEpisode!.series.emotion
          ? currentEpisode!.series.emotion.join()
          : undefined,
        currentEpisode!.episode!.info[seriesLanguage].title,
        currentEpisode!.episode!._id,
        currentEpisode!.episode!.season,
        currentEpisode!.series._id,
        currentEpisode!.series.info[seriesLanguage].title,
        profile!.isChild,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        currentEpisode!.series.mainImageId,
        Math.round(e),
        Math.round(
          (e / currentEpisode!.episode!.length + Number.EPSILON) * 1000
        ) / 1000,
        Math.round(currentEpisode!.episode!.length),
        Math.round(currentEpisode!.series.length)
      );
    } catch (e) {}
  };
  return (
    <div className="progress-container">
      <div className="progress">
        <Slider
          handleStyle={{
            scale: isDragging ? "2" : undefined,
            transition: "scale .1s ease-in",
          }}
          step={0.1}
          min={0}
          max={currentEpisode!.episode!.length}
          onBeforeChange={(e) => {
            if (ref && ref.current && !ref.current.isSeeking) {
              ref.current.isSeeking = true;
              window.analytics.track("Audio Content Seek Started", {
                subscription: !!localStorage.getItem("user")
                  ? JSON.parse(localStorage.getItem("user")!).subscription
                  : 0,
                platform: "Web",
                session_id: uuid,
                sound: volume,
                content_type:
                  currentEpisode && currentEpisode.sample
                    ? "Trailer"
                    : "Episode",
                    content_name:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.info[seriesLanguage].title
                    : undefined,
                series_id:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series._id
                    : undefined,
                episode_id:
                  currentEpisode && currentEpisode.episode
                    ? currentEpisode.episode._id
                    : undefined,
                content_length: currentEpisode && currentEpisode.series.length,
                position: Math.round(e),
                signup_type: localStorage.getItem("sy_signup_type")
                  ? localStorage.getItem("sy_signup_type")
                  : undefined,
                episode_name:
                  currentEpisode && currentEpisode.episode
                    ? currentEpisode.episode.info[seriesLanguage].title
                    : undefined,
                channel_id:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel._id
                    : undefined,
                channel_name:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.name
                    : undefined,
                channel_name_code:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.nameCode
                    : undefined,
                channel_umbrella_id:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.umbrella._id
                    : undefined,
                channel_umbrella_name:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.umbrella.name
                    : undefined,
                channel_umbrella_name_code:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.umbrella.nameCode
                    : undefined,
                    has_rss_feed:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.info[seriesLanguage] &&
                    currentEpisode.series.info[seriesLanguage].rssFeed
                      ? true
                      : false,
                      child_content:
                      currentEpisode && currentEpisode.series
                        ? currentEpisode.series.isChild
                        : undefined,
                    chronological:
                      currentEpisode && currentEpisode.series
                        ? currentEpisode.series.chronological
                        : undefined,
                    classification:
                      currentEpisode && currentEpisode.series
                        ? currentEpisode.series.classification
                        : undefined,
                    emotion:
                      currentEpisode && currentEpisode.series
                        ? currentEpisode.series.emotion
                        : undefined,
                    episode_index: currentEpisode && currentEpisode.episode
                      ? currentEpisode.episode.index
                      : undefined,
                    episode_length: currentEpisode && currentEpisode.episode
                      ? currentEpisode.episode.length
                      : undefined,
                    format:
                      currentEpisode && currentEpisode.series
                        ? currentEpisode.series.format
                        : undefined,
                    genre:
                      currentEpisode && currentEpisode.series
                        ? currentEpisode.series.genre
                        : undefined,
                    season: currentEpisode && currentEpisode.episode
                      ? currentEpisode.episode.season
                      : undefined,
                    total_length: currentEpisode && currentEpisode.episode
                      ? currentEpisode.episode.length
                      : undefined,
                      is_unit:
                      currentEpisode && currentEpisode.series
                        ? currentEpisode.series.isUnit
                        : undefined,
                    });
                    setIsDragging(true);
                    return setBeforeChange(e);
                  }
                }}
                onAfterChange={(e) => {
                  window.analytics.track("Audio Content Seek Completed", {
                    subscription: !!localStorage.getItem("user")
                    ? JSON.parse(localStorage.getItem("user")!).subscription
                    : 0,
                    platform: "Web",
                    session_id: uuid,
                    sound: volume,
                    content_type:
                    currentEpisode && currentEpisode.sample ? "Trailer" : "Episode",
                    content_name:
                    currentEpisode && currentEpisode.series
                  ? currentEpisode.series.info[seriesLanguage].title
                  : undefined,
                  series_id:
                currentEpisode && currentEpisode.series
                ? currentEpisode.series._id
                : undefined,
                episode_id:
                currentEpisode && currentEpisode.episode
                ? currentEpisode.episode._id
                : undefined,
                content_length: currentEpisode && currentEpisode.series.length,
                position: Math.round(e),
                signup_type: localStorage.getItem("sy_signup_type")
                ? localStorage.getItem("sy_signup_type")
                : undefined,
                episode_name:
                currentEpisode && currentEpisode.episode
                ? currentEpisode.episode.info[seriesLanguage].title
                : undefined,
                channel_id:
                currentEpisode &&
                currentEpisode.series &&
                currentEpisode.series.channel
                ? currentEpisode.series.channel._id
                : undefined,
                channel_name:
                currentEpisode &&
                currentEpisode.series &&
                currentEpisode.series.channel
                ? currentEpisode.series.channel.name
                : undefined,
                channel_name_code:
                currentEpisode &&
                currentEpisode.series &&
                currentEpisode.series.channel
                ? currentEpisode.series.channel.nameCode
                : undefined,
                channel_umbrella_id:
                currentEpisode &&
                currentEpisode.series &&
                currentEpisode.series.channel
                ? currentEpisode.series.channel.umbrella._id
                : undefined,
                channel_umbrella_name:
                currentEpisode &&
                currentEpisode.series &&
                currentEpisode.series.channel
                ? currentEpisode.series.channel.umbrella.name
                : undefined,
                channel_umbrella_name_code:
                currentEpisode &&
                currentEpisode.series &&
                currentEpisode.series.channel
                    ? currentEpisode.series.channel.umbrella.nameCode
                    : undefined,
                    has_rss_feed:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.info[seriesLanguage] &&
                    currentEpisode.series.info[seriesLanguage].rssFeed
                    ? true
                    : false,
                    child_content:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.isChild
                      : undefined,
                  chronological:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.chronological
                      : undefined,
                  classification:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.classification
                      : undefined,
                  emotion:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.emotion
                      : undefined,
                  episode_index: currentEpisode && currentEpisode.episode
                    ? currentEpisode.episode.index
                    : undefined,
                  episode_length: currentEpisode && currentEpisode.episode
                    ? currentEpisode.episode.length
                    : undefined,
                  format:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.format
                      : undefined,
                  genre:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.genre
                      : undefined,
                  season: currentEpisode && currentEpisode.episode
                    ? currentEpisode.episode.season
                    : undefined,
                  total_length: currentEpisode && currentEpisode.episode
                    ? currentEpisode.episode.length
                    : undefined,
                    is_unit:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.isUnit
                      : undefined,
                  });
            setCurrentTime(e);
            setIsDragging(false);
            ref.current.isSeeking = false;
          }}
          value={actual}
          className="progress-slider"
          onChange={(e) => {
            setActual(e);
          }}
        />
        <div
          className="loaded"
          style={{
            width:
              playerRef.current && currentEpisode!.episode
                ? `${
                    (playerRef.current.getSecondsLoaded() /
                      currentEpisode!.episode!.length) *
                    100
                  }%`
                : 0,
          }}
        ></div>
      </div>
      <div className="duration">
        <p>
          {playerRef.current && playerRef.current.getCurrentTime() >= 3600
            ? new Date(playerRef.current.getCurrentTime() * 1000)
                .toISOString()
                .substr(11, 8)
            : playerRef.current && playerRef.current.getCurrentTime() < 3600
            ? new Date(playerRef.current.getCurrentTime() * 1000)
                .toISOString()
                .substr(14, 5)
            : "--:--"}
        </p>
        <p
          className="duration-end"
          onClick={() => setIsCurrentTimeReversed(!isCurrentTimeReversed)}
        >
          {currentEpisode!.episode!.length &&
          playerRef.current &&
          playerRef.current.getCurrentTime() &&
          currentEpisode!.episode!.length >= 3600 &&
          isCurrentTimeReversed
            ? `- ${new Date(
                currentEpisode!.episode!.length * 1000 -
                  playerRef.current.getCurrentTime() * 1000
              )
                .toISOString()
                .substr(11, 8)}`
            : currentEpisode!.episode!.length &&
              playerRef.current &&
              playerRef.current.getCurrentTime() &&
              currentEpisode!.episode!.length < 3600 &&
              isCurrentTimeReversed
            ? `- ${new Date(
                currentEpisode!.episode!.length * 1000 -
                  playerRef.current.getCurrentTime() * 1000
              )
                .toISOString()
                .substr(14, 5)}`
            : currentEpisode!.episode!.length &&
              currentEpisode!.episode!.length < 3600 &&
              !isCurrentTimeReversed
            ? `${new Date(currentEpisode!.episode!.length * 1000)
                .toISOString()
                .substr(14, 5)}`
            : currentEpisode!.episode!.length &&
              currentEpisode!.episode!.length >= 3600 &&
              !isCurrentTimeReversed
            ? `${new Date(currentEpisode!.episode!.length * 1000)
                .toISOString()
                .substr(11, 8)}`
            : "--:--"}
        </p>
      </div>
    </div>
  );
}
