import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";

export const UseSortSeason = (id: string): { sortedSeason: number[] } => {
  const [sortedSeason, setSortedSeason] = useState<number[]>([1]);

  const { series } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
  }));
  useEffect(() => {
    if (series[id!].chronological) {
      setSortedSeason(
        Array.from(
          new Set(
            series[id!].seasonsList.sort((a: number, b: number) => {
              return a - b;
            })
          )
        )
      );
    } else {
      setSortedSeason(
        Array.from(
          new Set(
            series[id!].seasonsList.sort((a: number, b: number) => {
              return b - a;
            })
          )
        )
      );
    }
  }, [series, id]);
  return { sortedSeason };
};
