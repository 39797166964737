import amplitude from "amplitude-js";
import StoreType from "../interfaces/StoreType";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import TagManager from "react-gtm-module";
import SeriesType from "../interfaces/SeriesType";

export default function EventStore() {
  const { profile, userContentLanguage, profiles, user, isTCValidated, uuid } =
    useSelector((state: StoreType) => ({
      ...state.ProfileReducer,
      ...state.UserReducer,
      ...state.RouterReducer,
    }));
  const setGroups = useCallback(() => {
    amplitude.getInstance().setGroup("account_id", user._id);
  }, [user]);
  const setUserId = useCallback(
    (couponId?: string) => {
      if (!!user && !!profile) {
        amplitude
          .getInstance()
          .setUserId(
            profile && profile._id
              ? profile._id
              : profiles && profiles[0] && profiles[0]._id
              ? profiles[0]._id
              : null
          );
        return amplitude.getInstance().setUserProperties({
          catalog: userContentLanguage ? userContentLanguage : undefined,
          userId: user && user._id ? user._id : undefined,
          mainProfileId:
            profiles && profiles[0] && profiles[0]._id
              ? profiles[0]._id
              : undefined,
          email: user && user.email ? user.email : undefined,
          profiles:
            profiles && profiles.length > 0 ? profiles.length : undefined,
          isMain: profile && profile.isMain ? profile.isMain : undefined,
          isChild: profile && profile.isChild ? profile.isChild : undefined,
          gender: profile && profile.gender ? profile.gender : undefined,
          birthday: profile && profile.birthday ? profile.birthday : undefined,
          subscription:
            user && user.subscription ? user.subscription : undefined,
          subscriptionData:
            user && user.subscriptionData ? user.subscriptionData : undefined,
          subscriptionExpirationDate: user.subscriptionExpirationDate,
          subscriptionTrial:
            user && user.subscriptionTrial ? user.subscriptionTrial : undefined,
          productId: user && user.productId ? user.productId : undefined,
          renewalStatus:
            user && user.renewalStatus ? user.renewalStatus : undefined,
          emailVerified:
            user && user.emailVerified ? user.emailVerified : undefined,
          "Stripe Coupon ID": user && user.coupons ? user.coupons : undefined,
          couponId,
        });
      }
    },
    [user, profile, profiles, userContentLanguage]
  );
  const openPartnersConnectBanner = useCallback(
    (context: "Sign Up" | "Sign In") => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "openPartnersConnectBanner",
          dataLayer: { context, profileId: uuid },
        });
      }
      amplitude
        .getInstance()
        .logEvent("Open Partners Connect Banner", { Context: context });
    },
    [setUserId, isTCValidated, uuid]
  );
  const openPartnerConnectCard = useCallback(
    (partner: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "openPartnerConnectCard",
          dataLayer: { partner, profileId: uuid },
        });
      }
      amplitude
        .getInstance()
        .logEvent("Open Partner Connect Card", { Partner: partner });
    },
    [setUserId, isTCValidated, uuid]
  );
  const view = useCallback(
    (screenName: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "view",
          dataLayer: { screenName, profileId: uuid },
        });
      }
      amplitude.getInstance().logEvent("view", { screenName });
    },
    [setUserId, isTCValidated, uuid]
  );
  const logout = useCallback(
    (context: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "logout",
          dataLayer: { context, profileId: uuid },
        });
      }
      amplitude.getInstance().logEvent("logout", { context });
    },
    [setUserId, isTCValidated, uuid]
  );

  const profileCreation = useCallback(
    (
      isChild: boolean,
      profileIndex: number,
      context: string,
      profileId: string
    ) => {
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "profileCreation",
          dataLayer: {
            isChild,
            profileIndex,
            context,
            realProfileId: profileId,
            profileId: uuid,
          },
        });
      }
      setUserId();
      amplitude.getInstance().logEvent("profileCreation", {
        isChild,
        profileIndex,
        context,
        profileId,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const openMyList = useCallback(
    (fromPage: string, fromUniverseName: string, catalogLanguage: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "openMyList",
          dataLayer: {
            context: "NavBarActions",
            fromPage,
            fromUniverseName,
            catalogLanguage,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Open My List", {
        Context: "NavBar Actions",
        "From Page": fromPage,
        "From Universe Name": fromUniverseName,
        "Catalog Language": catalogLanguage,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const viewForgotPasswordScreen = useCallback(() => {
    setGroups();
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "viewForgotPasswordScreen",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("View Forgot Password Screen");
    window.analytics.page(`Viewed Forgot Password Page`);
  }, [setUserId, setGroups, isTCValidated, uuid]);
  const clickForgotPassword = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "clickForgotPassword",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("clickForgotPassword");
  }, [setUserId, isTCValidated, uuid]);

  const recoverPasswordSuccess = useCallback(
    (email: string) => {
      setUserId();
      setGroups();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "ViewRecoverPasswordScreen",
          dataLayer: { userEmail: email, profileId: uuid },
        });
      }

      amplitude
        .getInstance()
        .logEvent("View Recover Password Screen", { "User Email": email });
      window.analytics.page(`Viewed Recover Password Page`, undefined, {
        user_email: email,
      });
    },
    [setUserId, setGroups, isTCValidated, uuid]
  );
  const recoverPasswordError = useCallback(
    (email: string, errorMessage: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "viewRecoverPasswordScreen",
          dataLayer: {
            userEmail: email,
            errorMessage,
            profileId: uuid,
          },
        });
      }
      setGroups();
      amplitude.getInstance().logEvent("View Recover Password Screen", {
        "User Email": email,
        "Error Message": errorMessage,
      });
      window.analytics.page(`Viewed Recover Password Page`, undefined, {
        user_email: email,
        error_message: errorMessage,
      });
    },
    [setUserId, setGroups, isTCValidated, uuid]
  );
  const setPinCode = useCallback(
    (context: string) => {
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "setPinCode",
          dataLayer: {
            context,
            profileId: uuid,
          },
        });
      }
      setUserId();
      amplitude.getInstance().logEvent("setPinCode", { context });
    },
    [setUserId, isTCValidated, uuid]
  );
  const forgotPinCode = useCallback(
    (context: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "forgotPinCode",
          dataLayer: {
            context,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("forgotPinCode", { context });
    },
    [setUserId, isTCValidated, uuid]
  );
  const deletePinCode = useCallback(
    (context: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "deletePinCode",
          dataLayer: {
            context,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("deletePinCode", { context });
    },
    [setUserId, isTCValidated, uuid]
  );
  const editPinCode = useCallback(
    (context: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "editPinCode",
          dataLayer: {
            context,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("editPinCode", { context });
    },
    [setUserId, isTCValidated, uuid]
  );

  const switchInterfaceLanguage = useCallback(
    (language: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "switchInterfaceLanguage",
          dataLayer: {
            language,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("switchInterfaceLanguage", { language });
    },
    [setUserId, isTCValidated, uuid]
  );
  const switchCatalog = useCallback(
    (language: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "switchCatalog",
          dataLayer: {
            language,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("switchCatalog", { language });
    },
    [setUserId, isTCValidated, uuid]
  );
  const addAccountEmail = useCallback(
    (context: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "addAccountEmail",
          dataLayer: {
            context,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("addAccountEmail", { context });
    },
    [setUserId, isTCValidated, uuid]
  );
  const updateAccountEmail = useCallback(
    (context: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "updateAccountEmail",
          dataLayer: {
            context,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("updateAccountEmail", { context });
    },
    [setUserId, isTCValidated, uuid]
  );
  const playSample = useCallback(
    (
      contentChild: boolean,
      contentContext: string,
      contentEmotion: string,
      contentId: string,
      contentName: string,
      isChild: boolean,
      contentCategory?: string,
      categoryType?: string,
      categoryPosition?: number,
      contentUniverse?: string
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "playSample",
          dataLayer: {
            categoryPosition,
            contentCategory,
            categoryType,
            contentChild,
            contentContext,
            contentEmotion,
            contentId,
            contentName,
            contentUniverse,
            isChild,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("playSample", {
        "Category Position": categoryPosition,
        "Content Category": contentCategory,
        "Category Type": categoryType,
        contentChild,
        contentContext,
        contentEmotion,
        contentId,
        contentName,
        contentUniverse,
        isChild,
        profileId: uuid,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const openSeries = useCallback(
    (
      contentChild: boolean,
      contentContext: string,
      contentEmotion: string,
      contentId: string,
      contentName: string,
      isChild: boolean,
      contentCategory?: string,
      categoryType?: string,
      categoryPosition?: number,
      contentUniverse?: string,
      universeId?: string,
      contentImageId?: string,
      contentPosition?: number,
      catalog?: string,
      fromSimilarSeriesId?: string,
      fromSimilarSeriesName?: string,
      searchedText?: string,
      universeName?: string,
      channel?: SeriesType["channel"],
      has_rss_feed?: boolean,
      scripted?:   SeriesType["scripted"] ,
      genre?:  SeriesType["genre"],
      format?:  SeriesType["format"],
      content_length?: SeriesType["length"],
      chronological?:  SeriesType["chronological"],
      classification?: SeriesType["classification"],
      publication_date?:  SeriesType["publicationDate"],
      seasons_list?:  SeriesType["seasonsList"],
      header_mention?: string,
      authors?:SeriesType["authors"],
      owner_id?: SeriesType["owner_id"],
      recomm_id?: string
    ) => {
      setGroups();
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "openSeries",
          dataLayer: {
            categoryPosition,
            contentCategory,
            categoryType,
            contentChild,
            contentContext,
            contentEmotion,
            contentId,
            contentImageId,
            contentName,
            contentUniverse,
            isChild,
            universeId,
            contentPosition,
            catalog,
            fromSimilarSeriesId,
            fromSimilarSeriesName,
            searchedText,
            universeName,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("openSeries", {
        "Category Position": categoryPosition,
        "Content Category": contentCategory,
        "Category Type": categoryType,
        contentChild,
        contentContext,
        contentEmotion,
        contentId,
        contentImageId,
        contentName,
        contentUniverse,
        isChild,
        universeId,
        contentPosition,
        catalog,
        "From Similar Series ID": fromSimilarSeriesId,
        "From Similar Series Name": fromSimilarSeriesName,
        "Searched Text": searchedText,
        "Universe Name": universeName,
      });
      window.analytics.track(`Content Opened`, {
        session_id: uuid,
        category_position: categoryPosition,
        content_category: contentCategory,
        category_type: categoryType,
        child_content: contentChild,
        context: contentContext ? contentContext : "none",
        emotion: contentEmotion,
        series_id: contentId,
        content_image_id: contentImageId,
        content_name: contentName,
        content_universe: contentUniverse,
        universe_id: universeId,
        content_position: contentPosition,
        catalog: catalog,
        from_similar_series_id: fromSimilarSeriesId,
        from_similar_series_name: fromSimilarSeriesName,
        searched_text: searchedText,
        universe_name: universeName,
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
          channel_id: channel ? channel._id : undefined,
          channel_name: channel ? channel.name : undefined,
          channel_name_code: channel ? channel.nameCode : undefined,
          channel_umbrella_id: channel ? channel.umbrella._id : undefined,
          channel_umbrella_name: channel ? channel.umbrella.name : undefined,
          channel_umbrella_name_code: channel
            ? channel.umbrella.nameCode
            : undefined,
            has_rss_feed,
            content_type: "Series",
            signup_type: localStorage.getItem("sy_signup_type"),
            scripted,
            platform: "Web",
            genre,
            content_length,
            chronological,
            classification,
            publication_date,
            seasons_list,
            header_mention,
            authors,
            owner_id,
            format, 
            recomm_id
      });






      window.analytics.page(`Viewed Series Page`, undefined, {
        session_id: uuid,
        category_position: categoryPosition,
        content_category: contentCategory,
        category_type: categoryType,
        content_child: contentChild,
        context: contentContext ? contentContext : "none",
        content_emotion: contentEmotion,
        series_id: contentId,
        content_image_id: contentImageId,
        content_name: contentName,
        content_universe: contentUniverse,
        ischild: isChild,
        universe_id: universeId,
        content_position: contentPosition,
        catalog: catalog,
        from_similar_series_id: fromSimilarSeriesId,
        from_similar_series_name: fromSimilarSeriesName,
        searched_text: searchedText,
        universe_name: universeName,
        channel_id: channel ? channel._id : undefined,
        channel_name: channel ? channel.name : undefined,
        channel_name_code: channel ? channel.nameCode : undefined,
        channel_umbrella_id: channel ? channel.umbrella._id : undefined,
        channel_umbrella_name: channel ? channel.umbrella.name : undefined,
        channel_umbrella_name_code: channel
          ? channel.umbrella.nameCode
          : undefined,
          has_rss_feed,
          
      });
    },
    [setUserId, setGroups, isTCValidated, uuid]
  );

  const viewSignInForm = useCallback(
    (next: string) => {
      setGroups();
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "viewSignInForm",
          dataLayer: {
            next,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("View Sign In Form", { Next: next });
    },
    [setUserId, setGroups, isTCValidated, uuid]
  );
  const viewSignUpForm = useCallback(
    (next: string) => {
      setUserId();
      setGroups();
      if (isTCValidated && isTCValidated.adds)
        TagManager.dataLayer({
          dataLayerName: "viewSignUpForm",
          dataLayer: {
            next,
            profileId: uuid,
          },
        });
      amplitude.getInstance().logEvent("View Sign Up Form", { Next: next });
    },
    [setUserId, setGroups, isTCValidated, uuid]
  );
  const userSignIn = useCallback(
    (
      SignInMethod: "Email" | "Facebook" | "Google" | "Apple" | "Alexa" | "SFR",
      next: string
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "viewSignUpForm",
          dataLayer: {
            next,
            SignInMethod,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("User Sign In", {
        "Sign In Method": SignInMethod,
        Next: next,
      });
    },

    [setUserId, isTCValidated, uuid]
  );
  const userSignUp = useCallback(
    (
      SignUpMethod: "Email" | "Facebook" | "Google" | "Apple" | "Alexa" | "SFR",
      next: string
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "userSignUp",
          dataLayer: {
            next,
            SignUpMethod,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("User Sign Up", {
        "Sign In Method": SignUpMethod,
        Next: next,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const switchToSignUp = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "switchToSignUp",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("Switch To Sign Up");
  }, [setUserId, isTCValidated, uuid]);
  const switchToSignIn = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "switchToSignIn",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("Switch To Sign In");
  }, [setUserId, isTCValidated, uuid]);
  const clickRecoverPassword = useCallback(() => {
    setUserId();
    if (!!isTCValidated && !!isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "Click Recover Password",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("Click Recover Password");
  }, [setUserId, isTCValidated, uuid]);
  const chooseProfileEvent = useCallback(
    (context: string, isChild: boolean, isMain: boolean | undefined) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "chooseProfile",
          dataLayer: { profileId: uuid },
        });
      }
      amplitude
        .getInstance()
        .logEvent("Choose Profile", { context, isChild, isMain });
    },
    [setUserId, isTCValidated, uuid]
  );
  const clickEditProfile = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "clickEditProfile",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("Click Edit Profile");
  }, [setUserId, isTCValidated, uuid]);
  const deleteProfile = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "clickEditProfile",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("Delete Profile");
  }, [setUserId, isTCValidated, uuid]);
  const clickAddAdultProfile = useCallback(
    (context: "Profiles List NavBar Actions" | "New Session") => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "clickEditProfile",
          dataLayer: { context, profileId: uuid },
        });
      }
      amplitude
        .getInstance()
        .logEvent("Click Add Adult Profile", { Context: context });
    },
    [setUserId, isTCValidated, uuid]
  );
  const clickAddKidsProfile = useCallback(
    (context: "Profiles List NavBar Actions" | "New Session") => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "clickAddChildProfile",
          dataLayer: { context, profileId: uuid },
        });
      }
      amplitude
        .getInstance()
        .logEvent("Click Add Child Profile", { Context: context });
    },
    [setUserId, isTCValidated, uuid]
  );

  const share = useCallback(
    (
      contentContext: string,
      contentName: string,
      contentId: string,
      contentChild: boolean | null,
      contentEmotion: string | undefined
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "share",
          dataLayer: {
            contentContext,
            contentName,
            contentId,
            contentChild,
            contentEmotion,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("share", {
        contentContext,
        contentName,
        contentId,
        contentChild,
        contentEmotion,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const bookmark = useCallback(
    (
      contentContext: string,
      contentId: string,
      isChild: boolean,
      contentName?: string,
      contentChild?: boolean | null,
      contentEmotion?: string | undefined,
      categoryType?: string,
      contentUniverse?: string,
      universeId?: string,
      contentPosition?: number,
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "bookmark",
          dataLayer: {
            contentContext,
            contentName,
            contentId,
            contentChild,
            contentEmotion,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("bookmark", {
        contentContext,
        contentName,
        contentId,
        contentChild,
        contentEmotion,
        isChild,
        "Category Type": categoryType,
        contentUniverse,
        universeId,
        contentPosition,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const unbookmark = useCallback(
    (
      contentContext: string,
      contentId: string,
      isChild: boolean,
      contentName?: string,
      contentChild?: boolean | null,
      contentEmotion?: string | undefined,
      categoryType?: string,
      contentUniverse?: string,
      universeId?: string,
      contentPosition?: number
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "unbookmark",
          dataLayer: {
            contentContext,
            contentName,
            contentId,
            contentChild,
            contentEmotion,
            isChild,
            categoryType,
            contentUniverse,
            universeId,
            contentPosition,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("unbookmark", {
        contentContext,
        contentName,
        contentId,
        contentChild,
        contentEmotion,
        isChild,
        "Category Type": categoryType,
        contentUniverse,
        universeId,
        contentPosition,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const muteVolume = useCallback(
    (
      contentContext: string,
      contentId: string,
      isChild: boolean,
      contentName?: string,
      contentChild?: boolean | null,
      contentEmotion?: string | undefined,
      categoryType?: string,
      contentUniverse?: string,
      universeId?: string
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "muteVolume",
          dataLayer: {
            contentContext,
            contentName,
            contentId,
            contentChild,
            contentEmotion,
            isChild,
            categoryType,
            contentUniverse,
            universeId,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("muteVolume", {
        contentContext,
        contentName,
        contentId,
        contentChild,
        contentEmotion,
        isChild,
        "Category Type": categoryType,
        contentUniverse,
        universeId,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const unmuteVolume = useCallback(
    (
      contentContext: string,
      contentId: string,
      isChild: boolean,
      contentName?: string,
      contentChild?: boolean | null,
      contentEmotion?: string | undefined,
      categoryType?: string,
      contentUniverse?: string,
      universeId?: string
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "unmuteVolume",
          dataLayer: {
            contentContext,
            contentName,
            contentId,
            contentChild,
            contentEmotion,
            isChild,
            categoryType,
            contentUniverse,
            universeId,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("unmuteVolume", {
        contentContext,
        contentName,
        contentId,
        contentChild,
        contentEmotion,
        isChild,
        "Category Type": categoryType,
        contentUniverse,
        universeId,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const userCreation = useCallback(() => {
    setUserId();
    amplitude.getInstance().logEvent("userCreation");
  }, [setUserId]);

  const openSeriesPageDetailsTab = useCallback(
    (
      contentContext: string,
      contentId: string,
      isChild: boolean,
      contentName?: string,
      contentChild?: boolean | null,
      contentEmotion?: string | undefined,
      fromTab?: string,
      contentImageId?: string,
      contentPosition?: number
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "openSeriesPageDetailsTab",
          dataLayer: {
            contentContext,
            contentName,
            contentId,
            contentChild,
            contentEmotion,
            isChild,
            fromTab,
            contentImageId,
            contentPosition,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Open Series Page Details Tab", {
        contentContext,
        contentName,
        contentId,
        contentChild,
        contentEmotion,
        isChild,
        "From Tab": fromTab,
        contentImageId,
        contentPosition,
        profileId: uuid,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const openSeriesPageSimilarTab = useCallback(
    (
      contentContext: string,
      contentId: string,
      isChild: boolean,
      contentName?: string,
      contentChild?: boolean | null,
      contentEmotion?: string | undefined,
      fromTab?: string,
      contentImageId?: string,
      contentPosition?: number
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "openSeriesPageSimilarTab",
          dataLayer: {
            contentContext,
            contentName,
            contentId,
            contentChild,
            contentEmotion,
            isChild,
            fromTab,
            contentImageId,
            contentPosition,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Open Series Page Similar Tab", {
        contentContext,
        contentName,
        contentId,
        contentChild,
        contentEmotion,
        isChild,
        "From Tab": fromTab,
        contentImageId,
        contentPosition,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const openSeriesPageEpisodeTab = useCallback(
    (
      contentContext: string,
      contentId: string,
      isChild: boolean,
      contentName?: string,
      contentChild?: boolean | null,
      contentEmotion?: string | undefined,
      fromTab?: string,
      contentImageId?: string,
      contentPosition?: number
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "openSeriesPageEpisodeTab",
          dataLayer: {
            contentContext,
            contentName,
            contentId,
            contentChild,
            contentEmotion,
            isChild,
            fromTab,
            contentImageId,
            contentPosition,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Open Series Page Episode Tab", {
        contentContext,
        contentName,
        contentId,
        contentChild,
        contentEmotion,
        isChild,
        "From Tab": fromTab,
        contentImageId,
        contentPosition,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const seeSeriesDetails = useCallback(
    (
      contentContext: string,
      contentName: string,
      contentId: string,
      contentChild: boolean,
      contentEmotion: string | undefined
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "seeSeriesDetails",
          dataLayer: {
            contentContext,
            contentName,
            contentId,
            contentChild,
            contentEmotion,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("seeSeriesDetails", {
        contentContext,
        contentName,
        contentId,
        contentChild,
        contentEmotion,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const play = useCallback(
    (
      contentChild: boolean | null,
      contentContext: string,
      contentEmotion: string | undefined,
      contentEpisodeName: string,
      contentEpisodeId: string,
      contentEpisodeSeason: number,
      contentId: string,
      contentName: string,
      isChild: boolean,
      contentCategory?: string,
      categoryType?: string,
      categoryPosition?: number,
      contentUniverse?: string,
      universeId?: string,
      contentImageId?: string,
      Timestamp?: number,
      lengthPercentage?: number,
      episodeLength?: number,
      contentLength?: number,
      contentCompletion?: number
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "playContent",
          dataLayer: {
            contentCategory,
            categoryType,
            categoryPosition,
            contentChild,
            contentContext,
            contentEmotion,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            isChild,
            contentUniverse,
            universeId,
            contentImageId,
            Timestamp,
            episodeCompletion: lengthPercentage,
            episodeLength,
            contentLength,
            contentCompletion,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Play Content", {
        "Content Category": contentCategory,
        "Category Type": categoryType,
        "Category Position": categoryPosition,
        contentChild,
        contentContext,
        contentEmotion,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        contentId,
        contentName,
        isChild,
        contentUniverse,
        universeId,
        contentImageId,
        Timestamp,
        "Episode Completion": lengthPercentage,
        "Episode Length": episodeLength,
        "Content Length": contentLength,
        "Content Completion": contentCompletion,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const pause = useCallback(
    (
      contentChild: boolean | null,
      contentContext: string,
      contentEmotion: string | undefined,
      contentEpisodeName: string,
      contentEpisodeId: string,
      contentEpisodeSeason: number,
      contentId: string,
      contentName: string,
      isChild: boolean,
      contentCategory?: string,
      categoryType?: string,
      categoryPosition?: number,
      contentUniverse?: string,
      universeId?: string,
      contentImageId?: string,
      Timestamp?: number,
      lengthPercentage?: number,
      episodeLength?: number,
      contentLength?: number,
      nameOfTargetContent?: string,
      idOfTargetContent?: string,
      contentCompletion?: number
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "pauseContent",
          dataLayer: {
            contentCategory,
            categoryType,
            categoryPosition,
            contentChild,
            contentContext,
            contentEmotion,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            isChild,
            contentUniverse,
            universeId,
            contentImageId,
            Timestamp,
            episodeCompletion: lengthPercentage,
            episodeLength,
            contentLength,
            contentCompletion,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Pause Content", {
        "Content Category": contentCategory,
        "Category Type": categoryType,
        "Category Position": categoryPosition,
        contentChild,
        contentContext,
        contentEmotion,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        contentId,
        contentName,
        isChild,
        contentUniverse,
        universeId,
        contentImageId,
        Timestamp,
        "Episode Completion": lengthPercentage,
        "Episode Length": episodeLength,
        "Content Length": contentLength,
        "Name of Target Content": nameOfTargetContent,
        "Id of Target Content": idOfTargetContent,
        "Content Completion": contentCompletion,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const fastForward = useCallback(
    (
      numberOfSecond: number,
      contentName: string,
      contentId: string,
      contentEpisodeName: string,
      contentEpisodeId: string,
      contentEpisodeSeason: number,
      contentCompletion?: number
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "clickFastForward",
          dataLayer: {
            numberOfSecond,
            contentContext: "Player Actions",
            contentName,
            contentId,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentCompletion,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Click Fast Forward", {
        "Number of seconds": numberOfSecond,
        contentContext: "Player Actions",
        contentName,
        contentId,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        "Content Completion": contentCompletion,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const fastBackward = useCallback(
    (
      numberOfSecond: number,
      contentName: string,
      contentId: string,
      contentEpisodeName: string,
      contentEpisodeId: string,
      contentEpisodeSeason: number,
      contentCompletion: number
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "clickFastForward",
          dataLayer: {
            numberOfSecond,
            contentContext: "Player Actions",
            contentName,
            contentId,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentCompletion,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Click Fast Backward", {
        "Number of seconds": numberOfSecond,
        contentContext: "Player Actions",
        contentName,
        contentId,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        "Content Completion": contentCompletion,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const sleepMode = useCallback(
    (
      duration:
        | "episode"
        | "10min"
        | "20min"
        | "30min"
        | " 45min"
        | "60min"
        | string
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "sleepMode",
          dataLayer: {
            duration,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("sleepMode", {
        duration,
      });
      window.analytics.track("Sleepmode On", {
        duration:
          duration === "episode"
            ? "episode"
            : JSON.parse(duration.substring(0, 2)) * 60,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const sleepModeOff = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "sleepModeOff",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("sleepModeOff");
    window.analytics.track("Sleepmode Off", {
      signup_type: localStorage.getItem("sy_signup_type")
        ? localStorage.getItem("sy_signup_type")
        : undefined,
      subscription: !!localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).subscription
        : 0,
    });
  }, [setUserId, isTCValidated, uuid]);
  const cancelSubscription = useCallback(
    (productId: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "cancelSubscription",
          dataLayer: {
            productId,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("cancelSubscription", {
        productId,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const subscriptionError = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "cancelSubscription",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("subscriptionError");
  }, [setUserId, isTCValidated, uuid]);
  const viewHome = useCallback(
    (
      contentFocusedOnHeader: string,
      universeName: string,
      catalog: string,
      universeID: string
    ) => {
      setUserId();
      setGroups();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "viewHome",
          dataLayer: {
            contentFocusedOnHeader,
            universeName,
            Catalog: catalog,
            universeID,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("View Home", {
        "Content Focused On Header": contentFocusedOnHeader,
        "Universe Name": universeName,
        Catalog: catalog,
        "Universe ID": universeID,
      });
    },
    [setUserId, setGroups, isTCValidated, uuid]
  );
  const viewUniverse = useCallback(
    (
      contentFocusedOnHeader: string,
      universeName: string,
      catalog: string,
      universeID: string
    ) => {
      setUserId();
      setGroups();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "viewUniverse",
          dataLayer: {
            contentFocusedOnHeader,
            universeName,
            catalog,
            universeID,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("View Universe", {
        "Content Focused On Header": contentFocusedOnHeader,
        "Universe Name": universeName,
        Catalog: catalog,
        "Universe ID": universeID,
      });
      window.analytics.page("Viewed Universe Page", undefined, {
        content_focused_on_header: contentFocusedOnHeader,
        universe_name: universeName,
        catalog: catalog,
        universe_id: universeID,
      });
    },
    [setUserId, setGroups, isTCValidated, uuid]
  );
  const openUniverse = useCallback(
    (fromUniverseName: string, fromPage: string, catalogLanguage: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "openUniverse",
          dataLayer: {
            context: "NavBar Actions",
            fromPage,
            fromUniverseName,
            catalogLanguage,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Open Universe", {
        Context: "NavBar Actions",
        "From Page": fromPage,
        "From Universe Name": fromUniverseName,
        "Catalog Language": catalogLanguage,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const openHome = useCallback(
    (
      context: string,
      fromPage: string,
      fromUniverseName?: string,
      catalogLanguage?: string
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "openHome",
          dataLayer: {
            context,
            fromPage,
            fromUniverseName,
            catalogLanguage,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Open Home", {
        Context: context,
        "From Page": fromPage,
        "From Universe Name": fromUniverseName,
        "Catalog Language": catalogLanguage,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const clickSeeMoreSeriesDescription = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "clickSeeMoreSeriesDescription",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("Click See More Series Description");
  }, [setUserId, isTCValidated, uuid]);
  const clickSeeMoreSeriesEpisodeDescription = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "clickSeeMoreSeriesEpisodeDescription",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude
      .getInstance()
      .logEvent("Click See More Series Episode Description");
  }, [setUserId, isTCValidated, uuid]);
  const viewUpdatePasswordScreen = useCallback(() => {
    setUserId();
    setGroups();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "viewUpdatePasswordScreen",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("View Update Password Screen");
    window.analytics.page("Viewed Update Password Page");
  }, [setUserId, setGroups, isTCValidated, uuid]);
  const updatePasswordSuccess = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "updatePasswordSuccess",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("Update Password Success");
  }, [setUserId, isTCValidated, uuid]);
  const updatePasswordError = useCallback(
    (errorMessage?: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "updatePasswordError",
          dataLayer: { errorMessage, profileId: uuid },
        });
      }
      amplitude
        .getInstance()
        .logEvent("Update Password Error", { "Error Message": errorMessage });
    },
    [setUserId, isTCValidated, uuid]
  );
  const updateEmailSuccess = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "UpdateEmailSuccess",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("Update Email Success");
  }, [setUserId, isTCValidated, uuid]);
  const updateEmailError = useCallback(
    (errorMessage) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "updateEmailError",
          dataLayer: { errorMessage, profileId: uuid },
        });
      }
      amplitude
        .getInstance()
        .logEvent("Update Email Error", { "Error Message": errorMessage });
    },
    [setUserId, isTCValidated, uuid]
  );
  const clickResendVerifyEmail = useCallback(
    (context: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "clickResendVerifyEmail",
          dataLayer: { context, profileId: uuid },
        });
      }
      amplitude
        .getInstance()
        .logEvent("Click Resend Verify Email", { Context: context });
    },
    [setUserId, isTCValidated, uuid]
  );
  const clickBackHome = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "clickBackHome",
        dataLayer: { Context: "Settings", profileId: uuid },
      });
    }
    amplitude
      .getInstance()
      .logEvent("Click Back Home", { Context: "Settings" });
  }, [setUserId, isTCValidated, uuid]);
  const clickToDeleteAccount = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "clickToDeleteAccount",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("Click to Delete Account");
  }, [setUserId, isTCValidated, uuid]);
  const confirmDeleteAccount = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "confirmDeleteAccount",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("Confirm Delete Account");
  }, [setUserId, isTCValidated, uuid]);
  const deleteAccountSuccess = useCallback(() => {
    setUserId();
    if (isTCValidated && isTCValidated.adds) {
      TagManager.dataLayer({
        dataLayerName: "deleteAccountSuccess",
        dataLayer: { profileId: uuid },
      });
    }
    amplitude.getInstance().logEvent("Delete Account Success");
  }, [setUserId, isTCValidated, uuid]);
  const deleteAccountError = useCallback(
    (errorMessage: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "deleteAccountError",
          dataLayer: { errorMessage, profileId: uuid },
        });
      }
      amplitude
        .getInstance()
        .logEvent("Delete Account Error", { "Error message": errorMessage });
    },
    [setUserId, isTCValidated, uuid]
  );

  const finishAccountProfilesSetup = useCallback(
    (
      numberOfProfiles: number,
      childProfiles: number,
      adultProfiles: number
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "finishAccountProfilesSetup",
          dataLayer: {
            numberOfProfiles,
            childProfiles,
            adultProfiles,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Finish Account Profiles Setup", {
        "number of profiles": numberOfProfiles,
        "number of child profiles": childProfiles,
        "number of secondary adult profiles": adultProfiles,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const clickSearchIcon = useCallback(
    (fromPage: string, fromUniverseName: string, catalogLanguage: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "clickSearchIcon",
          dataLayer: {
            Context: "NavBar Actions",
            fromPage,
            fromUniverseName,
            catalogLanguage,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Click Search Icon", {
        Context: "NavBar Actions",
        "From Page": fromPage,
        "From Universe Name": fromUniverseName,
        "Catalog Language": catalogLanguage,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const searchEvent = useCallback(
    (
      fromPage: string,
      fromUniverseName: string,
      catalogLanguage: string,
      text: string
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "search",
          dataLayer: {
            Context: "NavBar Actions",
            fromPage,
            fromUniverseName,
            text,
            catalogLanguage,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Search", {
        Context: "NavBar Actions",
        "From Page": fromPage,
        "From Universe Name": fromUniverseName,
        "Searched Text": text,
        "Catalog Language": catalogLanguage,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const clickNavBarLanguageIcon = useCallback(
    (fromPage: string, fromUniverseName: string, catalogLanguage: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "clickNavBarLanguageIcon",
          dataLayer: {
            context: "NavBar Actions",
            fromPage,
            fromUniverseName,
            catalogLanguage,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Click NavBar Language Icon", {
        Context: "NavBar Actions",
        "From Page": fromPage,
        "From Universe Name": fromUniverseName,
        "Catalog Language": catalogLanguage,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const clickUnfurlProfileActions = useCallback(
    (fromPage: string, fromUniverseName: string, catalogLanguage: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "clickUnfurlProfileActions",
          dataLayer: {
            context: "NavBar Actions",
            fromPage,
            fromUniverseName,
            catalogLanguage,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Click Unfurl Profile Actions", {
        Context: "NavBar Actions",
        "From Page": fromPage,
        "From Universe Name": fromUniverseName,
        "Catalog Language": catalogLanguage,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const openSettings = useCallback(
    (fromPage: string, fromUniverseName: string, catalogLanguage: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "openSettings",
          dataLayer: {
            context: "NavBar Actions",
            fromPage,
            fromUniverseName,
            catalogLanguage,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Open Settings", {
        Context: "NavBar Actions",
        "From Page": fromPage,
        "From Universe Name": fromUniverseName,
        "Catalog Language": catalogLanguage,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const enterDiscoverCoupon = useCallback(
    (entryText: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "enterDiscoverCoupon",
          dataLayer: {
            entryText,
            profileId: uuid,
          },
        });
      }
      amplitude
        .getInstance()
        .logEvent("Enter Discover Coupon", { "Entry Text": entryText });
    },
    [setUserId, isTCValidated, uuid]
  );
  const redeemDiscoverCouponError = useCallback(
    (entryText: string, redeemDiscoverCouponError: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "redeemDiscoverCouponError",
          dataLayer: {
            entryText,
            redeemDiscoverCouponError,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("Redeem Discover Coupon Error", {
        "Entry Text": entryText,
        "Redeem Discover Coupon Error": redeemDiscoverCouponError,
      });
    },

    [setUserId, isTCValidated, uuid]
  );
  const userSignOut = useCallback(
    (context: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "userSignOut",
          dataLayer: {
            context,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("User Sign Out", { Context: context });
    },
    [setUserId, isTCValidated, uuid]
  );

  const stripePresentationViewed = useCallback(
    (
      context: string,
      presentedOffers: string,
      contentContext?: string,
      contentEpisodeName?: string,
      contentEpisodeId?: string,
      contentEpisodeSeason?: number,
      contentId?: string,
      contentName?: string
    ) => {
      setUserId();
      setGroups();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "presentationViewed",
          dataLayer: {
            context,
            presentedOffers,
            contentContext,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("[Stripe] Presentation Viewed", {
        Context: context,
        "Presented Offers": presentedOffers,
        contentContext,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        contentId,
        contentName,
      });
    },

    [setUserId, setGroups, isTCValidated, uuid]
  );

  const stripeSelectPlan = useCallback(
    (
      context: string,
      presentedOffers: string,
      planId: string,
      planPrice: number,
      planPeriod: string,
      planName?: string,
      contentContext?: string,
      contentEpisodeName?: string,
      contentEpisodeId?: string,
      contentEpisodeSeason?: number,
      contentId?: string,
      contentName?: string,
      isChild?: boolean
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "selectPlan",
          dataLayer: {
            context,
            presentedOffers,
            planId,
            planPrice,
            planPeriod,
            planName,
            contentContext,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            isChild,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("[Stripe] Select Plan", {
        Context: context,
        "Presented Offers": presentedOffers,
        "Plan ID": planId,
        "Plan Price": planPrice,
        "Plan Period": planPeriod,
        "Plan Name": planName,
        contentContext,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        contentId,
        contentName,
        isChild,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const stripePlanSelected = useCallback(
    (
      context: string,
      presentedOffers: string,
      planId: string,
      planPrice: number,
      planPeriod: string,
      planName?: string,
      contentContext?: string,
      contentEpisodeName?: string,
      contentEpisodeId?: string,
      contentEpisodeSeason?: number,
      contentId?: string,
      contentName?: string,
      isChild?: boolean
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "planSelected",
          dataLayer: {
            context,
            presentedOffers,
            planId,
            planPrice,
            planPeriod,
            planName,
            contentContext,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            isChild,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("[Stripe] Plan Selected", {
        Context: context,
        "Presented Offers": presentedOffers,
        "Plan ID": planId,
        "Plan Price": planPrice,
        "Plan Period": planPeriod,
        "Plan Name": planName,
        contentContext,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        contentId,
        contentName,
        isChild,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const stripeSubscriptionPurchased = useCallback(
    (
      presentedOffers: string,
      planId: string,
      planPrice: number,
      planPeriod: string,
      planName?: string,
      contentContext?: string,
      contentEpisodeName?: string,
      contentEpisodeId?: string,
      contentEpisodeSeason?: number,
      contentId?: string,
      contentName?: string,
      isChild?: boolean,
      stripeCouponId?: string
    ) => {
      setUserId(stripeCouponId ? stripeCouponId : undefined);
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "subscriptionPurchased",
          dataLayer: {
            presentedOffers,
            planId,
            planPrice,
            planPeriod,
            planName,
            stripeCouponId,
            contentContext,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            isChild,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("[Stripe] Subscription Purchased", {
        "Presented Offers": presentedOffers,
        "Plan ID": planId,
        "Plan Price": planPrice,
        "Plan Period": planPeriod,
        "Plan Name": planName,
        "Stripe Coupon ID": stripeCouponId,
        contentContext,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        contentId,
        contentName,
        isChild,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const stripeViewPaymentScreen = useCallback(
    (
      presentedOffers: string,
      planId?: string,
      planPrice?: number,
      planPeriod?: string,
      planName?: string,
      contentContext?: string,
      contentEpisodeName?: string,
      contentEpisodeId?: string,
      contentEpisodeSeason?: number,
      contentId?: string,
      contentName?: string,
      isChild?: boolean
    ) => {
      setUserId();
      setGroups();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "viewPaymentScreen",
          dataLayer: {
            presentedOffers,
            planId,
            planPrice,
            planPeriod,
            planName,
            contentContext,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            isChild,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("[Stripe] View Payment Screen", {
        "Presented Offers": presentedOffers,
        "Plan ID": planId,
        "Plan Price": planPrice,
        "Plan Period": planPeriod,
        "Plan Name": planName,
        contentContext,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        contentId,
        contentName,
        isChild,
      });
    },
    [setUserId, setGroups, isTCValidated, uuid]
  );

  const stripeCouponTyped = useCallback(
    (
      planId: string,
      price: number,
      planPeriod: string,
      stripeCouponId: string,
      planName?: string,
      contentContext?: string,
      contentEpisodeName?: string,
      contentEpisodeId?: string,
      contentEpisodeSeason?: number,
      contentId?: string,
      contentName?: string,
      isChild?: boolean
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "stripeCouponTyped",
          dataLayer: {
            stripeCouponId,
            planId,
            price,
            planPeriod,
            planName,
            contentContext,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            isChild,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("[Stripe] Stripe Coupon Typed", {
        "Stripe Coupon ID": stripeCouponId,
        "Plan ID": planId,
        Price: price,
        "Plan Period": planPeriod,
        "Plan Name": planName,
        contentContext,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        contentId,
        contentName,
        isChild,
      });
      window.analytics.track("Coupon Entered", {
        stripe_coupon_id: stripeCouponId,
        plan_id: planId,
        price,
        plan_period: planPeriod,
        plan_name: planName,
        content_context: contentContext,
        content_episode_name: contentEpisodeName,
        content_episode_id: contentEpisodeId,
        content_episode_season: contentEpisodeSeason,
        series_id: contentId,
        content_name: contentName,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const errorStripeCouponDoesntMatchWithProduct = useCallback(
    (
      planId: string,
      price: number,
      planPeriod: string,
      stripeCouponId: string,
      planName?: string,
      contentContext?: string,
      contentEpisodeName?: string,
      contentEpisodeId?: string,
      contentEpisodeSeason?: number,
      contentId?: string,
      contentName?: string,
      isChild?: boolean
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "errorStripeCouponDoesntMatchWithProduct",
          dataLayer: {
            stripeCouponId,
            planId,
            price,
            planPeriod,
            planName,
            contentContext,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            isChild,
            profileId: uuid,
          },
        });
      }
      amplitude
        .getInstance()
        .logEvent("[Stripe] Error Stripe Coupon Doesn't Match With Product", {
          "Stripe Coupon ID": stripeCouponId,
          "Plan ID": planId,
          Price: price,
          "Plan Period": planPeriod,
          "Plan Name": planName,
          contentContext,
          contentEpisodeName,
          contentEpisodeId,
          contentEpisodeSeason,
          contentId,
          contentName,
          isChild,
        });
      window.analytics.track("Coupon Denied", {
        stripe_coupon_id: stripeCouponId,
        plan_id: planId,
        price,
        plan_period: planPeriod,
        plan_name: planName,
        content_context: contentContext,
        content_episode_name: contentEpisodeName,
        content_episode_id: contentEpisodeId,
        content_episode_season: contentEpisodeSeason,
        series_id: contentId,
        content_name: contentName,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const errorStripeCouponMistyped = useCallback(
    (
      planId: string,
      price: number,
      planPeriod: string,
      stripeCouponId: string,
      planName?: string,
      contentContext?: string,
      contentEpisodeName?: string,
      contentEpisodeId?: string,
      contentEpisodeSeason?: number,
      contentId?: string,
      contentName?: string,
      isChild?: boolean
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "errorStripeCouponMistyped",
          dataLayer: {
            stripeCouponId,
            planId,
            price,
            planPeriod,
            planName,
            contentContext,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            isChild,
            profileId: uuid,
          },
        });
      }
      amplitude
        .getInstance()
        .logEvent("[Stripe] Error Stripe Coupon Mistyped", {
          "Stripe Coupon ID": stripeCouponId,
          "Plan ID": planId,
          Price: price,
          "Plan Period": planPeriod,
          "Plan Name": planName,
          contentContext,
          contentEpisodeName,
          contentEpisodeId,
          contentEpisodeSeason,
          contentId,
          contentName,
          isChild,
        });
      window.analytics.track("Coupon Denied", {
        stripe_coupon_id: stripeCouponId,
        plan_id: planId,
        price,
        plan_period: planPeriod,
        plan_name: planName,
        content_context: contentContext,
        content_episode_name: contentEpisodeName,
        content_episode_id: contentEpisodeId,
        content_episode_season: contentEpisodeSeason,
        series_id: contentId,
        content_name: contentName,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
      });
    },
    [setUserId, isTCValidated, uuid]
  );

  const stripeCouponApplied = useCallback(
    (
      planId: string,
      price: number,
      discountedPrice: any,
      planPeriod: string,
      stripeCouponId: string,
      planName?: string,
      contentContext?: string,
      contentEpisodeName?: string,
      contentEpisodeId?: string,
      contentEpisodeSeason?: number,
      contentId?: string,
      contentName?: string,
      isChild?: boolean
    ) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "stripeCouponApplied",
          dataLayer: {
            stripeCouponId,
            planId,
            price,
            discountedPrice,
            planPeriod,
            planName,
            contentContext,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            isChild,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("[Stripe] Stripe Coupon Applied", {
        "Stripe Coupon ID": stripeCouponId,
        "Plan ID": planId,
        Price: price,
        "Discounted Price": discountedPrice,
        "Plan Period": planPeriod,
        "Plan Name": planName,
        contentContext,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        contentId,
        contentName,
        isChild,
      });
      window.analytics.track("Coupon Applied", {
        stripe_coupon_id: stripeCouponId,
        plan_id: planId,
        price,
        plan_period: planPeriod,
        plan_name: planName,
        content_context: contentContext,
        content_episode_name: contentEpisodeName,
        content_episode_id: contentEpisodeId,
        content_episode_season: contentEpisodeSeason,
        series_id: contentId,
        content_name: contentName,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
      });
    },
    [setUserId, isTCValidated, uuid]
  );
  const stripeViewSuccessScreen = useCallback(
    (
      planId: string,
      price: number,
      planPeriod: string,
      stripeCouponId: any,
      planName?: string,
      discountedPrice?: any,
      contentContext?: string,
      contentEpisodeName?: string,
      contentEpisodeId?: string,
      contentEpisodeSeason?: number,
      contentId?: string,
      contentName?: string,
      isChild?: boolean
    ) => {
      setUserId();
      setGroups();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "viewSuccessScreen",
          dataLayer: {
            planId,
            price,
            discountedPrice,
            planPeriod,
            planName,
            contentContext,
            contentEpisodeName,
            contentEpisodeId,
            contentEpisodeSeason,
            contentId,
            contentName,
            isChild,
            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("[Stripe] View Success Screen", {
        "Plan ID": planId,
        Price: price,
        "Discounted Price": discountedPrice,
        "Plan Period": planPeriod,
        "Plan Name": planName,
        contentContext,
        contentEpisodeName,
        contentEpisodeId,
        contentEpisodeSeason,
        contentId,
        contentName,
        isChild,
      });
      window.analytics.track("Checkout Step Completed", {
        discounted_price: discountedPrice,
        plan_id: planId,
        plan_price: price,
        plan_period: planPeriod,
        plan_name: planName,
        content_context: contentContext,
        content_episode_name: contentEpisodeName,
        content_episode_id: contentEpisodeId,
        content_episode_season: contentEpisodeSeason,
        series_id: contentId,
        content_name: contentName,
        ischild: isChild,
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
      });
    },
    [setUserId, setGroups, isTCValidated, uuid]
  );
  const stripeUserAlreadySubscribed = useCallback(
    (context: string) => {
      setUserId();
      if (isTCValidated && isTCValidated.adds) {
        TagManager.dataLayer({
          dataLayerName: "userAlreadySubscribed",
          dataLayer: {
            context,

            profileId: uuid,
          },
        });
      }
      amplitude.getInstance().logEvent("[Stripe] User Already Subscribed", {
        Context: context,
      });
      window.analytics.page(
        "Viewed Stripe User Already Subscribed Page",
        undefined,
        {
          session_id: uuid,
          context: context,
        }
      );
    },
    [setUserId, isTCValidated, uuid]
  );
  return {
    chooseProfileEvent,
    clickEditProfile,
    view,
    logout,
    profileCreation,
    setPinCode,
    forgotPinCode,
    deletePinCode,
    editPinCode,
    switchInterfaceLanguage,
    switchCatalog,
    addAccountEmail,
    updateAccountEmail,
    playSample,
    openSeries,
    share,
    bookmark,
    unbookmark,
    seeSeriesDetails,
    play,
    pause,
    fastForward,
    fastBackward,
    sleepMode,
    sleepModeOff,
    cancelSubscription,
    subscriptionError,
    openPartnersConnectBanner,
    deleteProfile,
    clickAddAdultProfile,
    clickAddKidsProfile,
    unmuteVolume,
    muteVolume,
    openSeriesPageDetailsTab,
    openSeriesPageSimilarTab,
    openSeriesPageEpisodeTab,
    finishAccountProfilesSetup,
    userCreation,
    clickToDeleteAccount,
    confirmDeleteAccount,
    deleteAccountSuccess,
    deleteAccountError,
    clickBackHome,
    viewForgotPasswordScreen,
    clickForgotPassword,
    recoverPasswordSuccess,
    recoverPasswordError,
    viewUpdatePasswordScreen,
    updatePasswordSuccess,
    updatePasswordError,
    updateEmailSuccess,
    updateEmailError,
    clickResendVerifyEmail,
    viewSignInForm,
    viewSignUpForm,
    userSignIn,
    switchToSignUp,
    switchToSignIn,
    clickRecoverPassword,
    userSignUp,
    viewHome,
    openUniverse,
    openMyList,
    clickSearchIcon,
    clickNavBarLanguageIcon,
    clickUnfurlProfileActions,
    openSettings,
    searchEvent,
    openHome,
    clickSeeMoreSeriesDescription,
    clickSeeMoreSeriesEpisodeDescription,
    enterDiscoverCoupon,
    redeemDiscoverCouponError,
    userSignOut,
    stripePresentationViewed,
    stripeSelectPlan,
    stripePlanSelected,
    stripeSubscriptionPurchased,
    stripeViewPaymentScreen,
    stripeCouponTyped,
    stripeCouponApplied,
    stripeViewSuccessScreen,
    stripeUserAlreadySubscribed,
    errorStripeCouponDoesntMatchWithProduct,
    errorStripeCouponMistyped,
    viewUniverse,
    openPartnerConnectCard,
  };
}





