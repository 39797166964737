import React, {
  Dispatch,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import LoaderFullScreen from "../components/LoaderFullScreen";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";
import { seriesHeaderStyle } from "../utils/DynamicStyle";
import { createUseStyles } from "react-jss";
import { UsePopUp } from "../ressources/UsePopUp";
import { getUser } from "../services/UserService";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";
import { toast } from "react-toastify";
import { Action, Case } from "../interfaces/ReducerCase";
import ReactPixel from "react-facebook-pixel";
import amplitude from "amplitude-js";

export default function Offers(): ReactElement {
  const history = useHistory();
  const { isLoading, uuid } = useSelector((state: StoreType) => ({
    ...state.RouterReducer,
    ...state.UserReducer,
  }));
  const closingModal = (isSubscribed?: boolean) => {
    window.scrollTo(0, 0);
    history.push("/home");
  };
  let { idparams } = useParams<Record<string, string | undefined>>();
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        window.location.reload();
      }
    });
  }, []);
  const {
    offerSelected,
    isSuccess,
    isPayment,
    selectedOffer,
    firstOffer,
    secondOffer,
    isInit,
  } = UsePopUp(closingModal, undefined);
  const [isFetchingUser, setIsFetchingUser] = useState(true);

  useEffect(() => {
    amplitude.getInstance().logEvent("[Stripe] Presentation Viewed", {
      Context: "Direct to url",
      "Presented Offers": "Default offers",
    });
  }, []);
  useEffect(() => {
    if (!!isInit && !!firstOffer && !isLoading && !isFetchingUser) {
      window.analytics.page("Offer Presented", undefined, {
        context: "Direct to url",
        plan_id: firstOffer.id,
        plan_period: firstOffer.interval,
        plan_price: firstOffer.amount,
        platform: "Web",
        presented_offers: "Default offers",
        session_id: uuid,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
      });
    }
  }, [isInit, firstOffer, uuid, isFetchingUser, isLoading]);

  const useStyles = createUseStyles({
    "pop-up-dynamic-header": {
      position: "relative",
      height: (props) =>
        !props.seriesContext && !props.isPayment ? "100vh" : "auto",
      minHeight: (props) =>
        !props.seriesContext && !props.isPayment ? "700px !important" : "auto",
      "&::before": {
        backgroundImage: (props) =>
          (props.seriesContext && !props.isPayment) ||
          (props.seriesContext && props.isPayment && props.isSuccess)
            ? `url("${props.seriesContext.coverImage}")`
            : !props.seriesContext &&
              !props.isPayment &&
              props.idparams &&
              props.idparams === "culture"
            ? 'url("/image/culture-bckg.png")'
            : !props.seriesContext && !props.isPayment
            ? 'url("/image/pop-up-background2.png")'
            : "none",
        ...seriesHeaderStyle["pop-up-dynamic-header-before"],
        backgroundPosition: "top",
      },
      "&::after": {
        background: (props) =>
          props.seriesContext && !props.isPayment
            ? `linear-gradient(180deg, rgba(25, 25, 25, 0) 1.92%, #191919 100%)`
            : !props.seriesContext && !props.isPayment
            ? "rgba(11, 15, 29, 0.486)"
            : "none",
        ...seriesHeaderStyle["pop-up-dynamic-header-before"],
        filter: (props) =>
          (props.seriesContext && !props.isPayment) ||
          (props.seriesContext && props.isPayment && props.isSuccess)
            ? "blur(96px)"
            : "none",
        ...seriesHeaderStyle["pop-up-dynamic-header-after"],
      },
    },
  });
  const classes = useStyles({ isPayment, isSuccess, idparams });
  const showPayment = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const offer = selectedOffer?.index === 0 ? firstOffer : secondOffer;
    if (offer) {
      ReactPixel.track("AddToCart", {
        order_id: offer.id,
        subscription_type: `${offer.amount} ${offer.interval} ${offer.currency}`,
        Trial:
          offer.trial_period_days && offer.trial_period_days > 0 ? true : false,
        numero_trial:
          offer.trial_period_days && offer.trial_period_days > 0
            ? offer.trial_period_days
            : null,
      });

      window.scrollTo(0, 0);
      history.push(`/payment/${offer.id}`);
    }
  };
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);

  useEffect(() => {
    const fetchUser = async () => {
      setIsFetchingUser(true);
      try {
        const res = await getUser(
          JSON.parse(localStorage.getItem("user")!)._id,
          JSON.parse(localStorage.getItem("user")!).token
        );
        const userData: any = await res.json();
        stableDispatch({ type: Case.UPDATE_USER, payload: { user: userData } });
        setIsFetchingUser(false);
      } catch (error: any) {
        setIsFetchingUser(false);
        return toast(language("codeError"), notificationsParam);
      }
    };
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")!).token &&
      JSON.parse(localStorage.getItem("user")!)._id
    ) {
      fetchUser();
    }
  }, [stableDispatch]);

  return (
    <>
      {isLoading || isFetchingUser ? (
        <LoaderFullScreen />
      ) : (
        <div
          className={`${classes["pop-up-dynamic-header"]} pop-up`}
          id="offers"
        >
          <div className="pop-up-header" style={{ height: "420px" }}>
            <img
              style={{ marginBottom: "10px" }}
              className="logo"
              src="/image/logo/sybelplus_logo.svg"
              alt=""
            />
            <ul style={{ color: "#fff", margin: "20px" }}>
              <li>{language("offersArguments1")}</li>
              <li>{language("offersArguments2")}</li>
              <li>{language("offersArguments3")}</li>
              <li>{language("offersArguments4")}</li>
              <li>{language("offersArguments5")}</li>
            </ul>
            <p>
              {selectedOffer &&
              selectedOffer.offer.trial_period_days &&
              selectedOffer.offer.trial_period_days !== 0
                ? `${language("popUpTitle")}${language("popUpTitleFree")} ${
                    selectedOffer.offer.trial_period_days
                  } ${language("days")}`
                : language("popUpTitle")}
            </p>
          </div>
          <div
            style={{ marginTop: "15px" }}
            className="pop-up-offers-container"
          >
            {firstOffer && (
              <div
                style={{
                  border:
                    selectedOffer &&
                    selectedOffer.offer &&
                    selectedOffer.offer.id === firstOffer.id
                      ? " 2px solid #FF8475"
                      : "2px solid #A5A5A5",
                  opacity:
                    selectedOffer &&
                    selectedOffer.offer &&
                    selectedOffer.offer.id === firstOffer.id
                      ? 1
                      : 0.4,
                }}
                className="pop-up-card"
                onClick={() => offerSelected(0, firstOffer)}
              >
                {/*                 <p
                  className="offers-special-banner"
                  style={{
                    backgroundColor:
                      selectedOffer &&
                      selectedOffer.offer &&
                      selectedOffer.offer.id === firstOffer.id
                        ? "#F23568"
                        : "#A5A5A5",
                  }}
                >
                  {language("summerOffer")}
                  <span>{` (-${Math.floor(
                    100 - (firstOffer.amount / (secondOffer!.amount * 12)) * 100
                  )}%)`}</span>
                </p> */}
                <p className="offer-title">{language("yearly")}</p>
                <p className="offer-price">
                  {language("defaultYearPrice", {
                    price: `${firstOffer.amount.toFixed(2)} ${
                      firstOffer.currency
                    }`,
                  })}
                </p>
                {/*                 {firstOffer.trial_period_days &&
                firstOffer.trial_period_days !== 0 ? (
                  <p className="offer-trial">
                    {language("YearlyOfferUnitaryPrice", {
                      price: firstOffer.amount,
                    })}
                  </p>
                ) : null} */}
              </div>
            )}
            {secondOffer && (
              <div
                style={{
                  border:
                    selectedOffer &&
                    selectedOffer.offer &&
                    selectedOffer.offer.id === secondOffer.id
                      ? " 2px solid #FF8475"
                      : "2px solid #A5A5A5",
                  opacity:
                    selectedOffer &&
                    selectedOffer.offer &&
                    selectedOffer.offer.id === secondOffer.id
                      ? 1
                      : 0.4,
                }}
                className="pop-up-card"
                onClick={() => offerSelected(1, secondOffer)}
              >
                <p className="offer-title">{language("monthly")}</p>
                <p className="offer-price">
                  {language("defaultMonthPrice", {
                    price: `${secondOffer.amount.toFixed(2)} ${
                      secondOffer.currency
                    }`,
                  })}
                </p>
                {secondOffer.trial_period_days &&
                secondOffer.trial_period_days !== 0 ? (
                  <p className="offer-trial">
                    {secondOffer.trial_period_days}{" "}
                    {language("trialPremium", {
                      count: secondOffer.trial_period_days,
                    })}
                  </p>
                ) : null}
              </div>
            )}
          </div>
          <button
            className="activate-payment"
            type="submit"
            onClick={(e) => showPayment(e)}
          >
            <p>
              {selectedOffer &&
              selectedOffer.offer &&
              selectedOffer.offer.trial_period_days &&
              selectedOffer.offer.trial_period_days !== 0
                ? language("beginTrialPremium", {
                    count: selectedOffer.offer.trial_period_days,
                  })
                : language("continue")}
            </p>
          </button>
        </div>
      )}
    </>
  );
}
