import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import useKeyPress from "./UseKeyPress";

export default function UseControlePlayerWithKeyBoard(
  playerRef: React.RefObject<ReactPlayer>,
  isComponentVisible: boolean,
  playerButtonRewind: React.MutableRefObject<null>,
  playerButtonForward: React.MutableRefObject<null>,
  playerButtonPlay: React.MutableRefObject<null>
) {
  const rightArrow = useKeyPress(39);
  const leftArrow = useKeyPress(37);
  const space = useKeyPress(32);
  const { currentEpisode } = useSelector((state: StoreType) => ({
    ...state.PlayerReducer,
  }));
  useEffect(() => {
    function eventFire(el, etype) {
      if (el.fireEvent) {
        el.fireEvent("on" + etype);
      } else {
        var evObj = document.createEvent("Events");
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
      }
    }
    if (
      leftArrow &&
      isComponentVisible &&
      currentEpisode &&
      currentEpisode.episode
    ) {
      eventFire(playerButtonRewind.current, "click");
    } else if (
      rightArrow &&
      isComponentVisible &&
      currentEpisode &&
      currentEpisode.episode
    ) {
      eventFire(playerButtonForward.current, "click");
    } else if (space && isComponentVisible && currentEpisode) {
      eventFire(playerButtonPlay.current, "click");
    } else {
      return;
    }
  }, [
    rightArrow,
    leftArrow,
    space,
    playerRef,
    isComponentVisible,
    currentEpisode,
    playerButtonForward, 
    playerButtonPlay,
    playerButtonRewind
  ]); // eslint-disable-line react-hooks/exhaustive-deps
}
