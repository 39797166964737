import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action, Dispatch } from "redux";
import { Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import { UseSeriesLanguage } from "./UseSeriesLanguage";
import { editProfile } from "../services/ProfileService";
import EventStore from "../stores/AmplitudeStore";

interface Props {
  each?: {
    channel?: {
      _id: string;
      name: string;
      nameCode: string;
      umbrella: {
        _id: string;
        name: string;
        nameCode: string;
      };
    } | null
    billboard?: boolean;
    highlight?: boolean;
    active?: boolean;
    index?: number;
    title?: string;
    _id: string;
    bannerImage?: string;
    isChild?: boolean;
    coverImage?: string;
    titleImage?: string;
    seasonsList?: [number];
    mainImage?: string;
    chronological?: boolean;
    classification?: string;
    productor?: string;
    isUnit?: boolean;
    similar?: [string];
    compositor?: string;
    authors?: string;
    beneficiaries?: string;
    realisator?: string;
    length?: number;
    actors?: string;
    info?: {
      [key: string]: {
        seasonsList?: number[];
        _id: string;
        title: string;
        details: string;
        headerMention: string;
        sample: string;
        video: string;
        share: string;
        credits: string;
        titleImage: string;
        coverImage: string;
        mainImage: string;
        rssFeed?: string;
      };
    };
    emotion?: [string];
    imageUrl?: string;
    mainImageId?: string;
    locked?: boolean;
    newEpisodes?: boolean;
  };
  universeToShow?: string;
  index: number;
  likeListe?: boolean;
}

export default function UseMainCategorieesCard({
  each,
  universeToShow,
  likeListe,
  index
}: Props): {
  addToLikeList: (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => Promise<void>;
  isLiked: boolean;
} {
  const { unbookmark, bookmark } = EventStore();
  const { seriesLanguage } = UseSeriesLanguage();
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const dispatch: Dispatch<Action> = useDispatch();

  const { profile, user, profiles, universes, uuid } = useSelector(
    (state: StoreType) => ({
      ...state.ProfileReducer,
      ...state.UserReducer,
      ...state.SeriesReducer
    })
  );

  useEffect(() => {
    if (
      each &&
      profile &&
      profile.likeList &&
      profiles &&
      profiles.length > 0
    ) {
      return setIsLiked(
        profiles[
          profiles.findIndex((eachProfile) => eachProfile._id === profile._id)
        ].likeList.find((eachLikedSeries) => eachLikedSeries === each._id) !==
          undefined
      );
    }
    return () => setIsLiked(false);
  }, [profile, profiles, each, seriesLanguage]);
  const addToLikeList = async (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (!each) {
      return;
    } else {
      const isLikedTemp = isLiked;
      if (!isLikedTemp) {
        bookmark(
          "Click Default Category",
          each._id,
          profile!.isChild,
          undefined,
          undefined,
          undefined,
          "Default Category",
          universes && universeToShow
            ? universes.filter(
                (each) => each.headerCategoryId === universeToShow
              )[0].title
            : "Main Homepage",
          universeToShow ? universeToShow : undefined
        );
        window.analytics.track("Content Bookmarked", {
          subscription: !!localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!).subscription : 0,
          session_id: uuid,
          series_id: each._id,
          content_name:
            each && !!each.info ? each.info[seriesLanguage].title : undefined,
          content_length: !!each.length ? each.length : undefined,
          platform: "Web",
          signup_type: localStorage.getItem("sy_signup_type")
            ? localStorage.getItem("sy_signup_type")
            : undefined,
            channel_id:
            each &&
            each.channel
              ? each.channel._id
              : undefined,
          channel_name:
          each &&
          each.channel
            ? each.channel.name
              : undefined,
          channel_name_code:
          each &&
          each.channel
            ? each.channel.nameCode
              : undefined,
          channel_umbrella_id:
          each &&
          each.channel
            ? each.channel.umbrella._id
              : undefined,
          channel_umbrella_name:
          each &&
          each.channel
            ? each.channel.umbrella.name
              : undefined,
          channel_umbrella_name_code:
          each &&
          each.channel
            ? each.channel.umbrella.nameCode
              : undefined,
              has_rss_feed:
              each &&
              each.info &&  each.info[seriesLanguage] &&
              each.info[seriesLanguage].rssFeed
                ? true
                : false,
        });
      } else {
        unbookmark(
          likeListe ? "My List Screen" : "Click Default Category",
          each._id,
          profile!.isChild,
          undefined,
          undefined,
          undefined,
          likeListe ? "My List Screen" : "Default Category",
          likeListe
            ? undefined
            : universes && universeToShow
            ? universes.filter(
                (each) => each.headerCategoryId === universeToShow
              )[0].title
            : "Main Homepage",
          !likeListe && universeToShow ? universeToShow : undefined,
          index
        );
        window.analytics.track("Content Unbookmarked", {
          subscription: !!localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!).subscription : 0,
          session_id: uuid,
          series_id: each._id,
          content_name:
            each && !!each.info ? each.info[seriesLanguage].title : undefined,
          content_length: !!each.length ? each.length : undefined,
          platform: "Web",
          signup_type: localStorage.getItem("sy_signup_type")
            ? localStorage.getItem("sy_signup_type")
            : undefined,
            channel_id:
            each &&
            each.channel
              ? each.channel._id
              : undefined,
          channel_name:
          each &&
          each.channel
            ? each.channel.name
              : undefined,
          channel_name_code:
          each &&
          each.channel
            ? each.channel.nameCode
              : undefined,
          channel_umbrella_id:
          each &&
          each.channel
            ? each.channel.umbrella._id
              : undefined,
          channel_umbrella_name:
          each &&
          each.channel
            ? each.channel.umbrella.name
              : undefined,
          channel_umbrella_name_code:
          each &&
          each.channel
            ? each.channel.umbrella.nameCode
              : undefined,
              has_rss_feed:
              each &&
              each.info &&  each.info[seriesLanguage] &&
              each.info[seriesLanguage].rssFeed
                ? true
                : false,
        });
      }
      setIsLiked(!isLiked);
      try {
        const profilePending = await editProfile(
          profile!,
          user,
          isLikedTemp
            ? profiles[
                profiles.findIndex((each) => each._id === profile!._id)
              ]!.likeList.slice().filter(
                (eachLikedSeries) => eachLikedSeries !== each!._id
              )
            : [
                ...profiles[
                  profiles.findIndex((each) => each._id === profile!._id)
                ]!.likeList.slice(),
                each._id
              ]
        );
        const profileUpdated = await profilePending.json();
        dispatch({
          type: Case.UPDATE_PROFILES,
          payload: { profile: profileUpdated }
        });
      } catch (e) {
        setIsLiked(!isLiked);
      }
    }
  };

  return { isLiked, addToLikeList };
}
