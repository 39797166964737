import ProfileType from "../interfaces/ProfileType";
import UserType from "../interfaces/UserType";

const getProfiles = async (user: UserType | { _id: string; token: string }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user._id}/profile`,
    requestOptions
  );
};

const createProfile = async (profile: ProfileType | any, user: UserType) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
    body: JSON.stringify({
      gender: profile.gender,
      name: profile.userName,
      isChild: profile.isChild,
      image: profile.image,
      isMain: profile.isMain,
      birthday: profile.birthday,
    }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user._id}/profile`,
    requestOptions
  );
};
const editProfile = async (
  profile: ProfileType,
  user: UserType,
  likeList?: string[]
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
    body: JSON.stringify({
      name: profile.name,
      isChild: profile.isChild,
      image: profile.image,
      gender: profile.gender,
      birthday: profile.birthday,
      likeList,
    }),
  };

  return await fetch(
    `${process.env.REACT_APP_API}/user/${user._id}/profile/${profile._id}`,
    requestOptions
  );
};

const deleteProfileService = async (profile: ProfileType, user: UserType) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API}/user/${user._id}/profile/${profile._id}`,
    requestOptions
  );
};

const getAvatars = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  };
  return await fetch(`${process.env.REACT_APP_API}/avatar`, requestOptions);
};

export {
  getProfiles,
  editProfile,
  createProfile,
  getAvatars,
  deleteProfileService,
};
