import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SignUpSignIn from "../components/SignUpSignIn";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";
import "../styles/auth.scss";
import EventStore from "../stores/AmplitudeStore";
import UseAuth from "../ressources/UseAuth";
import { UseSignout } from "../ressources/UseSignout";

export default function Auth({ renault }: { renault?: boolean }): ReactElement {
  const { openPartnersConnectBanner } = EventStore();
  const { signinMode } = useSelector((state: StoreType) => ({
    ...state.AuthReducer,
    ...state.RouterReducer,
  }));
  const { signIn, signUp, isAppleLoading } = UseAuth(renault);
  const { signout } = UseSignout();
  useEffect(() => {
    if (
      renault &&
      !!localStorage.getItem("user") &&
      !!JSON.parse(localStorage.getItem("user")!).token
    ) {
      signout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renault]);

  return (
    <>
      {!renault && !sessionStorage.getItem("redeemCode") && (
        <Link
          onClick={() =>
            openPartnersConnectBanner(signinMode ? "Sign In" : "Sign Up")
          }
          to="/partner-connect"
          className="sfr-activate"
        >
          <h3>{language("operatorActivate")}</h3>
          <img src="/image/logo/sfr_logo_small.svg" alt="sfr logo" />
          <img src="/image/logo/red_by_sfr_small.svg" alt="red by sfr logo" />
          <img src="/image/logo/free_logo_small.svg" alt="free logo" />
        </Link>
      )}
      <div
        style={{
          backgroundImage: renault
            ? "url('/image/main-renault-landing-background.png')"
            : undefined,
          padding: renault ? 0 : undefined,
        }}
        className="sign-up-sign-in-container"
      >
        {!!sessionStorage.getItem("redeemCode") && (
          <p
            style={{
              position: "absolute",
              left: "0",
              right: 0,
              top: "40px",
              margin: "auto",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 700,
              letterSpacing: "0.25px",
            }}
            className="redeemstep"
          >
            <span style={{ color: "#9C9EAB", textDecoration: "initial" }}>
              Activation du code
            </span>
            <svg
              style={{ margin: "0 16px" }}
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"
                fill="#9C9EAB"
              />
            </svg>
            <span
              style={{
                color: "#ED5A5A",
                textDecoration: "underline #ED5A5A",
              }}
            >
              {signinMode ? "Connexion au compte" : "Création du compte"}
            </span>
          </p>
        )}
        <SignUpSignIn
          renault={renault}
          isAppleLoading={isAppleLoading}
          signIn={signIn}
          signUp={signUp}
        />
      </div>
    </>
  );
}
