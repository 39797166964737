import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EpisodeType from "../interfaces/EpisodeType";
import StoreType from "../interfaces/StoreType";

export const UseEpisodeStatus = (
  id: string,
  episode: EpisodeType
): { isListened: boolean; isInProgress: boolean; progressBarSize: number } => {
  const { episodesHistory } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
  }));
  const [isListened, setIsListened] = useState<boolean>(false);
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const [progressBarSize, setProgressBarSize] = useState<number>(0);

  useEffect(() => {
    if (
      episodesHistory &&
      episodesHistory[id] &&
      episodesHistory[id][episode._id]
      ) {
      if (episodesHistory[id][episode._id].status === false) {
        setProgressBarSize(
          (episodesHistory[id][episode._id].progress * 100) / episode.length
        );
        setIsInProgress(true);
      } else if (episodesHistory[id][episode._id].status === true) {
        setIsListened(true);
      }
    } else {
      setIsListened(false);
      setIsInProgress(false);
    }
  }, [episodesHistory, episode, id]);
  return { isListened, isInProgress, progressBarSize };
};
