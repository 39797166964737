import StoreType from "../interfaces/StoreType";
import { Action, Case } from "../interfaces/ReducerCase";
import { language } from "./18nStore";
const initProfile = () => {
  if (
    sessionStorage.getItem("profile") &&
    sessionStorage.getItem("profile") !== "undefined"
  ) {
    window.analytics.identify(
      JSON.parse(sessionStorage.getItem("profile")!)._id,
      {
        created_at: JSON.parse(sessionStorage.getItem("profile")!)._created_at,
        user_id: JSON.parse(sessionStorage.getItem("profile")!)._id,
        ismain: JSON.parse(sessionStorage.getItem("profile")!).isMain,
        ischild: JSON.parse(sessionStorage.getItem("profile")!).isChild,
        like_list: JSON.parse(sessionStorage.getItem("profile")!).likeList,
        name: JSON.parse(sessionStorage.getItem("profile")!).name,
        birthday: JSON.parse(sessionStorage.getItem("profile")!).birthday,
        gender: JSON.parse(sessionStorage.getItem("profile")!).gender,
        subscription: JSON.parse(localStorage.getItem("user")!).subscription
      }
    );
    return JSON.parse(sessionStorage.getItem("profile")!);
  } else {
    return undefined;
  }
};
const ProfileStore: StoreType["ProfileReducer"] = {
  home: undefined,
  universes: undefined,
  universesCategories: undefined,
  categories: undefined,
  profileIsLoading: false,
  setupProfileValue: {
    userName: ""
  },
  setupProfileMainInput: {
    htmlFor: "userName",
    type: "text",
    label: language("mainUserTitle"),
    autocomplete: "off",
    errorStyle: false
  },
  multipleProfileInput: [],
  profiles: [],
  profile: initProfile(),
  editProfilValue: {
    pseudo: "",
    gender: undefined,
    birthday: undefined
  },
  editProfilInput: [
    {
      htmlFor: "pseudo",
      type: "text",
      label: language("firstName"),
      autocomplete: "on",
      errorStyle: false
    },
    {
      htmlFor: "gender",
      type: "select",
      choice: [
        language("wontSay"),
        language("male"),
        language("female"),
        language("other")
      ],
      label: language("gender"),
      autocomplete: "on",
      errorStyle: false
    },
    {
      htmlFor: "birthday",
      type: "text",
      label: language("birthday"),
      autocomplete: "on",
      errorStyle: false
    }
  ]
};
export default function ProfileReducer(state = ProfileStore, action: Action) {
  switch (action.type) {
    case Case.PROFILE_IS_LOADING: {
      return { ...state, profileIsLoading: true };
    }
    case Case.PROFILE_IS_NOT_LOADING: {
      return { ...state, profileIsLoading: false };
    }
    case Case.SET_PROFILES:
      return {
        ...state,
        profiles: [...action.payload.profiles]
      };
    case Case.UPDATE_PROFILES: {
      const clone = state.profiles.slice();
      const index = clone.findIndex(
        (eachProfile) => eachProfile._id === action.payload.profile._id
      );
      clone.splice(index, 1, action.payload.profile);
      return {
        ...state,
        profiles: clone
      };
    }
    case Case.DELETE_PROFILES: {
      const clone = state.profiles.slice();
      const index = clone.findIndex(
        (eachProfile) => eachProfile._id === action.payload.profile._id
      );
      clone.splice(index, 1);
      return {
        ...state,
        profiles: clone
      };
    }
    case Case.SET_PROFILE:
      window.analytics.identify(action.payload.profile._id, {
        created_at: action.payload.profile._created_at
          ? action.payload.profile._created_at
          : undefined,
          subscribtion: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).subscription : 0,
        user_id: action.payload.profile._id
          ? action.payload.profile._id
          : undefined,
        ismain: action.payload.profile.isMain
          ? action.payload.profile.isMain
          : undefined,
        ischild: action.payload.profile.isChild
          ? action.payload.profile.isChild
          : undefined,
        like_list: action.payload.profile.likeList
          ? action.payload.profile.likeList
          : undefined,
        name: action.payload.profile.name
          ? action.payload.profile.name
          : undefined,
        birthday: action.payload.profile.birthday
          ? action.payload.profile.birthday
          : undefined,
        gender: action.payload.profile.gender
          ? action.payload.profile.gender
          : undefined,
         
      });
      return {
        ...state,
        profile: action.payload.profile
      };
    case Case.SET_MAIN_PROFILE_VALUE:
      return {
        ...state,
        setupProfileValue: {
          ...state.setupProfileValue,
          [action.payload.htmlFor]: action.payload.value
        }
      };
    case Case.SET_MAIN_PROFILE_ERROR:
      return {
        ...state,
        setupProfileMainInput: {
          htmlFor: "userName",
          type: "text",
          label: language("mainUserTitle"),
          autocomplete: "false",
          errorStyle: state.setupProfileValue.userName === "" ? true : false
        }
      };
    case Case.SET_DEFAULT_MAIN_PROFILE:
      return {
        ...state,
        setupProfileMainInput: {
          htmlFor: "userName",
          type: "text",
          label: language("mainUserTitle"),
          autocomplete: "true",
          errorStyle: false
        }
      };
    case Case.SET_NEW_PROFILE:
      return {
        ...state,
        multipleProfileInput: [
          ...state.multipleProfileInput,
          {
            htmlFor: `${state.multipleProfileInput.length + 2}Profil`,
            type: "text",
            label: `Profil ${state.multipleProfileInput.length + 2}`,
            autocomplete: "off",
            errorStyle: false,
            userName: "",
            isChild: false
          }
        ]
      };

    case Case.DELETE_IN_SETUP_PROFILE: {
      let multipleProfileInput = state.multipleProfileInput;
      const clone = [...multipleProfileInput];
      const finalClone: any[] = [];
      clone.forEach((each, index) => {
        if (index < action.payload.index) {
          return finalClone.push({
            htmlFor: `${index + 2}Profil`,
            type: "text",
            label: `${language("profile")} ${index + 2}`,
            autocomplete: "off",
            errorStyle: false,
            userName: each.userName,
            isChild: each.isChild
          });
        } else if (index > action.payload.index) {
          return finalClone.push({
            htmlFor: `${index + 1}Profil`,
            type: "text",
            label: `${language("profile")} ${index + 1}`,
            autocomplete: "off",
            errorStyle: false,
            userName: clone[index].userName,
            isChild: clone[index].isChild
          });
        } else {
          return;
        }
      });
      return {
        ...state,
        multipleProfileInput: finalClone
      };
    }

    case Case.PROFILE_IS_CHILD: {
      let multipleProfileInput = state.multipleProfileInput;
      const clone = [...multipleProfileInput];
      clone.splice(action.payload.index, 1, {
        htmlFor: `${action.payload.index + 2}Profil`,
        type: "text",
        label: `${language("profile")} ${action.payload.index + 2}`,
        autocomplete: "off",
        errorStyle: false,
        userName: clone[action.payload.index]!.userName,
        isChild: !clone[action.payload.index]!.isChild
      });
      return {
        ...state,
        multipleProfileInput: clone
      };
    }
    case Case.SET_PROFILE_VALUE: {
      let multipleProfileInput = state.multipleProfileInput;
      const clone = [...multipleProfileInput];
      clone.splice(action.payload.index, 1, {
        htmlFor: `${action.payload.index ? action.payload.index + 2 : 2}Profil`,
        type: "text",
        label: `${language("profile")} ${
          action.payload.index ? action.payload.index + 2 : 2
        }`,
        autocomplete: "off",
        errorStyle: false,
        userName: action.payload.value,
        isChild: action.payload.index
          ? clone[action.payload.index]!.isChild
          : clone[0]!.isChild
      });
      return {
        ...state,
        multipleProfileInput: clone
      };
    }
    case Case.SET_PROFILE_ERROR:
      let multipleProfileInput = state.multipleProfileInput;
      const clone = [...multipleProfileInput];
      clone.splice(
        action.payload && action.payload.index ? action.payload.index : 0,
        1,
        {
          htmlFor: `${
            action.payload && action.payload.index
              ? action.payload.index + 2
              : 2
          }Profil`,
          type: "text",
          label: `${language("profile")} ${
            action.payload && action.payload.index
              ? action.payload.index + 2
              : 2
          }`,
          autocomplete: "off",
          errorStyle:
            clone[action.payload.index]!.userName === "" ? true : false,
          userName: clone[action.payload.index]!.userName,
          isChild:
            action.payload && action.payload.index
              ? clone[action.payload.index]!.isChild
              : clone[0]!.isChild
        }
      );
      return {
        ...state,
        multipleProfileInput: clone
      };
    case Case.SET_DEFAULT_PROFILE: {
      let multipleProfileInput = state.multipleProfileInput;
      const clone = [...multipleProfileInput];
      clone.splice(
        action.payload && action.payload.index ? action.payload.index : 0,
        1,
        {
          htmlFor: `${
            action.payload && action.payload.index
              ? action.payload.index + 2
              : 2
          }Profil`,
          type: "text",
          label: `${language("profile")} ${
            action.payload && action.payload.index
              ? action.payload.index + 2
              : 2
          }`,
          autocomplete: "off",
          errorStyle: false,
          userName: clone[action.payload.index]!.userName,
          isChild:
            action.payload && action.payload.index
              ? clone[action.payload.index]!.isChild
              : clone[0]!.isChild
        }
      );
      return {
        ...state,
        multipleProfileInput: clone
      };
    }

    case Case.SIGNOUT:
      return {
        home: undefined,
        profile: undefined,
        universes: undefined,
        universesCategories: undefined,
        categories: undefined,
        profileIsLoading: false,
        setupProfileValue: {
          userName: ""
        },
        setupProfileMainInput: {
          htmlFor: "userName",
          type: "text",
          label: language("mainUserTitle"),
          autocomplete: "off",
          errorStyle: false
        },
        multipleProfileInput: [],
        profiles: [],
        editProfilValue: {
          pseudo: "",
          gender: undefined,
          birthday: undefined
        },
        editProfilInput: [
          {
            htmlFor: "pseudo",
            type: "text",
            label: language("firstName"),
            autocomplete: "on",
            errorStyle: false
          },
          {
            htmlFor: "gender",
            type: "select",
            choice: [
              language("wontSay"),
              language("male"),
              language("female"),
              language("other")
            ],
            label: language("gender"),
            autocomplete: "on",
            errorStyle: false
          },
          {
            htmlFor: "birthday",
            type: "text",
            label: language("birthday"),
            autocomplete: "on",
            errorStyle: false
          }
        ]
      };
    case Case.SET_CHOOSE_PROFILE_VALUE:
      return {
        ...state,
        editProfilValue: {
          ...state.editProfilValue,
          [action.payload.htmlFor]: action.payload.value
        }
      };
    case Case.SET_CHOOSE_PROFILE_EDIT_VALUE:
      return {
        ...state,
        editProfilValue: {
          pseudo: action.payload.pseudo,
          birthday: action.payload.birthday,
          gender: action.payload.gender
        }
      };
    case Case.SET_CHOOSE_PROFILE_DEFAULT_VALUE:
      return {
        ...state,
        editProfilValue: {
          pseudo: "",
          gender: undefined,
          birthday: undefined
        }
      };
    case Case.SET_HOME:
      return {
        ...state,
        home: action.payload.home
      };
    case Case.SET_CHOOSE_PROFILE_ERROR:
      return {
        ...state,
        editProfilInput: [
          {
            htmlFor: "pseudo",
            type: "text",
            label: language("firstName"),
            autocomplete: "on",
            errorStyle: state.editProfilValue.pseudo === "" ? true : false
          },
          {
            htmlFor: "gender",
            type: "select",
            choice: [
              language("wontSay"),
              language("male"),
              language("female"),
              language("other")
            ],
            label: language("gender"),
            autocomplete: "on",
            errorStyle: false
          },
          {
            htmlFor: "birthday",
            type: "text",
            label: language("birthday"),
            autocomplete: "on",
            errorStyle: false
          }
        ]
      };
    case Case.SET_DEFAULT_CHOOSE_PROFILE:
      return {
        ...state,
        editProfilInput: [
          {
            htmlFor: "pseudo",
            type: "text",
            label: language("firstName"),
            autocomplete: "on",
            errorStyle: false
          },
          {
            htmlFor: "gender",
            type: "select",
            choice: [
              language("wontSay"),
              language("male"),
              language("female"),
              language("other")
            ],
            label: language("gender"),
            autocomplete: "on",
            errorStyle: false
          },
          {
            htmlFor: "birthday",
            type: "text",
            label: language("birthday"),
            autocomplete: "on",
            errorStyle: false
          }
        ]
      };
    case Case.REINIT_HOME:
      return {
        ...state,
        home: undefined
      };
    case Case.SET_UNIVERSES:
      return {
        ...state,
        universes: action.payload.universes
      };
    case Case.SET_UNIVERSES_CATEGORIES:
      return {
        ...state,
        universesCategories: action.payload.universesCategories
      };
    case Case.SET_CATEGORIES:
      return {
        ...state,
        categories: state.categories
          ? [...state.categories, ...action.payload.categories]
          : action.payload.categories
      };
    default:
      return state;
  }
}
