import StoreType from "../interfaces/StoreType";
import { Action, Case } from "../interfaces/ReducerCase";

const RouterStore: StoreType["RouterReducer"] = {
  
  
  nextUrl: localStorage.getItem("nextUrl") && localStorage.getItem("nextUrl") !== null ? sessionStorage.getItem("nextUrl")! : undefined,
  isLoading: false,
  isTCValidated: localStorage.getItem("isTCValidated") ? JSON.parse(localStorage.getItem("isTCValidated")!) : undefined,
  isSeriesOpen: undefined
};
export default function ProfileReducer(state = RouterStore, action: Action) {
  switch (action.type) {
    case Case.OPEN_SERIES: {
      return { ...state, isSeriesOpen: true };
    }
    case Case.CLOSE_SERIES: {
      return { ...state, isSeriesOpen: false };
    }
    case Case.SET_NEXT_URL: {
      return { ...state, nextUrl: action.payload.url };
    }
    case Case.SET_NEXT_URL_DEFAULT: {
      return { ...state, nextUrl: undefined };
    }
    case Case.IS_GLOBAL_LOADING: {
      return { ...state, isLoading: true };
    }
    case Case.IS_GLOBAL_NOT_LOADING: {
      return { ...state, isLoading: false };
    }
    case Case.SIGNOUT: {
      return {
        ...state,
        nextUrl: undefined,
        isLoading: false
        };
    }
    case Case.SET_TC: {
      return {
        ...state,
        isTCValidated: action.payload.choices
      }
    }
    case Case.SET_MAIN_OVERFLOW: {
      return {
        ...state,
        isMainOverflow: true
      }
    }
    case Case.UNSET_MAIN_OVERFLOW: {
      return {
        ...state,
        isMainOverflow: false
      }
    }

    default:
      return state;
  }
}
