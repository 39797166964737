import React, { ReactElement } from "react";
import EpisodeType from "../interfaces/EpisodeType";
import { language } from "../stores/18nStore";
import moment from "moment";

interface Props {
  episode: EpisodeType;
  seriesLanguage: string;
}

export default function SeriesEpisodesTitle({
  episode,
  seriesLanguage,
}: Props): ReactElement {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h2
        style={
          (!episode.bonus) ||
          !episode.info[seriesLanguage].title ||
          !episode.publicationDate ||
          new Date(episode.publicationDate) > new Date()
            ? {
                color: "#a5a5a5",
                textTransform: "capitalize",
                fontWeight: "normal",
              }
            : {}
        }
      >
        {episode.bonus
          ? `Bonus - ${episode.info[seriesLanguage].title}`
          : `${
              episode.info[seriesLanguage].title
                ? episode.info[seriesLanguage].title
                : language("episodes")
            }`}
        <i style={{ marginLeft: "6px" }}>
          <small>
            {((!episode.bonus ||
              !episode.info[seriesLanguage].title ||
              !episode.publicationDate ) && 
              new Date(episode.publicationDate) > new Date()) &&
              `${language("comingSoonEpisode")} ${moment(
                new Date(episode.publicationDate)
              ).format("DD/MM/YYYY")}`}
          </small>
        </i>
      </h2>
      {episode.length && (
        <h2 style={{ fontWeight: 500 }}>
          {Math.round(episode.length / 60) === 0
            ? 1
            : Math.round(episode.length / 60)}
          {language("min")}
        </h2>
      )}
    </div>
  );
}
