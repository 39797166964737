import { Dispatch, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import {
  getUniversService,
  getUniversServiceById,
} from "../services/DashboardService";
import { language } from "../stores/18nStore";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";

export const UseUnivers = () => {
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const { profile, user, userContentLanguage, universes } = useSelector(
    (state: StoreType) => ({
      ...state.PlayerReducer,
      ...state.ProfileReducer,
      ...state.UserReducer,
    })
  );

  const history = useHistory();
  const location = useLocation();
  const toastId = "unique";
  useEffect(() => {
    const getUnivers = async () => {
      try {
        const universPending = await getUniversService(userContentLanguage);
        const universeData: StoreType["ProfileReducer"]["universes"] = await universPending.json();
        Promise.all(
          universeData!
            .filter((each) => each.headerCategoryId)
            .map(async (eachUniverse) => {
              try {
                const categoryPending = await getUniversServiceById(
                  user,
                  userContentLanguage,
                  eachUniverse.headerCategoryId!
                );
                const categoryData = await categoryPending.json();
                return categoryData;
              } catch (e) {
                return;
              }
            })
        ).then((finalCategories) => {
          stableDispatch({
            type: Case.SET_UNIVERSES,
            payload: {
              universes: universeData!.filter((each) => each.headerCategoryId),
            },
          });
          stableDispatch({
            type: Case.SET_UNIVERSES_CATEGORIES,
            payload: { universesCategories: finalCategories },
          });
        });
      } catch (e) {
        return toast(language("codeError"), {
          ...notificationsParam,
          toastId,
        });
      }
    };
    if (!profile) {
      stableDispatch({
        type: Case.SET_NEXT_URL,
        payload: { url: location.pathname },
      });
      window.scrollTo(0, 0);
      history.push("/choose-profile");
    } else if (
      user &&
      !!user._id &&
      !!user.token &&
      profile &&
      !!profile._id &&
      userContentLanguage &&
      !universes
    ) {
      getUnivers();
    }
  }, [
    location,
    history,
    profile,
    user,
    userContentLanguage,
    stableDispatch,
    toastId,
    universes,
  ]);
};
