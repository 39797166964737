import React, { ReactElement } from "react";

interface Props {
  nextEpisode: Function;
}

export default function PlayerNextEpisode({
  nextEpisode,
}: Props): ReactElement {
  return (
    <div
      className="next-episode"
      onClick={() => nextEpisode(true)}
    >
      <img src="/image/nextEpisode.svg" alt="" />
    </div>
  );
}
