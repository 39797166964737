import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import UserReducer from "./stores/UserReducer";
import AuthReducer from "./stores/AuthReducer";
import RouterReducer from "./stores/RouterReducer";
import ProfileReducer from "./stores/ProfileReducer";
import SeriesReducer from "./stores/SeriesReducer";
import PlayerReducer from "./stores/PlayerReducer";
import thunk from "redux-thunk";


const rootReducer = combineReducers({
  AuthReducer,
  UserReducer,
  ProfileReducer,
  RouterReducer,
  SeriesReducer,
  PlayerReducer,
});
const store = createStore(rootReducer, applyMiddleware(thunk));


  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
reportWebVitals();
