import { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EpisodeType from "../interfaces/EpisodeType";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import { getEpisodesService } from "../services/SeriesService";

export const UseEpisodesBySeasons = (
  id: string | undefined,
  season: number
) => {
  const { user, userContentLanguage, episodes, series } = useSelector(
    (state: StoreType) => ({
      ...state.UserReducer,
      ...state.ProfileReducer,
      ...state.SeriesReducer,
    })
  );
  const [isEpisodesLoaded, setIsEpisodesLoaded] = useState<boolean>(false);
  const toastId = "unique";
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  useEffect(() => {
    const ac = new AbortController();
    const { signal } = ac;
    const getEpisodes = async () => {
      try {
        const episodesPending = await getEpisodesService(
          user.token,
          id!,
          series[id!].chronological,
          season,
          userContentLanguage,
          signal
        );
        const episodesData: EpisodeType[] = await episodesPending.json();

        stableDispatch({
          type: Case.PUSH_EPISODES,
          payload: {
            series: series[id!],
            episodes: episodesData,
            season: season,
          },
        });
        
        setIsEpisodesLoaded(true);
      } catch (e) {
        return;
      }
    };
    if (
      id &&
      user &&
      user._id &&
      !!user.token &&
      series[id] &&
      series[id!].chronological !== undefined &&
      (!episodes[id] || (episodes[id] && !episodes[id][season])) &&
      userContentLanguage
    ) {
      getEpisodes();
    } else if (id && episodes[id] && episodes[id][season]) {
      setIsEpisodesLoaded(true);
    }
    return () => ac.abort();
  }, [
    id,
    season,
    userContentLanguage,
    stableDispatch,
    user,
    toastId,
    series,
    episodes,
  ]);
  return { isEpisodesLoaded };
};
