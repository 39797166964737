const swiperMainOptions = {
    spaceBetween: 0,
    mousewheel: { forceToAxis: true },
    breakpoints : {
      "300": {
        slidesPerView: 3.4,
      },
      "700": {
        slidesPerView: 4.4,
      },
      "850": {
        slidesPerView: 5.4,
      },
      "1000": {
        slidesPerView: 6.4,
      },
      "1177": {
        slidesPerView: 7.4,
      },
      "1300": {
        slidesPerView: 8.4,
      },
      "1700": {
        slidesPerView: 9.4,
      },
      "2000": {
        slidesPerView: 10.4,
      },
      "2500": {
        slidesPerView: 14.4,
      },
      "3000": {
        slidesPerView: 16.4,
      },
    },
    slidesPerView :8,
    className: "home-current-slider",
    freeMode: true,
    navigation: true,

}

export default swiperMainOptions