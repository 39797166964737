import SignUpFormType from "../interfaces/SignUpFormType";
import StoreType from "../interfaces/StoreType";
import UserType from "../interfaces/UserType";

const signUpService = async (
  signUpValue:
    | SignUpFormType
    | { appleCode: string; appleToken: string; origin: string }
    | { googleToken: string; platform: "WEB" },
    renault? : true | undefined
) => {
    const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({...signUpValue, partners: [renault ? "renault" : undefined], originDomain: "renault.sybel.co" }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/auth/signup`,
    requestOptions
  );
};

const signInService = async (
  signInValue: StoreType["AuthReducer"]["signInValue"],
  renault? : true | undefined
) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: signInValue.email,
      password: signInValue.password,
      partner:{renault}
    }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/auth/signin`,
    requestOptions
  );
};
const oauthSignInService = async (user: UserType, clientId: string) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: user.token },
    body: JSON.stringify({
      clientId,
    }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/auth/oauth/access_token`,
    requestOptions
  );
};

const unlinkFacebookService = async (user: UserType, facebook_id: string) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: user.token },
    body: JSON.stringify({
      user: user,
      facebookId: facebook_id,
    }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/auth/oauth/unlink`,
    requestOptions
  );
};

const forgetPassword = async (email: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ email }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/auth/forgotPassword`,
    requestOptions
  );
};
const recoverPassword = async (data: {
  email: string;
  token: string;
  password: string;
}) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      email: data.email,
      token: data.token,
      password: data.password,
    }),
  };
  return await fetch(
    `${process.env.REACT_APP_API}/auth/recoverPassword`,
    requestOptions
  );
};

export {
  signUpService,
  signInService,
  forgetPassword,
  recoverPassword,
  unlinkFacebookService,
  oauthSignInService,
};
