import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";
export default function PlayerEpisodeTitle(): ReactElement {
  const { currentEpisode } = useSelector((state: StoreType) => ({
    ...state.PlayerReducer,
  }));
  return (
    <Link
      to={{
        pathname: `/series/${currentEpisode!.series._id}`,
        state: {
          contentEventDetails: {
            id: currentEpisode!.series._id,
            contentContext: "Player",
          },
        },
      }}
      className="episode-details-player"
    >
      <p className="title">
        {currentEpisode && currentEpisode.episode &&  currentEpisode!.series.info && currentEpisode!.series.info[currentEpisode!.lng] && currentEpisode!.series.info[currentEpisode!.lng].title
          ? currentEpisode!.series.info[currentEpisode!.lng].title
          : currentEpisode && currentEpisode.sample && currentEpisode.series &&  currentEpisode!.series.info && currentEpisode!.series.info[currentEpisode!.lng] && currentEpisode!.series.info[currentEpisode!.lng].title
          ? `${language("sampleFrom")} ${
              currentEpisode!.series.info[currentEpisode!.lng].title
            }`
          : null}
      </p>
      <p className="season-episode-player">
        {currentEpisode && currentEpisode.episode
          ? `${language("shortSeason")}${
              currentEpisode!.episode!.season
            }`
          : currentEpisode &&
            currentEpisode.sample &&
            currentEpisode.episodes[0]
          ? `${language("comingNext")}`
          : null}
      </p>
      <p className="season-title-player">
        {currentEpisode &&
        currentEpisode.episode &&
        currentEpisode.episode.info[currentEpisode!.lng]
          ? currentEpisode.episode.info[currentEpisode!.lng].title
          : currentEpisode &&
            currentEpisode.sample &&
            currentEpisode.episodes[0]
          ? `${language("shortSeason")}${
              currentEpisode.episodes[0].season
            }`
          : null}
      </p>
    </Link>
  );
}
