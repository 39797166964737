import { Dispatch, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { UseSeries } from "./UseSeries";
import { UseSeriesLanguage } from "./UseSeriesLanguage";
import { UseUrlNext } from "./UseUrlNext";
import { UseChildRestriction } from "./UseChildRestriction";
import SeriesType from "../interfaces/SeriesType";

export const UseSeriesMain = (
  id: string | undefined
): {
  seasonIndex: number;
  isEpisodeView: boolean;
  setIsEpisodeView: Dispatch<React.SetStateAction<boolean>>;
  isSimilarView: boolean;
  setIsSimilarView: Dispatch<React.SetStateAction<boolean>>;
  isLiked: boolean;
  seriesLanguage: string | undefined;
  navigation: (
    openView: Function,
    closeSeconView: Function,
    closeThirdView: Function
  ) => void;
  toastId: string;
  setIsLiked: Dispatch<React.SetStateAction<boolean>>;
  isDetailView: boolean;
  setIsDetailView: Dispatch<React.SetStateAction<boolean>>;
  setSeasonIndex: Dispatch<React.SetStateAction<number>>;
  isSerieLoaded: boolean;
  fetchedSeries: SeriesType | undefined;
  lastNavigation: "Similar Series Tab" | "Episodes List Tab" | "Details Tab";
} => {
  UseUrlNext();
  const { seriesLanguage } = UseSeriesLanguage();
  UseChildRestriction(id);
  const { isSerieLoaded, fetchedSeries } = UseSeries(id);
  const { series, seriesHistory, profiles } = useSelector(
    (state: StoreType) => ({
      ...state.ProfileReducer,
      ...state.SeriesReducer,
    })
  );
  const [seasonIndex, setSeasonIndex] = useState<number>(1);
  const [isEpisodeView, setIsEpisodeView] = useState<boolean>(true);
  const [isSimilarView, setIsSimilarView] = useState<boolean>(false);
  const [isDetailView, setIsDetailView] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [lastNavigation, setLastNavigation] = useState<
    "Similar Series Tab" | "Episodes List Tab" | "Details Tab"
  >("Episodes List Tab");
  const toastId = "unique";
  useEffect(() => {
    if (
      id &&
      isSerieLoaded &&
      series[id] &&
      !!sessionStorage.getItem("profile")! && JSON.parse(sessionStorage.getItem("profile")!) &&
      profiles &&
      profiles.length > 0 && JSON.parse(sessionStorage.getItem("profile")!).likeList && JSON.parse(sessionStorage.getItem("profile")!).likeList.length > 0
    ) {
      setIsLiked(
        profiles[
          profiles.findIndex((eachProfile) => eachProfile._id === JSON.parse(sessionStorage.getItem("profile")!)._id)
        ].likeList.find((each) => each === series[id!]._id) !== undefined
      );
    }
  }, [id, series, isSerieLoaded, profiles]);

  useEffect(() => {
    if (
      id &&
      isSerieLoaded &&
      series[id] && sessionStorage.getItem("profile") &&
      JSON.parse(sessionStorage.getItem("profile")!) && 
      profiles &&
      profiles.length > 0 && JSON.parse(sessionStorage.getItem("profile")!).likeList && JSON.parse(sessionStorage.getItem("profile")!).likeList.length > 0
    ) {
      if (series[id].chronological === false) {
        setSeasonIndex(series[id].seasonsList.length);
      }
      setIsLiked(
        profiles[
          profiles.findIndex((eachProfile) => eachProfile._id === JSON.parse(sessionStorage.getItem("profile")!)._id)
        ].likeList.find((each) => each === series[id]._id) !== undefined
      );
    }
  }, [series, id, isSerieLoaded, profiles]);

  useEffect(() => {
    if (id && seriesHistory && seriesHistory[id]) {
      setSeasonIndex(seriesHistory[id].episode.season);
    }
  }, [seriesHistory, id]);

  const navigation = (
    openView: Function,
    closeSeconView: Function,
    closeThirdView: Function
  ) => {
    setLastNavigation(
      isSimilarView
        ? "Similar Series Tab"
        : isDetailView
        ? "Details Tab"
        : "Episodes List Tab"
    );

    openView(true);
    closeSeconView(false);
    closeThirdView(false);
  };

  return {
    seasonIndex,
    isEpisodeView,
    setIsEpisodeView,
    isSimilarView,
    setIsSimilarView,
    isLiked,
    seriesLanguage,
    navigation,
    toastId,
    setIsLiked,
    setIsDetailView,
    setSeasonIndex,
    isDetailView,
    isSerieLoaded,
    fetchedSeries,
    lastNavigation,
  };
};
