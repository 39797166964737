import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import HomeHeaderCard from "./HomeHeaderCard";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Mousewheel,
  EffectCoverflow,
  Lazy,
} from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import {
  LazyLoadComponent,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import UseHomeHeaderSlider from "../ressources/UseHomeHeaderSlider";
// install Swiper modules
SwiperCore.use([Navigation, Mousewheel, EffectCoverflow, Lazy]);

interface Props {
  universToShow?: string;
  isFromSeries?: true;
  scrollPosition: ScrollPosition;
}
function HomeHeaderSlider({
  universToShow,
  isFromSeries,
  scrollPosition,
}: Props): ReactElement {
  const { home, universes, universesCategories, profile } = useSelector(
    (state: StoreType) => ({
      ...state.ProfileReducer,
      ...state.SeriesReducer,
      ...state.UserReducer,
      ...state.PlayerReducer,
    })
  );

  const {
    classes,
    fromClick,
    shuffledHeader,
    actualIndex,
    setActualIndex,
    nextArrowRef,
    prevArrowRef,
    seriesLanguage,
    shuffledUniverse,
  } = UseHomeHeaderSlider({ universToShow, isFromSeries });

  return (
    <LazyLoadComponent
    
      threshold={0}
      scrollPosition={scrollPosition}
      placeholder={
        <div className={`home-extract-slider-container ${classes.bg}`}>
          <img
            className="slick-next"
            alt="arrows"
            src="/image/coverflowNext.svg"
          />
          <img
            alt="arrows"
            className="slick-prev"
            src="/image/coverflowNext.svg"
          />
          <Swiper
          grabCursor
          freeModeMinimumVelocity={4}
            lazy
            freeMode
            freeModeSticky
            mousewheel={{ forceToAxis: true }}
            coverflowEffect={{ rotate: 0, depth: 200 }}
            effect={"coverflow"}
            className="home-extract-slider"
            direction={"horizontal"}
            loop
            slidesPerView={3}
            centeredSlides
            navigation={{
              prevEl: prevArrowRef.current,
              nextEl: nextArrowRef.current,
            }}
            onRealIndexChange={(swiper) => {
              setActualIndex(swiper.realIndex);
            }}
          >
            {Array.from(Array(4), (e, i) => {
              return (
                <SwiperSlide virtualIndex={i} key={i}>
                  <HomeHeaderCard
                    isCurrent={actualIndex === i ? true : false}
                    actualIndex={actualIndex}
                    universToShow={universToShow}
                    seriesLanguage={seriesLanguage}
                    isFromClick={fromClick}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      }
    >
      <div className={`home-extract-slider-container ${classes.bg}`}>
        <img
          ref={nextArrowRef}
          className="slick-next"
          alt="arrows"
          src="/image/coverflowNext.svg"
        />
        <img
          ref={prevArrowRef}
          alt="arrows"
          className="slick-prev"
          src="/image/coverflowNext.svg"
        />
        {profile && shuffledHeader && home && home.headers && !universToShow ? (
          <Swiper
          grabCursor
          freeModeMinimumVelocity={4}
          lazy
            freeMode
            freeModeSticky
            mousewheel={{ forceToAxis: true }}
            coverflowEffect={{ rotate: 0, depth: 200 }}
            effect={"coverflow"}
            className="home-extract-slider"
            direction={"horizontal"}
            loop
            slidesPerView={3}
            centeredSlides
            slideToClickedSlide={true}
            navigation={{
              prevEl: prevArrowRef.current,
              nextEl: nextArrowRef.current,
            }}
            onRealIndexChange={(swiper) => {
              setTimeout(() => {
                return setActualIndex(swiper.realIndex);
              }, 200);
            }}
          >
            {[...shuffledHeader, ...shuffledHeader].map(
              (each, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <HomeHeaderCard
                      isCurrent={actualIndex === index ? true : false}
                      each={each}
                      actualIndex={actualIndex}
                      isFromClick={fromClick}
                      universToShow={universToShow}
                      seriesLanguage={seriesLanguage}
                      shuffledHeader={[...shuffledHeader, ...shuffledHeader]}
                    />
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>
        ) : universes &&
          universesCategories &&
          universToShow &&
          shuffledUniverse ? (
          <Swiper
          grabCursor
          freeModeMinimumVelocity={4}
          lazy
            init
            itemRef="universe"
            preventClicksPropagation
            preventClicks
            freeMode
            freeModeSticky
            mousewheel={{ forceToAxis: true }}
            coverflowEffect={{ rotate: 0, depth: 200 }}
            effect={"coverflow"}
            className="home-extract-slider"
            direction={"horizontal"}
            loop
            slidesPerView={3}
            centeredSlides
            navigation={{
              prevEl: prevArrowRef.current,
              nextEl: nextArrowRef.current,
            }}
            onRealIndexChange={(swiper) => {
              setActualIndex(swiper.realIndex);
            }}
          >
            {[...shuffledUniverse, ...shuffledUniverse].map(
              (each, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <HomeHeaderCard
                      shuffledUniverse={[
                        ...shuffledUniverse,
                        ...shuffledUniverse,
                      ]}
                      isCurrent={actualIndex === index ? true : false}
                      each={each}
                      actualIndex={actualIndex}
                      isFromClick={fromClick}
                      universToShow={universToShow}
                      seriesLanguage={seriesLanguage}
                    />
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>
        ) : (
          <Swiper
          grabCursor
          freeModeMinimumVelocity={4}
          lazy
            preventClicksPropagation
            preventClicks
            freeMode
            freeModeSticky
            mousewheel={{ forceToAxis: true }}
            coverflowEffect={{ rotate: 0, depth: 200 }}
            effect={"coverflow"}
            className="home-extract-slider"
            direction={"horizontal"}
            loop
            slidesPerView={3}
            centeredSlides
            navigation={{
              prevEl: prevArrowRef.current,
              nextEl: nextArrowRef.current,
            }}
            onRealIndexChange={(swiper) => {
              setActualIndex(swiper.realIndex);
            }}
          >
            {Array.from(Array(4), (e, i) => {
              return (
                <SwiperSlide virtualIndex={i} key={i}>
                  <HomeHeaderCard
                    isCurrent={actualIndex === i ? true : false}
                    actualIndex={actualIndex}
                    universToShow={universToShow}
                    seriesLanguage={seriesLanguage}
                    isFromClick={fromClick}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </LazyLoadComponent>
  );
}

export default trackWindowScroll(HomeHeaderSlider);
