import i18next, { ThirdPartyModule } from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import Phrases from "../locale/Phrases";

import es from "../locale/es";
import fr from "../locale/fr";
import en from "../locale/en";

const resources = {
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  en: { translation: en },
};

const options = {
  lookupQuerystring: "lng",
  lookupCookie: "i18n",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"],
  cookieMinutes: 10,
  cookieDomain: "app.sybel.co",
  htmlTag: document.documentElement,
  cookieOptions: { path: "/", sameSite: "strict" },
};

i18next
  .use(detector)
  .use(reactI18nextModule as ThirdPartyModule)
  .init({
    ...options,
    fallbackLng: "en",
    resources,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export function language(key: keyof Phrases | string, template?: any): string {
  return i18next.t(key, { ...template });
}
