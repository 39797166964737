import StoreType from "../interfaces/StoreType";
import { Action, Case } from "../interfaces/ReducerCase";
import Regex from "../utils/Regex";
import { language } from "./18nStore";
import i18next from "i18next";

/* Initial User State */
export const setContentLanguage = (language: string) => {
  switch (language) {
    case "fr":
      return "fra";
    case "es":
      return "esp";
    case "en":
      return "usa";
    default:
      return "fra";
  }
};

const userStore: StoreType["UserReducer"] = {
  user: {
    _id: "",

    token: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")!).token
      : "",
    username: "",

    email: null,

    emailVerified: false,

    facebookId: null,

    firstName: null,

    lastName: null,

    subscription: undefined,

    subscriptionExpirationDate: null,

    subscriptionTrial: null,

    subscriptionData: null,

    renewalStatus: false,

    lastContext: null,

    pinCode: null,

    coupons: [],

    appleId: null,

    offersEmail: null,

    productId: null,

    freeOttLinkStatus: undefined,
  },
  invoices: undefined,
  subscription: undefined,
  changePasswordInputs: [
    {
      htmlFor: "oldPassword",
      type: "password",
      label: language("password"),
      autocomplete: "off",
      errorStyle: false,
    },
    {
      htmlFor: "newPassword",
      type: "password",
      label: language("newPassword"),
      autocomplete: "off",
      errorStyle: false,
    },
    {
      htmlFor: "newPasswordConfirmation",
      type: "password",
      label: language("confirmNewPassword"),
      autocomplete: "off",
      errorStyle: false,
    },
  ],
  changePasswordValue: {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  },
  changeEmailInputs: [
    {
      htmlFor: "password",
      type: "password",
      label: language("password"),
      autocomplete: "off",
      errorStyle: false,
    },
    {
      htmlFor: "email",
      type: "email",
      label: language("email"),
      autocomplete: "off",
      errorStyle: false,
    },
    {
      htmlFor: "emailconfirmation",
      type: "email",
      label: language("confirmEmail"),
      autocomplete: "off",
      errorStyle: false,
    },
  ],
  changeEmailValue: {
    password: "",
    email: "",
    emailconfirmation: "",
  },
  addEmailInputs: [
    {
      htmlFor: "email",
      type: "email",
      label: language("email"),
      autocomplete: "off",
      errorStyle: false,
    },
    {
      htmlFor: "emailconfirmation",
      type: "email",
      label: language("confirmEmail"),
      autocomplete: "off",
      errorStyle: false,
    },
  ],
  addEmailValue: {
    email: "",
    emailconfirmation: "",
  },
  couponValue: {
    coupon: "",
  },
  couponInputs: {
    htmlFor: "coupon",
    type: "text",
    label: language("coupon"),
    autocomplete: "off",
    errorStyle: false,
  },
  userContentLanguage: localStorage.getItem("contentLanguage")
    ? localStorage.getItem("contentLanguage")!
    : setContentLanguage(i18next.language.substr(0, 2)),
  uuid: undefined,
  isShuffle: undefined
};

export default function UserReducer(state = userStore, action: Action) {
  switch (action.type) {
    case Case.UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload.user } };
    case Case.UPDATE_SUBSCRIPTION:
      return { ...state, subscription: action.payload.subscription };
    case Case.NO_SUBSCRIPTION:
      return { ...state, subscription: undefined };
    case Case.UPDATE_INVOICES:
      return { ...state, invoices: [...action.payload.invoices] };
    case Case.NO_INVOICES:
      return { ...state, invoices: undefined };
    case Case.SET_CHANGE_PASSWORD_VALUE:
      return {
        ...state,
        changePasswordValue: {
          ...state.changePasswordValue,
          [action.payload.htmlFor]: action.payload.value,
        },
      };
    case Case.SET_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordInputs: [
          {
            htmlFor: "oldPassword",
            type: "password",
            label: language("password"),
            autocomplete: "off",
            errorStyle:
              state.changePasswordValue.oldPassword === "" ||
              state.changePasswordValue.oldPassword.length < 6 ||
              action.payload.oldPassword
                ? true
                : false,
          },
          {
            htmlFor: "newPassword",
            type: "password",
            label: language("newPassword"),
            autocomplete: "off",
            errorStyle:
              state.changePasswordValue.newPassword !==
                state.changePasswordValue.newPasswordConfirmation ||
              state.changePasswordValue.newPassword.length < 6
                ? true
                : false,
          },
          {
            htmlFor: "newPasswordConfirmation",
            type: "password",
            label: language("confirmNewPassword"),
            autocomplete: "off",
            errorStyle:
              state.changePasswordValue.newPassword !==
                state.changePasswordValue.newPasswordConfirmation ||
              state.changePasswordValue.newPasswordConfirmation.length < 6
                ? true
                : false,
          },
        ],
      };
    case Case.SET_DEFAULT_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordInputs: [
          {
            htmlFor: "oldPassword",
            type: "password",
            label: language("password"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "newPassword",
            type: "password",
            label: language("newPassword"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "newPasswordConfirmation",
            type: "password",
            label: language("confirmNewPassword"),
            autocomplete: "off",
            errorStyle: false,
          },
        ],
      };

    case Case.SET_CHANGE_EMAIL_VALUE:
      return {
        ...state,
        changeEmailValue: {
          ...state.changeEmailValue,
          [action.payload.htmlFor]: action.payload.value,
        },
      };
    case Case.SET_CHANGE_EMAIL_ERROR:
      return {
        ...state,
        changeEmailInputs: [
          {
            htmlFor: "password",
            type: "password",
            label: language("password"),
            autocomplete: "off",
            errorStyle:
              state.changeEmailValue.password.length < 6 ||
              state.changeEmailValue.password === ""
                ? true
                : false,
          },
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "off",
            errorStyle:
              state.changeEmailValue.email !==
                state.changeEmailValue.emailconfirmation ||
              state.changeEmailValue.emailconfirmation === "" ||
              !Regex.emailSchema.test(state.changeEmailValue.email)
                ? true
                : false,
          },
          {
            htmlFor: "emailconfirmation",
            type: "email",
            label: language("confirmEmail"),
            autocomplete: "off",
            errorStyle:
              state.changeEmailValue.email !==
                state.changeEmailValue.emailconfirmation ||
              state.changeEmailValue.emailconfirmation === "" ||
              !Regex.emailSchema.test(state.changeEmailValue.emailconfirmation)
                ? true
                : false,
          },
        ],
      };
    case Case.SET_DEFAULT_CHANGE_EMAIL:
      return {
        ...state,
        changeEmailInputs: [
          {
            htmlFor: "password",
            type: "password",
            label: language("password"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "emailconfirmation",
            type: "email",
            label: language("confirmEmail"),
            autocomplete: "off",
            errorStyle: false,
          },
        ],
      };
    case Case.SET_ADD_EMAIL_VALUE:
      return {
        ...state,
        addEmailValue: {
          ...state.addEmailValue,
          [action.payload.htmlFor]: action.payload.value,
        },
      };
    case Case.SET_ADD_EMAIL_ERROR:
      return {
        ...state,
        addEmailInputs: [
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "off",
            errorStyle:
              state.addEmailValue.email !==
                state.addEmailValue.emailconfirmation ||
              state.addEmailValue.emailconfirmation === "" ||
              !Regex.emailSchema.test(state.addEmailValue.email)
                ? true
                : false,
          },
          {
            htmlFor: "emailconfirmation",
            type: "email",
            label: language("confirmEmail"),
            autocomplete: "off",
            errorStyle:
              state.addEmailValue.email !==
                state.addEmailValue.emailconfirmation ||
              state.addEmailValue.emailconfirmation === "" ||
              !Regex.emailSchema.test(state.addEmailValue.emailconfirmation)
                ? true
                : false,
          },
        ],
      };
    case Case.SET_DEFAULT_ADD_EMAIL:
      return {
        ...state,
        addEmailInputs: [
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "emailconfirmation",
            type: "email",
            label: language("confirmEmail"),
            autocomplete: "off",
            errorStyle: false,
          },
        ],
      };

    case Case.SIGNOUT:
      return {
        user: {
          _id: "",
          token: localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")!).token
            : "",

          username: "",

          email: null,

          emailVerified: false,

          facebookId: null,

          firstName: null,

          lastName: null,

          subscription: undefined,

          subscriptionExpirationDate: null,

          subscriptionTrial: null,

          subscriptionData: null,

          renewalStatus: false,

          lastContext: null,

          pinCode: null,

          coupons: [],

          appleId: null,

          offersEmail: null,

          productId: null,

          freeOttLinkStatus: undefined,
        },
        invoices: undefined,
        subscription: undefined,
        changePasswordInputs: [
          {
            htmlFor: "oldPassword",
            type: "password",
            label: language("password"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "newPassword",
            type: "password",
            label: language("newPassword"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "newPasswordConfirmation",
            type: "password",
            label: language("confirmNewPassword"),
            autocomplete: "off",
            errorStyle: false,
          },
        ],
        changePasswordValue: {
          oldPassword: "",
          newPassword: "",
          newPasswordConfirmation: "",
        },
        changeEmailInputs: [
          {
            htmlFor: "password",
            type: "password",
            label: language("password"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "emailconfirmation",
            type: "email",
            label: language("confirmEmail"),
            autocomplete: "off",
            errorStyle: false,
          },
        ],
        changeEmailValue: {
          password: "",
          email: "",
          emailconfirmation: "",
        },
        addEmailInputs: [
          {
            htmlFor: "email",
            type: "email",
            label: language("email"),
            autocomplete: "off",
            errorStyle: false,
          },
          {
            htmlFor: "emailconfirmation",
            type: "email",
            label: language("confirmEmail"),
            autocomplete: "off",
            errorStyle: false,
          },
        ],
        addEmailValue: {
          email: "",
          emailconfirmation: "",
        },
        couponValue: {
          coupon: "",
        },
        couponInputs: {
          htmlFor: "coupon",
          type: "text",
          label: language("coupon"),
          autocomplete: "off",
          errorStyle: false,
        },
        userContentLanguage: localStorage.getItem("contentLanguage")
          ? localStorage.getItem("contentLanguage")!
          : setContentLanguage(i18next.language.substr(0, 2)),
          isShuffle: undefined
      };
    case Case.SET_CONTENT_LANGUAGE:
      return {
        ...state,
        userContentLanguage: action.payload.language,
      };
    case Case.SET_COUPON_VALUE:
      return {
        ...state,
        couponValue: {
          ...state.couponValue,
          [action.payload.htmlFor]: action.payload.value,
        },
      };
    case Case.SET_COUPON_ERROR:
      return {
        ...state,
        couponInputs: {
          htmlFor: "coupon",
          type: "text",
          label: language("coupon"),
          autocomplete: "off",
          errorStyle: true,
        },
      };
    case Case.SET_DEFAULT_COUPON:
      return {
        ...state,
        couponInputs: {
          htmlFor: "coupon",
          type: "text",
          label: language("coupon"),
          autocomplete: "off",
          errorStyle: false,
        },
      };
    case Case.RESET_FREE:
      return {
        ...state,
        user: {
          ...state.user,
          freeOttLinkStatus: undefined,
        },
      };
    case Case.SET_UUID:
      return {
        ...state,
        uuid: action.payload.uuid,
      };
    case Case.RESET_UUID:
      return {
        ...state,
        uuid: undefined,
      };
    case Case.IS_SHUFFULABLE:
      return {
        ...state,
        isShuffle: action.payload.isShuffle ,
      };
    default:
      return state;
  }
}
