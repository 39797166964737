import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { ProductStripeType } from "../interfaces/UserType";
import { language } from "../stores/18nStore";
import StoreType from "../interfaces/StoreType";

interface Props {
  showPayment: Function;
  offerSelected: Function;
  firstOffer: ProductStripeType | undefined;
  secondOffer: ProductStripeType | undefined;
  selectedOffer: { index: number; offer: ProductStripeType } | undefined;
  seriesLanguage: string;
}

export default function PopUpMarketingOffer({
  showPayment,
  selectedOffer,
}: Props): ReactElement {
  const { user } = useSelector((state: StoreType) => ({
    ...state.UserReducer,
  }));

  return (
    <>
      <div className="pop-up-header" id="offers">
        <img className={"logo"} src={"/image/whiteSybelLogo.svg"} alt="" />
{/*         <table>
          <thead>
            <th>Sybel Free</th>
            <th>Sybel +</th>
          </thead>
          <tr>
            <td>
              Une plateforme unique pour le meilleur de l’audio, regroupant tous
              vos podcasts favoris disponibles partout, tout le temps…
            </td>
            <td>
              <ul>
                <li>Profitez de centaines de contenus exclusifs</li>
                <li>Sans pub & en illlimité</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
            Sybel vous accompagne toute la journée, disponible
sur l’ensemble de vos appareils, mobiles, site web, Alexa, Freebox, profitez de l’expérience parfaite pour une écoute inégalée
            </td>
            <td>
            Des centaines d’histoires pour les enfants réservés aux abonnés Sybel+, découvrez la version audio du Roi Lion ou encore des documentaires spécialement conçus pour eux
            </td>
          </tr>
          <tr>
            <td>
            Des centaines de nouveautés chaque semaine
            </td>
            <td>
            Abonnement sans engagement
            </td>
          </tr>
          <tr>
            <td>
            </td>
            <td>
            Votre abonnement participe à la rémunération des créateurs de vos podcasts, Sybel est le seul service à leur verser 70% de votre abonnement
            </td>
          </tr>
        </table> */}
        <p
          style={{
            fontSize: "32px",
            marginTop: "0px",
            marginBottom: "30px",
          }}
        >
          {language("popupNewSession")}
        </p>
        <p
          style={{
            fontSize: "32px",
            marginTop: "0px",
            marginBottom: "30px",
            fontWeight: "bold",
          }}
        >
          {language("popupNewSessionDays", {
            count: user.subscriptionData
              ? Math.max.apply(
                  Math,
                  user.subscriptionData.productsStripe.map(
                    (o) => o.trial_period_days
                  )
                )
              : null,
          })}
        </p>
        <p
          style={{
            fontWeight: "lighter",
            maxWidth: "500px",
            padding: "0px 12px",
          }}
        >
          {language("popupNewSessionPrice", {
            count: user.subscriptionData
              ? Math.max.apply(
                  Math,
                  user.subscriptionData.productsStripe.map(
                    (o) => o.trial_period_days
                  )
                )
              : null,
            price:
              user.subscriptionData && user.subscriptionData.productsStripe
                ? (
                    user.subscriptionData.productsStripe[
                      user.subscriptionData.productsStripe.findIndex(
                        (eachProduct) =>
                          eachProduct.trial_period_days ===
                          Math.max.apply(
                            Math,
                            user.subscriptionData!.productsStripe.map(
                              (o) => o.trial_period_days
                            )
                          )
                      )
                    ] as ProductStripeType
                  ).amount
                : null,
          })}
        </p>
        {user.subscription === 0 &&
          user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) < new Date() && (
            <p>{language("subscriptionInsert")}</p>
          )}
        {user.subscription === 0 &&
          user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) < new Date() && (
            <p>{language("subscriptionInsert")}</p>
          )}
        {user.subscription === 0 &&
          user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) < new Date() && (
            <p>{language("subscriptionInsert")}</p>
          )}
      </div>
      <button
        disabled={selectedOffer ? false : true}
        className="activate-payment"
        type="submit"
        onClick={(e) => showPayment(e)}
        style={{ background: "#343434" }}
      >
        <p>{language("popUpNewSessionCta")}</p>
      </button>
    </>
  );
}
