import React, { ReactElement } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { UsePlay } from "../ressources/UsePlay";
import Loader from "./Loader";

interface Props {
  isPlayerLoad: boolean;
  playerRef: React.RefObject<ReactPlayer>;
}

export default function PlayerPlay({
  isPlayerLoad,
  playerRef,
}: Props): ReactElement {
  const { isPlaying, playerIsLoading } = useSelector((state: StoreType) => ({
    ...state.PlayerReducer,
  }));
  const { playInPlayer } = UsePlay();
  return (
    <div
      className="play-controle"
      onClick={() =>
        playInPlayer(
          playerRef,
          "Player",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          playerRef && playerRef.current
            ? Math.round(playerRef.current.getCurrentTime())
            : undefined,
          playerRef && playerRef.current
            ? Math.round(
                (playerRef.current?.getCurrentTime() /
                  playerRef.current?.getDuration() +
                  Number.EPSILON) *
                  1000
              ) / 1000
            : undefined
        )
      }
    >
      {isPlayerLoad || playerIsLoading ? (
        <div className="player-load">
          <Loader customeClassName="player-load-loader" />
        </div>
      ) : isPlaying ? (
        <img src="/image/pausePlayer.svg" alt="" />
      ) : !isPlaying ? (
        <img src="/image/playPlayer.svg" alt="" />
      ) : (
        <div className="player-load">
          <Loader customeClassName="player-load-loader" />
        </div>
      )}
    </div>
  );
}
