import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import EpisodeType from "../interfaces/EpisodeType";
import { Action, Case } from "../interfaces/ReducerCase";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import { getEpisodesService } from "../services/SeriesService";
import { UseSeriesLanguage } from "./UseSeriesLanguage";

export default function UseBillboard(each: SeriesType): {
  episodes: EpisodeType[] | undefined;
} {
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const { userContentLanguage, user } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
    ...state.UserReducer,
    ...state.ProfileReducer,
  }));

  const [episodes, setEpisodes] = useState<EpisodeType[] | undefined>();
  const { seriesLanguage } = UseSeriesLanguage();

  useEffect(() => {
    const ac = new AbortController();
    const { signal } = ac;
    const getFullBillboard = async () => {
      try {
        const episodesPending = await getEpisodesService(
          user.token,
          each._id,
          each.chronological,
          each.chronological
            ? 1
            : each.info[seriesLanguage].seasonsList![
                each.info[seriesLanguage].seasonsList!.length - 1
              ],
          userContentLanguage,
          signal
        );
        const episodesData: EpisodeType[] = await episodesPending.json();
        setEpisodes(episodesData);
        stableDispatch({
          type: Case.PUSH_EPISODES,
          payload: {
            series: each,
            episodes: episodesData,
            season: each.chronological
              ? 1
              : each.info[seriesLanguage].seasonsList![
                  each.info[seriesLanguage].seasonsList!.length - 1
                ],
          },
        });
      } catch (e) {
        console.log(e);
        return;
      }
    };
    if (each._id) {
      getFullBillboard();
    } else return;

    return () => ac.abort();
  }, [each, userContentLanguage, user, stableDispatch, seriesLanguage]);
  return { episodes };
}
