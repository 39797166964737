import React, { ReactElement } from "react";
import ContentLoader from "react-content-loader";

export default function SeriesEpisodesPlaceHolder(): ReactElement {
  return (
    <div className="series-episodes">
      <div
        className="season-picker"
        style={{
          width: "auto",
          borderRadius: 0,
          border: "none",
          background: "none",
        }}
      >
        <ContentLoader
          animate={true}
          speed={2}
          style={{ padding: 0, borderRadius: 0 }}
          className="actual-season"
          width="188"
          height="40"
          viewBox="0 0 188 40"
          backgroundColor="#343434"
          foregroundColor="#454545"
        >
          <rect width="188" height="40" rx="10.6273" />
        </ContentLoader>
      </div>
      {Array.from(Array(3), (e, i) => {
        return (
          <div
            key={i}
            style={{ overflowX: "hidden" }}
            className="episode-content"
          >
            <div
              className={`imgCover`}
              style={{
                backgroundImage:
                  'url("/image/episodeCoverImagePlaceholder.svg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <ContentLoader
                animate={true}
                speed={1.5}
                width="120"
                height="120"
                viewBox="0 0 120 120"
                backgroundColor="#343434"
                foregroundColor="#454545"
              >
                <rect width="120" height="120" rx="8" />
              </ContentLoader>
            </div>
            <div className="episode-text">
              <h2>
                <ContentLoader
                  animate={true}
                  speed={2}
                  width="84"
                  height="16"
                  viewBox="0 0 84 16"
                  backgroundColor="#343434"
                  foregroundColor="#454545"
                >
                  <rect width="84" height="16" rx="8" />
                </ContentLoader>
              </h2>
              <h3>
                <ContentLoader
                  animate={true}
                  speed={2}
                  width="628"
                  height="48"
                  viewBox="0 0 628 48"
                  backgroundColor="#343434"
                  foregroundColor="#454545"
                >
                  <rect width="628" height="8" rx="4" />
                  <rect y="20" width="628" height="8" rx="4" />
                  <rect y="40" width="479" height="8" rx="4" />
                </ContentLoader>
              </h3>
            </div>
          </div>
        );
      })}
    </div>
  );
}
