import { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action, Case } from "../interfaces/ReducerCase";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import { getSeriesService } from "../services/SeriesService";
import { UseSeriesLanguage } from "./UseSeriesLanguage";

export const UseSeries = (
  id: string | undefined
): { isSerieLoaded: boolean; fetchedSeries: SeriesType | undefined } => {
  const { userContentLanguage, series } = useSelector(
    (state: StoreType) => ({
      ...state.UserReducer,
      ...state.SeriesReducer,
    })
  );
  const history = useHistory();
  const { seriesLanguage } = UseSeriesLanguage();
  const [isSerieLoaded, setIsSerieLoaded] = useState<boolean>(true);
  const [fetchedSeries, setFetchedSeries] = useState<SeriesType>();
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  useEffect(() => {
    const ac = new AbortController();
    const { signal } = ac;
    const getSeries = async () => {
      try {
        const seriesPending = await getSeriesService(
          id!,
          JSON.parse(localStorage.getItem("user")!),
          userContentLanguage,
          signal,
        );
        const seriesData: SeriesType = await seriesPending.json();
        if (
          !seriesData ||
          !seriesData.info[seriesLanguage!] ||
          (seriesData.isChild === false &&  JSON.parse(sessionStorage.getItem("profile")!).isChild === true)
        ) {
          stableDispatch({ type: Case.CLOSE_SERIES });
          history.push("/home");
          return;
        } else {
          stableDispatch({
            type: Case.PUSH_SERIES,
            payload: { series: seriesData },
          });
          setIsSerieLoaded(true);
          setFetchedSeries(seriesData);
        }
      } catch (e) {
        stableDispatch({ type: Case.CLOSE_SERIES });
        history.push("/home");
        return;
      }
    };
    if (
      id &&
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")!) &&
      JSON.parse(localStorage.getItem("user")!)._id &&
      !!JSON.parse(localStorage.getItem("user")!).token && 
      sessionStorage.getItem("profile")
      &&
      JSON.parse(sessionStorage.getItem("profile")!) &&
      userContentLanguage &&
      series &&
      !series[id]
    ) {
      getSeries();
    } else if (id && series[id]) {
      setFetchedSeries(series[id]);
      setIsSerieLoaded(true);
    }
    return () => ac.abort();
  }, [
    id,
    history,
    stableDispatch,
    seriesLanguage,
    userContentLanguage,
    series,
  ]);
  return { isSerieLoaded, fetchedSeries };
};
