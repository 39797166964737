import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import { UseSortSeason } from "../ressources/UseSortSeason";
import { language } from "../stores/18nStore";
import SeriesEpisodesCard from "./SeriesEpisodesCard";
import EventStore from "../stores/AmplitudeStore";
import SeriesLocationTypes from "../interfaces/SeriesLocationTypes";
import "react-virtualized/styles.css";
import { getEpisodesService } from "../services/SeriesService";
import EpisodeType from "../interfaces/EpisodeType";
import Loader from "./Loader";
import { Action, Case } from "../interfaces/ReducerCase";
import { Dispatch } from "redux";

interface Props {
  id: string;
  seasonIndex: number;
  setSeasonIndex: Function;
  seriesLanguage: string;
  isEpisodesLoaded: boolean;
  fetchedSeries: SeriesType | undefined;
  lastNavigation: "Similar Series Tab" | "Episodes List Tab" | "Details Tab";
}

export default function SeriesEpisodes({
  id,
  seasonIndex,
  setSeasonIndex,
  seriesLanguage,
  fetchedSeries,
  lastNavigation,
}: Props): ReactElement {
  const { userContentLanguage } = useSelector((state: StoreType) => ({
    ...state.UserReducer,
  }));
  const [isSeasonPickerTriger, setIsSeasonPickerTriger] =
    useState<boolean>(false);
  const { sortedSeason } = UseSortSeason(id);
  const [isEpisodesLoaded, setIsEpisodesLoaded] = useState<boolean>(false);
  const [isFullyFetched, setIsFullyFetched] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [episodes, setEpisodes] = useState<EpisodeType[]>([]);
  const location = useLocation<SeriesLocationTypes>();
  const { openSeriesPageEpisodeTab } = EventStore();
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("profile")!) &&
      fetchedSeries &&
      seriesLanguage &&
      lastNavigation !== "Episodes List Tab"
    ) {
      return openSeriesPageEpisodeTab(
        location &&
          location.state &&
          location.state.isSimilar &&
          location.state.isSimilar.id === fetchedSeries._id
          ? "Similar Series"
          : "Series Deeplink",
        fetchedSeries._id,
        JSON.parse(localStorage.getItem("profile")!).isChild,
        fetchedSeries.info[seriesLanguage].title,
        fetchedSeries.isChild,
        fetchedSeries.emotion ? fetchedSeries.emotion!.join() : undefined,
        lastNavigation,
        fetchedSeries.mainImageId,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentPosition
          : undefined
      );
    }
  }, [
    openSeriesPageEpisodeTab,
    location,
    fetchedSeries,
    seriesLanguage,
    lastNavigation,
  ]);
  const dispatch: Dispatch<Action> = useDispatch();
  const isInView = async (isView: boolean) => {
    if (isView && !isFullyFetched) {
      const ac = new AbortController();
      const { signal } = ac;
      try {
        const episodesPending = await getEpisodesService(
          JSON.parse(localStorage.getItem("user")!).token,
          id!,
          fetchedSeries!.chronological,
          seasonIndex,
          userContentLanguage,
          signal,
          offset + 10,
          10
        );
        const episodesData: EpisodeType[] = await episodesPending.json();
        if (episodesData.length > 0 && offset + 10 > 0) {
          dispatch({
            type: Case.PUSH_EPISODES,
            payload: {
              series: { _id: id },
              episodes: [...episodes, ...episodesData],
              season: seasonIndex,
            },
          });
          setEpisodes((prev) => [...prev, ...episodesData]);
          setIsEpisodesLoaded(true);
        } else {
          setIsFullyFetched(true);
          setOffset(0);
        }
        if (episodesData.length !== 10) {
          setIsFullyFetched(true);
        }
      } catch (e) {
        return;
      }

      setOffset((prev) => prev + 10);
    }
  };

  useEffect(() => {
    const getEpisodes = async () => {
      const ac = new AbortController();
      const { signal } = ac;
      try {
        const episodesPending = await getEpisodesService(
          JSON.parse(localStorage.getItem("user")!).token,
          id!,
          fetchedSeries!.chronological,
          seasonIndex,
          userContentLanguage,
          signal,
          0,
          10
        );
        const episodesData: EpisodeType[] = await episodesPending.json();
        if (episodesData.length > 0) {
          setEpisodes((prev) => [...prev, ...episodesData]);
          setIsEpisodesLoaded(true);
        }
        if (episodesData.length !== 10) {
          setIsFullyFetched(true);
        }
      } catch (e) {
        return;
      }
    };
    if (
      fetchedSeries &&
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")!)
    ) {
      setOffset(0);
      getEpisodes();
    }
    return () => {
      setEpisodes([]);
      setIsFullyFetched(false);
    };
  }, [fetchedSeries, userContentLanguage, id, seasonIndex]);

  return (
    <div
      className="series-episodes"
      style={sortedSeason.length === 1 ? { marginTop: "60px" } : {}}
    >
      {sortedSeason.length > 1 ? (
        <div
          className="season-picker"
          onClick={() => setIsSeasonPickerTriger(!isSeasonPickerTriger)}
          style={{
            height: isSeasonPickerTriger ? "auto" : "40px",
          }}
        >
          <p className="actual-season">
            {language("season")} {seasonIndex}{" "}
            <img
              src="/image/arrowIcon.svg"
              style={{
                transform: isSeasonPickerTriger
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              }}
              alt=""
            />
          </p>
          {sortedSeason.map((eachSeason: number, index: number) => {
            return (
              <p
                className="season"
                onClick={() => setSeasonIndex(eachSeason)}
                key={index}
              >
                {language("season")} {eachSeason}
              </p>
            );
          })}
        </div>
      ) : null}
      {isEpisodesLoaded &&
        episodes &&
        episodes.filter((each) => new Date(each.publicationDate) <= new Date())
          .length > 0 &&
        episodes
          .filter(
            (each) =>
              new Date(each.publicationDate) <= new Date() &&
              each.info[seriesLanguage]
          )
          .map((eachEpisode, index) => {
            return (
              <SeriesEpisodesCard
                isInView={(inView: boolean) => isInView(inView)}
                eachEpisode={eachEpisode}
                seasonIndex={seasonIndex}
                key={index}
                id={id}
                fetchedSeries={fetchedSeries}
                isLastOne={
                  index ===
                  episodes.filter(
                    (each) =>
                      new Date(each.publicationDate) <= new Date() &&
                      each.info[seriesLanguage]
                  ).length -
                    1
                    ? true
                    : false
                }
              />
            );
          })}
      {episodes &&
        episodes.filter(
          (each) => new Date(each.publicationDate) > new Date()
        )[0] && (
          <>
            <p
              style={{
                marginTop:
                  episodes &&
                  episodes.filter(
                    (each) => new Date(each.publicationDate) <= new Date()
                  ).length > 0
                    ? "64px"
                    : "0px",
              }}
              className="episodes-comming-soon"
            >
              {language("comingSoon")}
            </p>
            {episodes
              .filter((each) => new Date(each.publicationDate) > new Date())
              .map((eachEpisode, index) => {
                return (
                  <SeriesEpisodesCard
                    isInView={(inView: boolean) => isInView(inView)}
                    eachEpisode={eachEpisode}
                    seasonIndex={seasonIndex}
                    key={index}
                    id={id}
                    fetchedSeries={fetchedSeries}
                    isLastOne={
                      index ===
                      episodes.filter(
                        (each) => new Date(each.publicationDate) > new Date()
                      ).length -
                        1
                        ? true
                        : false
                    }
                  />
                );
              })}
          </>
        )}
      {!isFullyFetched && <Loader />}
    </div>
  );
}
