import CouponReductionType from "../interfaces/CouponReductionType";
import { language } from "../stores/18nStore";

export const updatePrice = (
  stripeSubscription: any,
  coupon: CouponReductionType["coupon"] | any = undefined
): { couponText: string | undefined; priceText: string | undefined } => {
  let couponText: string | undefined = undefined;
  let priceText: string | undefined = undefined;

  const couponDetails = stripeSubscription.coupon
    ? stripeSubscription.coupon
    : coupon;    
  if (couponDetails) {
    if (couponDetails.percent_off && couponDetails.percent_off > 0) {
      if(couponDetails.duration === "forever"){
        if(stripeSubscription.interval === "month"){
          couponText = language("permanentMonthPaymentPrice", {
            newPrice: `${(
              stripeSubscription.amount -
              stripeSubscription.amount * (couponDetails.percent_off / 100)
            ).toFixed(2)} ${stripeSubscription.currency}`,
            price: `${stripeSubscription.amount} ${stripeSubscription.currency}`,
          })
        } else {
          couponText = language("permanentYearPaymentPrice", {
            newPrice: `${(
              stripeSubscription.amount -
              stripeSubscription.amount * (couponDetails.percent_off / 100)
            ).toFixed(2)} ${stripeSubscription.currency}`,
            price: `${stripeSubscription.amount} ${stripeSubscription.currency}`,
          })
        }  
      }
      priceText = stripeSubscription.interval === "month" &&
            stripeSubscription.duration !== "forever"
          ? language("defaultMonthPrice", {
              price: `${(
                stripeSubscription.amount -
                stripeSubscription.amount * (couponDetails.percent_off / 100)
              ).toFixed(2)} ${stripeSubscription.currency}`,
            })
          : language("defaultYearPrice", {
              price: `${(
                stripeSubscription.amount -
                stripeSubscription.amount * (couponDetails.percent_off / 100)
              ).toFixed(2)} ${stripeSubscription.currency}`,
            });
    } else if (
      couponDetails.amount_off &&
      couponDetails.amount_off > 0 &&
      stripeSubscription.duration !== "forever"
    ) {
      priceText =
        stripeSubscription.interval === "month"
          ? language("defaultMonthPrice", {
              price: `${(
                stripeSubscription.amount - couponDetails.amount_off
              ).toFixed(2)} ${stripeSubscription.currency}`,
            })
          : language("defaultYearPrice", {
              price: `${(
                stripeSubscription.amount - couponDetails.amount_off
              ).toFixed(2)} ${stripeSubscription.currency}`,
            });
    }
    if (
      couponDetails.duration === "repeating" &&
      stripeSubscription.interval === "month"
    ) {
      couponText = language("temporaryMonthReduction", {
        reducedPrice: priceText,
        duration: couponDetails.duration_in_months,
        price: `${stripeSubscription.amount} ${stripeSubscription.currency}`,
      });
    } else if (
      couponDetails.duration === "once" &&
      stripeSubscription.interval === "month"
    ) {
      couponText = language("temporaryOneMonthReduction", {
        reducedPrice: priceText,
        price: `${stripeSubscription.amount} ${stripeSubscription.currency}`,
      });
    } else if (
      couponDetails.duration === "repeating" &&
      stripeSubscription.interval === "year"
    ) {
      couponText = language("temporaryMonthReduction", {
        reducedPrice: priceText,
        duration: couponDetails.duration_in_months / 12,
        price: `${stripeSubscription.amount} ${stripeSubscription.currency}`,
      });
    } else if (
      couponDetails.duration === "once" &&
      stripeSubscription.interval === "year"
    ) {
      couponText = language("temporaryOneYearReduction", {
        reducedPrice: priceText,
        price: `${stripeSubscription.amount} ${stripeSubscription.currency}`,
      });
    }
  } else {
    priceText =
      stripeSubscription.interval === "month"
        ? language("defaultMonthPrice", {
            price: `${stripeSubscription.amount.toFixed(2)} ${
              stripeSubscription.currency
            }`,
          })
        : language("defaultYearPrice", {
            price: `${stripeSubscription.amount.toFixed(2)} ${
              stripeSubscription.currency
            }`,
          });
  }

  return { couponText, priceText };
};
