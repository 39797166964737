import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";
import EventStore from "../stores/AmplitudeStore";
import "../styles/auth.scss";

export default function PartnerConnect(): ReactElement {
  const { openPartnerConnectCard } = EventStore();
  const { uuid } = useSelector((state: StoreType) => ({
    ...state.UserReducer,
  }));
  const [state, setstate] = useState<string | undefined>(undefined);
  const partnerList: {
    name: string;
    image: string;
    locale: string;
    link: string;
    router?: true;
  }[] = [
    {
      name: "SFR",
      image: "/image/logo/sfr_logo.svg",
      locale: "sfr",
      link: `https://accounts.sd-sfr.fr/api/authorize?client_id=sybel&service_id=podcast&scope=openid+ups&redirect_uri=https://app.sybel.co/sso-sfr&response_type=code&prompt=select_account&code_challenge=${state}&code_challenge_method=plain&correlation_id=${uuid}`,
    },
    {
      name: "RED by SFR",
      image: "/image/logo/red_by_sfr_logo.svg",
      locale: "redBySfr",
      link: `https://accounts.sd-sfr.fr/api/authorize?client_id=sybel&service_id=podcast&scope=openid+ups&redirect_uri=https://app.sybel.co/sso-sfr&response_type=code&prompt=select_account&code_challenge=${state}&code_challenge_method=plain&correlation_id=${uuid}`,
    },
    {
      name: "Free",
      image: "/image/logo/free_logo.svg",
      locale: "free",
      link: "/activate-free",
      router: true,
    },
  ];

  useEffect(() => {
    function dec2hex(dec) {
      return dec.toString(16).padStart(2, "0");
    }
    function generateId(len) {
      var arr = new Uint8Array((len || 40) / 2);
      window.crypto.getRandomValues(arr);
      const sfrcrc = Array.from(arr, dec2hex).join("");
      window.localStorage.setItem("sfr_c_rc", sfrcrc);
      setstate(sfrcrc);
    }
    generateId(50);
  }, []);

  return (
    <div>
      <nav className="nav-links-partner">
        <Link to="/home">
          <img className="back" src="/image/backArrow.svg" alt="" />
        </Link>
      </nav>
      <div className="links-partner-container">
        <img
          className="logo-sybel-partner"
          src="/image/logo/sybelplus_logo.svg"
          alt=""
        />
        <h3>{language("operatorActivateWording")}</h3>
        <div className="links-partner-logo-container">
          {partnerList.map((eachPartner, index) => {
            return eachPartner.router ? (
              <Link
                key={index}
                className="links-partner-logo-card"
                to={eachPartner.link}
                onClick={() => openPartnerConnectCard(eachPartner.name)}
              >
                <img
                  className="logo"
                  src={eachPartner.image}
                  alt={eachPartner.name + " logo"}
                />
                <p>{language(eachPartner.locale)}</p>
              </Link>
            ) : (eachPartner.name === "SFR" && !state) ||
              (eachPartner.name === "RED by SFR" && !state) ? undefined : (
              <a
                key={index}
                className="links-partner-logo-card"
                href={eachPartner.link}
                rel="noreferrer"
                onClick={() => openPartnerConnectCard(eachPartner.name)}
              >
                <img
                  className="logo"
                  src={eachPartner.image}
                  alt={eachPartner.name + " logo"}
                />
                <p>{language(eachPartner.locale)}</p>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
