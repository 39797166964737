enum Case {
  IS_GLOBAL_LOADING = "IS_GLOBAL_LOADING",
  IS_GLOBAL_NOT_LOADING = "IS_GLOBAL_NOT_LOADING",
  UPDATE_USER = "UPDATE_USER",
  UPDATE_INVOICES = "UPDATE_INVOICES",
  NO_INVOICES = "NO_INVOICES",
  SIGNIN = "SIGNIN",
  SIGNUP = "SIGNUP",
  SIGNOUT = "SIGNOUT",
  AUTH_IS_LOADING = "AUTH_IS_LOADING",
  AUTH_IS_NOT_LOADING = "AUTH_IS_NOT_LOADING",
  ALLOW_NEWSLETTER = "ALLOW_NEWSLETTER",
  SET_SIGNUP_VALUE = "SET_SIGNUP_VALUE",
  SET_SIGNUP_ERROR = "SET_SIGNUP_ERROR",
  SET_DEFAULT_SINUP = "SET_DEFAULT_SINUP",
  SET_SIGNIN_VALUE = "SET_SIGNIN_VALUE",
  SET_FORGOTED_PASSWORD_ERROR = "SET_FORGOTED_PASSWORD_ERROR",
  SET_DEFAULT_FORGOTED_PASSWORD = "SET_DEFAULT_FORGOTED_PASSWORD",
  SET_FORGOTED_PASSWORD_VALUE = "SET_FORGOTED_PASSWORD_VALUE",
  SET_SIGNIN_ERROR = "SET_SIGNIN_ERROR",
  SET_DEFAULT_SIGNIN = "SET_DEFAULT_SIGNIN",
  SET_RECOVER_PASSWORD_VALUE = "SET_RECOVER_PASSWORD_VALUE",
  SET_RECOVER_PASSWORD_ERROR = "SET_RECOVER_PASSWORD_ERROR",
  SET_DEFAULT_RECOVER_PASSWORD = "SET_DEFAULT_RECOVER_PASSWORD",
  PROFILE_IS_LOADING = "PROFILE_IS_LOADING",
  PROFILE_IS_NOT_LOADING = "PROFILE_IS_NOT_LOADING",
  SET_PROFILES = "SET_PROFILES",
  SET_PROFILE = "SET_PROFILE",
  SET_MAIN_PROFILE_VALUE = "SET_MAIN_PROFILE_VALUE",
  SET_MAIN_PROFILE_ERROR = "SET_MAIN_PROFILE_ERROR",
  SET_DEFAULT_MAIN_PROFILE = "SET_DEFAULT_MAIN_PROFILE",
  SET_NEW_PROFILE = "SET_NEW_PROFILE",
  PROFILE_IS_CHILD = "PROFILE_IS_CHILD",
  SET_PROFILE_VALUE = "SET_PROFILE_VALUE",
  SET_PROFILE_ERROR = "SET_PROFILE_ERROR",
  SET_DEFAULT_PROFILE = "SET_DEFAULT_PROFILE",
  FB_SDK_IS_LOADED = "FB_SDK_IS_LOADED",
  FB_SDK_ERROR = "FB_SDK_ERROR",
  SET_CHANGE_PASSWORD_VALUE = "SET_CHANGE_PASSWORD_VALUE",
  SET_CHANGE_PASSWORD_ERROR = "SET_CHANGE_PASSWORD_ERROR",
  SET_DEFAULT_CHANGE_PASSWORD = "SET_DEFAULT_CHANGE_PASSWORD",
  SET_CHANGE_EMAIL_VALUE = "SET_CHANGE_EMAIL_VALUE",
  SET_CHANGE_EMAIL_ERROR = "SET_CHANGE_EMAIL_ERROR",
  SET_DEFAULT_CHANGE_EMAIL = "SET_DEFAULT_CHANGE_EMAIL",
  SET_ADD_EMAIL_VALUE = "SET_ADD_EMAIL_VALUE",
  SET_ADD_EMAIL_ERROR = "SET_ADD_EMAIL_ERROR",
  SET_DEFAULT_ADD_EMAIL = "SET_DEFAULT_ADD_EMAIL",
  SET_CONTENT_LANGUAGE = "SET_CONTENT_LANGUAGE",
  SET_NEXT_URL = "SET_NEXT_URL",
  SET_NEXT_URL_DEFAULT = "SET_NEXT_URL_DEFAULT",
  UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION",
  NO_SUBSCRIPTION = "NO_SUBSCRIPTION",
  SET_DEFAULT_CHOOSE_PROFILE = "SET_DEFAULT_CHOOSE_PROFILE",
  SET_CHOOSE_PROFILE_ERROR = "SET_CHOOSE_PROFILE_ERROR",
  SET_CHOOSE_PROFILE_VALUE = "SET_CHOOSE_PROFILE_VALUE",
  SET_CHOOSE_PROFILE_EDIT_VALUE = "SET_CHOOSE_PROFILE_EDIT_VALUE",
  SET_CHOOSE_PROFILE_DEFAULT_VALUE = "SET_CHOOSE_PROFILE_DEFAULT_VALUE",
  UPDATE_PROFILES = "UPDATE_PROFILES",
  DELETE_PROFILES = "DELETE_PROFILES",
  PUSH_SERIES = "PUSH_SERIES",
  PUSH_EPISODES = "PUSH_EPISODES",
  SET_COUPON_VALUE = "SET_COUPON_VALUE",
  SET_COUPON_ERROR = "SET_COUPON_ERROR",
  SET_DEFAULT_COUPON = "SET_DEFAULT_CHANGE_EMAIL",
  SET_CURRENT_EPISODE = "SET_CURRENT_EPISODE",
  SET_CURRENT_EPISODE_UNDEFINED = "SET_CURRENT_EPISODE_UNDEFINED",
  PLAYER_IS_LOADING = "PLAYER_IS_LOADING",
  PLAYER_IS_NOT_LOADING = "PLAYER_IS_NOT_LOADING",
  PUSH_SERIES_HISTORY = "PUSH_SERIES_HISTORY",
  PUSH_EPISODES_HISTORY = "PUSH_EPISODES_HISTORY",
  UPDATE_EPISODE_HISTORY = "UPDATE_EPISODE_HISTORY",
  UPDATE_SERIES_EPISODE_HISTORY = "UPDATE_SERIES_EPISODE_HISTORY",
  CREATE_EPISODE_HISTORY = "CREATE_EPISODE_HISTORY",
  IS_PLAYING = "IS_PLAYING",
  IS_NOT_PLAYING = "IS_NOT_PLAYING",
  SET_HOME = "SET_HOME",
  SET_CONTEXT = "SET_CONTEXT",
  SET_POPUP_CONTEXT = "SET_POPUP_CONTEXT",
  REINIT_IS_SEEKED_TO_HISTORY = "REINIT_IS_SEEKED_TO_HISTORY",
  SEEKED_TO_HISTORY = "SEEKED_TO_HISTORY",
  UPDATE_SERIES_HISTORY = "UPDATE_SERIES_HISTORY",
  CREATE_SERIES_HISTORY = "CREATE_SERIES_HISTORY",
  REINIT_EPISODE_HISTORY = "REINIT_EPISODE_HISTORY",
  REINIT_SERIES_HISTORY = "REINIT_SERIES_HISTORY",
  REINIT_HOME = "REINIT_HOME",
  SET_UNIVERSES = "SET_UNIVERSES",
  SET_UNIVERSES_CATEGORIES = "SET_UNIVERSES_CATEGORIES",
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_MAIN_OVERFLOW = "SET_MAIN_OVERFLOW",
  UNSET_MAIN_OVERFLOW = "UNSET_MAIN_OVERFLOW",
  DELETE_IN_SETUP_PROFILE = "DELETE_IN_SETUP_PROFILE",
  SET_TC = "SET_TC",
  OPEN_SERIES = "OPEN_SERIES",
  CLOSE_SERIES = "CLOSE_SERIES",
  PLAYER_IS_NOT_HIDDED="PLAYER_IS_NOT_HIDDED",
  PLAYER_IS_HIDDED="PLAYER_IS_HIDDED",
  RESET_FREE="RESET_FREE",
  SET_UUID="SET_UUID",
  RESET_UUID="RESET_UUID",
  IS_SHUFFULABLE="IS_SHUFFULABLE",
  SET_VOLUME= "SET_VOLUME"
}

export interface Action {
  type: keyof typeof Case;
  payload?: any;
}

export { Case };
