import React, { ReactElement } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import { sendProgressService } from "../services/SeriesService";
import EventStore from "../stores/AmplitudeStore";

interface Props {
  playerRef: React.RefObject<ReactPlayer>;
}

export default function PlayerRewind({
  playerRef,
}: Props): ReactElement {
  const { currentEpisode, profile, user, uuid } = useSelector(
    (state: StoreType) => ({
      ...state.SeriesReducer,
      ...state.PlayerReducer,
      ...state.ProfileReducer,
      ...state.UserReducer,
    })
  );
  const { seriesLanguage } = UseSeriesLanguage();
  const { play, pause, fastBackward } = EventStore();
  const rewind = async () => {
    if (playerRef && playerRef.current) {
      pause(
        currentEpisode!.series.isChild,
        "Player Actions",
        currentEpisode!.series.emotion
          ? currentEpisode!.series.emotion.join()
          : undefined,
        currentEpisode!.episode!.info[seriesLanguage].title,
        currentEpisode!.episode!._id,
        currentEpisode!.episode!.season,
        currentEpisode!.series._id,
        currentEpisode!.series.info[seriesLanguage].title,
        profile!.isChild,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        currentEpisode!.series.mainImageId,
        Math.round(playerRef.current?.getCurrentTime()),
        Math.round(
          (playerRef.current?.getCurrentTime() /
            currentEpisode!.episode!.length +
            Number.EPSILON) *
            1000
        ) / 1000,
        currentEpisode!.episode!.length,
        currentEpisode!.series.length
      );
      window.analytics.track("Audio Content Fastbackwarded", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        platform: "Web",
        session_id: uuid,
        series_id:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series._id
            : undefined,
        episode_id:
          currentEpisode && currentEpisode.episodes
            ? currentEpisode.episodes[0]._id
            : "trailer",
        content_name:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.info[seriesLanguage].title
            : undefined,
        episode_length:
          currentEpisode && currentEpisode.episodes
            ? currentEpisode.episodes[0].length
            : undefined,
        content_length:
          currentEpisode && currentEpisode.series
            ? Math.round(currentEpisode.series.length)
            : undefined,
        season:
          currentEpisode && currentEpisode.episodes
            ? currentEpisode.episodes[0].season
            : undefined,
        position: Math.round(playerRef.current.getCurrentTime()),
        duration: 10,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        episode_name:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode.info[seriesLanguage].title
            : undefined,
        channel_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel._id
            : undefined,
        channel_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.name
            : undefined,
        channel_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.nameCode
            : undefined,
        channel_umbrella_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella._id
            : undefined,
        channel_umbrella_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.name
            : undefined,
        channel_umbrella_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.nameCode
            : undefined,
            has_rss_feed:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.info[seriesLanguage] &&
            currentEpisode.series.info[seriesLanguage].rssFeed
              ? true
              : false,
              child_content:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.isChild
                : undefined,
            chronological:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.chronological
                : undefined,
            classification:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.classification
                : undefined,
            emotion:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.emotion
                : undefined,
            episode_index: currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.index
              : undefined,
            format:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.format
                : undefined,
            genre:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.genre
                : undefined,
            total_length: currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.length
              : undefined,
              is_unit:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.isUnit
                : undefined,
      });
      fastBackward(
        -10,
        currentEpisode!.series!.info[seriesLanguage].title,
        currentEpisode!.series._id,
        currentEpisode!.episode!.info[seriesLanguage].title,
        currentEpisode!.episode!._id,
        currentEpisode!.episode!.season,
        0
      );
      play(
        currentEpisode!.series.isChild,
        "Player Actions",
        currentEpisode!.series.emotion
          ? currentEpisode!.series.emotion.join()
          : undefined,
        currentEpisode!.episode!.info[seriesLanguage].title,
        currentEpisode!.episode!._id,
        currentEpisode!.episode!.season,
        currentEpisode!.series._id,
        currentEpisode!.series.info[seriesLanguage].title,
        profile!.isChild,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        currentEpisode!.series.mainImageId,
        Math.round(playerRef.current?.getCurrentTime() - 10),
        Math.round(
          ((playerRef.current?.getCurrentTime() - 10) /
            currentEpisode!.episode!.length +
            Number.EPSILON) *
            1000
        ) / 1000,
        Math.round(currentEpisode!.episode!.length),
        Math.round(currentEpisode!.series.length)
      );
    }
    const ac = new AbortController();
    const { signal } = ac;
    playerRef.current?.seekTo(
      playerRef.current?.getCurrentTime() - 10,
      "seconds"
    );
    try {
      const historyPending = await sendProgressService(
        {
          userId: user._id,
          profileId: profile!._id!,
          seriesId: currentEpisode!.series._id,
          episodeId: currentEpisode!.episode!._id,
          start: playerRef.current!.getCurrentTime(),
          progress: playerRef.current!.getCurrentTime(),
        },
        user,
        profile!,
        signal
      );
      await historyPending.json();
    } catch (e) {}
  };
  return (
    <div
      className="rewind-controle"
      onClick={rewind}
    >
      <img src="/image/rewind.svg" alt="" />
    </div>
  );
}
