import React from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { UseUpdateEpisodesHistory } from "./UseUpdateEpisodeHistory";
import { UseUpdateSeriesHistory } from "./useUpdateSeriesHistory";

export default function UseProgress(
  playerRef: React.RefObject<ReactPlayer>,
  isPlayerLoad: boolean
): {
  setCurrentTimeInformation: (progress: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => void;
} {
  const {
    currentEpisode,
    episodesHistory,
    seriesHistory,
    episodes,
  } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
    ...state.PlayerReducer,
  }));
  const { updateSeriesHistory } = UseUpdateSeriesHistory();
  const { updateEpisodeHistory } = UseUpdateEpisodesHistory();
  const setCurrentTimeInformation = (progress: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    if (
      seriesHistory &&
      episodesHistory &&
      currentEpisode &&
      currentEpisode.episode &&
      currentEpisode.series &&  currentEpisode.episodes &&
      playerRef.current &&
      !isPlayerLoad
    ) {
      if (
        episodesHistory[currentEpisode.series._id] &&
        episodesHistory[currentEpisode.series._id][currentEpisode.episode._id]
      ) {
        updateEpisodeHistory(
          currentEpisode.episode,
          progress.playedSeconds >= currentEpisode.episode.length * 0.95
            ? true
            : false,
          progress.playedSeconds
        );
        updateSeriesHistory(
          progress.playedSeconds >= currentEpisode.episode.length * 0.95 &&
            currentEpisode.episodes[
              currentEpisode.episodes.findIndex(
                (each) => each._id === currentEpisode.episode?._id
              )
            ]._id !==
              currentEpisode.episodes[currentEpisode.episodes.length - 1]._id
            ? currentEpisode.episodes[
                currentEpisode.episodes.findIndex(
                  (each) => each._id === currentEpisode.episode?._id
                ) + 1
              ]
            : currentEpisode.episode,
          currentEpisode.series,
          progress.playedSeconds >= currentEpisode.episode.length * 0.95 &&
            currentEpisode.episode._id ===
              episodes[currentEpisode.series._id][
                currentEpisode.series.seasonsList[
                  currentEpisode.series.seasonsList.length - 1
                ]
              ][
                episodes[currentEpisode.series._id][
                  currentEpisode.series.seasonsList[
                    currentEpisode.series.seasonsList.length - 1
                  ]
                ].length - 1
              ]._id
            ? true
            : false,
          progress.playedSeconds >= currentEpisode.episode.length * 0.95 &&
            currentEpisode.episodes[
              currentEpisode.episodes.findIndex(
                (each) => each._id === currentEpisode.episode?._id
              )
            ]._id !==
              currentEpisode.episodes[currentEpisode.episodes.length - 1]._id
            ? 0
            : progress.playedSeconds,
          currentEpisode.episode &&
            progress.playedSeconds >= currentEpisode.episode.length * 0.95 &&
            currentEpisode.episodes[
              currentEpisode.episodes.findIndex(
                (each) => each._id === currentEpisode.episode?._id
              )
            ]._id ===
              currentEpisode.episodes[currentEpisode.episodes.length - 1]._id &&
            currentEpisode.episode.season !==
              currentEpisode.series.seasonsList[
                currentEpisode.series.seasonsList.length - 1
              ] &&
            currentEpisode.series.chronological === true
            ? currentEpisode.episode.season + 1
            : currentEpisode.episode &&
              progress.playedSeconds >= currentEpisode.episode.length * 0.95 &&
              currentEpisode.episodes[
                currentEpisode.episodes.findIndex(
                  (each) => each._id === currentEpisode.episode?._id
                )
              ]._id ===
                currentEpisode.episodes[currentEpisode.episodes.length - 1]
                  ._id &&
              currentEpisode.episode.season !==
                currentEpisode.series.seasonsList[
                  currentEpisode.series.seasonsList.length - 1
                ] &&
              currentEpisode.series.chronological === false
            ? currentEpisode.episode.season - 1
            : undefined
        );
      } else {
        updateEpisodeHistory(
          currentEpisode.episode,
          progress.playedSeconds >= currentEpisode.episode.length * 0.95
            ? true
            : false,
          progress.playedSeconds
        );
        updateSeriesHistory(
          currentEpisode.episode,
          currentEpisode.series,
          progress.playedSeconds >= currentEpisode.episode.length * 0.95 &&
            currentEpisode.episode._id ===
              episodes[currentEpisode.series._id][
                currentEpisode.series.seasonsList[
                  currentEpisode.series.seasonsList.length - 1
                ]
              ][
                episodes[currentEpisode.series._id][
                  currentEpisode.series.seasonsList[
                    currentEpisode.series.seasonsList.length - 1
                  ]
                ].length - 1
              ]._id
            ? true
            : false,
          progress.playedSeconds
        );
      }
    }
  };

  return { setCurrentTimeInformation };
}
