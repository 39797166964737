import React, { ReactElement, useEffect } from "react";
import EpisodeType from "../interfaces/EpisodeType";
import SeriesEpisodesTitle from "./SeriesEpisodesTitle";
import SeriesEpidodesCover from "./SeriesEpidodesCover";
import SeriesEpisodesDetails from "./SeriesEpisodesDetails";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import { UseEpisodeStatus } from "../ressources/UseEpisodeStatus";
import { UsePlay } from "../ressources/UsePlay";
import {
  LazyLoadComponent,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import SeriesType from "../interfaces/SeriesType";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { useLocation } from "react-router";
import SeriesLocationTypes from "../interfaces/SeriesLocationTypes";
import { useInView } from "react-intersection-observer";
interface Props {
  eachEpisode: EpisodeType;
  id: string;
  seasonIndex: number;
  scrollPosition: ScrollPosition;
  fetchedSeries: SeriesType | undefined;
  isLastOne?: boolean
  isInView: (isView: boolean) => void
}
function SeriesEpisodesCard({
  scrollPosition,
  eachEpisode,
  id,
  seasonIndex,
  fetchedSeries,
  isLastOne,
  isInView
}: Props): ReactElement {
  const { seriesLanguage } = UseSeriesLanguage();
  const { isListened, isInProgress, progressBarSize } = UseEpisodeStatus(
    id,
    eachEpisode
  );

  const { play } = UsePlay();
  const { seriesHistory } = useSelector((state: StoreType) => ({
    ...state.ProfileReducer,
    ...state.SeriesReducer,
  }));
  const location = useLocation<SeriesLocationTypes>();
  const { ref, inView} = useInView({
    threshold: 0,
  });

useEffect(() => {
  if(isLastOne && inView){ 
    isInView(inView)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [inView, isLastOne])
  return (
    <div
      ref={isLastOne ? ref : undefined}
      className={isListened ? "episode-content listened" : "episode-content"}
      onClick={() => {
        if (
          new Date(eachEpisode.publicationDate) <= new Date() &&
          fetchedSeries
        ) {
          play(
            id,
            eachEpisode,
            "Series",
            location && location.state && location.state.contentEventDetails
              ? location.state.contentEventDetails.contentUniverse
              : undefined,
            location && location.state && location.state.contentEventDetails
              ? location.state.contentEventDetails.universeId
              : undefined,
            undefined,
            undefined,
            undefined,
            seriesHistory &&
              seriesHistory[id] &&
              seriesHistory[id].episode &&
              seriesHistory[id].episode._id === eachEpisode._id
              ? Math.round(seriesHistory[id].progress)
              : 0,
            seriesHistory &&
              seriesHistory[id] &&
              seriesHistory[id].episode &&
              seriesHistory[id].episode._id === eachEpisode._id
              ? Math.round(
                  (seriesHistory[id].progress /
                    seriesHistory[id].episode.length +
                    Number.EPSILON) *
                    1000
                ) / 1000
              : 0,
              location &&
              location.state &&
              location.state.contentEventDetails?.recommId &&
              location.state.contentEventDetails &&
              location.state.contentEventDetails?.recommId,
          );
        } else {
          return null;
        }
      }}
    >
      {eachEpisode && (
        <LazyLoadComponent threshold={0} scrollPosition={scrollPosition}>
          <SeriesEpidodesCover
            isInProgress={isInProgress}
            isListened={isListened}
            progressBarSize={progressBarSize}
            episode={eachEpisode}
            seasonIndex={seasonIndex}
            id={id}
          />
          <div className="episode-text">
            <SeriesEpisodesTitle
              episode={eachEpisode}
              seriesLanguage={seriesLanguage}
            />
            <SeriesEpisodesDetails
              episode={eachEpisode}
              seriesLanguage={seriesLanguage}
            />
          </div>
        </LazyLoadComponent>
      )}
    </div>
  );
}

export default trackWindowScroll(SeriesEpisodesCard);
