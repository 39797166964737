import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";
import ReactPixel from "react-facebook-pixel";
import { Link } from "react-router-dom";
import UseMainCategorieesCard from "../ressources/UseMainCategorieesCard";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import HomeMainCategoriesCardType from "../interfaces/components/HomeMainCategoriesCardType";

export default function HomeMainCategoriesCard({
  each,
  category,
  universeToShow,
  index,
  likeListe,
}: HomeMainCategoriesCardType): ReactElement {
  const { universes } = useSelector((state: StoreType) => ({
    ...state.ProfileReducer,
  }));

  const { seriesLanguage } = UseSeriesLanguage();
  const { isLiked, addToLikeList } = UseMainCategorieesCard({
    each,
    universeToShow,
    likeListe,
    index,
  });

  return (
    <div
      className="each-resume-card"
      style={{
        background:
          each && each.mainImage
            ? `center center / cover url(${each.mainImage})`
            : "#343434",
      }}
    >
      <Link
        to={{
          pathname: each ? `/series/${each._id}` : undefined,
          state: {
            likeList: likeListe,
            contentEventDetails: each
              ? {
                  id: each._id,
                  contentContext: "Explorer",
                  categoryType: "Default Category",
                  contentCategory: category?.title,
                  categoryPosition: category?.index,
                  universeId: universeToShow ? universeToShow : undefined,
                  contentUniverse:
                    universes && universeToShow
                      ? universes.filter(
                          (each) => each.headerCategoryId === universeToShow
                        )[0].title
                      : "Main Homepage",
                  contentPosition: index,
                  catalog: seriesLanguage,
                }
              : undefined,
          },
        }}
        className="each-resume-card-cover-container"
        onClick={() => {
          if (each) {
            ReactPixel.trackSingle("2507418622671662", "ViewCategory", {
              content_type: "product",
              content_category: category?.title,
              content_ids: each._id,
              content_name: null,
              univers_affichage:
                universes && universeToShow
                  ? universes.filter(
                      (each) => each.headerCategoryId === universeToShow
                    )[0].title
                  : "Main Homepage",
              page_type: "Main Homepage",
            });
          }
        }}
      >
        {each && each.newEpisodes && (
          <p className="new-episode-categories">{language("newEpisodes")}</p>
        )}
        <img
          style={{ bottom: each && each.newEpisodes ? "30px" : "8px" }}
          onClick={(e) => addToLikeList(e)}
          className="like-epidode-button"
          src={
            isLiked
              ? "/image/heartHeader.svg"
              : "/image/heartHeaderNotLiked.svg"
          }
          alt=""
        />
        <img className="play-epidode-button" src="/image/playLogo.svg" alt="" />
      </Link>
    </div>
  );
}
