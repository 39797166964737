import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import EpisodeType from "../interfaces/EpisodeType";
import { Action, Case } from "../interfaces/ReducerCase";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import EventStore from "../stores/AmplitudeStore";
import { UseSeriesLanguage } from "./UseSeriesLanguage";

export default function UseSample(isFromPlayer?: boolean): {
  playExtract: (
    series: SeriesType,
    episodes: EpisodeType[],
    contentContext: string,
    contentCategory?: string | undefined,
    categoryType?: string | undefined,
    categoryPosition?: number | undefined,
    universToShow?: string | undefined
  ) => void;
  isExtractLoading: boolean;
} {
  const [isExtractLoading, setIsExtractLoading] = useState(false);
  const { seriesLanguage } = UseSeriesLanguage();
  const { profile, universes, currentEpisode, uuid, user, userContentLanguage} = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
    ...state.ProfileReducer,
    ...state.PlayerReducer,
    ...state.UserReducer,
  }));

  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const { playSample } = EventStore();
  const playExtract = async (
    series: SeriesType,
    episodes: EpisodeType[],
    contentContext: string,
    contentCategory?: string,
    categoryType?: string,
    categoryPosition?: number,
    universToShow?: string | undefined
  ) => {
    dispatch({ type: Case.PLAYER_IS_LOADING});
    setIsExtractLoading(true);
    if (series.info[seriesLanguage].sample) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: user.token,
        },
      };

      const playlistPending = await fetch(
        `${process.env.REACT_APP_API}/${userContentLanguage}/series/${series._id}/next?profileId=${profile?._id}&duration=108000`,
        requestOptions
      );
      const playlist = await playlistPending.json();
      Promise.all(
        playlist.map(async (eachEpisode) => {
          const episodePending = await fetch(
            `${process.env.REACT_APP_API}/${userContentLanguage}/series/${series._id}/episode/${eachEpisode._id}/`,
            requestOptions
          );
          const episode = await episodePending.json();
          return episode;
        })
      ).then((playlist: any) => {
        dispatch({
          type: Case.SET_CURRENT_EPISODE,
          payload: {
            series: series,
            episodes: playlist,
            sample: series.info[seriesLanguage].sample,
            lng: seriesLanguage,
            uuid
          },
        });
      });

      window.analytics.track("Audio Content Started", {      
        subscription: !!localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!).subscription : 0,  
        platform: "Web",
        session_id: uuid,
        series_id: series._id,
        episode_id: "Trailer",
        content_name: series.info[seriesLanguage].title,
        content_length: Math.round(series.length),
        position: 0,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        chronological: series.chronological,
        classification: series.classification,
        emotion: series.emotion,
        format: series.format,
        is_unit: series.isUnit,
        publication_date: series.publicationDate,
        seasons_list: series.seasonsList,
        header_mention: series.info[seriesLanguage].headerMention,
        context: contentContext ? contentContext : "none",
        channel_id: series && series.channel ? series.channel._id : undefined,
        channel_name:
          series && series.channel ? series.channel.name : undefined,
        channel_name_code:
          series && series.channel ? series.channel.nameCode : undefined,
        channel_umbrella_id:
          series && series.channel ? series.channel.umbrella._id : undefined,
        channel_umbrella_name:
          series && series.channel ? series.channel.umbrella.name : undefined,
        channel_umbrella_name_code:
          series && series.channel
            ? series.channel.umbrella.nameCode
            : undefined,
        has_rss_feed:
          series &&
          series.info &&
          series.info[seriesLanguage] &&
          series.info[seriesLanguage].rssFeed
            ? true
            : false,
        child_content: series && series.isChild ? series.isChild : undefined,
        episode_index: "Trailer",
        genre: series && series.genre ? series.genre : undefined,
      });
    }
    playSample(
      series.isChild,
      contentContext,
      series.emotion ? series.emotion.join() : "",
      series._id,
      series.info[seriesLanguage].title,
      profile!.isChild,
      contentCategory,
      categoryType,
      categoryPosition,
      universes && universToShow
        ? universes.filter((each) => each.headerCategoryId === universToShow)[0]
            .title
        : "Main Homepage"
    );
    setIsExtractLoading(false);
  };

  useEffect(() => {
    if (isFromPlayer && currentEpisode && currentEpisode.sample) {
      stableDispatch({
        type: Case.IS_PLAYING,
      });
    }
  }, [currentEpisode, stableDispatch, isFromPlayer]);

  return { playExtract, isExtractLoading };
}
