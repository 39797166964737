import { Dispatch, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import HomeType from "../interfaces/HomeType";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import { getHomeService } from "../services/DashboardService";

export const UseHome = () => {
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const { profile, user, userContentLanguage, home } = useSelector(
    (state: StoreType) => ({
      ...state.PlayerReducer,
      ...state.ProfileReducer,
      ...state.UserReducer,
    })
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      sessionStorage.getItem("profile") &&
      sessionStorage.getItem("profile") === "undefined"
    ) {
      sessionStorage.removeItem("profile");
      history.push("/choose-profile");
    }
  }, [history]);
  useEffect(() => {
    const getHome = async () => {
      const homePending = await getHomeService(
        user,
        userContentLanguage,
        profile
      );
      const homeData: HomeType = await homePending.json();
      stableDispatch({
        type: Case.SET_HOME,
        payload: { home: homeData },
      });
    };
    if (!profile || profile._id === undefined) {
      stableDispatch({
        type: Case.SET_NEXT_URL,
        payload: { url: location.pathname },
      });
      history.push("/choose-profile");
    } else if (
      user &&
      !!user._id &&
      !!user.token &&
      !!profile &&
      !!profile._id &&
      userContentLanguage &&
      !home
    ) {
      getHome();
    }
  }, [
    location,
    history,
    profile,
    user,
    userContentLanguage,
    stableDispatch,
    home,
  ]);
};
