import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action, Dispatch } from "redux";
import SeriesType from "../interfaces/SeriesType";
import EventStore from "../stores/AmplitudeStore";
import ReactPixel from "react-facebook-pixel";
import StoreType from "../interfaces/StoreType";
import { editProfile } from "../services/ProfileService";
import { Case } from "../interfaces/ReducerCase";
import ProfileType from "../interfaces/ProfileType";
import {
  getEpisodesService,
  getSeriesService,
} from "../services/SeriesService";
import EpisodeType from "../interfaces/EpisodeType";

interface Props {
  each?: SeriesType;
  universToShow: string | undefined;
  seriesLanguage: string;
  shuffledHeader?: any[];
  shuffledUniverse?: any[];
  actualIndex: number;
  isCurrent?: boolean;
  isFromClick: boolean;
}

export default function UseHomeHeaderCard({
  each,
  universToShow,
  seriesLanguage,
  shuffledHeader,
  shuffledUniverse,
  actualIndex,
  isFromClick,
  isCurrent,
}: Props): {
  isLiked: boolean;
  addToLikeList: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => Promise<void>;
  launchExtract: (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => Promise<void>;
  stopExtract: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  openSeries: () => void;
} {
  const {
    profile,
    profiles,
    user,
    home,
    universesCategories,
    series,
    episodes,
    userContentLanguage,
    universes,
    uuid,
    volume,
  } = useSelector((state: StoreType) => ({
    ...state.PlayerReducer,
    ...state.ProfileReducer,
    ...state.UserReducer,
    ...state.SeriesReducer,
  }));

  const { bookmark, unbookmark, muteVolume, playSample, unmuteVolume } =
    EventStore();
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const dispatch: Dispatch<Action> = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (
      each &&
      profile &&
      profile.likeList &&
      profiles &&
      profiles.length > 0
    ) {
      return setIsLiked(
        profiles[
          profiles.findIndex((eachProfile) => eachProfile._id === profile._id)
        ].likeList.find((eachLikedSeries) => eachLikedSeries === each._id) !==
          undefined
      );
    } else {
      setIsLiked(false);
    }
  }, [profile, profiles, each, seriesLanguage]);

  const addToLikeList = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (!each) {
      return;
    } else {
      const isLikedTemp = isLiked;
      if (!isLikedTemp) {
        bookmark(
          "Home Header Carousel",
          each._id,
          profile!.isChild,
          undefined,
          undefined,
          undefined,
          "Home Header Carousel",
          universes && universToShow
            ? universes.filter(
                (each) => each.headerCategoryId === universToShow
              )[0].title
            : "Main Homepage",
          universToShow ? universToShow : undefined
        );
        window.analytics.track("Content Bookmarked", {
          subscription: !!localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")!).subscription
            : 0,
          session_id: uuid,
          series_id: each._id,
          platform: "Web",
          signup_type: localStorage.getItem("sy_signup_type")
            ? localStorage.getItem("sy_signup_type")
            : undefined,
          has_rss_feed:
            each &&
            each.info &&
            each.info[seriesLanguage] &&
            each.info[seriesLanguage].rssFeed
              ? true
              : false,
        });
      } else {
        unbookmark(
          "Home Header Carousel",
          each._id,
          profile!.isChild,
          undefined,
          undefined,
          undefined,
          "Home Header Carousel",
          universes && universToShow
            ? universes.filter(
                (each) => each.headerCategoryId === universToShow
              )[0].title
            : "Main Homepage",
          universToShow ? universToShow : undefined
        );
        window.analytics.track("Content Unbookmarked", {
          subscription: !!localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")!).subscription
            : 0,
          session_id: uuid,
          series_id: each._id,
          platform: "Web",
          signup_type: localStorage.getItem("sy_signup_type")
            ? localStorage.getItem("sy_signup_type")
            : undefined,
          has_rss_feed:
            each &&
            each.info &&
            each.info[seriesLanguage] &&
            each.info[seriesLanguage].rssFeed
              ? true
              : false,
        });
      }

      setIsLiked(!isLiked);
      try {
        const profilePending = await editProfile(
          profile!,
          user,
          isLikedTemp
            ? profiles[
                profiles.findIndex((each) => each._id === profile!._id)
              ].likeList
                .slice()
                .filter((eachLikedSeries) => eachLikedSeries !== each?._id)
            : [
                ...profiles[
                  profiles.findIndex((each) => each._id === profile!._id)
                ].likeList.slice(),
                each._id,
              ]
        );
        const profileUpdated: ProfileType = await profilePending.json();
        dispatch({
          type: Case.UPDATE_PROFILES,
          payload: { profile: profileUpdated },
        });
      } catch (e) {
        setIsLiked(!isLiked);
      }
    }
  };

  const stopExtract = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    window.analytics.track("Audio Content Interrupted", {
      subscription: !!localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).subscription
        : 0,
      context: "clicked_trailer_button",
      platform: "Web",
      session_id: uuid,
      sound: volume,
      content_type: "Trailer",
      series_id: each ? each._id : undefined,
      content_length: series[each!._id].length,
      signup_type: localStorage.getItem("sy_signup_type")
        ? localStorage.getItem("sy_signup_type")
        : undefined,
      has_rss_feed:
        each &&
        each.info &&
        each.info[seriesLanguage] &&
        each.info[seriesLanguage].rssFeed
          ? true
          : false,
      child_content: each && each.isChild ? each.isChild : undefined,
      chronological:
        each && each.chronological ? each.chronological : undefined,
      classification:
        each && each.classification ? each.classification : undefined,
      emotion: each && each.emotion ? each.emotion : undefined,
      format: each && each.format ? each.format : undefined,
      genre: each && each.genre ? each.genre : undefined,
      is_unit: each && each.isUnit ? each.isUnit : undefined,
      production: each && each.production ? each.production : undefined,
      scripted: each && each.scripted ? each.scripted : undefined,
      catalog: seriesLanguage,
      episode_id: "trailer",
      content_name: each ? each.info[seriesLanguage].title : undefined,
      episode_length: each ? each.length : undefined,
    });
    dispatch({ type: Case.SET_CURRENT_EPISODE_UNDEFINED });
    muteVolume(
      "Home Header Carousel",
      each!._id,
      profile!.isChild,
      series[each!._id].info[seriesLanguage].title,
      series[each!._id].isChild,
      series[each!._id].emotion ? series[each!._id].emotion!.join() : "",
      "Home Header Carousel",
      universes && universToShow
        ? universes.filter((each) => each.headerCategoryId === universToShow)[0]
            .title
        : "Main Homepage",
      universToShow ? universToShow : undefined
    );
  };
  const launchExtract = async (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch({ type: Case.PLAYER_IS_LOADING});
    const ac = new AbortController();
    const { signal } = ac;
    if (
      home &&
      shuffledHeader &&
      shuffledHeader[actualIndex] &&
      shuffledHeader.length > 0 &&
      !universToShow
    ) {
      if (
        !series ||
        (series && !series[shuffledHeader[actualIndex]._id]) ||
        !episodes ||
        (episodes && !episodes[shuffledHeader[actualIndex]._id])
      ) {
        try {
          const seriesPending = await getSeriesService(
            shuffledHeader[actualIndex]._id,
            user,
            userContentLanguage,
            signal
          );
          const seriesData: SeriesType = await seriesPending.json();
          if (
            !seriesData ||
            !seriesData.info[seriesLanguage!] ||
            (seriesData.isChild === false &&
              profile &&
              profile.isChild === true)
          ) {
            window.scrollTo(0, 0);
            history.push("/home");
            return;
          } else {
            dispatch({
              type: Case.PUSH_SERIES,
              payload: { series: seriesData },
            });
            if (seriesData.info[seriesLanguage].sample) {
              const requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                  Authorization: user.token,
                },
              };

              const playlistPending = await fetch(
                `${process.env.REACT_APP_API}/${userContentLanguage}/series/${seriesData._id}/next?profileId=${profile?._id}&duration=108000`,
                requestOptions
              );
              const playlist = await playlistPending.json();
              Promise.all(
                playlist.map(async (eachEpisode) => {
                  const episodePending = await fetch(
                    `${process.env.REACT_APP_API}/${userContentLanguage}/series/${seriesData._id}/episode/${eachEpisode._id}/`,
                    requestOptions
                  );
                  const episode = await episodePending.json();
                  return episode;
                })
              ).then((playlist: any) => {
                dispatch({
                  type: Case.SET_CURRENT_EPISODE,
                  payload: {
                    series: seriesData,
                    episodes: playlist,
                    sample: seriesData.info[seriesLanguage].sample,
                    lng: seriesLanguage,
                    uuid,
                  },
                });
              });

              window.analytics.track("Audio Content Started", {
                subscription: !!localStorage.getItem("user")
                  ? JSON.parse(localStorage.getItem("user")!).subscription
                  : 0,
                platform: "Web",
                session_id: uuid,
                series_id: seriesData._id,
                episode_id: "Trailer",
                content_name: seriesData.info[seriesLanguage].title,
                content_length: Math.round(seriesData.length),
                position: 0,
                signup_type: localStorage.getItem("sy_signup_type")
                  ? localStorage.getItem("sy_signup_type")
                  : undefined,
                chronological: seriesData.chronological,
                classification: seriesData.classification,
                emotion: seriesData.emotion,
                format: seriesData.format,
                is_unit: seriesData.isUnit,
                publication_date: seriesData.publicationDate,
                seasons_list: seriesData.seasonsList,
                header_mention: seriesData.info[seriesLanguage].headerMention,
                context: "Explorer",
                channel_id:
                  seriesData && seriesData.channel
                    ? seriesData.channel._id
                    : undefined,
                channel_name:
                  seriesData && seriesData.channel
                    ? seriesData.channel.name
                    : undefined,
                channel_name_code:
                  seriesData && seriesData.channel
                    ? seriesData.channel.nameCode
                    : undefined,
                channel_umbrella_id:
                  seriesData && seriesData.channel
                    ? seriesData.channel.umbrella._id
                    : undefined,
                channel_umbrella_name:
                  seriesData && seriesData.channel
                    ? seriesData.channel.umbrella.name
                    : undefined,
                channel_umbrella_name_code:
                  seriesData && seriesData.channel
                    ? seriesData.channel.umbrella.nameCode
                    : undefined,
                has_rss_feed:
                  seriesData &&
                  seriesData.info &&
                  seriesData.info[seriesLanguage] &&
                  seriesData.info[seriesLanguage].rssFeed
                    ? true
                    : false,
                child_content:
                  seriesData && seriesData.isChild
                    ? seriesData.isChild
                    : undefined,
                genre:
                  seriesData && seriesData.genre ? seriesData.genre : undefined,

                production:
                  seriesData && seriesData.production
                    ? seriesData.production
                    : undefined,
                scripted:
                  seriesData && seriesData.scripted
                    ? seriesData.scripted
                    : undefined,
                catalog: seriesLanguage,
              });
            }
          }

          unmuteVolume(
            "Home Header Carousel",
            seriesData._id,
            profile!.isChild,
            seriesData.info[seriesLanguage].title,
            seriesData.isChild,
            seriesData.emotion ? seriesData.emotion!.join() : "",
            "Home Header Carousel",
            universes && universToShow
              ? universes.filter(
                  (each) => each.headerCategoryId === universToShow
                )[0].title
              : "Main Homepage",
            universToShow ? universToShow : undefined
          );
          playSample(
            seriesData.isChild,
            "Home Header Carousel",
            seriesData.emotion ? seriesData.emotion!.join() : "",
            seriesData._id,
            seriesData.info[seriesLanguage].title,
            profile!.isChild,
            undefined,
            "Home Header Carousel",
            undefined,
            universes && universToShow
              ? universes.filter(
                  (each) => each.headerCategoryId === universToShow
                )[0].title
              : "Main Homepage"
          );
        } catch (e) {
          window.scrollTo(0, 0);
          history.push("/home");
          return;
        }
      } else {
        if (
          series[shuffledHeader[actualIndex]._id].info[seriesLanguage].sample
        ) {
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              Authorization: user.token,
            },
          };

          const playlistPending = await fetch(
            `${process.env.REACT_APP_API}/${userContentLanguage}/series/${series[shuffledHeader[actualIndex]._id]._id}/next?profileId=${profile?._id}&duration=108000`,
            requestOptions
          );
          const playlist = await playlistPending.json();
          Promise.all(
            playlist.map(async (eachEpisode) => {
              const episodePending = await fetch(
                `${process.env.REACT_APP_API}/${userContentLanguage}/series/${series[shuffledHeader[actualIndex]._id]._id}/episode/${eachEpisode._id}/`,
                requestOptions
              );
              const episode = await episodePending.json();
              return episode;
            })
          ).then((playlist: any) => {
            dispatch({
              type: Case.SET_CURRENT_EPISODE,
              payload: {
                series: series[shuffledHeader[actualIndex]._id],
                episodes: playlist,
                sample:
                  series[shuffledHeader[actualIndex]._id].info[seriesLanguage]
                    .sample,
                lng: seriesLanguage,
                uuid,
              },
            });
          });
          window.analytics.track("Audio Content Started", {
            subscription: !!localStorage.getItem("user")
              ? JSON.parse(localStorage.getItem("user")!).subscription
              : 0,
            platform: "Web",
            session_id: uuid,
            series_id: series[shuffledHeader[actualIndex]._id]._id,
            episode_id: "Trailer",
            content_name:
              series[shuffledHeader[actualIndex]._id].info[seriesLanguage]
                .title,
            content_length: Math.round(
              series[shuffledHeader[actualIndex]._id].length
            ),
            position: 0,
            signup_type: localStorage.getItem("sy_signup_type")
              ? localStorage.getItem("sy_signup_type")
              : undefined,
            chronological:
              series[shuffledHeader[actualIndex]._id].chronological,
            classification:
              series[shuffledHeader[actualIndex]._id].classification,
            emotion: series[shuffledHeader[actualIndex]._id].emotion,
            format: series[shuffledHeader[actualIndex]._id].format,
            is_unit: series[shuffledHeader[actualIndex]._id].isUnit,
            publication_date:
              series[shuffledHeader[actualIndex]._id].publicationDate,
            seasons_list: series[shuffledHeader[actualIndex]._id].seasonsList,
            header_mention:
              series[shuffledHeader[actualIndex]._id].info[seriesLanguage]
                .headerMention,
            context: "Explorer",
            channel_id:
              series[shuffledHeader[actualIndex]._id] &&
              series[shuffledHeader[actualIndex]._id].channel
                ? series[shuffledHeader[actualIndex]._id].channel!._id
                : undefined,
            channel_name:
              series[shuffledHeader[actualIndex]._id] &&
              series[shuffledHeader[actualIndex]._id].channel
                ? series[shuffledHeader[actualIndex]._id].channel!.name
                : undefined,
            channel_name_code:
              series[shuffledHeader[actualIndex]._id] &&
              series[shuffledHeader[actualIndex]._id].channel
                ? series[shuffledHeader[actualIndex]._id].channel!.nameCode
                : undefined,
            channel_umbrella_id:
              series[shuffledHeader[actualIndex]._id] &&
              series[shuffledHeader[actualIndex]._id].channel
                ? series[shuffledHeader[actualIndex]._id].channel!.umbrella._id
                : undefined,
            channel_umbrella_name:
              series[shuffledHeader[actualIndex]._id] &&
              series[shuffledHeader[actualIndex]._id].channel
                ? series[shuffledHeader[actualIndex]._id].channel!.umbrella.name
                : undefined,
            channel_umbrella_name_code:
              series[shuffledHeader[actualIndex]._id] &&
              series[shuffledHeader[actualIndex]._id].channel
                ? series[shuffledHeader[actualIndex]._id].channel!.umbrella
                    .nameCode
                : undefined,
            has_rss_feed:
              series[shuffledHeader[actualIndex]._id] &&
              series[shuffledHeader[actualIndex]._id].info &&
              series[shuffledHeader[actualIndex]._id].info[seriesLanguage] &&
              series[shuffledHeader[actualIndex]._id].info[seriesLanguage]
                .rssFeed
                ? true
                : false,
            child_content:
              series[shuffledHeader[actualIndex]._id] &&
              series[shuffledHeader[actualIndex]._id].isChild
                ? series[shuffledHeader[actualIndex]._id].isChild
                : undefined,
            genre:
              series[shuffledHeader[actualIndex]._id] &&
              series[shuffledHeader[actualIndex]._id].genre
                ? series[shuffledHeader[actualIndex]._id].genre
                : undefined,

            production:
              series[shuffledHeader[actualIndex]._id] &&
              series[shuffledHeader[actualIndex]._id].production
                ? series[shuffledHeader[actualIndex]._id].production
                : undefined,
            scripted:
              series[shuffledHeader[actualIndex]._id] &&
              series[shuffledHeader[actualIndex]._id].scripted
                ? series[shuffledHeader[actualIndex]._id].scripted
                : undefined,
            catalog: seriesLanguage,
          });
          unmuteVolume(
            "Home Header Carousel",
            series[shuffledHeader[actualIndex]._id]._id,
            profile!.isChild,
            series[shuffledHeader[actualIndex]._id].info[seriesLanguage].title,
            series[shuffledHeader[actualIndex]._id].isChild,
            series[shuffledHeader[actualIndex]._id].emotion
              ? series[shuffledHeader[actualIndex]._id].emotion!.join()
              : "",
            "Home Header Carousel",
            universes && universToShow
              ? universes.filter(
                  (each) => each.headerCategoryId === universToShow
                )[0].title
              : "Main Homepage",
            universToShow ? universToShow : undefined
          );
          playSample(
            series[shuffledHeader[actualIndex]._id].isChild,
            "Home Header Carousel",
            series[shuffledHeader[actualIndex]._id].emotion
              ? series[shuffledHeader[actualIndex]._id].emotion!.join()
              : "",
            series[shuffledHeader[actualIndex]._id]._id,
            series[shuffledHeader[actualIndex]._id].info[seriesLanguage].title,
            profile!.isChild,
            undefined,
            "Home Header Carousel",
            undefined,
            universes && universToShow
              ? universes.filter(
                  (each) => each.headerCategoryId === universToShow
                )[0].title
              : "Main Homepage"
          );
        }
      }
    } else if (
      universes &&
      shuffledUniverse &&
      universToShow &&
      universesCategories
    ) {
      if (
        !series ||
        (series && !series[shuffledUniverse[actualIndex]._id]) ||
        !episodes ||
        (episodes && !episodes[shuffledUniverse[actualIndex]._id])
      ) {
        try {
          const seriesPending = await getSeriesService(
            shuffledUniverse[actualIndex]._id,
            user,
            userContentLanguage,
            signal
          );
          const seriesData: SeriesType = await seriesPending.json();
          if (
            !seriesData ||
            !seriesData.info[seriesLanguage!] ||
            (seriesData.isChild === false &&
              profile &&
              profile.isChild === true)
          ) {
            window.scrollTo(0, 0);
            history.push("/home");
            return;
          } else {
            dispatch({
              type: Case.PUSH_SERIES,
              payload: { series: seriesData },
            });
            const episodesPending = await getEpisodesService(
              user.token,
              shuffledUniverse[actualIndex]._id,
              seriesData.chronological,
              seriesData.chronological
                ? 1
                : seriesData.seasonsList[seriesData.seasonsList.length - 1],
              userContentLanguage,
              signal
            );
            const episodesData: EpisodeType[] = await episodesPending.json();
            dispatch({
              type: Case.PUSH_EPISODES,
              payload: {
                series: seriesData,
                episodes: episodesData,
                season: seriesData.chronological
                  ? 1
                  : seriesData.seasonsList[seriesData.seasonsList.length - 1],
              },
            });
            if (seriesData.info[seriesLanguage].sample) {
              const requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                  Authorization: user.token,
                },
              };

              const playlistPending = await fetch(
                `${process.env.REACT_APP_API}/${userContentLanguage}/series/${seriesData._id}/next?profileId=${profile?._id}&duration=108000`,
                requestOptions
              );
              const playlist = await playlistPending.json();
              Promise.all(
                playlist.map(async (eachEpisode) => {
                  const episodePending = await fetch(
                    `${process.env.REACT_APP_API}/${userContentLanguage}/series/${seriesData._id}/episode/${eachEpisode._id}/`,
                    requestOptions
                  );
                  const episode = await episodePending.json();
                  return episode;
                })
              ).then((playlist: any) => {
                dispatch({
                  type: Case.SET_CURRENT_EPISODE,
                  payload: {
                    series: seriesData,
                    episodes: playlist,
                    sample: seriesData.info[seriesLanguage].sample,
                    lng: seriesLanguage,
                    uuid,
                  },
                });
              });
              window.analytics.track("Audio Content Started", {
                subscription: !!localStorage.getItem("user")
                  ? JSON.parse(localStorage.getItem("user")!).subscription
                  : 0,
                platform: "Web",
                session_id: uuid,
                series_id: seriesData.info[seriesLanguage]._id,
                episode_id: "Trailer",
                content_name: seriesData.info[seriesLanguage].title,
                content_length: Math.round(seriesData.length),
                position: 0,
                signup_type: localStorage.getItem("sy_signup_type")
                  ? localStorage.getItem("sy_signup_type")
                  : undefined,
                chronological: seriesData.chronological,
                classification: seriesData.classification,
                emotion: seriesData.emotion,
                format: seriesData.format,
                is_unit: seriesData.isUnit,
                publication_date: seriesData.publicationDate,
                seasons_list: seriesData.seasonsList,
                header_mention: seriesData.info[seriesLanguage].headerMention,
                context: "Explorer",
                channel_id:
                  seriesData && seriesData.channel
                    ? seriesData.channel._id
                    : undefined,
                channel_name:
                  seriesData && seriesData.channel
                    ? seriesData.channel.name
                    : undefined,
                channel_name_code:
                  seriesData && seriesData.channel
                    ? seriesData.channel.nameCode
                    : undefined,
                channel_umbrella_id:
                  seriesData && seriesData.channel
                    ? seriesData.channel.umbrella._id
                    : undefined,
                channel_umbrella_name:
                  seriesData && seriesData.channel
                    ? seriesData.channel.umbrella.name
                    : undefined,
                channel_umbrella_name_code:
                  seriesData && seriesData.channel
                    ? seriesData.channel.umbrella.nameCode
                    : undefined,
                has_rss_feed:
                  seriesData &&
                  seriesData.info &&
                  seriesData.info[seriesLanguage] &&
                  seriesData.info[seriesLanguage].rssFeed
                    ? true
                    : false,
                child_content:
                  seriesData && seriesData.isChild
                    ? seriesData.isChild
                    : undefined,
                genre:
                  seriesData && seriesData.genre ? seriesData.genre : undefined,

                production:
                  seriesData && seriesData.production
                    ? seriesData.production
                    : undefined,
                scripted:
                  seriesData && seriesData.scripted
                    ? seriesData.scripted
                    : undefined,
                catalog: seriesLanguage,
              });
            }
          }
          unmuteVolume(
            "Home Header Carousel",
            seriesData._id,
            profile!.isChild,
            seriesData.info[seriesLanguage].title,
            seriesData.isChild,
            seriesData.emotion ? seriesData.emotion!.join() : "",
            "Home Header Carousel",
            universes && universToShow
              ? universes.filter(
                  (each) => each.headerCategoryId === universToShow
                )[0].title
              : "Main Homepage",
            universToShow ? universToShow : undefined
          );
          playSample(
            seriesData.isChild,
            "Home Header Carousel",
            seriesData.emotion ? seriesData.emotion!.join() : "",
            seriesData._id,
            seriesData.info[seriesLanguage].title,
            profile!.isChild,
            undefined,
            "Home Header Carousel",
            undefined,
            universes && universToShow
              ? universes.filter(
                  (each) => each.headerCategoryId === universToShow
                )[0].title
              : "Main Homepage"
          );
        } catch (e) {
          window.scrollTo(0, 0);
          history.push("/home");
          return;
        }
      } else {
        if (
          series[shuffledUniverse[actualIndex]._id] &&
          series[shuffledUniverse[actualIndex]._id].info &&
          series[shuffledUniverse[actualIndex]._id].info[seriesLanguage].sample
        ) {


          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              Authorization: user.token,
            },
          };
      
            const playlistPending = await fetch(
              `${process.env.REACT_APP_API}/${userContentLanguage}/series/${series[shuffledUniverse[actualIndex]._id]._id}/next?profileId=${profile?._id}&duration=108000`,
              requestOptions
            );
            const playlist = await playlistPending.json();
            Promise.all(
              playlist.map(async (eachEpisode) => {
                const episodePending = await fetch(
                  `${process.env.REACT_APP_API}/${userContentLanguage}/series/${series[shuffledUniverse[actualIndex]._id]._id}/episode/${eachEpisode._id}/`,
                  requestOptions
                );
                const episode = await episodePending.json();
                return episode;
              })
            ).then((playlist: any) =>{
              dispatch({
                type: Case.SET_CURRENT_EPISODE,
                payload: {
                  series:  series[shuffledUniverse[actualIndex]._id],
                  episodes: playlist,
                  sample: series[shuffledUniverse[actualIndex]._id].info[seriesLanguage]
                  .sample,
                  lng: seriesLanguage,
                  uuid,
                },
              })
            }
            );
          window.analytics.track("Audio Content Started", {
            subscription: !!localStorage.getItem("user")
              ? JSON.parse(localStorage.getItem("user")!).subscription
              : 0,
            platform: "Web",
            session_id: uuid,
            series_id: series[shuffledUniverse[actualIndex]._id]._id,
            episode_id: "Trailer",
            content_name: series[shuffledUniverse[actualIndex]._id].title,
            content_length: Math.round(
              series[shuffledUniverse[actualIndex]._id].length
            ),
            position: 0,
            signup_type: localStorage.getItem("sy_signup_type")
              ? localStorage.getItem("sy_signup_type")
              : undefined,
            chronological:
              series[shuffledUniverse[actualIndex]._id].chronological,
            classification:
              series[shuffledUniverse[actualIndex]._id].classification,
            emotion: series[shuffledUniverse[actualIndex]._id].emotion,
            format: series[shuffledUniverse[actualIndex]._id].format,
            is_unit: series[shuffledUniverse[actualIndex]._id].isUnit,
            publication_date:
              series[shuffledUniverse[actualIndex]._id].publicationDate,
            seasons_list: series[shuffledUniverse[actualIndex]._id].seasonsList,
            header_mention:
              series[shuffledUniverse[actualIndex]._id].info[seriesLanguage]
                .headerMention,
            context: "Explorer",
            channel_id:
              series[shuffledUniverse[actualIndex]._id] &&
              series[shuffledUniverse[actualIndex]._id].channel
                ? series[shuffledUniverse[actualIndex]._id].channel!._id
                : undefined,
            channel_name:
              series[shuffledUniverse[actualIndex]._id] &&
              series[shuffledUniverse[actualIndex]._id].channel
                ? series[shuffledUniverse[actualIndex]._id].channel!.name
                : undefined,
            channel_name_code:
              series[shuffledUniverse[actualIndex]._id] &&
              series[shuffledUniverse[actualIndex]._id].channel
                ? series[shuffledUniverse[actualIndex]._id].channel!.nameCode
                : undefined,
            channel_umbrella_id:
              series[shuffledUniverse[actualIndex]._id] &&
              series[shuffledUniverse[actualIndex]._id].channel
                ? series[shuffledUniverse[actualIndex]._id].channel!.umbrella
                    ._id
                : undefined,
            channel_umbrella_name:
              series[shuffledUniverse[actualIndex]._id] &&
              series[shuffledUniverse[actualIndex]._id].channel
                ? series[shuffledUniverse[actualIndex]._id].channel!.umbrella
                    .name
                : undefined,
            channel_umbrella_name_code:
              series[shuffledUniverse[actualIndex]._id] &&
              series[shuffledUniverse[actualIndex]._id].channel
                ? series[shuffledUniverse[actualIndex]._id].channel!.umbrella
                    .nameCode
                : undefined,
            has_rss_feed:
              series[shuffledUniverse[actualIndex]._id] &&
              series[shuffledUniverse[actualIndex]._id].info &&
              series[shuffledUniverse[actualIndex]._id].info[seriesLanguage] &&
              series[shuffledUniverse[actualIndex]._id].info[seriesLanguage]
                .rssFeed
                ? true
                : false,
            child_content:
              series[shuffledUniverse[actualIndex]._id] &&
              series[shuffledUniverse[actualIndex]._id].isChild
                ? series[shuffledUniverse[actualIndex]._id].isChild
                : undefined,
            genre:
              series[shuffledUniverse[actualIndex]._id] &&
              series[shuffledUniverse[actualIndex]._id].genre
                ? series[shuffledUniverse[actualIndex]._id].genre
                : undefined,

            production:
              series[shuffledUniverse[actualIndex]._id] &&
              series[shuffledUniverse[actualIndex]._id].production
                ? series[shuffledUniverse[actualIndex]._id].production
                : undefined,
            scripted:
              series[shuffledUniverse[actualIndex]._id] &&
              series[shuffledUniverse[actualIndex]._id].scripted
                ? series[shuffledUniverse[actualIndex]._id].scripted
                : undefined,
            catalog: seriesLanguage,
          });
          unmuteVolume(
            "Home Header Carousel",
            series[shuffledUniverse[actualIndex]._id]._id,
            profile!.isChild,
            series[shuffledUniverse[actualIndex]._id].info[seriesLanguage]
              .title,
            series[shuffledUniverse[actualIndex]._id].isChild,
            series[shuffledUniverse[actualIndex]._id].emotion
              ? series[shuffledUniverse[actualIndex]._id].emotion!.join()
              : "",
            "Home Header Carousel",
            universes && universToShow
              ? universes.filter(
                  (each) => each.headerCategoryId === universToShow
                )[0].title
              : "Main Homepage",
            universToShow ? universToShow : undefined
          );
          playSample(
            series[shuffledUniverse[actualIndex]._id].isChild,
            "Home Header Carousel",
            series[shuffledUniverse[actualIndex]._id].emotion
              ? series[shuffledUniverse[actualIndex]._id].emotion!.join()
              : "",
            series[shuffledUniverse[actualIndex]._id]._id,
            series[shuffledUniverse[actualIndex]._id].info[seriesLanguage]
              .title,
            profile!.isChild,
            undefined,
            "Home Header Carousel",
            undefined,
            universes && universToShow
              ? universes.filter(
                  (each) => each.headerCategoryId === universToShow
                )[0].title
              : "Main Homepage"
          );
        }
      }
    }
  };

  const openSeries = () => {
    history.push({
      pathname:
        each && isCurrent && !isFromClick ? `/series/${each._id}` : undefined,
      state:
        each && isCurrent
          ? {
              contentEventDetails: {
                id: each._id,
                contentContext: "Explorer",
                categoryType: "Home Header Carousel",
                contentCategory: undefined,
                categoryPosition: undefined,
                universeId: universToShow ? universToShow : undefined,
                contentUniverse:
                  universes && universToShow
                    ? universes.filter(
                        (each) => each.headerCategoryId === universToShow
                      )[0].title
                    : "Main Homepage",
              },
            }
          : undefined,
    });
    if (each && isCurrent) {
      ReactPixel.track("ViewCategory", {
        content_type: "product",
        content_category: "Home Header",
        content_ids: each._id,
        content_name: null,
        univers_affichage:
          universes && universToShow
            ? universes.filter(
                (each) => each.headerCategoryId === universToShow
              )[0].title
            : "Main Homepage",
        page_type: "Main Homepage",
      });
    }
  };

  return { isLiked, stopExtract, launchExtract, addToLikeList, openSeries };
}
