import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import { Action, Case } from "../interfaces/ReducerCase";

export default function UseSignInSignUp(): { isUrlLoaded: boolean } {
  const location = useLocation();
  const dispatch: Dispatch<Action> = useDispatch();
  const [isUrlLoaded, setIsUrlLoaded] = useState<boolean>(false);
  const stableDispatch = useCallback(dispatch, [dispatch]);
  useEffect(() => {
    if (location.pathname.slice(0, 7) === "/signin") {
      stableDispatch({ type: Case.SIGNIN });
      setIsUrlLoaded(true);
    } else if (location.pathname.slice(0, 7) === "/signup") {
      stableDispatch({ type: Case.SIGNUP });
      setIsUrlLoaded(true);
    }
  }, [location, stableDispatch]);
  return { isUrlLoaded };
}
