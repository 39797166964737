import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import { searchService } from "../services/SearchService";
import EventStore from "../stores/AmplitudeStore";
import { UseSeriesLanguage } from "./UseSeriesLanguage";

interface Props {
  universToShow: string | undefined;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

export default function UseSearch({ universToShow, setSearch }: Props): {
  handleChange: (event: any) => Promise<void>;
  debouncedState: string;
  searchSeries: SeriesType[] | undefined;
  setSearchSeries: React.Dispatch<
    React.SetStateAction<SeriesType[] | undefined>
  >;
} {
  const { profile, universes, userContentLanguage, user } = useSelector(
    (state: StoreType) => ({
      ...state.ProfileReducer,
      ...state.UserReducer,
    })
  );

  const { seriesLanguage } = UseSeriesLanguage();
  const location = useLocation();

  const { searchEvent } = EventStore();

  const [searchSeries, setSearchSeries] = useState<SeriesType[] | undefined>();
  const [debouncedState, setDebouncedState] = useState("");
  const [isChild, setIsChild] = useState<boolean>(false);

  useEffect(() => {
    if (profile && profile.isChild) {
      setIsChild(true);
    } else {
      setIsChild(false);
    }
  }, [profile]);

  const handleChange = async (event: any) => {
    setSearch(event.target.value);
    debounce.current(event.target.value, isChild);
  };

  const debounce = useRef<
    _.DebouncedFunc<(_searchVal: string, isChild?: boolean) => Promise<void>>
  >(
    _.debounce(async (_searchVal: string, isChild?: boolean) => {
      setDebouncedState(_searchVal);
      const searchPending = await searchService(
        user,
        _searchVal,
        isChild ? true : false,
        userContentLanguage
      );
      const searchData: { series: SeriesType[] } = await searchPending.json();
      if (_searchVal.length >= 3) {
        searchEvent(
          location.pathname,
          universes && universToShow
            ? universes[
                universes.findIndex(
                  (each) => each.headerCategoryId === universToShow
                )
              ].title
            : "Home",
          seriesLanguage,
          _searchVal
        );
      }
      setSearchSeries(searchData.series);
    }, 500)
  );
  return { debouncedState, searchSeries, handleChange, setSearchSeries };
}
