import React, { ReactElement } from "react";
import ContentLoader from "react-content-loader";
import { language } from "../stores/18nStore";

interface Props {
  title?: string;
}

export default function HomeBillboardLoader({ title }: Props): ReactElement {
  return (
    <div className="billboard">
      {title && <p className="resume-title">{title}</p>}
      <div className="billboard-container">
        <div className="billboard-left-side">
          <div className="banner-image">
            <ContentLoader
              className="billboard-cover-placeholder"
              animate={true}
              speed={1.5}
              width="680"
              height="383"
              viewBox="0 0 680 383"
              backgroundColor="#343434"
              foregroundColor="#454545"
            >
              <rect
                opacity="0.2"
                width="680"
                height="382.5"
                rx="16"
                fill="white"
              />
            </ContentLoader>
          </div>
        </div>
        <div className="billboard-right-side hidden">
          <p className="billboard-title">
            <ContentLoader
              animate={true}
              speed={2}
              width="109"
              height="24"
              viewBox="0 0 109 24"
              backgroundColor="#343434"
              foregroundColor="#454545"
            >
              <rect width="109" height="24" rx="12" />
            </ContentLoader>
          </p>
          <div className="series-header-mentions">
            <p>
              <ContentLoader
                animate={true}
                speed={1.5}
                width="58"
                height="12"
                viewBox="0 0 58 12"
                backgroundColor="#343434"
                foregroundColor="#454545"
              >
                <rect width="58" height="12" rx="6" />
              </ContentLoader>
            </p>
            <p>
              <ContentLoader
                animate={true}
                speed={1.5}
                width="58"
                height="12"
                viewBox="0 0 58 12"
                backgroundColor="#343434"
                foregroundColor="#454545"
              >
                <rect width="58" height="12" rx="6" />
              </ContentLoader>
            </p>
            <p>
              <ContentLoader
                animate={true}
                speed={1.5}
                width="135"
                height="12"
                viewBox="0 0 135 12"
                backgroundColor="#343434"
                foregroundColor="#454545"
              >
                <rect width="135" height="12" rx="6" />
              </ContentLoader>
            </p>
          </div>
          <p className="billboard-description">
            <ContentLoader
              animate={true}
              speed={2}
              width="602"
              height="68"
              viewBox="0 0 602 68"
              backgroundColor="#343434"
              foregroundColor="#454545"
            >
              <rect y="20" width="602" height="8" rx="4" />
              <rect width="602" height="8" rx="4" />
              <rect y="40" width="602" height="8" rx="4" />
              <rect y="60" width="459" height="8" rx="4" />
            </ContentLoader>
          </p>
          <div className="billboard-cta">
            <button className="billboard-extract">
              <div className="launchExtract">
                <img src="/image/playExtract.svg" alt="" />
                <p>{language("seriesPagePlay")}</p>
              </div>
            </button>
            <button className="billboard-openseries">
              <div className="billboard-series">
                <p>{language("billboardDiscover")}</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
