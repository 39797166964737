import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { UsePlay } from "../ressources/UsePlay";
import HomeResumeCard from "./HomeResumeCard";
import { language } from "../stores/18nStore";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation, Mousewheel } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import swiperMainOptions from "../utils/SwiperOptions";
import {
  LazyLoadComponent,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";

SwiperCore.use([Navigation, Mousewheel]);

interface Props {
  scrollPosition: ScrollPosition;
  universToShow?: string | undefined;
}
function HomeResumeListe({
  universToShow,
  scrollPosition,
}: Props): ReactElement {
  const { seriesHistory, profile } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
    ...state.ProfileReducer,
  }));

  const { play } = UsePlay();
  return (
    <LazyLoadComponent
      threshold={0}
      scrollPosition={scrollPosition}
      placeholder={
        <div className="home-resume">
          <p className="resume-title">{language("resumeListening")}</p>
          <Swiper {...swiperMainOptions}>
            {Array.from(Array(8), (e, i) => {
              return (
                <SwiperSlide key={i}>
                  {" "}
                  <HomeResumeCard
                    key={i}
                    index={i}
                    universToShow={universToShow}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      }
    >
      <div className="home-resume">
        <p className="resume-title">{language("resumeListening")}</p>
        <Swiper {...swiperMainOptions}>
          {profile && seriesHistory
            ? Object.keys(seriesHistory)
                .filter((each) => seriesHistory[each].status === false)
                .map((each, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <HomeResumeCard
                        seriesHistory={seriesHistory}
                        each={each}
                        index={index}
                        play={play}
                        universToShow={universToShow}
                      />
                    </SwiperSlide>
                  );
                })
            : Array.from(Array(8), (e, i) => {
                return (
                  <SwiperSlide key={i}>
                    {" "}
                    <HomeResumeCard
                      key={i}
                      index={i}
                      universToShow={universToShow}
                    />
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </div>
    </LazyLoadComponent>
  );
}

export default trackWindowScroll(HomeResumeListe);
