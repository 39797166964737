/* import amplitude from "amplitude-js"; */
import React, { ReactElement, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import StoreType from "../interfaces/StoreType";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import { language } from "../stores/18nStore";
import useComponentVisible from "../utils/OnClickElsewhere";
import HomeProfileDeployed from "./HomeProfileDeployed";
import { HashLink as Link } from "react-router-hash-link";
import EventStore from "../stores/AmplitudeStore";
import HomeNavBarBurger from "./HomeNavBarBurger";
import { UsePreferenceSettings } from "../ressources/UsePreferenceSettings";
import PreferencesSettingsContentLanguage from "./PreferencesSettingsContentLanguage";
import amplitude from "amplitude-js";
import { ProductStripeType } from "../interfaces/UserType";
import UseSearch from "../ressources/UseSearch";
import { UsePlay } from "../ressources/UsePlay";

interface Props {
  setUniversToShow: React.Dispatch<React.SetStateAction<string | undefined>>;
  universToShow: string | undefined;
  searchParams?: string;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}
export default function HomeNavBar({
  universToShow,
  setUniversToShow,
  search,
  setSearch,
}: Props): ReactElement {
  const {
    profile,
    universes,
    userContentLanguage,
    user,
    isLoading,
    currentEpisode,
    isSeriesOpen,
    isShuffle,
  } = useSelector((state: StoreType) => ({
    ...state.ProfileReducer,
    ...state.UserReducer,
    ...state.PlayerReducer,
    ...state.RouterReducer,
  }));
  const {
    clickUnfurlProfileActions,
    clickNavBarLanguageIcon,
    clickSearchIcon,
    openMyList,
    openHome,
    openUniverse,
  } = EventStore();

  const location = useLocation();
  const { seriesLanguage } = UseSeriesLanguage();

  const [scrollTop, setScrollTop] = useState(window.scrollY);

  const { debouncedState, handleChange, searchSeries, setSearchSeries } =
    UseSearch({ universToShow, setSearch });
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
    secondRef,
    isSecondComponentVisible,
    setIsSecondComponentVisible,
  } = useComponentVisible(false);
  const { setContentLanguage, triggerLanguages } = UsePreferenceSettings(
    setIsComponentVisible,
    setIsSecondComponentVisible
  );

  useEffect(() => {
    const onScroll = (e: any) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const { play } = UsePlay();

  return (
    <div className="header-home">
      <nav
        className="main-nav"
        style={{
          background:
            !search && scrollTop <= 170
              ? "rgba(0, 0, 0, 0)"
              : "rgba(0, 0, 0, 1)",
          zIndex: isSeriesOpen ? 999 : currentEpisode ? 9999999999 : 999,
        }}
      >
        <HomeNavBarBurger
          setUniversToShow={setUniversToShow}
          universToShow={universToShow}
          setSearch={setSearch}
        />
        <div className="nav-links">
          <Link
            to="/home"
            onClick={() => {
              openHome(
                "NavBar Sybel Icon",
                location.pathname,
                universes && universToShow
                  ? universes[
                      universes.findIndex(
                        (each) => each.headerCategoryId === universToShow
                      )
                    ].title
                  : "Home",
                seriesLanguage
              );
              setSearch("");
            }}
          >
            <img className="logo" src="/image/logo/whitelogo.svg" alt="" />
          </Link>
          <Link
            onClick={() => {
              openHome(
                "NavBar Actions",
                location.pathname,
                universes && universToShow
                  ? universes[
                      universes.findIndex(
                        (each) => each.headerCategoryId === universToShow
                      )
                    ].title
                  : "Home",

                seriesLanguage
              );
              setSearch("");
              setUniversToShow(undefined);
            }}
            to="/home"
            style={
              !universToShow && location.pathname === "/home"
                ? { fontWeight: "bold" }
                : {}
            }
          >
            {language("home")}
          </Link>
          <Link
            onClick={() => {
              openMyList(
                location.pathname,
                universes && universToShow
                  ? universes[
                      universes.findIndex(
                        (each) => each.headerCategoryId === universToShow
                      )
                    ].title
                  : "Home",
                seriesLanguage
              );
            }}
            to="/my-list"
            style={
              location.pathname === "/my-list" ? { fontWeight: "bold" } : {}
            }
          >
            {language("likeList")}
          </Link>
          {universes && profile && !profile.isChild
            ? universes.map((each, index) => (
                <Link
                  to={{
                    pathname: "/home",
                    state: { headerCategoryId: each.headerCategoryId },
                  }}
                  className="universe-nav-link"
                  key={index}
                  onClick={() => {
                    openUniverse(
                      universToShow && universes
                        ? universes[
                            universes.findIndex(
                              (each) => each.headerCategoryId === universToShow
                            )
                          ].title
                        : "Home",
                      location.pathname,
                      seriesLanguage
                    );
                    setSearch("");
                    setUniversToShow(each.headerCategoryId);
                  }}
                  style={
                    each.headerCategoryId === universToShow
                      ? { fontWeight: "bold" }
                      : {}
                  }
                >
                  {each.title}
                </Link>
              ))
            : null}
        </div>
        <div className="nav-profile">
          <div
            className="search"
            onClick={() => {
              clickSearchIcon(
                location.pathname,
                universes && universToShow
                  ? universes[
                      universes.findIndex(
                        (each) => each.headerCategoryId === universToShow
                      )
                    ].title
                  : "Home",
                seriesLanguage
              );
            }}
          >
            <input
              placeholder={language("searchPlaceholder")}
              autoFocus={search ? true : false}
              style={{
                backgroundImage: "url('/image/search.svg')",
                width: search ? "274px" : "",
              }}
              onChange={(e) => handleChange(e)}
              type="text"
              value={search}
            />
          </div>
          {user && (user.subscription === null || user.subscription === 0) && (
            <Link
              className="nav-profile-premium"
              to={
                user.subscription === 0 &&
                user.subscriptionExpirationDate &&
                new Date(user.subscriptionExpirationDate) < new Date()
                  ? "/offers"
                  : `/payment/${
                      (
                        user.subscriptionData!.productsStripe[
                          user.subscriptionData!.productsStripe.findIndex(
                            (eachProduct) =>
                              eachProduct.trial_period_days ===
                              Math.max.apply(
                                Math,
                                user.subscriptionData!.productsStripe.map(
                                  (o) => o.trial_period_days
                                )
                              )
                          )
                        ] as ProductStripeType
                      ).id
                    }`
              }
              onClick={() =>
                amplitude
                  .getInstance()
                  .logEvent("Click Premium Button", { Context: "NavBar" })
              }
            >
              {user.subscription === 0 &&
              user.subscriptionExpirationDate &&
              new Date(user.subscriptionExpirationDate) < new Date()
                ? language("subscriptionInsert").toUpperCase()
                : language("popUpNewSessionCta").toUpperCase()}
            </Link>
          )}
          <div
            className="choose-home-language"
            onClick={() =>
              triggerLanguages(
                isSecondComponentVisible,
                setIsSecondComponentVisible,
                setIsComponentVisible
              )
            }
          >
            <p
              className="content-language"
              onClick={() => {
                clickNavBarLanguageIcon(
                  location.pathname,
                  universes && universToShow
                    ? universes[
                        universes.findIndex(
                          (each) => each.headerCategoryId === universToShow
                        )
                      ].title
                    : "Home",
                  seriesLanguage
                );
              }}
            >
              {seriesLanguage}
            </p>
            {isSecondComponentVisible && (
              <PreferencesSettingsContentLanguage
                refChild={secondRef}
                setContentLanguage={setContentLanguage}
              />
            )}
          </div>

          {isShuffle && (
            <div className="shuffle">
              <img
                src="/image/shuffle.svg"
                alt="discover randomly a new series on Sybel"
                onClick={() =>
                  play(
                    isShuffle.series._id,
                    isShuffle.series.chronological
                      ? isShuffle.episodes[0]
                      : isShuffle.episodes[isShuffle.episodes.length - 1],
                    "Explorer"
                  )
                }
              />
              <p>{isShuffle.title}</p>
            </div>
          )}
          <div
            className="profile-home"
            ref={ref}
            onClick={() => {
              clickUnfurlProfileActions(
                location.pathname,
                universes && universToShow
                  ? universes[
                      universes.findIndex(
                        (each) => each.headerCategoryId === universToShow
                      )
                    ].title
                  : "Home",
                seriesLanguage
              );
              return setIsComponentVisible(!isComponentVisible);
            }}
          >
            {isComponentVisible && (
              <HomeProfileDeployed
                universToShow={universToShow}
                setUniversToShow={setUniversToShow}
                setSearch={setSearch}
                setSearchSeries={setSearchSeries}
              />
            )}
            <div className="profile-image-home">
              <LazyLoadImage
                className="profile-picture"
                placeholderSrc="/image/profilePlaceholder.svg"
                src={
                  profile && profile.image
                    ? profile.image
                    : "/image/profilePlaceholder.svg"
                }
                alt=""
              />
            </div>
            <img
              src="/image/arrowIcon.svg"
              className="arrow"
              alt=""
              style={isComponentVisible ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
        </div>
      </nav>
      {!!search && (
        <div className="search-container">
          <div className="search-content">
            {searchSeries && searchSeries.length > 0 ? (
              <p className="search-summup">
                {language("searched", { count: searchSeries.length })}"
                {debouncedState}"
              </p>
            ) : (
              <p className="search-summup">
                {" "}
                {language("noResult")}"{debouncedState}"
              </p>
            )}
            <div
              className="search-covers"
              style={{
                marginBottom:
                  currentEpisode &&
                  user &&
                  userContentLanguage !== null &&
                  !isLoading
                    ? "80px"
                    : "0",
              }}
            >
              {searchSeries && searchSeries.length > 0
                ? searchSeries.map((each, index) => {
                    return (
                      <div
                        style={{
                          background:
                            each && each.mainImage
                              ? `center center / cover url(${each.mainImage})`
                              : "#343434",
                        }}
                        key={index}
                        className="each-resume-card"
                      >
                        <Link
                          to={{
                            pathname: `/series/${each._id}`,
                            state: {
                              contentEventDetails: {
                                id: each._id,
                                contentContext: "Search",
                                categoryType: undefined,
                                contentCategory: undefined,
                                categoryPosition: undefined,
                                universeId: undefined,
                                contentUniverse: undefined,
                                contentPosition: index + 1,
                                catalog: seriesLanguage,
                                searchedText: search,
                              },
                            },
                          }}
                          className="each-resume-card-cover-container"
                        >
                          <img
                            className="play-epidode-button"
                            src="/image/playLogo.svg"
                            alt=""
                          />
                        </Link>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
