import { Dispatch, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import EpisodeType from "../interfaces/EpisodeType";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";

export const UseUpdateEpisodesHistory = (): {
  updateEpisodeHistory: (
    episode: EpisodeType,
    status: boolean,
    progress: number
  ) => void;
} => {
  const { episodesHistory } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
  }));
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const updateEpisodeHistory = (
    episode: EpisodeType,
    status: boolean,
    progress: number
  ) => {
    if (
      episodesHistory &&
      episodesHistory[episode.seriesId] &&
      episodesHistory[episode.seriesId][episode._id]
    ) {
      stableDispatch({
        type: Case.UPDATE_EPISODE_HISTORY,
        payload: {
          seriesId: episode.seriesId,
          episodeId: episode._id,
          seriesHistory: {
            seriesId: episode.seriesId,
            status,
            progress,
            _updated_at: new Date(),
            episodeId: episode._id,
          },
        },
      });
    } else if (episodesHistory) {
      stableDispatch({
        type: Case.CREATE_EPISODE_HISTORY,
        payload: {
          seriesId: episode.seriesId,
          episodeId: episode._id,
          seriesHistory: {
            seriesId: episode.seriesId,
            status,
            progress,
            _updated_at: new Date(),
            episodeId: episode._id,
          },
        },
      });
    }
  };
  return { updateEpisodeHistory };
};
