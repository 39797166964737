import { Dispatch, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EpisodeType from "../interfaces/EpisodeType";
import { Action, Case } from "../interfaces/ReducerCase";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import { getEpisodesService } from "../services/SeriesService";
import { language } from "../stores/18nStore";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";

export const UseUpdateSeriesHistory = (): {
  updateSeriesHistory: (
    episode: EpisodeType,
    series: SeriesType,
    status: boolean,
    progress: number,
    season?: number
  ) => Promise<void>;
} => {
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const { userContentLanguage, user } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
    ...state.PlayerReducer,
    ...state.UserReducer,
  }));
  const updateSeriesHistory = async (
    episode: EpisodeType,
    series: SeriesType,
    status: boolean,
    progress: number,
    season?: number
  ) => {
    const ac = new AbortController();
    const { signal } = ac;
    if (season) {
      try {
        const episodesPending = await getEpisodesService(
          user.token,
          series._id,
          series.chronological,
          season,
          userContentLanguage,
          signal
        );
        const episodesData: EpisodeType[] = await episodesPending.json();
        stableDispatch({
          type: Case.PUSH_EPISODES,
          payload: {
            series: series,
            episodes: episodesData,
            season: season,
          },
        });
        stableDispatch({
          type: Case.UPDATE_SERIES_HISTORY,
          payload: {
            seriesId: episodesData[0].seriesId,
            seriesHistory: {
              _id: episodesData[0].seriesId,
              status,
              progress,
              series: {
                _id: series._id,
                isUnit: series.isUnit,
                chronological: series.chronological,
                mainImage: series.mainImage,
              },
              episode: {
                _id: episodesData[0]._id,
                season: episodesData[0].season,
                length: episodesData[0].length,
              },
            },
          },
        });
      } catch (e) {
        toast(language("codeError"), notificationsParam);
      }
    } else {
      stableDispatch({
        type: Case.UPDATE_SERIES_HISTORY,
        payload: {
          seriesId: episode.seriesId,
          seriesHistory: {
            _id: episode.seriesId,
            status,
            progress,
            series: {
              _id: series._id,
              isUnit: series.isUnit,
              chronological: series.chronological,
              mainImage: series.mainImage,
            },
            episode: {
              _id: episode._id,
              season: episode.season,
              length: episode.length,
            },
          },
        },
      });
    }
  };
  return { updateSeriesHistory };
};
