import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import SeriesLocationTypes from "../interfaces/SeriesLocationTypes";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import { getSeriesService } from "../services/SeriesService";
import EventStore from "../stores/AmplitudeStore";
import SimilarSeriesCard from "./SimilarSeriesCard";

interface Props {
  id: string;
  seriesLanguage: string;
  setIsSimilarView: Function;
  setIsEpisodeView: Function;
  setIsDetailView: Function;
  navigation: Function;
  setSeasonIndex: Function;
  toastId: string;
  fetchedSeries: SeriesType | undefined;
  lastNavigation: "Similar Series Tab" | "Episodes List Tab" | "Details Tab";
}
export default function SeriesSimilar({
  id,
  seriesLanguage,
  setIsSimilarView,
  setIsEpisodeView,
  setIsDetailView,
  navigation,
  setSeasonIndex,
  toastId,
  lastNavigation,
  fetchedSeries,
}: Props): ReactElement {
  const { series, profile, user, userContentLanguage } = useSelector(
    (state: StoreType) => ({
      ...state.UserReducer,
      ...state.RouterReducer,
      ...state.ProfileReducer,
      ...state.SeriesReducer,
    })
  );
  const [similarSeries, setSimilarSeries] = useState<SeriesType[]>();

  const location = useLocation<SeriesLocationTypes>();

  const history = useHistory();
  const { openSeriesPageSimilarTab } = EventStore();
  const changeSeries = (each: string, index: number) => {
    setSeasonIndex(1);
    navigation(setIsEpisodeView, setIsSimilarView, setIsDetailView);
    history.push(`/series/${each}`, {
      isSimilar: {
        id: each,
        lastId: id,
        title: series[id].info[seriesLanguage].title,
        index,
      },
      contentEventDetails:
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails
          : undefined,
    });
  };
  const [isSeriesSimillarLoaded, setIsSeriesSimillarLoaded] = useState(false);
  useEffect(() => {
    const ac = new AbortController();
    const { signal } = ac;
    const similarArray: SeriesType[] = [];
    const getSimillar = async () => {
      Promise.all(
        series[id].similar.map(async (each) => {
          try {
            const seriesPending = await getSeriesService(
              each,
              user,
              userContentLanguage,
              signal
            );
            const seriesData: SeriesType = await seriesPending.json();
            if (
              !seriesData ||
              !seriesData.info[seriesLanguage!] ||
              (seriesData.isChild === false &&
                profile &&
                profile.isChild === true)
            ) {
              return;
            } else {
              similarArray.push(seriesData);
            }
          } catch (e) {
            return;
          }
        })
      ).then(() => {
        setIsSeriesSimillarLoaded(true);
        setSimilarSeries(similarArray);
      });
    };
    if (
      series &&
      series[id] &&
      series[id].similar &&
      user &&
      !!user.token &&
      user._id &&
      userContentLanguage &&
      !isSeriesSimillarLoaded
    ) {
      getSimillar();
    }
    return () => ac.abort();
  }, [
    user,
    id,
    userContentLanguage,
    profile,
    seriesLanguage,
    isSeriesSimillarLoaded,
    series,
  ]);
  useEffect(() => {
    if (profile && fetchedSeries && seriesLanguage) {
      return openSeriesPageSimilarTab(
        location &&
          location.state &&
          location.state.isSimilar &&
          location.state.isSimilar.id === fetchedSeries._id
          ? "Similar Series"
          : "Series Deeplink",
        fetchedSeries._id,
        profile!.isChild,
        fetchedSeries.info[seriesLanguage].title,
        fetchedSeries.isChild,
        fetchedSeries.emotion ? fetchedSeries.emotion!.join() : undefined,
        lastNavigation,
        fetchedSeries.mainImageId,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentPosition
          : undefined
      );
    }
  }, [
    openSeriesPageSimilarTab,
    profile,
    location,
    fetchedSeries,
    seriesLanguage,
    lastNavigation,
  ]);

  return (
    <div className="series-similar-container">
      {similarSeries && profile && profile.isChild
        ? similarSeries
            .filter((eachSeries) => eachSeries.isChild === true)
            .map((each, index: number) =>
              each ? (
                <SimilarSeriesCard
                  each={each}
                  key={index}
                  toastId={toastId}
                  index={index}
                  changeSeries={(id: string, index: number) =>
                    changeSeries(id, index)
                  }
                  seriesLanguage={seriesLanguage}
                />
              ) : null
            )
        : similarSeries &&
          profile &&
          similarSeries.map((each: SeriesType, index: number) => (
            <SimilarSeriesCard
              each={each}
              key={index}
              toastId={toastId}
              index={index}
              changeSeries={(id: string, index: number) =>
                changeSeries(id, index)
              }
              seriesLanguage={seriesLanguage}
            />
          ))}
    </div>
  );
}
