import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(
    initialIsVisible
  );
  const [
    isSecondComponentVisible,
    setIsSecondComponentVisible,
  ] = useState<boolean>(initialIsVisible);
  const ref = useRef<HTMLDivElement>(null);
  const secondRef = useRef<HTMLDivElement>(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
      setIsSecondComponentVisible(false);
    }
  };

  const handleClickOutside = (event: Event) => {
    if (
      (ref.current && !ref.current.contains(event.target as Node))
    ) {
      setIsComponentVisible(false);
    } else if (
        (secondRef.current && !secondRef.current.contains(event.target as Node))
      ) {
        setIsSecondComponentVisible(false);
      }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return {
    ref,
    isComponentVisible,
    setIsComponentVisible,
    secondRef,
    isSecondComponentVisible,
    setIsSecondComponentVisible,
  };
}
