import { useCallback, Dispatch, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import EpisodeType from "../interfaces/EpisodeType";
import { Action, Case } from "../interfaces/ReducerCase";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import {
  getEpisodesService,
  getSeriesService,
  sendProgressService,
} from "../services/SeriesService";
import EventStore from "../stores/AmplitudeStore";
import { UseSeriesLanguage } from "./UseSeriesLanguage";
export const UseNextEpisode = (
  setSleepModeInfo: React.Dispatch<
    React.SetStateAction<
      | {
          time: number | null;
          second: number | null;
          wording: string;
          isActivated: boolean | undefined;
        }
      | undefined
    >
  >,
  playerRef: React.RefObject<ReactPlayer>
): {
  nextEpisode: (clicked?: true) => Promise<void>;
  episodeEndSleep: boolean;
  setEpisodeEndSleep: Dispatch<React.SetStateAction<boolean>>;
} => {
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const getSerie = async (id: string) => {
    const ac = new AbortController();
    const { signal } = ac;
    try {
      const seriesPending = await getSeriesService(
        id,
        user,
        userContentLanguage,
        signal
      );
      const seriesData: SeriesType = await seriesPending.json();
      if (
        !seriesData ||
        !seriesData.info[seriesLanguage!] ||
        (seriesData.isChild === false && profile && profile.isChild === true)
      ) {
        return;
      } else {
        stableDispatch({
          type: Case.PUSH_SERIES,
          payload: { series: seriesData },
        });
        return seriesData;
      }
    } catch (e) {
      return;
    }
  };
  const { seriesLanguage } = UseSeriesLanguage();
  const { play, pause } = EventStore();
  const [episodeEndSleep, setEpisodeEndSleep] = useState<boolean>(false);
  const {
    currentEpisode,
    profile,
    user,
    episodes,
    userContentLanguage,
    episodesHistory,
    uuid,
    volume,
  } = useSelector((state: StoreType) => ({
    ...state.PlayerReducer,
    ...state.SeriesReducer,
    ...state.ProfileReducer,
    ...state.UserReducer,
  }));
  const getSeason = async (
    id: string,
    season: number,
    isChronological: boolean
  ) => {
    const ac = new AbortController();
    const { signal } = ac;
    try {
      const episodesPending = await getEpisodesService(
        user.token,
        id,
        isChronological,
        season,
        userContentLanguage,
        signal
      );
      if (episodesPending.status === 404) {
        return { error: true };
      } else {
        const episodesData: EpisodeType[] = await episodesPending.json();
        dispatch({
          type: Case.PUSH_EPISODES,
          payload: {
            series: currentEpisode!.series,
            episodes: episodesData,
            season: season,
          },
        });
        return { episodes: episodesData };
      }
    } catch (e) {
      return { error: true };
    }
  };
  const nextEpisode = async (clicked?: true) => {
    dispatch({ type: Case.PLAYER_IS_LOADING});
    if (!clicked) {
      window.analytics.track("Audio Content Completed", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        context: "Player",
        platform: "Web",
        session_id: uuid,
        series_id:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series._id
            : undefined,
        episode_id:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode._id
            : "Trailer",
        content_name:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.info[seriesLanguage].title
            : undefined,
        episode_length:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode.length
            : undefined,
        content_length:
          currentEpisode && currentEpisode.series
            ? Math.round(currentEpisode.series.length)
            : undefined,
        season:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode.season
            : undefined,
        position:
          currentEpisode && currentEpisode.episode
            ? Math.round(currentEpisode.episode.length)
            : undefined,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        chronological:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.chronological
            : undefined,
        classification:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.classification
            : undefined,
        emotion:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.emotion
            : undefined,
        format:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.format
            : undefined,
        is_unit:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.isUnit
            : undefined,
        publication_date:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.publicationDate
            : undefined,
        seasons_list:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.seasonsList
            : undefined,
        header_mention:
          currentEpisode && currentEpisode.series && seriesLanguage
            ? currentEpisode.series.info[seriesLanguage].headerMention
            : undefined,
        episode_name:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode.info[seriesLanguage].title
            : undefined,
        channel_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel._id
            : undefined,
        channel_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.name
            : undefined,
        channel_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.nameCode
            : undefined,
        channel_umbrella_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella._id
            : undefined,
        channel_umbrella_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.name
            : undefined,
        channel_umbrella_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.nameCode
            : undefined,
            has_rss_feed:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.info[seriesLanguage] &&
            currentEpisode.series.info[seriesLanguage].rssFeed
              ? true
              : false,
              child_content:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.isChild
                : undefined,
            episode_index: currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.index
              : undefined,
            genre:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.genre
                : undefined,
            total_length: currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.length
              : undefined,
              recomm_id : sessionStorage.getItem("recommId") ? sessionStorage.getItem("recommId") : undefined
      });
       sessionStorage.removeItem("recommId")
    } else {
      window.analytics.track("Audio Content Interrupted", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        context: "clicked_next_episode",
        platform: "Web",
        session_id: uuid,
        series_id:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series._id
            : undefined,
        episode_id:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode._id
            : "Trailer",
        content_name:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.info[seriesLanguage].title
            : undefined,
        episode_length:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode.length
            : undefined,
        content_length:
          currentEpisode && currentEpisode.series
            ? Math.round(currentEpisode.series.length)
            : undefined,
        season:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode.season
            : undefined,
        position:
          playerRef && playerRef.current
            ? Math.round(playerRef.current.getCurrentTime())
            : undefined,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        chronological:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.chronological
            : undefined,
        classification:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.classification
            : undefined,
        emotion:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.emotion
            : undefined,
        format:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.format
            : undefined,
        is_unit:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.isUnit
            : undefined,
        publication_date:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.publicationDate
            : undefined,
        seasons_list:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.seasonsList
            : undefined,
        header_mention:
          currentEpisode && currentEpisode.series && seriesLanguage
            ? currentEpisode.series.info[seriesLanguage].headerMention
            : undefined,
        channel_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel._id
            : undefined,
        channel_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.name
            : undefined,
        channel_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.nameCode
            : undefined,
        channel_umbrella_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella._id
            : undefined,
        channel_umbrella_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.name
            : undefined,
        channel_umbrella_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.nameCode
            : undefined,
            has_rss_feed:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.info[seriesLanguage] &&
            currentEpisode.series.info[seriesLanguage].rssFeed
              ? true
              : false,
              child_content:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.isChild
                : undefined,
            episode_index: currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.index
              : undefined,
            genre:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.genre
                : undefined,
            total_length: currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.length
              : undefined,
              recomm_id : sessionStorage.getItem("recommId") ? sessionStorage.getItem("recommId") : undefined
      });      
      sessionStorage.removeItem("recommId")
    }
    if (episodeEndSleep) {
      pause(
        currentEpisode!.series.isChild,
        "Auto From SleepMode",
        currentEpisode!.series.emotion
          ? currentEpisode!.series.emotion!.join()
          : undefined,
        currentEpisode!.episode!.info[seriesLanguage].title,
        currentEpisode!.episode!._id,
        currentEpisode!.episode!.season,
        currentEpisode!.series._id,
        currentEpisode!.series.info[seriesLanguage].title,
        profile!.isChild,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        currentEpisode!.series.mainImageId,
        currentEpisode!.episode?.length,
        1,
        currentEpisode!.episode?.length,
        currentEpisode!.series?.length
      );
      setSleepModeInfo(undefined);
      setEpisodeEndSleep(false);
      if (
        currentEpisode &&
        currentEpisode.episode &&
        playerRef &&
        playerRef.current &&
        Math.round(playerRef!.current!.getCurrentTime()) <
          Math.round(currentEpisode!.episode!.length)
      ) {
        window.analytics.track("Audio Content Interrupted", {
          subscription: !!localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")!).subscription
            : 0,
          context: "sleepmode",
          platform: "Web",
          session_id: uuid,
          sound: volume,
          content_type:
            currentEpisode && currentEpisode.sample ? "Trailer" : "Episode",
          series_id:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series._id
              : undefined,
          episode_id:
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode._id
              : undefined,
          content_length: currentEpisode && currentEpisode.series.length,
          position:
            playerRef && playerRef.current
              ? Math.round(playerRef.current.getCurrentTime())
              : undefined,
          signup_type: localStorage.getItem("sy_signup_type")
            ? localStorage.getItem("sy_signup_type")
            : undefined,
          episode_name:
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.info[seriesLanguage].title
              : undefined,
          channel_id:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel._id
              : undefined,
          channel_name:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.name
              : undefined,
          channel_name_code:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.nameCode
              : undefined,
          channel_umbrella_id:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.umbrella._id
              : undefined,
          channel_umbrella_name:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.umbrella.name
              : undefined,
          channel_umbrella_name_code:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.umbrella.nameCode
              : undefined,
              has_rss_feed:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.info[seriesLanguage] &&
              currentEpisode.series.info[seriesLanguage].rssFeed
                ? true
                : false,
                child_content:
                currentEpisode && currentEpisode.series
                  ? currentEpisode.series.isChild
                  : undefined,
              chronological:
                currentEpisode && currentEpisode.series
                  ? currentEpisode.series.chronological
                  : undefined,
              classification:
                currentEpisode && currentEpisode.series
                  ? currentEpisode.series.classification
                  : undefined,
              emotion:
                currentEpisode && currentEpisode.series
                  ? currentEpisode.series.emotion
                  : undefined,
              episode_index: currentEpisode && currentEpisode.episode
                ? currentEpisode.episode.index
                : undefined,
              format:
                currentEpisode && currentEpisode.series
                  ? currentEpisode.series.format
                  : undefined,
              genre:
                currentEpisode && currentEpisode.series
                  ? currentEpisode.series.genre
                  : undefined,
              total_length: currentEpisode && currentEpisode.episode
                ? currentEpisode.episode.length
                : undefined,
                is_unit:
                currentEpisode && currentEpisode.series
                  ? currentEpisode.series.isUnit
                  : undefined,
                  recomm_id : sessionStorage.getItem("recommId") ? sessionStorage.getItem("recommId") : undefined
        });
      }
      sessionStorage.removeItem("recommId")
      return stableDispatch({
        type: Case.SET_CURRENT_EPISODE_UNDEFINED,
      });
    }
    if (
      currentEpisode &&
      !currentEpisode.episode &&
      currentEpisode.sample &&
      currentEpisode.episodes[0]
    ) {
      play(
        currentEpisode!.series.isChild,
        "Auto From Sample",
        currentEpisode!.series.emotion
          ? currentEpisode!.series.emotion!.join()
          : undefined,
        currentEpisode.episodes[0].info[seriesLanguage].title,
        currentEpisode.episodes[0]._id,
        currentEpisode.episodes[0].season,
        currentEpisode!.series._id,
        currentEpisode!.series.info[seriesLanguage].title,
        profile!.isChild,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        currentEpisode!.series.mainImageId,
        0,
        0,
        currentEpisode.episodes[0].length,
        currentEpisode!.series?.length
      );
      dispatch({
        type: Case.SET_CONTEXT,
        payload: { context: "playAutoFromSample" },
      });
      dispatch({
        type: Case.UPDATE_SERIES_EPISODE_HISTORY,
        payload: {
          id: currentEpisode!.series._id,
          episodeHistory: {
            _id: currentEpisode.episodes[0]._id,
            length: currentEpisode.episodes[0].length,
            season: currentEpisode.episodes[0].season,
          },
        },
      });
      dispatch({ type: Case.REINIT_IS_SEEKED_TO_HISTORY });
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: user.token,
        },
      };

      const rest = currentEpisode!.episodes.slice(
        currentEpisode!.episodes.findIndex(
          (eachEpiode) => currentEpisode.episodes[0]._id === eachEpiode._id
        )
      );
      let restTimstamp: number = 0;
      rest.map((each) => (restTimstamp += each.length));
      console.log(restTimstamp);

      if (restTimstamp < 3600) {
        console.log({ dedans: restTimstamp });
        const playlistPending = await fetch(
          `${process.env.REACT_APP_API}/${userContentLanguage}/series/${currentEpisode.episodes[0].seriesId}/next?profileId=${profile?._id}&duration=108000&episodeId=${currentEpisode.episodes[0]._id}`,
          requestOptions
        );
        const playlist = await playlistPending.json();
        Promise.all(
          playlist.map(async (eachEpisode) => {
            const episodePending = await fetch(
              `${process.env.REACT_APP_API}/${userContentLanguage}/series/${currentEpisode.episodes[0].seriesId}/episode/${eachEpisode._id}/`,
              requestOptions
            );
            const episode = await episodePending.json();
            return episode;
          })
        ).then(async (playlist: any) => {
          if (
            currentEpisode.episodes[0].seriesId !== currentEpisode!.series._id
          ) {
            const seriesRes = await getSerie(
              currentEpisode.episodes[0].seriesId
            );
            dispatch({
              type: Case.SET_CURRENT_EPISODE,
              payload: {
                series: seriesRes,
                episodes: playlist,
                episode: currentEpisode.episodes[0],
                lng: currentEpisode!.lng,
                uuid,
              },
            });
          } else {
            dispatch({
              type: Case.SET_CURRENT_EPISODE,
              payload: {
                series: currentEpisode.series,
                episodes: playlist,
                episode: currentEpisode.episodes[0],
                lng: currentEpisode!.lng,
                uuid,
              },
            });
          }
        });
      } else {
        if (
          currentEpisode.episodes[0].seriesId !== currentEpisode!.series._id
        ) {
          const seriesRes = await getSerie(currentEpisode.episodes[0].seriesId);
          dispatch({
            type: Case.SET_CURRENT_EPISODE,
            payload: {
              series: seriesRes,
              episodes: currentEpisode.episodes,
              episode: currentEpisode.episodes[0],
              lng: currentEpisode.lng,
              uuid,
            },
          });
        } else {
          dispatch({
            type: Case.SET_CURRENT_EPISODE,
            payload: {
              series: currentEpisode.series,
              episodes: currentEpisode.episodes,
              episode: currentEpisode.episodes[0],
              lng: currentEpisode.lng,
              uuid,
            },
          });
        }
      }
      window.analytics.track("Audio Content Started", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        platform: "Web",
        session_id: uuid,
        series_id:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series._id
            : undefined,
        episode_id:
          currentEpisode && currentEpisode.episodes
            ? currentEpisode.episodes[0]._id
            : "Trailer",
        content_name:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.info[seriesLanguage].title
            : undefined,
        episode_length:
          currentEpisode && currentEpisode.episodes
            ? currentEpisode.episodes[0].length
            : undefined,
        content_length:
          currentEpisode && currentEpisode.series
            ? Math.round(currentEpisode.series.length)
            : undefined,
        season:
          currentEpisode && currentEpisode.episodes
            ? currentEpisode.episodes[0].season
            : undefined,
        position: 0,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        chronological:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.chronological
            : undefined,
        classification:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.classification
            : undefined,
        emotion:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.emotion
            : undefined,
        format:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.format
            : undefined,
        is_unit:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.isUnit
            : undefined,
        publication_date:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.publicationDate
            : undefined,
        seasons_list:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.seasonsList
            : undefined,
        header_mention:
          currentEpisode && currentEpisode.series && seriesLanguage
            ? currentEpisode.series.info[seriesLanguage].headerMention
            : undefined,
        context: "Playlist",
        episode_name:
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episodes[0].info[seriesLanguage].title
            : undefined,
        channel_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel._id
            : undefined,
        channel_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.name
            : undefined,
        channel_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.nameCode
            : undefined,
        channel_umbrella_id:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella._id
            : undefined,
        channel_umbrella_name:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.name
            : undefined,
        channel_umbrella_name_code:
          currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.channel
            ? currentEpisode.series.channel.umbrella.nameCode
            : undefined,
            has_rss_feed:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.info[seriesLanguage] &&
            currentEpisode.series.info[seriesLanguage].rssFeed
              ? true
              : false,
              child_content:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.isChild
                : undefined,
            episode_index: currentEpisode && currentEpisode.episodes
              ? currentEpisode.episodes[0].index
              : undefined,
            genre:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series.genre
                : undefined,
            total_length: currentEpisode && currentEpisode.episodes
              ? currentEpisode.episodes[0].length
              : undefined,
              recomm_id : sessionStorage.getItem("recommId") ? sessionStorage.getItem("recommId") : undefined
      });
    } else {
      if (currentEpisode && currentEpisode.episode) {
        pause(
          currentEpisode!.series.isChild,
          clicked && playerRef && playerRef.current
            ? "Next Episode Button Clicked"
            : "End of Episode",
          currentEpisode!.series.emotion
            ? currentEpisode!.series.emotion!.join()
            : undefined,
          currentEpisode.episode.info[seriesLanguage].title,
          currentEpisode.episode._id,
          currentEpisode.episode.season,
          currentEpisode!.series._id,
          currentEpisode!.series.info[seriesLanguage].title,
          profile!.isChild,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          currentEpisode!.series.mainImageId,
          clicked && playerRef && playerRef.current
            ? Math.round(playerRef.current.getCurrentTime())
            : Math.round(currentEpisode.episode.length),
          clicked && playerRef && playerRef.current
            ? Math.round(
                (playerRef.current.getCurrentTime() /
                  currentEpisode.episode.length +
                  Number.EPSILON) *
                  1000
              ) / 1000
            : 1,
          Math.round(currentEpisode.episode.length),
          Math.round(currentEpisode!.series?.length)
        );
      }
      const ac = new AbortController();
      const { signal } = ac;
      try {
        const historyPending = await sendProgressService(
          {
            userId: user._id,
            profileId: profile!._id!,
            seriesId: currentEpisode!.series._id,
            episodeId: currentEpisode!.episode!._id,
            start: playerRef.current!.getCurrentTime(),
            progress: playerRef.current!.getCurrentTime(),
          },
          user,
          profile!,
          signal
        );
        await historyPending.json();
      } catch (e) {}
      const next =
        currentEpisode!.episodes!.findIndex(
          (each) => each._id === currentEpisode!.episode!._id
        ) + 1;
      if (currentEpisode!.episodes![next]) {
        if (currentEpisode!.episode) {
          if (episodesHistory && episodesHistory[currentEpisode!.series._id]) {
            dispatch({
              type: Case.UPDATE_SERIES_EPISODE_HISTORY,
              payload: {
                id: currentEpisode!.series._id,
                episodeHistory: {
                  _id: currentEpisode!.episode._id,
                  length: currentEpisode!.episode.length,
                  season: currentEpisode!.episode.season,
                },
              },
            });
          } else {
            dispatch({
              type: Case.CREATE_EPISODE_HISTORY,
              payload: {
                id: currentEpisode!.series._id,
                episodeHistory: {
                  _id: currentEpisode!.episode._id,
                  length: currentEpisode!.episode.length,
                  season: currentEpisode!.episode.season,
                },
              },
            });
          }
        }
        dispatch({ type: Case.REINIT_IS_SEEKED_TO_HISTORY });

        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: user.token,
          },
        };

        const rest = currentEpisode!.episodes.slice(
          currentEpisode!.episodes.findIndex(
            (eachEpiode) =>
              currentEpisode!.episodes![next]._id === eachEpiode._id
          )
        );
        let restTimstamp: number = 0;
        rest.map((each) => (restTimstamp += each.length));
        console.log(restTimstamp);

        if (restTimstamp < 3600) {
          console.log({ dedans: restTimstamp });
          const playlistPending = await fetch(
            `${process.env.REACT_APP_API}/${userContentLanguage}/series/${
              currentEpisode!.episodes![next].seriesId
            }/next?profileId=${profile?._id}&duration=108000&episodeId=${
              currentEpisode!.episodes[next]._id
            }`,
            requestOptions
          );
          const playlist = await playlistPending.json();
          Promise.all(
            playlist.map(async (eachEpisode) => {
              const episodePending = await fetch(
                `${process.env.REACT_APP_API}/${userContentLanguage}/series/${currentEpisode!.episodes![next].seriesId}/episode/${eachEpisode._id}/`,
                requestOptions
              );
              const episode = await episodePending.json();
              return episode;
            })
          ).then(async (playlist: any) => {
            if (
              currentEpisode!.episodes![next].seriesId !==
              currentEpisode!.series._id
            ) {
              const seriesRes = await getSerie(
                currentEpisode!.episodes![next].seriesId
              );
              dispatch({
                type: Case.SET_CURRENT_EPISODE,
                payload: {
                  series: seriesRes,
                  episodes: playlist,
                  episode: currentEpisode!.episodes![next],
                  lng: currentEpisode!.lng,
                  uuid,
                },
              });
            } else {
              dispatch({
                type: Case.SET_CURRENT_EPISODE,
                payload: {
                  series: currentEpisode!.series,
                  episodes: playlist,
                  episode: currentEpisode!.episodes![next],
                  lng: currentEpisode!.lng,
                  uuid,
                },
              });
            }
          });
        } else {
          if (
            currentEpisode!.episodes![next].seriesId !==
            currentEpisode!.series._id
          ) {
            const seriesRes = await getSerie(
              currentEpisode!.episodes![next].seriesId
            );
            dispatch({
              type: Case.SET_CURRENT_EPISODE,
              payload: {
                series: seriesRes,
                episodes: currentEpisode!.episodes,
                episode: currentEpisode!.episodes![next],
                lng: currentEpisode!.lng,
                uuid,
              },
            });
          } else {
            dispatch({
              type: Case.SET_CURRENT_EPISODE,
              payload: {
                series: currentEpisode!.series,
                episodes: currentEpisode!.episodes,
                episode: currentEpisode!.episodes![next],
                lng: currentEpisode!.lng,
                uuid,
              },
            });
          }
        }
        window.analytics.track("Audio Content Started", {
          subscription: !!localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")!).subscription
            : 0,
          platform: "Web",
          session_id: uuid,
          series_id: currentEpisode!.series._id,
          episode_id:
            currentEpisode && currentEpisode.episodes
              ? currentEpisode!.episodes![next]._id
              : "Trailer",
          content_name:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.info[seriesLanguage].title
              : undefined,
          episode_length:
            currentEpisode && currentEpisode.episodes
              ? currentEpisode!.episodes![next].length
              : undefined,
          content_length:
            currentEpisode && currentEpisode.series
              ? Math.round(currentEpisode.series.length)
              : undefined,
          season:
            currentEpisode && currentEpisode.episodes
              ? currentEpisode!.episodes![next].season
              : undefined,
          position: 0,
          signup_type: localStorage.getItem("sy_signup_type")
            ? localStorage.getItem("sy_signup_type")
            : undefined,
          chronological:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.chronological
              : undefined,
          classification:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.classification
              : undefined,
          emotion:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.emotion
              : undefined,
          format:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.format
              : undefined,
          is_unit:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.isUnit
              : undefined,
          publication_date:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.publicationDate
              : undefined,
          seasons_list:
            currentEpisode && currentEpisode.series
              ? currentEpisode.series.seasonsList
              : undefined,
          header_mention:
            currentEpisode && currentEpisode.series && seriesLanguage
              ? currentEpisode.series.info[seriesLanguage].headerMention
              : undefined,
          context: "Playlist",
          episode_name:
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode.info[seriesLanguage].title
              : undefined,
          channel_id:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel._id
              : undefined,
          channel_name:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.name
              : undefined,
          channel_name_code:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.nameCode
              : undefined,
          channel_umbrella_id:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.umbrella._id
              : undefined,
          channel_umbrella_name:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.umbrella.name
              : undefined,
          channel_umbrella_name_code:
            currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.channel
              ? currentEpisode.series.channel.umbrella.nameCode
              : undefined,
              has_rss_feed:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.info[seriesLanguage] &&
              currentEpisode.series.info[seriesLanguage].rssFeed
                ? true
                : false,
                  child_content:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.isChild
                    : undefined,

                episode_index: currentEpisode && currentEpisode.episodes![next]
                  ? currentEpisode.episodes![next].index
                  : undefined,
                genre:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.genre
                    : undefined,
                total_length: currentEpisode && currentEpisode.episodes![next]
                  ? currentEpisode.episodes![next].length
                  : undefined,
                  recomm_id : sessionStorage.getItem("recommId") ? sessionStorage.getItem("recommId") : undefined
        });

        play(
          currentEpisode!.series.isChild,
          "Auto From Previous Episode",
          currentEpisode!.series.emotion
            ? currentEpisode!.series.emotion!.join()
            : undefined,
          currentEpisode!.episodes![next].info[seriesLanguage].title,
          currentEpisode!.episodes![next]._id,
          currentEpisode!.episodes![next].season,
          currentEpisode!.series._id,
          currentEpisode!.series.info[seriesLanguage].title,
          profile!.isChild,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          currentEpisode!.series.mainImageId,
          0,
          0,
          Math.round(currentEpisode!.episodes![next].length),
          Math.round(currentEpisode!.episodes![next].length)
        );
      } else if (!currentEpisode!.episodes![next]) {
        dispatch({
          type: Case.SET_CONTEXT,
          payload: { context: "playAutoFromEpisode" },
        });
        if (currentEpisode!.series.chronological === false) {
          if (currentEpisode!.episode!.season > 1) {
            if (
              episodes[currentEpisode!.series._id][
                currentEpisode!.episode!.season - 1
              ]
            ) {
              dispatch({ type: Case.REINIT_IS_SEEKED_TO_HISTORY });
              dispatch({
                type: Case.UPDATE_SERIES_EPISODE_HISTORY,
                payload: {
                  id: currentEpisode!.series._id,
                  episodeHistory: {
                    _id: episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season - 1
                    ][0]._id,
                    length:
                      episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season - 1
                      ][0].length,
                    season:
                      episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season - 1
                      ][0].season,
                  },
                },
              });

              const requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                  Authorization: user.token,
                },
              };
              const rest = currentEpisode!.series.chronological
                ? episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season - 1
                  ]
                    .filter(
                      (each) => new Date(each.publicationDate) <= new Date()
                    )
                    .slice(
                      episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season - 1
                      ]
                        .filter(
                          (each) => new Date(each.publicationDate) <= new Date()
                        )
                        .findIndex(
                          (eachEpiode) =>
                            episodes[currentEpisode!.series._id][
                              currentEpisode!.episode!.season - 1
                            ][0]._id === eachEpiode._id
                        )
                    )
                : episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season - 1
                  ]
                    .filter(
                      (each) => new Date(each.publicationDate) <= new Date()
                    )
                    .slice(
                      episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season - 1
                      ]
                        .filter(
                          (each) => new Date(each.publicationDate) <= new Date()
                        )
                        .findIndex(
                          (eachEpiode) =>
                            episodes[currentEpisode!.series._id][
                              currentEpisode!.episode!.season - 1
                            ][0]._id === eachEpiode._id
                        )
                    );
              let restTimstamp: number = 0;
              rest.map((each) => (restTimstamp += each.length));
              if (restTimstamp < 3600) {
                const playlistPending = await fetch(
                  `${process.env.REACT_APP_API}/${userContentLanguage}/series/${
                    episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season - 1
                    ][0].seriesId
                  }/next?profileId=${profile?._id}&duration=108000&episodeId=${
                    episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season - 1
                    ][0]._id
                  }`,
                  requestOptions
                );
                const playlist = await playlistPending.json();
                Promise.all(
                  playlist.map(async (eachEpisode) => {
                    const episodePending = await fetch(
                      `${process.env.REACT_APP_API}/${userContentLanguage}/series/${                    episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season - 1
                      ][0].seriesId}/episode/${eachEpisode._id}/`,
                      requestOptions
                    );
                    const episode = await episodePending.json();
                    return episode;
                  })
                ).then(async (playlist: any) => {
                  if (
                    episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season - 1
                    ][0].seriesId !== currentEpisode!.series._id
                  ) {
                    const seriesRes = await getSerie(
                      episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season - 1
                      ][0].seriesId
                    );
                    dispatch({
                      type: Case.SET_CURRENT_EPISODE,
                      payload: {
                        series: seriesRes,
                        episodes: playlist,
                        episode:
                          episodes[currentEpisode!.series._id][
                            currentEpisode!.episode!.season - 1
                          ][0],
                        lng: currentEpisode!.lng,
                        uuid,
                      },
                    });
                  } else {
                    dispatch({
                      type: Case.SET_CURRENT_EPISODE,
                      payload: {
                        series: currentEpisode!.series,
                        episodes: playlist,
                        episode:
                          episodes[currentEpisode!.series._id][
                            currentEpisode!.episode!.season - 1
                          ][0],
                        lng: currentEpisode!.lng,
                        uuid,
                      },
                    });
                  }
                });
              } else {
                if (
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season - 1
                  ][0].seriesId !== currentEpisode!.series._id
                ) {
                  const seriesRes = await getSerie(
                    episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season - 1
                    ][0].seriesId
                  );
                  dispatch({
                    type: Case.SET_CURRENT_EPISODE,
                    payload: {
                      series: seriesRes,
                      episodes: currentEpisode!.series.chronological
                        ? episodes[currentEpisode!.series._id][
                            currentEpisode!.episode!.season - 1
                          ].filter(
                            (each) =>
                              new Date(each.publicationDate) <= new Date()
                          )
                        : episodes[currentEpisode!.series._id][
                            currentEpisode!.episode!.season - 1
                          ].filter(
                            (each) =>
                              new Date(each.publicationDate) <= new Date()
                          ),
                      episode:
                        episodes[currentEpisode!.series._id][
                          currentEpisode!.episode!.season - 1
                        ][0],
                      lng: currentEpisode!.lng,
                      uuid,
                    },
                  });
                } else {
                  dispatch({
                    type: Case.SET_CURRENT_EPISODE,
                    payload: {
                      series: currentEpisode!.series,
                      episodes: currentEpisode!.series.chronological
                        ? episodes[currentEpisode!.series._id][
                            currentEpisode!.episode!.season - 1
                          ].filter(
                            (each) =>
                              new Date(each.publicationDate) <= new Date()
                          )
                        : episodes[currentEpisode!.series._id][
                            currentEpisode!.episode!.season - 1
                          ].filter(
                            (each) =>
                              new Date(each.publicationDate) <= new Date()
                          ),
                      episode:
                        episodes[currentEpisode!.series._id][
                          currentEpisode!.episode!.season - 1
                        ][0],
                      lng: currentEpisode!.lng,
                      uuid,
                    },
                  });
                }
              }
              window.analytics.track("Audio Content Started", {
                subscription: !!localStorage.getItem("user")
                  ? JSON.parse(localStorage.getItem("user")!).subscription
                  : 0,
                platform: "Web",
                session_id: uuid,
                series_id:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series._id
                    : undefined,
                episode_id:
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season - 1
                  ][0]._id,
                content_name:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.info[seriesLanguage].title
                    : undefined,
                episode_length:
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season - 1
                  ][0].length,
                content_length:
                  currentEpisode && currentEpisode.series
                    ? Math.round(currentEpisode.series.length)
                    : undefined,
                season:
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season - 1
                  ][0].season,
                position: 0,
                signup_type: localStorage.getItem("sy_signup_type")
                  ? localStorage.getItem("sy_signup_type")
                  : undefined,
                chronological:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.chronological
                    : undefined,
                classification:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.classification
                    : undefined,
                emotion:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.emotion
                    : undefined,
                format:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.format
                    : undefined,
                is_unit:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.isUnit
                    : undefined,
                publication_date:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.publicationDate
                    : undefined,
                seasons_list:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.seasonsList
                    : undefined,
                header_mention:
                  currentEpisode && currentEpisode.series && seriesLanguage
                    ? currentEpisode.series.info[seriesLanguage].headerMention
                    : undefined,
                context: "Playlist",
                episode_name:
                  currentEpisode && currentEpisode.episode
                    ? currentEpisode.episode.info[seriesLanguage].title
                    : undefined,
                channel_id:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel._id
                    : undefined,
                channel_name:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.name
                    : undefined,
                channel_name_code:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.nameCode
                    : undefined,
                channel_umbrella_id:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.umbrella._id
                    : undefined,
                channel_umbrella_name:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.umbrella.name
                    : undefined,
                channel_umbrella_name_code:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.umbrella.nameCode
                    : undefined,
                    has_rss_feed:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.info[seriesLanguage] &&
                    currentEpisode.series.info[seriesLanguage].rssFeed
                      ? true
                      : false,
                      child_content:
                      currentEpisode && currentEpisode.series
                        ? currentEpisode.series.isChild
                        : undefined,
    
                    episode_index: episodes && episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season - 1
                    ][0]
                      ? episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season - 1
                      ][0].index
                      : undefined,
                    genre:
                      currentEpisode && currentEpisode.series
                        ? currentEpisode.series.genre
                        : undefined,
                    total_length: episodes && episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season - 1
                    ][0]
                      ? episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season - 1
                      ][0].length
                      : undefined,
                      recomm_id : sessionStorage.getItem("recommId") ? sessionStorage.getItem("recommId") : undefined
              });
              play(
                currentEpisode!.series.isChild,
                "Auto From Previous Episode",
                currentEpisode!.series.emotion
                  ? currentEpisode!.series.emotion!.join()
                  : undefined,
                episodes[currentEpisode!.series._id][
                  currentEpisode!.episode!.season - 1
                ][0].info[seriesLanguage].title,
                episodes[currentEpisode!.series._id][
                  currentEpisode!.episode!.season - 1
                ][0]._id,
                episodes[currentEpisode!.series._id][
                  currentEpisode!.episode!.season - 1
                ][0].season,
                currentEpisode!.series._id,
                currentEpisode!.series.info[seriesLanguage].title,
                profile!.isChild,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                currentEpisode!.series.mainImageId,
                0,
                0,
                Math.round(
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season - 1
                  ][0].length
                ),
                Math.round(
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season - 1
                  ][0].length
                )
              );
            } else {
              const newSeason = await getSeason(
                currentEpisode!.series._id,
                currentEpisode!.episode!.season - 1,
                currentEpisode!.series.chronological
              );

              if (newSeason.error) {
                return;
              } else {
                dispatch({ type: Case.REINIT_IS_SEEKED_TO_HISTORY });
                dispatch({
                  type: Case.SET_CONTEXT,
                  payload: { context: "playAutoFromEpisode" },
                });
                dispatch({
                  type: Case.UPDATE_SERIES_EPISODE_HISTORY,
                  payload: {
                    id: currentEpisode!.series._id,
                    episodeHistory: {
                      _id: newSeason.episodes![0]._id,
                      length: newSeason.episodes![0].length,
                      season: newSeason.episodes![0].season,
                    },
                  },
                });

                const requestOptions = {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: user.token,
                  },
                };

                const rest = newSeason.episodes
                  ? newSeason.episodes
                      .filter(
                        (each) => new Date(each.publicationDate) <= new Date()
                      )
                      .slice(
                        newSeason.episodes
                          .filter(
                            (each) =>
                              new Date(each.publicationDate) <= new Date()
                          )
                          .findIndex(
                            (eachEpiode) =>
                              newSeason.episodes![0]._id === eachEpiode._id
                          )
                      )
                  : [];
                let restTimstamp: number = 0;
                rest.map((each) => (restTimstamp += each.length));
                if (restTimstamp < 3600) {
                  const playlistPending = await fetch(
                    `${
                      process.env.REACT_APP_API
                    }/${userContentLanguage}/series/${
                      newSeason.episodes![0].seriesId
                    }/next?profileId=${
                      profile?._id
                    }&duration=108000&episodeId=${newSeason.episodes![0]._id}`,
                    requestOptions
                  );
                  const playlist = await playlistPending.json();
                  Promise.all(
                    playlist.map(async (eachEpisode) => {
                      const episodePending = await fetch(
                        `${process.env.REACT_APP_API}/${userContentLanguage}/series/${newSeason.episodes![0].seriesId}/episode/${eachEpisode._id}/`,
                        requestOptions
                      );
                      const episode = await episodePending.json();
                      return episode;
                    })
                  ).then(async (playlist: any) => {
                    if (
                      newSeason.episodes![0].seriesId !==
                      currentEpisode!.series._id
                    ) {
                      const seriesRes = await getSerie(
                        newSeason.episodes![0].seriesId
                      );
                      dispatch({
                        type: Case.SET_CURRENT_EPISODE,
                        payload: {
                          series: seriesRes,
                          episodes: playlist,
                          episode: newSeason.episodes![0],
                          lng: currentEpisode!.lng,
                          uuid,
                        },
                      });
                    } else {
                      dispatch({
                        type: Case.SET_CURRENT_EPISODE,
                        payload: {
                          series: currentEpisode!.series,
                          episodes: playlist,
                          episode: newSeason.episodes![0],
                          lng: currentEpisode!.lng,
                          uuid,
                        },
                      });
                    }
                  });
                } else {
                  if (
                    newSeason.episodes![0].seriesId !==
                    currentEpisode!.series._id
                  ) {
                    const seriesRes = await getSerie(
                      newSeason.episodes![0].seriesId
                    );
                    dispatch({
                      type: Case.SET_CURRENT_EPISODE,
                      payload: {
                        series: seriesRes,
                        episodes:
                          newSeason.episodes &&
                          currentEpisode!.series.chronological
                            ? newSeason.episodes.filter(
                                (each) =>
                                  new Date(each.publicationDate) <= new Date()
                              )
                            : newSeason.episodes
                            ? newSeason.episodes.filter(
                                (each) =>
                                  new Date(each.publicationDate) <= new Date()
                              )
                            : undefined,

                        episode: newSeason.episodes![0],
                        lng: currentEpisode!.lng,
                        uuid,
                      },
                    });
                  } else {
                    dispatch({
                      type: Case.SET_CURRENT_EPISODE,
                      payload: {
                        series: currentEpisode!.series,
                        episodes:
                          newSeason.episodes &&
                          currentEpisode!.series.chronological
                            ? newSeason.episodes.filter(
                                (each) =>
                                  new Date(each.publicationDate) <= new Date()
                              )
                            : newSeason.episodes
                            ? newSeason.episodes.filter(
                                (each) =>
                                  new Date(each.publicationDate) <= new Date()
                              )
                            : undefined,

                        episode: newSeason.episodes![0],
                        lng: currentEpisode!.lng,
                        uuid,
                      },
                    });
                  }
                }
                window.analytics.track("Audio Content Started", {
                  subscription: !!localStorage.getItem("user")
                    ? JSON.parse(localStorage.getItem("user")!).subscription
                    : 0,
                  platform: "Web",
                  session_id: uuid,
                  series_id:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series._id
                      : undefined,
                  episode_id: newSeason.episodes![0]._id,
                  content_name:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.info[seriesLanguage].title
                      : undefined,
                  episode_length: newSeason.episodes![0].length,
                  content_length:
                    currentEpisode && currentEpisode.series
                      ? Math.round(currentEpisode.series.length)
                      : undefined,
                  season: newSeason.episodes![0].season,
                  position: 0,
                  signup_type: localStorage.getItem("sy_signup_type")
                    ? localStorage.getItem("sy_signup_type")
                    : undefined,
                  chronological:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.chronological
                      : undefined,
                  classification:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.classification
                      : undefined,
                  emotion:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.emotion
                      : undefined,
                  format:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.format
                      : undefined,
                  is_unit:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.isUnit
                      : undefined,
                  publication_date:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.publicationDate
                      : undefined,
                  seasons_list:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.seasonsList
                      : undefined,
                  header_mention:
                    currentEpisode && currentEpisode.series && seriesLanguage
                      ? currentEpisode.series.info[seriesLanguage].headerMention
                      : undefined,
                  context: "Playlist",
                  episode_name:
                    currentEpisode && currentEpisode.episode
                      ? currentEpisode.episode.info[seriesLanguage].title
                      : undefined,
                  channel_id:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel._id
                      : undefined,
                  channel_name:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.name
                      : undefined,
                  channel_name_code:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.nameCode
                      : undefined,
                  channel_umbrella_id:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.umbrella._id
                      : undefined,
                  channel_umbrella_name:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.umbrella.name
                      : undefined,
                  channel_umbrella_name_code:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.umbrella.nameCode
                      : undefined,
                      has_rss_feed:
                      currentEpisode &&
                      currentEpisode.series &&
                      currentEpisode.series.info[seriesLanguage] &&
                      currentEpisode.series.info[seriesLanguage].rssFeed
                        ? true
                        : false,
                        child_content:
                        currentEpisode && currentEpisode.series
                          ? currentEpisode.series.isChild
                          : undefined,
                      episode_index:  newSeason.episodes 
                        ? newSeason.episodes![0].index
                        : undefined,
                      genre:
                        currentEpisode && currentEpisode.series
                          ? currentEpisode.series.genre
                          : undefined,
                      total_length:  newSeason.episodes![0]
                        ?  newSeason.episodes![0].length
                        : undefined,
                        recomm_id : sessionStorage.getItem("recommId") ? sessionStorage.getItem("recommId") : undefined
                });
              }
              play(
                currentEpisode!.series.isChild,
                "Auto From Previous Episode",
                currentEpisode!.series.emotion
                  ? currentEpisode!.series.emotion!.join()
                  : undefined,
                newSeason.episodes![0].info[seriesLanguage].title,
                newSeason.episodes![0]._id,
                newSeason.episodes![0].season,
                currentEpisode!.series._id,
                currentEpisode!.series.info[seriesLanguage].title,
                profile!.isChild,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                currentEpisode!.series.mainImageId,
                0,
                0,
                Math.round(newSeason.episodes![0].length),
                Math.round(newSeason.episodes![0].length)
              );
            }
          } else {
            return dispatch({
              type: Case.SET_CURRENT_EPISODE_UNDEFINED,
            });
          }
        }
        if (currentEpisode!.series.chronological === true) {
          if (
            currentEpisode!.episode!.season <
            Math.max(...currentEpisode!.series.seasonsList)
          ) {
            if (
              episodes[currentEpisode!.series._id][
                currentEpisode!.episode!.season + 1
              ]
            ) {
              dispatch({
                type: Case.UPDATE_SERIES_EPISODE_HISTORY,
                payload: {
                  id: currentEpisode!.series._id,
                  episodeHistory: {
                    _id: episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season + 1
                    ][0]._id,
                    length:
                      episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season + 1
                      ][0].length,
                    season:
                      episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season + 1
                      ][0].season,
                  },
                },
              });
              dispatch({ type: Case.REINIT_IS_SEEKED_TO_HISTORY });

              const requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                  Authorization: user.token,
                },
              };

              const rest = episodes[currentEpisode!.series._id][
                currentEpisode!.episode!.season + 1
              ]
                .filter((each) => new Date(each.publicationDate) <= new Date())
                .slice(
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season + 1
                  ]
                    .filter(
                      (each) => new Date(each.publicationDate) <= new Date()
                    )
                    .findIndex(
                      (eachEpiode) =>
                        episodes[currentEpisode!.series._id][
                          currentEpisode!.episode!.season + 1
                        ][0]._id === eachEpiode._id
                    )
                );
              let restTimstamp: number = 0;
              rest.map((each) => (restTimstamp += each.length));
              if (restTimstamp < 3600) {
                const playlistPending = await fetch(
                  `${process.env.REACT_APP_API}/${userContentLanguage}/series/${
                    episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season + 1
                    ][0].seriesId
                  }/next?profileId=${profile?._id}&duration=108000&episodeId=${
                    episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season + 1
                    ][0]._id
                  }`,
                  requestOptions
                );
                const playlist = await playlistPending.json();
                Promise.all(
                  playlist.map(async (eachEpisode) => {
                    const episodePending = await fetch(
                      `${process.env.REACT_APP_API}/${userContentLanguage}/series/${episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season + 1
                      ][0].seriesId}/episode/${eachEpisode._id}/`,
                      requestOptions
                    );
                    const episode = await episodePending.json();
                    return episode;
                  })
                ).then(async (playlist: any) => {
                  if (
                    episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season + 1
                    ][0].seriesId !== currentEpisode!.series._id
                  ) {
                    const seriesRes = await getSerie(
                      episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season + 1
                      ][0].seriesId
                    );
                    dispatch({
                      type: Case.SET_CURRENT_EPISODE,
                      payload: {
                        series: seriesRes,
                        episodes: playlist,
                        episode:
                          episodes[currentEpisode!.series._id][
                            currentEpisode!.episode!.season + 1
                          ][0],
                        lng: currentEpisode!.lng,
                        uuid,
                      },
                    });
                  } else {
                    dispatch({
                      type: Case.SET_CURRENT_EPISODE,
                      payload: {
                        series: currentEpisode!.series,
                        episodes: playlist,
                        episode:
                          episodes[currentEpisode!.series._id][
                            currentEpisode!.episode!.season + 1
                          ][0],
                        lng: currentEpisode!.lng,
                        uuid,
                      },
                    });
                  }
                });
              } else {
                if (
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season + 1
                  ][0].seriesId !== currentEpisode!.series._id
                ) {
                  const seriesRes = await getSerie(
                    episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season + 1
                    ][0].seriesId
                  );
                dispatch({
                  type: Case.SET_CURRENT_EPISODE,
                  payload: {
                    series: seriesRes,
                    episodes: episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season + 1
                    ].filter(
                      (each) => new Date(each.publicationDate) <= new Date()
                    ),
                    episode:
                      episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season + 1
                      ][0],
                    lng: currentEpisode!.lng,
                    uuid,
                  },
                });}else {
                  dispatch({
                    type: Case.SET_CURRENT_EPISODE,
                    payload: {
                      series: currentEpisode!.series,
                      episodes: episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season + 1
                      ].filter(
                        (each) => new Date(each.publicationDate) <= new Date()
                      ),
                      episode:
                        episodes[currentEpisode!.series._id][
                          currentEpisode!.episode!.season + 1
                        ][0],
                      lng: currentEpisode!.lng,
                      uuid,
                    },
                  })
                }
              }
              window.analytics.track("Audio Content Started", {
                platform: "Web",
                session_id: uuid,
                series_id:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series._id
                    : undefined,
                episode_id:
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season + 1
                  ][0]._id,
                content_name:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.info[seriesLanguage].title
                    : undefined,
                episode_length:
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season + 1
                  ][0].length,
                content_length:
                  currentEpisode && currentEpisode.series
                    ? Math.round(currentEpisode.series.length)
                    : undefined,
                season:
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season + 1
                  ][0].season,
                position: 0,
                signup_type: localStorage.getItem("sy_signup_type")
                  ? localStorage.getItem("sy_signup_type")
                  : undefined,
                chronological:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.chronological
                    : undefined,
                classification:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.classification
                    : undefined,
                emotion:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.emotion
                    : undefined,
                format:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.format
                    : undefined,
                is_unit:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.isUnit
                    : undefined,
                publication_date:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.publicationDate
                    : undefined,
                seasons_list:
                  currentEpisode && currentEpisode.series
                    ? currentEpisode.series.seasonsList
                    : undefined,
                header_mention:
                  currentEpisode && currentEpisode.series && seriesLanguage
                    ? currentEpisode.series.info[seriesLanguage].headerMention
                    : undefined,
                context: "Playlist",
                episode_name:
                  currentEpisode && currentEpisode.episode
                    ? currentEpisode.episode.info[seriesLanguage].title
                    : undefined,
                channel_id:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel._id
                    : undefined,
                channel_name:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.name
                    : undefined,
                channel_name_code:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.nameCode
                    : undefined,
                channel_umbrella_id:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.umbrella._id
                    : undefined,
                channel_umbrella_name:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.umbrella.name
                    : undefined,
                channel_umbrella_name_code:
                  currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.channel
                    ? currentEpisode.series.channel.umbrella.nameCode
                    : undefined,
                    has_rss_feed:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.info[seriesLanguage] &&
                    currentEpisode.series.info[seriesLanguage].rssFeed
                      ? true
                      : false,
                      child_content:
                      currentEpisode && currentEpisode.series
                        ? currentEpisode.series.isChild
                        : undefined,
                    episode_index:   episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season + 1
                    ][0]
                      ?  episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season + 1
                      ][0].index
                      : undefined,
                    genre:
                      currentEpisode && currentEpisode.series
                        ? currentEpisode.series.genre
                        : undefined,
                    total_length:  episodes[currentEpisode!.series._id][
                      currentEpisode!.episode!.season + 1
                    ][0]
                      ?   episodes[currentEpisode!.series._id][
                        currentEpisode!.episode!.season + 1
                      ][0].length
                      : undefined,
                      recomm_id : sessionStorage.getItem("recommId") ? sessionStorage.getItem("recommId") : undefined
              });
              play(
                currentEpisode!.series.isChild,
                "Auto From Previous Episode",
                currentEpisode!.series.emotion
                  ? currentEpisode!.series.emotion!.join()
                  : undefined,
                episodes[currentEpisode!.series._id][
                  currentEpisode!.episode!.season + 1
                ][0].info[seriesLanguage].title,
                episodes[currentEpisode!.series._id][
                  currentEpisode!.episode!.season + 1
                ][0]._id,
                episodes[currentEpisode!.series._id][
                  currentEpisode!.episode!.season + 1
                ][0].season,
                currentEpisode!.series._id,
                currentEpisode!.series.info[seriesLanguage].title,
                profile!.isChild,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                currentEpisode!.series.mainImageId,
                0,
                0,
                Math.round(
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season + 1
                  ][0].length
                ),
                Math.round(
                  episodes[currentEpisode!.series._id][
                    currentEpisode!.episode!.season + 1
                  ][0].length
                )
              );
            } else {
              const newSeason = await getSeason(
                currentEpisode!.series._id,
                currentEpisode!.episode!.season + 1,
                currentEpisode!.series.chronological
              );
              if (newSeason.error) {
                return;
              } else {
                dispatch({
                  type: Case.UPDATE_SERIES_EPISODE_HISTORY,
                  payload: {
                    id: currentEpisode!.series._id,
                    episodeHistory: {
                      _id: newSeason.episodes![0]._id,
                      length: newSeason.episodes![0].length,
                      season: newSeason.episodes![0].season,
                    },
                  },
                });
                dispatch({ type: Case.REINIT_IS_SEEKED_TO_HISTORY });

                const requestOptions = {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: user.token,
                  },
                };

                const rest = newSeason.episodes
                  ? newSeason.episodes
                      .filter(
                        (each) => new Date(each.publicationDate) <= new Date()
                      )
                      .slice(
                        newSeason.episodes
                          .filter(
                            (each) =>
                              new Date(each.publicationDate) <= new Date()
                          )
                          .findIndex(
                            (eachEpiode) =>
                              newSeason.episodes![0]._id === eachEpiode._id
                          )
                      )
                  : [];
                let restTimstamp: number = 0;
                rest.map((each) => (restTimstamp += each.length));
                if (restTimstamp < 3600) {
                  const playlistPending = await fetch(
                    `${
                      process.env.REACT_APP_API
                    }/${userContentLanguage}/series/${
                      newSeason.episodes![0].seriesId
                    }/next?profileId=${
                      profile?._id
                    }&duration=108000&episodeId=${newSeason.episodes![0]._id}`,
                    requestOptions
                  );
                  const playlist = await playlistPending.json();
                  Promise.all(
                    playlist.map(async (eachEpisode) => {
                      const episodePending = await fetch(
                        `${process.env.REACT_APP_API}/${userContentLanguage}/series/${newSeason.episodes![0].seriesId}/episode/${eachEpisode._id}/`,
                        requestOptions
                      );
                      const episode = await episodePending.json();
                      return episode;
                    })
                  ).then( async (playlist: any) => {
                    if (
                      newSeason.episodes![0].seriesId !==
                      currentEpisode!.series._id
                    ) {
                      const seriesRes = await getSerie(
                        newSeason.episodes![0].seriesId
                      );
                    dispatch({
                      type: Case.SET_CURRENT_EPISODE,
                      payload: {
                        series: seriesRes,
                        episodes: playlist,
                        episode: newSeason.episodes![0],
                        lng: currentEpisode!.lng,
                        uuid,
                      },
                    });}
                    else {
                      dispatch({
                        type: Case.SET_CURRENT_EPISODE,
                        payload: {
                          series: currentEpisode!.series,
                          episodes: playlist,
                          episode: newSeason.episodes![0],
                          lng: currentEpisode!.lng,
                          uuid,
                        },
                      })
                    }
                  });
                } else {
                  if (
                    newSeason.episodes![0].seriesId !==
                    currentEpisode!.series._id
                  ) {
                    const seriesRes = await getSerie(
                      newSeason.episodes![0].seriesId
                    );
                  dispatch({
                    type: Case.SET_CURRENT_EPISODE,
                    payload: {
                      series: seriesRes,
                      episodes:
                        newSeason.episodes &&
                        newSeason.episodes.filter(
                          (each) => new Date(each.publicationDate) <= new Date()
                        ),
                      episode: newSeason.episodes![0],
                      lng: currentEpisode!.lng,
                    },
                  });}
                  else {
                    dispatch({
                      type: Case.SET_CURRENT_EPISODE,
                      payload: {
                        series: currentEpisode!.series,
                        episodes:
                          newSeason.episodes &&
                          newSeason.episodes.filter(
                            (each) => new Date(each.publicationDate) <= new Date()
                          ),
                        episode: newSeason.episodes![0],
                        lng: currentEpisode!.lng,
                      },
                    })
                  }
                }
                window.analytics.track("Audio Content Started", {
                  subscription: !!localStorage.getItem("user")
                    ? JSON.parse(localStorage.getItem("user")!).subscription
                    : 0,
                  platform: "Web",
                  session_id: uuid,
                  series_id:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series._id
                      : undefined,
                  episode_id: newSeason.episodes![0]._id,
                  content_name:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.info[seriesLanguage].title
                      : undefined,
                  episode_length: newSeason.episodes![0].length,
                  content_length:
                    currentEpisode && currentEpisode.series
                      ? Math.round(currentEpisode.series.length)
                      : undefined,
                  season: newSeason.episodes![0].season,
                  position: 0,
                  signup_type: localStorage.getItem("sy_signup_type")
                    ? localStorage.getItem("sy_signup_type")
                    : undefined,
                  chronological:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.chronological
                      : undefined,
                  classification:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.classification
                      : undefined,
                  emotion:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.emotion
                      : undefined,
                  format:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.format
                      : undefined,
                  is_unit:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.isUnit
                      : undefined,
                  publication_date:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.publicationDate
                      : undefined,
                  seasons_list:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.seasonsList
                      : undefined,
                  header_mention:
                    currentEpisode && currentEpisode.series && seriesLanguage
                      ? currentEpisode.series.info[seriesLanguage].headerMention
                      : undefined,
                  context: "Playlist",
                  episode_name:
                    currentEpisode && currentEpisode.episode
                      ? currentEpisode.episode.info[seriesLanguage].title
                      : undefined,
                  channel_id:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel._id
                      : undefined,
                  channel_name:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.name
                      : undefined,
                  channel_name_code:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.nameCode
                      : undefined,
                  channel_umbrella_id:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.umbrella._id
                      : undefined,
                  channel_umbrella_name:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.umbrella.name
                      : undefined,
                  channel_umbrella_name_code:
                    currentEpisode &&
                    currentEpisode.series &&
                    currentEpisode.series.channel
                      ? currentEpisode.series.channel.umbrella.nameCode
                      : undefined,
                      has_rss_feed:
                      currentEpisode &&
                      currentEpisode.series &&
                      currentEpisode.series.info[seriesLanguage] &&
                      currentEpisode.series.info[seriesLanguage].rssFeed
                        ? true
                        : false,
                        child_content:
                        currentEpisode && currentEpisode.series
                          ? currentEpisode.series.isChild
                          : undefined,
                      episode_index:  newSeason.episodes 
                        ? newSeason.episodes![0].index
                        : undefined,
                      genre:
                        currentEpisode && currentEpisode.series
                          ? currentEpisode.series.genre
                          : undefined,
                      total_length:  newSeason.episodes![0]
                        ?  newSeason.episodes![0].length
                        : undefined,
                        recomm_id : sessionStorage.getItem("recommId") ? sessionStorage.getItem("recommId") : undefined
                });
                play(
                  currentEpisode!.series.isChild,
                  "Auto From Previous Episode",
                  currentEpisode!.series.emotion
                    ? currentEpisode!.series.emotion!.join()
                    : undefined,
                  newSeason.episodes![0].info[seriesLanguage].title,
                  newSeason.episodes![0]._id,
                  newSeason.episodes![0].season,
                  currentEpisode!.series._id,
                  currentEpisode!.series.info[seriesLanguage].title,
                  profile!.isChild,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  currentEpisode!.series.mainImageId,
                  0,
                  0,
                  Math.round(newSeason.episodes![0].length),
                  Math.round(newSeason.episodes![0].length)
                );
              }
            }
          } else {
            return dispatch({
              type: Case.SET_CURRENT_EPISODE_UNDEFINED,
            });
          }
        }
      }
    }
  };
  return { nextEpisode, setEpisodeEndSleep, episodeEndSleep };
};
