import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import ButtonOnClick from "../components/ButtonOnClick";
import Loader from "../components/Loader";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";
import EventStore from "../stores/AmplitudeStore";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";

export default function SsoSfr(): ReactElement {
  const location = useLocation();
  const { nextUrl } = useSelector((state: StoreType) => ({
    ...state.RouterReducer,
  }));
  const history = useHistory();
  const { userSignIn, userSignUp } = EventStore();
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const [isNoSfrSubscription, setIsNoSfrSubscription] =
    useState<boolean>(false);
  useEffect(() => {
    const signinSfr = async () => {
      const correlationId: string = new URLSearchParams(location.search).get(
        "correlation_id"
      )!;
      const code: string = new URLSearchParams(location.search).get("code")!;
      if (
        (window as any) &&
        !!(window as any).webkit &&
        !!(window as any).webkit.messageHandlers
      ) {
        (window as any).webkit.messageHandlers.jsMessageHandler.postMessage(
          JSON.stringify({
            locale: "fr",
            originUrl: "https://app.sybel.co/sso-sfr",
            sfrCode: code,
            correlationId,
            codeChallenge: window.localStorage.getItem("sfr_c_rc"),
          }),
          "*"
        );
        return // Yes, exit here because android handles next steps.
      }
      console.log("SSO-SFR");
      console.log({ correlationId, sfrCode: code });
      window.postMessage(
        {
          locale: "fr",
          originUrl: "https://app.sybel.co/sso-sfr",
          sfrCode: code,
          correlationId,
          codeChallenge: window.localStorage.getItem("sfr_c_rc"),
        },
        "*"
      );
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          originUrl: "https://app.sybel.co/sso-sfr",
          locale: "fr",
          sfrCode: code,
          correlationId: correlationId,
          codeChallenge: window.localStorage.getItem("sfr_c_rc"),
        }),
      };
      console.log(requestOptions);

      try {
        const sfrPending = await fetch(
          `${process.env.REACT_APP_API}/auth/ssoSfr`,
          requestOptions
        );
        const sfrResponse:
          | {
              user: {
                _id: string;
                coupons: [];
                email: string;
                emailVerified: boolean;
                existed: boolean;
                token: string;
              };
            }
          | any = await sfrPending.json();
        if (
          sfrPending.status === 502 &&
          sfrResponse.message &&
          sfrResponse.message ===
            "ERROR: pims_id null, user not subscribed to SFR"
        ) {
          return setIsNoSfrSubscription(true);
        }
        if (sfrResponse.user.existed) {
          console.log({ guiguiReponseUserExsisted: sfrResponse });

          stableDispatch({
            type: Case.UPDATE_USER,
            payload: { user: sfrResponse.user },
          });
          localStorage.setItem("user", JSON.stringify(sfrResponse.user));

          userSignIn("SFR", nextUrl ? nextUrl : "Default");
          localStorage.setItem("sy_signup_type", "SFR");
          window.analytics.group(sfrResponse.user._id, {
            email: sfrResponse.user.email,
            email_verified: sfrResponse.user.emailVerified,
          });
          window.analytics.track("Signed In", {
            signup_type: "SFR",
            email: sfrResponse.user.email,
            platform: "Web",
            subscription: !!localStorage.getItem("user")
              ? JSON.parse(localStorage.getItem("user")!).subscription
              : 0,
          });
          history.push(nextUrl ? nextUrl : "/choose-profile");
        } else {
          console.log({ guiguiReponseUserDoNotExsisted: sfrResponse });
          stableDispatch({
            type: Case.UPDATE_USER,
            payload: { user: sfrResponse.user },
          });
          localStorage.setItem("user", JSON.stringify(sfrResponse.user));
          localStorage.setItem("sy_signup_type", "SFR");
          window.analytics.group(sfrResponse.user._id, {
            email: sfrResponse.user.email,
            email_verified: sfrResponse.user.emailVerified,
          });

          window.analytics.track("Signed Up", {
            signup_type: "SFR",
            email: sfrResponse.user.email,
            platform: "Web",
            subscription: !!localStorage.getItem("user")
              ? JSON.parse(localStorage.getItem("user")!).subscription
              : 0,
          });
          console.log({ localStorage: JSON.stringify(sfrResponse.user) });

          userSignUp("SFR", nextUrl ? nextUrl : "Default");
          console.log("et BAM redirection");
          /*           history.push("/setup-profile", { nextUrl, isLegit: true }); */
        }
      } catch (e) {
        console.log(e);

        toast(language("codeError"), notificationsParam);
        return history.push("/partner-connect");
      }
    };

    signinSfr();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className="loading-settings"
        style={{
          position: "relative",
          zIndex: 9999999,
          userSelect: "auto",
          pointerEvents: "auto",
          padding: "40px 20px",
        }}
      >
        {isNoSfrSubscription ? (
          <>
            <Loader customeClassName="splash-loader" fix />
            <img
              style={{ animation: "none" }}
              src="/image/logo/brand.svg"
              alt="Sybel Compagny Name"
            />
            <p style={{ color: "#fff", marginTop: "50px" }}>
              Vous n’êtes pas abonné à l’offre Sybel via notre partenaire SFR,
              nous ne pouvons pas vous identifier.
              <br />
              Vérifiez votre souscription sur votre espace client SFR, ou
              contactez-nous à support@sybel.co
            </p>
            <ButtonOnClick
              cta={language("back")}
              onClick={() => history.push("/signup")}
              customClassName="sso-back-button"
            />
          </>
        ) : (
          <>
            <Loader customeClassName="splash-loader" />
            <img src="/image/logo/brand.svg" alt="Sybel Compagny Name" />
          </>
        )}
      </div>
    </>
  );
}
