import React, { ReactElement } from "react";
import { language } from "../stores/18nStore";
import Loader from "./Loader";

interface Props {
  loading: boolean;
  cta: string;
  className: string;
  style: React.CSSProperties;
  disabledStyle: React.CSSProperties;
  value: Object;
}

export default function SubmitButtonConditions({
  loading,
  cta,
  className,
  value,
  style,
  disabledStyle,
}: Props): ReactElement {
  return (
    <button
      type="submit"
      style={
        Object.keys(value).some(function (key) {
          return value[key] === "";
        })
          ? disabledStyle
          : style
      }
      disabled={
        loading ||
        Object.keys(value).some(function (key) {
          return value[key] === "";
        })
      }
      className={className}
      aria-disabled={
        loading ||
        Object.keys(value).some(function (key) {
          return value[key] === "";
        })
      }
    >
      {loading ? <Loader /> : `${language(cta)}`}
    </button>
  );
}
