import Regex from "./Regex";

export default function authFormValidator(form: any): { error?: string } {
  if (
    form.userName === "" ||
    form.email === "" ||
    form.emailconfirmation === "" ||
    form.password === "" ||
    form.confirmpassword === "" ||
    form.oldPassword === "" ||
    form.newPassword === "" ||
    form.newPasswordConfirmation === "" ||
    form.pseudo === ""
    || form.coupon === ""
  ) {
    return { error: "empty" };
  } else if (form.emailconfirmation && form.emailconfirmation !== form.email) {
    return { error: "signupFailIdenticalMail" };
  } else if (form.email && !Regex.emailSchema.test(form.email)) {
    return { error: "signupFailMail" };
  } else if (
    (form.password && form.password.length < 6) ||
    (form.newPassword && form.newPassword.length < 6) ||
    (form.oldPassword && form.oldPassword.length < 6)
  ) {
    return { error: "signupFailPasswordLength" };
  } else if (
    (form.confirmpassword &&
      form.password &&
      form.confirmpassword !== form.password) ||
    (form.newPasswordConfirmation &&
      form.newPassword &&
      form.newPasswordConfirmation !== form.newPassword)
  ) {
    return { error: "recoverFailPasswordDifferent" };
  } else {
    return { error: undefined };
  }
}
