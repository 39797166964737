const seriesHeaderStyle = {
  seriesHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "100px 2.5vw",
    width: "100%",
    overflow: "hidden",
    position: "relative",
    height: "auto",
},
  before: {
    zIndex: "3",
    content: '""',
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    filter: "blur(64px)",
    "-webkit-filter": "blur(64px)",
    backgroundRepeat: "no-repeat",
  },
  after: {
    zIndex: "10",
    content: '""',
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(180deg,rgba(25, 25, 25, 0) 0%,rgba(25, 25, 25, 0) 51.04%,#191919 92.04%)",
  },
  seriesHeaderQueries: {
    flexDirection: "column",
    alignItems: "center",
  },
  "pop-up-dynamic-header-before": {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    content: "''",
    zIndex: "1",
    position: "absolute",
    top: "0",
    bottom: "0",
    margin: "auto",
    width: "100%",
    height: "100%",
  },
  "pop-up-dynamic-header-after": {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    content: "''",
    zIndex: "2",
    position: "absolute",
    top: "0",
    bottom: "0",
    margin: "auto",
    width: "100%",
    height: "100%",
  },
};

const PopUpOfferClassNames = {
  modalContainer: "pop-background-modale",
  modal: "popup-container",
  closeIcon: "exit",
  closeButton: "exit",
  overlay: "background-pop",
};
const StripeBaseEllementsStyle = {
  fontSize: "18px",
  color: "#A5A5A5",
  backgroundColor: "#343434",
  lineHeight: "24px",
  fontWeight: "300",
  ':-webkit-autofill': {
    color: '#fff',
  },
  "::placeholder": {
    color: "#A5A5A5",
  },
};

export { seriesHeaderStyle, PopUpOfferClassNames, StripeBaseEllementsStyle };
