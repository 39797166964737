import { useState, useEffect, useCallback, Dispatch } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import { getEpisodesUrlService } from "../services/SeriesService";
import EventStore from "../stores/AmplitudeStore";
import { UseSeriesLanguage } from "./UseSeriesLanguage";

export const UseEpisodeUrl = (
  playerRef: React.RefObject<ReactPlayer> | undefined
): {
  url: string | undefined;
  isLocked: boolean | undefined;
  setIsPlayerLoad: Dispatch<React.SetStateAction<boolean>>;
  isPlayerLoad: boolean;
  setIsLocked: Dispatch<React.SetStateAction<boolean | undefined>>;
} => {
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const { seriesLanguage } = UseSeriesLanguage();
  const { pause } = EventStore();
  const { currentEpisode, profile, userContentLanguage, user } = useSelector(
    (state: StoreType) => ({
      ...state.PlayerReducer,
      ...state.ProfileReducer,
      ...state.UserReducer,
      ...state.SeriesReducer,
    })
  );
  const [url, setUrl] = useState<string | undefined>(
    navigator.userAgent.toLowerCase().indexOf("safari") !== -1
      ? "https://github.com/anars/blank-audio/blob/master/2-minutes-and-30-seconds-of-silence.mp3"
      : undefined
  );
  const [isPlayerLoad, setIsPlayerLoad] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    const ac = new AbortController();
    const { signal } = ac;
    const getEpisodeUrl = async () => {
      setIsPlayerLoad(true);
      try {
        const urlPending = await getEpisodesUrlService(
          JSON.parse(localStorage.getItem("user")!),
          currentEpisode!.series._id,
          currentEpisode!.episode!._id,
          JSON.parse(sessionStorage.getItem("profile")!),
          userContentLanguage,
          signal
        );
        if (urlPending.status === 402) {
          stableDispatch({
            type: Case.SET_POPUP_CONTEXT,
            payload: { context: "Play Locked Episode" },
          });
          setIsLocked(true);
        }
        const urlData: {
          url: string;
          locked: boolean;
        } = await urlPending.json();        
          setUrl(urlData.url);
        if (urlData.locked !== undefined && urlData.locked !== null) {
          setIsLocked(urlData.locked);
        }
        stableDispatch({
          type: Case.IS_PLAYING,
        });
        setIsPlayerLoad(false);
        stableDispatch({ type: Case.PLAYER_IS_NOT_LOADING});
      } catch (e) {
        setIsPlayerLoad(false);
        stableDispatch({ type: Case.PLAYER_IS_NOT_LOADING});
      }
    };
    if (
      currentEpisode &&
      currentEpisode.episode &&
      userContentLanguage &&
      localStorage.getItem("user") &&
      sessionStorage.getItem("profile")
    ) {
      getEpisodeUrl();
    }
    return () => ac.abort();
  }, [currentEpisode, userContentLanguage, stableDispatch, playerRef]);

  useEffect(() => {
    if (
      isLocked &&
      profile &&
      currentEpisode &&
      currentEpisode.episode &&
      user &&
      !!user.token &&
      (user.subscription === 0 ||
        user.subscription === null ||
        (user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) < new Date()))
    ) {
      pause(
        currentEpisode!.series.isChild,
        "Pause From Subscription Pop Up",
        currentEpisode!.series.emotion
          ? currentEpisode!.series.emotion.join()
          : undefined,
        currentEpisode!.episode!.info[seriesLanguage].title,
        currentEpisode!.episode._id,
        currentEpisode.episode.season,
        currentEpisode.series._id,
        currentEpisode.series.info[seriesLanguage].title,
        profile!.isChild,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        currentEpisode!.series.mainImageId,
        playerRef && playerRef.current && playerRef.current.getCurrentTime()
          ? Math.round(playerRef.current.getCurrentTime())
          : undefined,
        playerRef && playerRef.current && playerRef.current.getCurrentTime()
          ? Math.round(
              (playerRef &&
                playerRef.current &&
                playerRef.current.getCurrentTime() /
                  currentEpisode!.episode.length +
                  Number.EPSILON) * 1000
            ) / 1000
          : undefined,
        Math.round(currentEpisode!.episode.length),
        Math.round(currentEpisode!.series.length)
      );
    }
  }, [
    currentEpisode,
    isLocked,
    pause,
    profile,
    seriesLanguage,
    user,
    playerRef,
  ]);

  return { url, isLocked, setIsPlayerLoad, isPlayerLoad, setIsLocked };
};
