import React, { ReactElement, useEffect } from "react";
import { createUseStyles } from "react-jss";
import Modal from "react-responsive-modal";
import SeriesType from "../interfaces/SeriesType";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import { UsePopUp } from "../ressources/UsePopUp";
import PopUpOffers from "./PopUpOffers";
import PopUpPayment from "./PopUpPayment";
import { PopUpOfferClassNames, seriesHeaderStyle } from "../utils/DynamicStyle";
import EventStore from "../stores/AmplitudeStore";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";

interface Props {
  seriesContext?: SeriesType;
  isLocked: boolean;
  dismissPopUp: (isSubscribed?: boolean | undefined) => void;
  isNewSession?: true;
}

export default function PopUp({
  isLocked,
  seriesContext,
  dismissPopUp,
  isNewSession,
}: Props): ReactElement {
  const { seriesLanguage } = UseSeriesLanguage();
  const { popUpContext, context, currentEpisode, uuid } = useSelector(
    (state: StoreType) => ({
      ...state.PlayerReducer,
      ...state.UserReducer,
    })
  );
  const { stripePresentationViewed } = EventStore();
  const {
    offerSelected,
    showPayment,
    isSuccess,
    setIsSuccess,
    resumeOnSubscribed,
    isPayment,
    setIsPayment,
    selectedOffer,
    setUpdatedUser,
    isMultipleOffers,
    firstOffer,
    secondOffer,
    isInit,
  } = UsePopUp(dismissPopUp, seriesContext, isNewSession);
  const useStyles = createUseStyles({
    "pop-up-dynamic-header": {
      position: "relative",
      "&::before": {
        ...seriesHeaderStyle["pop-up-dynamic-header-before"],
      },
      "&::after": {
        backgroundImage: (props) =>
          (props.seriesContext && !props.isPayment) ||
          (props.seriesContext && props.isPayment && props.isSuccess)
            ? `url("${props.seriesContext.coverImage}")`
            : props.isNewSession && !props.isPayment
            ? 'url("/image/pop-up-background.png")'
            : "none",
        filter: (props) =>
          ((props.seriesContext && !props.isPayment) ||
            (props.seriesContext && props.isPayment && props.isSuccess)) &&
          !isNewSession
            ? "blur(96px)"
            : "none",
        ...seriesHeaderStyle["pop-up-dynamic-header-after"],
        backgroundSize: ({ isNewSession }) =>
          isNewSession ? "contain" : undefined,
      },
    },
  });
  const classes = useStyles({
    seriesContext,
    isPayment,
    isSuccess,
  });
  useEffect(() => {
    if (seriesLanguage) {
      stripePresentationViewed(
        popUpContext ? popUpContext : "Direct to url",
        "Default offers",
        context,
        currentEpisode && currentEpisode.episode && currentEpisode.episode.info
          ? currentEpisode.episode.info[seriesLanguage].title
          : undefined,
        currentEpisode && currentEpisode.episode && currentEpisode.episode
          ? currentEpisode.episode._id
          : undefined,
        currentEpisode && currentEpisode.episode && currentEpisode.episode
          ? currentEpisode.episode.season
          : undefined,
        currentEpisode && currentEpisode.series
          ? currentEpisode.series._id
          : undefined,
        currentEpisode && currentEpisode.series && currentEpisode.series.info
          ? currentEpisode.series.info[seriesLanguage].title
          : undefined
      );
    }
  }, [
    popUpContext,
    currentEpisode,
    seriesLanguage,
    context,
    stripePresentationViewed,
  ]);
  useEffect(() => {
    if (!!isInit && !!firstOffer) {
      window.analytics.page("Offer Presented", undefined, {
        context: context ? context : undefined,
        plan_id: firstOffer.id,
        plan_period: firstOffer.interval,
        plan_price: firstOffer.amount,
        platform: "Web",
        presented_offers: "Default offers",
        session_id: uuid,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
      });
    }
  }, [isInit, firstOffer, context, uuid]);
  return (
    <Modal
      open={isLocked === true ? true : false}
      onClose={() => {
        isSuccess ? resumeOnSubscribed("popupSucessDismiss") : dismissPopUp();
      }}
      classNames={PopUpOfferClassNames}
      closeOnOverlayClick={false}
      center={true}
      showCloseIcon={!isPayment || (isPayment && isSuccess)}
      closeOnEsc={false}
      closeIcon={<img src="/image/exit.svg" alt="exit series" />}
    >
      <div className={`${classes["pop-up-dynamic-header"]} pop-up`}>
        {isPayment && selectedOffer && selectedOffer.offer !== undefined ? (
          <PopUpPayment
            dismissPopUp={dismissPopUp}
            selectedOffer={selectedOffer}
            isMultipleOffers={isMultipleOffers}
            setIsPayment={setIsPayment}
            seriesContext={seriesContext}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
            resumeOnSubscribed={resumeOnSubscribed}
            setUpdatedUser={setUpdatedUser}
            seriesLanguage={seriesLanguage}
            isNewSession={isNewSession}
          />
        ) : (
          <PopUpOffers
            showPayment={showPayment}
            offerSelected={offerSelected}
            firstOffer={firstOffer}
            secondOffer={secondOffer}
            selectedOffer={selectedOffer}
            seriesLanguage={seriesLanguage}
            seriesContext={seriesContext}
            isNewSession={isNewSession}
          />
        )}
      </div>
    </Modal>
  );
}
