import React, { ReactElement } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import HomeBillboardLeftSideType from "../interfaces/components/HomeBillboardLeftSideType";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";

export default function HomeBillboardLeftSide({
  each,
  episodes,
  universToShow,
  universes,
  categoryPosition,
  play,
}: HomeBillboardLeftSideType): ReactElement {
  const { seriesHistory } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
  }));
  return (
    <div className="billboard-left-side">
      <div className="banner-image">
        {each && each[0] && each.seriesList[0].newEpisodes && (
          <p className="new-episode-categories-billbord">
            {language("newEpisodes")}
          </p>
        )}
        {each &&
          episodes &&
          new Date(episodes[0].publicationDate) <= new Date() && (
            <div
              className="billboard-play"
              onClick={() => {
                play(
                  each.seriesList[0]._id,
                  seriesHistory &&
                    seriesHistory[each.seriesList[0]._id] &&
                    seriesHistory[each.seriesList[0]._id].episode
                    ? episodes[
                        episodes.findIndex(
                          (eachEpisode) =>
                            eachEpisode._id ===
                            seriesHistory[each.seriesList[0]._id].episode._id
                        )
                      ]
                    : episodes[0],
                  "Explorer",
                  universes && universToShow
                    ? universes.filter(
                        (each) => each.headerCategoryId === universToShow
                      )[0].title
                    : "Main Homepage",
                  universToShow ? universToShow : undefined,
                  "Billboard",
                  categoryPosition,
                  universes && universToShow
                    ? universes.filter(
                        (each) => each.headerCategoryId === universToShow
                      )[0].title
                    : "Main Homepage",
                  seriesHistory &&
                    seriesHistory[each.seriesList[0]._id] &&
                    seriesHistory[each.seriesList[0]._id].episode
                    ? Math.round(seriesHistory[each.seriesList[0]._id].progress)
                    : 0,
                  seriesHistory &&
                    seriesHistory[each.seriesList[0]._id] &&
                    seriesHistory[each.seriesList[0]._id].episode
                    ? Math.round(
                        (seriesHistory[each.seriesList[0]._id].progress /
                          seriesHistory[each.seriesList[0]._id].episode.length +
                          Number.EPSILON) *
                          1000
                      ) / 1000
                    : 0
                );
              }}
            >
              <p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.7127 11.7969L10.787 6.86377C10.948 6.76537 11.0791 6.6361 11.1695 6.48653C11.2599 6.33697 11.307 6.17139 11.307 6.00343C11.307 5.83547 11.2599 5.66989 11.1695 5.52033C11.0791 5.37076 10.948 5.24149 10.787 5.14309L2.7127 0.203067C2.52526 0.0877822 2.30374 0.0186427 2.07267 0.00327057C1.84161 -0.0121015 1.61002 0.0269197 1.40356 0.116C1.19711 0.20508 1.02385 0.340745 0.902975 0.50797C0.782104 0.675195 0.718342 0.867419 0.718752 1.0634V10.9365C0.718342 11.1325 0.782104 11.3248 0.902975 11.492C1.02385 11.6593 1.19711 11.7949 1.40356 11.884C1.61002 11.9731 1.84161 12.0121 2.07267 11.9967C2.30374 11.9814 2.52526 11.9122 2.7127 11.7969Z"
                    fill="#343434"
                  />
                </svg>

                {language("listen")}
              </p>
            </div>
          )}
        <LazyLoadImage src={each.seriesList[0].bannerImage} />
      </div>
    </div>
  );
}
