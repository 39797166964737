import React, { ReactElement } from "react";
import ContentLoader from "react-content-loader";
import { createUseStyles } from "react-jss";
import { seriesHeaderStyle } from "../utils/DynamicStyle";

export default function SeriesHeaderPlaceHolder(): ReactElement {
  const useStyles = createUseStyles({
    "series-header": {
      ...seriesHeaderStyle.seriesHeader,
      "@media (max-width: 1084px)": {
        "series-header": {
          ...seriesHeaderStyle.seriesHeaderQueries,
        },
      },
    },
  });
  const classes = useStyles();
  return (
    <div className={classes["series-header"]}>
      <div className="series-header-left-side">
        <div className="series-header-left-side-title">
          <h1>
            <ContentLoader
              animate={true}
              speed={2}
              width="109"
              height="24"
              viewBox="0 0 109 24"
              backgroundColor="#343434"
              foregroundColor="#454545"
            >
              <rect width="109" height="24" rx="12" />
            </ContentLoader>
          </h1>
          <div className="series-length"></div>
        </div>
        <div className="series-header-mentions">
          <p>
            <ContentLoader
              animate={true}
              speed={1.5}
              width="58"
              height="12"
              viewBox="0 0 58 12"
              backgroundColor="#343434"
              foregroundColor="#454545"
            >
              <rect width="58" height="12" rx="6" />
            </ContentLoader>
          </p>
          <p>
            <ContentLoader
              animate={true}
              speed={1.5}
              width="58"
              height="12"
              viewBox="0 0 58 12"
              backgroundColor="#343434"
              foregroundColor="#454545"
            >
              <rect width="58" height="12" rx="6" />
            </ContentLoader>
          </p>
          <p>
            <ContentLoader
              animate={true}
              speed={1.5}
              width="135"
              height="12"
              viewBox="0 0 135 12"
              backgroundColor="#343434"
              foregroundColor="#454545"
            >
              <rect width="135" height="12" rx="6" />
            </ContentLoader>
          </p>
        </div>
        <div className="description" style={{ overflowX: "hidden" }}>
          <p>
            <ContentLoader
              animate={true}
              speed={2}
              width="602"
              height="68"
              viewBox="0 0 602 68"
              backgroundColor="#343434"
              foregroundColor="#454545"
            >
              <rect y="20" width="602" height="8" rx="4" />
              <rect width="602" height="8" rx="4" />
              <rect y="40" width="602" height="8" rx="4" />
              <rect y="60" width="459" height="8" rx="4" />
            </ContentLoader>
          </p>
        </div>
        <div className="header-button">
          <button></button>

          <div className="action"></div>

          <div className="action"></div>
        </div>
      </div>
      <div className="series-header-right-side">
        <ContentLoader
          animate={true}
          speed={1.5}
          className="cover-image-placeholder"
          width="288"
          height="288"
          viewBox="0 0 288 288"
          backgroundColor="#343434"
          foregroundColor="#454545"
        >
          <rect width="288" height="288" rx="8" fill="#343434" />
        </ContentLoader>
      </div>
    </div>
  );
}
