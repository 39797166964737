import StoreType from "../interfaces/StoreType";
import { Action, Case } from "../interfaces/ReducerCase";

const PlayerStore: StoreType["PlayerReducer"] = {
  currentEpisode: undefined,
  playerIsLoading: false,
  isPlaying: false,
  context: "",
  isSeekedToHistory: false,
  popUpContext: undefined,
  playerIsHidded: false,
  volume: 1,
};

export default function PlayerReducer(state = PlayerStore, action: Action) {
  switch (action.type) {
    case Case.SET_CURRENT_EPISODE: {
      return {
        ...state,
        currentEpisode: {
          series: { ...action.payload.series },
          episodes: action.payload.episodes
            ? [...action.payload.episodes]
            : undefined,
          episode: action.payload.episode
            ? { ...action.payload.episode }
            : undefined,
          lng: action.payload.lng,
          sample: action.payload.sample ? action.payload.sample : undefined,
        },
      };
    }
    case Case.SET_CURRENT_EPISODE_UNDEFINED: {
      return {
        ...state,
        currentEpisode: undefined,
        isPlaying: false,
      };
    }
    case Case.SIGNOUT: {
      return {
        currentEpisode: undefined,
        playerIsLoading: false,
        isPlaying: false,
        context: "",
        isSeekedToHistory: false,
        volume: 1,
      };
    }
    case Case.PLAYER_IS_HIDDED: {
      return {
        ...state,
        playerIsHidded: true,
      };
    }
    case Case.PLAYER_IS_NOT_HIDDED: {
      return {
        ...state,
        playerIsHidded: false,
      };
    }
    case Case.PLAYER_IS_LOADING: {
      return {
        ...state,
        playerIsLoading: true,
      };
    }
    case Case.PLAYER_IS_NOT_LOADING: {
      return {
        ...state,
        playerIsLoading: false,
      };
    }
    case Case.IS_PLAYING: {
      return {
        ...state,
        isPlaying: true,
      };
    }
    case Case.IS_NOT_PLAYING: {
      return {
        ...state,
        isPlaying: false,
      };
    }
    case Case.SET_CONTEXT: {
      return {
        ...state,
        context: action.payload.context,
      };
    }
    case Case.SET_POPUP_CONTEXT: {
      return {
        ...state,
        popUpContext: action.payload.context,
      };
    }
    case Case.REINIT_IS_SEEKED_TO_HISTORY: {
      return {
        ...state,
        isSeekedToHistory: false,
      };
    }
    case Case.SEEKED_TO_HISTORY: {
      return {
        ...state,
        isSeekedToHistory: true,
      };
    }
    case Case.SET_VOLUME: {
      return {
        ...state,
        volume: action.payload.volume,
      };
    }
    default:
      return state;
  }
}
