import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { Action, Case } from "../interfaces/ReducerCase";
import amplitude from "amplitude-js";


export const UseSignout = (): {
  signout: () => void;
} => {
  const dispatch: Dispatch<Action> = useDispatch();
  const signout = () => {
    window.analytics.track("Signed Out", {    
      subscription: !!localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!).subscription : 0,    
      platform: "Web",
      signup_type: localStorage.getItem("sy_signup_type") ? localStorage.getItem("sy_signup_type") : undefined
    });
    window.analytics.reset();
    localStorage.setItem('fm_sttgs', "true");
    localStorage.removeItem("sy_signup_type");
    localStorage.removeItem("popup");
    localStorage.removeItem("user");
    localStorage.removeItem("uuid");
    sessionStorage.removeItem("profile");
    dispatch({ type: Case.RESET_UUID});
    amplitude.getInstance().clearUserProperties()
    dispatch({ type: Case.SIGNOUT });
  };
  return {
    signout,
  };
};
