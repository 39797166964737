import Phrases from "./Phrases";

const phrases: Phrases = {
  inputPlaceholder: "Escribe aquí",
  alreadyHasAnAccount: "¿Ya tienes una cuenta? ",
  alreadyHasAnAccountLink: "Iniciar sesión",
  notAlreadyAnAccount: "¿Todavía no tienes cuenta?",
  notAlreadyAnAccountLink: "Crear una",
  forgotedPassword: "¿Has olvidado tu contraseña? ",
  forgotedPasswordLink: "Haga clic aquí",
  or: "O",
  cgu: "CGU",
  authCgu: "Términos de uso",
  signup: "Crear cuenta",
  signin: "Iniciar sesión",
  signout: "Cerrar sesión",
  email: "Correo electrónico",
  confirmEmail: "Confirma tu correo",
  emailSuccess: "Correo electrónico cambiado con éxito",
  password: "Contraseña",
  wrongOldPassword: "Verifica tu contraseña actual",
  newPassword: "Nueva contraseña",
  confirmNewPassword: "Confirma tu nueva contraseña",
  newPasswordSuccess: "Contraseña modificada con éxito",
  newPasswordTitle: "Crear nueva contraseña",
  newPasswordParagraphe: "La contraseña debe tener al menos 6 carácteres",
  confirmButton: "CONFIRMAR",
  facebookLinking: "Asociar una cuenta Facebook",
  facebookUnlinking: "Disociar una cuenta Facebook",
  facebookUnlinkingUnauthorized:
    "Primero añade un correo electrónico para disociar la cuenta Facebook",
  facebookSignup: "Continuar con Facebook",
  facebookLogin: "Continuar con Facebook",
  appleContinue: "Continuar con Apple",
  googleContinue: "Continuar con Google",
  emailSignup: "Crear cuenta con email",
  emailLogin: "Iniciar sesión con email",
  wellcome: "¡Bienvenido en Sybel!",
  wellcomeSubtitle: "¿Con quién vas a compartir tu cuenta?",
  chooseProfileTitle: "¿Quién está para escuchar hoy?",
  chooseProfileSubTitle: "Elige un perfil para seguir",
  finishing: "Acabar",
  isChild: "¿Para un niño?",
  searchPlaceholder: "Título, palabras claves, temas...",
  profile: "Perfil",
  likeList: "Mi lista",
  backToHome: "Volver al inicio",
  shortHome: "Inicio",
  back: "Volver",
  settingsScreenTitle: "Ajustes de la cuenta",
  subscription: "Manejar la suscripción",
  subscriptionWord: "Suscripción ",
  cancelSubscription: "Cancelar la suscripción",
  cancelSubscriptionQuestion: "¿Seguro que quieres cancelar la suscripción?",
  paymentMethod: "Método de pago",
  billingDetails: "Ver detalle de facturación",
  billingDetailsTitle: "Detalle de facturación",
  annualWord: "anual ",
  monthWord: "mensual",
  aWithAccent: " a ",
  subscribe: "Suscribir",
  stripeSubscriptionTitle: "Informaciones de pago",
  sfrSubscription: "Has contratado una opción Sybel+ con nuestro socio ",
  sfrSubscriptionTwo: "SFR",
  sfrSubscriptionThree: "Maneja la suscripción desde tu",
  sfrSubscriptionFour: "cuenta cliente SFR",
  subscriptionStore:
    "Has suscrito a Sybel+ con la aplicación móvil, maneja la suscripción desde el store de aplicaciónes (Google Play Store o App Store).",
  subscriptionCoupon:
    "Has activado un bono que te garantiza un acceso ilimitado al catálogo Sybel hasta el ",
  subscriptionStripe: "Estás suscrito hasta el ",
  subscriptionLife:
    "Disfrutas de un acceso a Sybel+ que te durará toda la vida ❤️",
  signinInformation: "INFORMACIÓN DE CONEXIÓN",
  addEmailSentence:
    "Añade un correo electrónico para compartir tu cuenta más fácilmente",
  addEmail: "Añadir un correo electrónico",
  modifyEmail: "Modificar el correo electrónico",
  modifyPassword: "Modificar la contraseña",
  loginMethod:
    "Para iniciar sesión con un clic en todos tus aparatos, puedes añadir un método de conexión:",
  parentalCodeSettings: "CONTENIDO EXPLÍCITO Y CÓDIGO PARENTAL",
  dontHaveParentalCodeSettings:
    "Si compartes tu cuenta con una persona joven que puede ser ofendida por ciertos contenidos inadaptados a su edad, te aconsejamos añadir un código parental.",
  haveParentalCodeSettings:
    "Has creado un código parental, la lectura de contenidos para mayores de 16 años y más será controlada con este código.",
  addProfile: "Añadir otro perfil",
  modifyProfile: "Modificar el perfil",
  firstName: "Nombre",
  gender: "Género",
  wontSay: "No prefiero decirlo",
  male: "Masculino",
  female: "Feminino",
  other: "Otro",
  birthday: "Fecha de nacimiento",
  birthdayFormat: "(dd/mm/aaaa)",
  childMode: "Modo infantil",
  anyQuestions: "¿Tienes pregunta?",
  helpCenter: "Accede al centro de ayuda",
  contactUs: "Contáctanos por email",
  contactUsOn: "Contáctanos en",
  tc: "Términos de uso",
  listen: "Escuchar",
  episodes: "episodios",
  similar: "títulos similares",
  details: "detalles",
  season: "Temporada",
  sample: "Tráiler",
  seemore: "Ver más...",
  seeless: "Ver menos",
  resumeListening: "Reanudar la lectura",
  resume: "Reanudar",
  avatarPickerTitle: "Elegir un avatar",
  info: "Info",
  yes: "Sí",
  no: "No",
  cancel: "Detener",
  search: "Búsqueda",
  noLikeList:
    "¡Añade tus contenidos favoritos o los que quieres escuchar próximamente a tu lista para encontrarlos más facilmente aquí!",
  emailVerificationSuccess:
    "¡Tu correo electrónico ha sido verificado! Te deseamos una buena escucha con Sybel",
  emailVerificationTitle: "Verifica tu correo electrónico para seguir",
  emailVerificationSubtitle:
    "Necesitamos esa información para asegurar tu cuenta y recomendarte contenido.",
  emailVerificationClickLink: "Haga clic en el enlace que fue enviado a",
  emailVerificationResend: "Enviar otro email de verificación",
  newEpisodes: "Nuevos episodios",
  preferences: "Preferencias",
  newsletterSybel: "Suscribir a la Newsletter Sybel",
  discover: "DESCUBRIR",
  signupFailIdenticalMail: "Ains... los correos no concordan, intenta de nuevo",
  signupFailMail: "El correo electrónico no parece correcto",
  failBirthday: "La fecha de nacimiento no parece correcta",
  empty: "L'un des champs est vide",
  signupFailPasswordLength:
    "Ains... la contraseña debe hacer al menos 6 carácteres",
  signupFail: "Una cuenta ya usa este correo, intenta iniciar sesión.",
  recoverFailPasswordDifferent: "Las dos contraseñas no concordan",
  forgotPasswordAlert:
    // eslint-disable-next-line
    "Te hemos enviado un email a {{email}}. Si una cuenta existe con esta dirección, recibirás un enlace para reiniciar tu contraseña.",
  emailAlert:
    // eslint-disable-next-line
    "Te hemos enviado un email a {{email}}.",
  signinFail:
    "Las informaciones no concordan, intenta de nuevo o reinicia la contraseña",
  forgotPassword: "He olvidado mi contraseña",
  forgotPasswordTitle: "Reiniciar la contraseña",
  forgotPasswordParagraph:
    "Tecla tu correo electrónico, te mandermos un enlace si tu dirección está vinculada a una cuenta en nuestra base de datos",
  discoverTitle: "Imaginación a la carta",
  newsletter:
    "Recibir nuestras actualidades, ofertas y recomendaciones de escucha",
  cgu1: "Haciendo clic en ese botón, reconoces aceptar nuestros",
  cgu2: "Términos de uso",
  cgu3: `. Tu suscripción empieza a partir del momento que eliges una oferta y defines un modo de pago. Tu suscripción a Sybel+ será automaticamente renovada al menos que desactives la opción desde los ajustes de la aplicación web al menos 24 horas antes del fin del periodo de facturación vigente`,
  cgu3Culture: `. Tu suscripción empieza a partir del momento que eliges una oferta y defines un modo de pago. Al suscribir reconoces renunciar al derecho de desistimiento conforme al Artículo 103 de la Ley General para la Defensa de los Consumidores y Usuarios.`,
  changeOffer: "CAMBIAR",
  continue: "Seguir",
  then: "y después",
  per: "pour", // TODO
  addProfileHomeTitle: "Añadir un perfil",
  addProfileHomeButton: "Añadir un perfil adulto",
  days: "días", // TODO
  month: "meses", // TODO
  year: "años", // TODO
  remaining: "Te quedan", // TODO
  addKidsProfile: "Añadir un perfil infantil",
  comingSoon: "Pronto disponible...",
  comingSoonEpisode: "Disponible el :",
  popUpTitle: "Centenas de audioseries para descubrir",
  popUpTitleFree: ", gratis durante",
  seriesContextPopUpTitleNoTrial: "Suscríbete ahora para escuchar",
  sampleFrom: "Tráiler de ",
  comingNext: "Para seguir ",
  mainUserTitle: "Pseudo*",
  emptyDate: "DD/MM/AAAA",
  sleepAfter: "MODO DE SUSPENCIÓN",
  endOfEpisode: "Al fin del episodio",
  oneHour: "hora",
  minutes: "minutos",
  disableSleep: "Desactivar el modo de suspensión",
  episodesList: "Episodios", //TODO
  interfaceLanguage: "Idioma de la interfaz",
  contentLanguage: "Idioma del contenido",
  seriesContextPopUpTitle: "Activa tu prueba gratuita para escuchar", //TODO ATTENTION ICI SI PAS DE FREE TRIAL QUOI AFFICHER
  trialPremium: "JOURS D’ESSAI GRATUITS", //TODO
  trialPremium_plural: "JOURS D’ESSAI GRATUITS", //TODO
  explicitEmailSubtitle:
    "Primero añade un correo electrónico a tu cuenta para crear un código parental, nos será útil en caso lo pierdes",
  explicitCodeEditionSubtitle:
    "Para modificar el código parental, tecla el código actual y el nuevo",
  explicitCodeDeleteSubtitle:
    "Tecla el código parental actual para suprimirlo.",
  explicitCodeCreationSubtitle: "Crea un código parental para más seguridad",
  chooseNumbers: "(Elige 4 cifras)",
  typeNumbers: "(Tecla las 4 cifras)",
  wrongCode: "El código no concorda, probar de nuevo",
  forgotCode: "Código olvidado?",
  codeSended: "Te hemos mandado un email con el código parental",
  createCode: "CREAR UN CÓDIGO PARENTAL",
  modifyCode: "Modificar el código",
  codeParental: "Código parental",
  currentCode: "Código actual:",
  newCode: "Nuevo código:",
  parentalCode: "Código parental",
  promoCodeSubTitle: "Activar un código de descuento",
  deleteCode: "Suprimir el código",
  codeCreated: "Código creado",
  codeDeleted:
    "Código suprimido. Se pueden aceder a contenidos para los mayores de 16 y más con cualquier profil adulto.",
  codeModified: "El código parental ha sido modificado",
  codeError: "Un error ocurrió, si persigue contacta con nosotros",
  yearly: "Anual",
  monthly: "Mensual",
  yearUnit: "año",
  noSubscription: "Hmmm... parece que no tienes suscripción a Sybel+ vigente",
  discoverOffers: "Descubre nuestras ofertas",
  letsGo: "¡Vámonos",
  operatorActivate: "ACTIVAR UNA OPCIÓN SYBEL+ CON TU OPERADOR TELEFÓNICO",
  operatorActivateWording:
    "Elige el operador con el que quieres activar una opción Sybel+ :",
  redBySfr: "RED by SFR",
  sfr: "SFR",
  deleteAccount: "Suprimir la cuenta",
  delete: "SUPRIMIR",
  deleteLabel: 'Tecla "SUPPIMIR" para seguir',
  deleteAccountSuccess: "Ya te extrañamos 😢",
  deleteAccountSubscribed:
    "No puedes suprimir tu cuenta, ya que todavía estás suscrito",
  changeLanguage: "Modificar el idioma",
  fr: "Français",
  es: "Español",
  en: "English",
  notSubscribedSettingsOffers:
    "Todas las funcionalidades y un acceso ilimitado a partir de ",
  notSubscribedSettingsOffersEmphase: "3,99/mes !",
  notSubscribedSettingsCoupon:
    "¿Tienes un código regalo o un bono de una empresa socia? Activa ahora tu acceso a ",
  notSubscribedSettingsCouponEmphase: "Sybel+",
  notSubscribedSettingsCouponCta: "ACTIVAR UN BONO",
  notSubscribedSettingsSfr: "Todas las ventajas de Sybel+ por sólo ",
  notSubscribedSettingsSfrEmphase: "2€/mes",
  notSubscribedSettingsSfrPostEmphase: " con la opción SFR !",
  notSubscribedSettingsSfrCta: "ACTIVAR la opción",
  coupon: "Activar un bono",
  couponWording:
    "Activa aquí un código regalo o un bono de una empresa socia, ingresaremos meses a tu cuenta después de verificarlo.",
  couponWordingBefore:
    "Validez le code puis créez un compte pour profiter de l’offre",
  couponFail: "Este código no existe ! 🤷‍♀️",
  couponSuccess: "Código activado ! 🤗",
  couponClick: "Activado",
  couponAlreadySubscribed:
    "Ya estás suscrito, no puedes beneficiar de este descuento",
  couponNotCorrect: "Código no válido, verifica tu entrada",
  couponConflict:
    "Este descuento no se aplica a esa suscripción, cambiála para seguir",
  couponConflictToast:
    "Descuento no valable para  non válido para esa suscripción ",
  couponExpired:
    "Este descuento ha expirado y ya no es válido, prueba con otro",
  couponUsed:
    "Este descuento ya ha llegado al límite de activación, prueba con otro",
  nextBill: "Próxima factura el",
  total: "Total facturado",
  period: "Periodo",
  billingDate: "Fecha de facturación",
  from: "del",
  to: "al",
  editProfile: "Modificar perfiles",
  editProfileSubtitle: "Haga clic en el perfil que quieres modificar.",
  chooseAvatar: "Elige tu avatar",
  chooseAvatarSubTitle: "Haga clic en tu avatar favorito",
  seriesCopy: "Copiado en el portapapeles",
  seriesShareMobile: "Compartir",
  seriesPagePlay: "Tráiler",
  seriesPagePause: "Pausa",
  min: "min",
  home: "Inicio",
  premium: "Sybel+",
  profileManagment: "Manejar perfiles",
  openSybel: "Abrir Sybel",
  bonus: "bonus",
  shortSeason: "T",
  shortEpisode: "E",
  cardNumberPlaceholder: "Número de tarjeta",
  expirationCardPlaceholder: "Fecha de expiración",
  cvcCardPlaceholder: "CVV",
  discountPaymentPlaceholder: "¿Tienes código de descuento?",
  ok: "OK",
  sybelPlusWelcome: "Bienvenido en Sybel+,",
  sybelPlusSeries: "disfruta de tu suscripción desde ahora y escucha ", //TODO
  sybelPlusNoContext:
    "Estás a punto de descubrir un nuevo tipo de contenido. No es podcast, ni audiolibro audio, nuestras creaciones originales con sound design inmersivo te habrán descubrir lugares inexplorados de tu imaginación.... Listo? Escucha atentivamente",
  billboardDiscover: "Descubrir la serie",
  searched: "Résultat de recherche pour ", //TODO
  searched_plural: "Résultats de recherche pour ", //TODO
  noResult: "Ains... No hemos encontrado resultados para ", //TODO
  life: "¡Sybel+ para la vida!",
  lifeLong: "Sybel+ ilimitado, para toda la vida",
  lifePayment: "Sybel+ para toda la vida",
  cultureCta: "(50% donados a la cultura)",
  cultureTitle: 'OFERTAS ESPECIALES "VIVA LA CULTURA"',
  paymentHasAnAccountTitle: "Hmmm...",
  paymentHasAnAccountParagraphe:
    "Ya tienes suscripción vigente a Sybel+, no puedes suscribir otra vez.",
  durring: "pendant",
  firstMonth: "el primer mes, y después",
  firstYear: "el primer año",
  changeCreditCardCTA: "Modificar el modo de pago",
  changeCreditCardGood: "El modo de pago fue modificado con éxito",
  cookiesTitle: "NECESITAMOS TU CONSENTIMIENTO",
  cookiesSubTitle1: "Las cookies utilizadas por Sybel y nuestras ",
  cookiesSubTitle2: "empresas socias",
  cookiesSubTitle3:
    "nos permiten de:  medir y analizar la audiencia de Sybel, personaliar el contenido y los servicios que te proponemos según tus intereses y darte la posibilidad de compartir en tus redes sociales.",
  cookiesSubTitle4: "Para leer más, ve en nuestra",
  cookiesSubTitle5: "política de cookies.",
  cookiesSubTitle6:
    "Puedes expresar o revocar tu consentimiento en cualquier momento, haciendo clic en el enlace “Manejar las cookies” abajo de la página de ajustes de la app.",
  cookiesAllowAll: "Aceptar todas",
  cookiesDisableAll: "Denegar todas",
  cookiesSettingsCTA: "Manejar",
  cookiesSettingsTitle: "Ajustes de las cookies 🍪",
  cookiesSettingsSubTitle:
    "Para los usos abajo, necesitamos tu consentimiento:",
  cookiesSettingsTitleCantDisable:
    "Cookies operacionales necesarias al funcionamiento",
  cookiesSettingsTitleCantDisableParagraph:
    "Las cookies operacionales, son las cookies de funcionamiento y performancias utilizadas para proveerte nuestro servicio en la plataforma Sybel. Estas no pueden ser desactivadas.",
  cookiesSettingsTitleCantDisableMoreInformations:
    "Leer más sobre las cookies operacionales",
  cookiesSettingsTitleCantDisableMoreInformationsListTitle:
    "Utilizamos cookies para proveerte el servicio, y más precisamente para:",
  cookiesSettingsTitleCantDisableMoreInformationsList1:
    "Reconocerte cuando te conectas otra vez para usar la Plataforma.",
  cookiesSettingsTitleCantDisableMoreInformationsList2:
    "Activar ciertas funcionalidades, contenido y servicios que podrían interesarte, entre las cuales las publicidades relativas a los contenidos.",
  cookiesSettingsTitleCantDisableMoreInformationsList3:
    "Guardar tus preferencias de contenido de una sesión a otra.",
  cookiesSettingsTitleCantDisableMoreInformationsList4:
    "Preveer las actividades fraudulentas.",
  cookiesSettingsTitleCantDisableMoreInformationsList5:
    "Mejorar la seguridad, y entre otros un pago seguro.",
  cookiesSettingsTitleCantDisableMoreInformationsList6:
    "Guardar tus preferencias de navegación, como el idioma que prefieres para navegar y escuchar contenidos.",
  cookiesSettingsTitleCantDisableMoreInformationsListFooter:
    "Utilizamos también cookies para entender como utilizar la Plataforma para mejorar nuestro servicio. Por ejemplo, usamos cookiees para investigar, hacer estadísticas anonimizadas y establecer diagnósticos para mejorar el contenidos que te proponemos así como el servicio Sybel en general.",
  cookiesAddsTitle: "Cookies publicitarias",
  cookiesAddsParagraphe:
    "El objetivo es de memorizar vuestras accionees (ejemplo: suscribir a un abono) después de que hayas visto o interactuado con las publicidades Sybel difundidas en otras plataformas con el fin de medir sus performancias.",
  cookiesSocialNetworkTitle: "Cookies de redes sociales",
  cookiesSocialNetworkParagraphe:
    "Estas cookies te permite interactuar con tus redes sociales directamente desde nuestra aplicación web, de iniciar sesión en Sybel con tu cuenta de redes sociales y de compartir nuestros contenidos con tu comunidad haciendo clic en los botones previstos para ello.",
  cookiesSettingsValidation: "Confirmar",
  signupSuccessMail: "La dirección es correcta",
  signupFailIdenticalMailOnChange: "Los correos no concordan",
  manageCookies: "Manejar las cookies",
  defaultYearPrice: "{{price}} / año",
  defaultMonthPrice: "{{price}} / mes",
  temporaryYearReduction:
    "{{reducedPrice}} durante {{duration}} años, luego {{price}} / año",
  temporaryMonthReduction:
    "{{reducedPrice}} durante {{duration}} meses, luego {{price}} / mes",
  temporaryOneYearReduction:
    "{{reducedPrice}} el primer año, luego {{price}} / año",
  temporaryOneMonthReduction:
    "{{reducedPrice}} el primer mes, luego {{price}} / mes",
  YearlyOfferUnitaryPrice: "facturados {{price}}eur / año de una vez",
  summerOffer: "OFERTA ESPECIAL DÍAS SOLEADOS",
  beginTrialPremium: "Activar mi {{count}} día gratis", //TODO
  beginTrialPremium_plural: "Activar mis {{count}} días gratis", //TODO
  YearlyPaymentUnitaryPrice: "{{price}} / mes, facturado de una vez",

  permanentYearPaymentPrice: "{{newPrice}} / an au lieu de {{price}}", //TODO
  permanentMonthPaymentPrice: "{{newPrice}} / mois au lieu de {{price}}", //TODO

  maillinglist:
    "Hemos borrado la dirección <strong><em>{{email}}</em></strong> de la newsletter Sybel, no volverá a recibir nuestras ofertas y recomendaciones.",
  maillinglistChoice:
    "Si quieres inscribirte otra vez, puedes hacerlo desde los ajustes de tu aplicación.",
  discoverApp: "Découvrir L’app mobile", //TODO
  freeInSettings: "Associer mon compte Sybel à ma Freebox", //TODO
  freeUnlinkInSettings: "Dissocier mon compte Sybel de ma Freebox", //TODO
  freeUnlinkInSettingsSuccess: "Votre compte a été dissocié avec succès", //TODO
  assiociateFreeSuccess:
    "Bravo, vous pouvez désormais profiter de Sybel sur tous vos appareils compatibles : mobile, web, assistants vocaux ou tv !", //TODO
  freeSignin: "UTILISEZ VOTRE MéTHODE DE CONNEXION HABITUELLE", //TODO
  associateFreeDescription:
    "Associez un compte Sybel pour profiter de votre abonnement sur la FreeBox et toutes nos autres applications. Entrez le code d’association qui s’affiche sur votre TV pour lier un compte à votre application FreeBox :", //TODO
  activateFreeLinkEmailDescription:
    "Associer un compte Sybel existant à ma Freebox", //TODO
  activateFreeAddEmailDescription:
    "Ajouter un email pour profiter de mon abonnement Free sur toutes les plateformes", //TODO
  activateFreeLinkEmailCta: "ASSOCIER MON COMPTE", //TODO
  activateFreeAddEmailCta: "AJOUTER UN EMAIL", //TODO
  FreeErrorBothAccountsAreSubscribed:
    "Nous ne pouvons associer ces deux comptes car ils sont tous deux abonnés, veuillez vous déconnecter et choisir un autre compte sans abonnement.", //TODO
  FreeErrorNoFreeAccount:
    "Le code est invalide ou vous avez quitté le mode association de votre application Freebox. Veuillez vérifier l’écran de votre TV.", //TODO
  freeSubscription: "Vous vous êtes abonné via notre partenaire",
  freeSubscriptionTwo: "Free",
  freeSubscriptionThree: "rendez-vous dans votre",
  freeSubscriptionFour: "Freebox",
  subscriptionInsert: "ECOUTER SYBEL +", //TODO
  userCanaccessToAFreeTrial: "ESSAYER DURANT {{count}} JOUR", //TODO
  userCanaccessToAFreeTrial_plural: "ESSAYER DURANT {{count}} JOURS", //TODO
  popupNewSession: "ESSAYER SYBEL+", //TODO
  popupNewSessionDays: "{{count}} jours gratuits*", //TODO
  popupNewSessionPrice:
    "*{{count}} jours d’essai gratuit, sans engagemet, résiliation à tout moment, puis {{price}}€ / an.", //TODO
  popUpNewSessionCta: "essayer Sybel +", //TODO
  categoryInsert: "Essayez gratuitement Sybel+ pendant {{count}} jours* !", //TODO
  categoryInsertPoint1:
    "Découvrez + de 1000 heures d'écoute de livres audio et séries exclusives.", //TODO
  categoryInsertPoint2: "Chaque semaine des nouveautés vous attendent.", //TODO
  categoryInsertPoint3:
    "Créer jusqu'à 5 profils, et profitez du mode enfant pour un espace dédié et sécurisé.", //TODO
  categoryInsertPoint4:
    "Profitez de votre abonnement partout: sur mobiles, ordinateurs, tablettes, enceintes connectées, FreeBox...", //TODO
  categoryInsertPoint5:
    "Et tout le temps, téléchargez vos séries favorites pour une écoute hors-ligne", //TODO
  categoryInsertButton: "démarrer mon ESSAI GRATUIT", //TODO
  radioTitle: "Découvrez gratuitement les  séries Sybel", //TODO
  radioSubTitle: "Cliquez pour démarrer la radio de votre choix", //TODO
  radioKids: "SYBEL RADIO POUR ENFANT", //TODO
  radioAdult: "SYBEL RADIO", //TODO
  popupPomote1: "Marre des vidéos sur les réseaux sociaux ?", //TODO
  popupPomote2: "Marre de perdre son temps ?", //TODO
  popupPomote3:
    "Découvrez des heures de documentaires et de fictions audio originales et exclusives pour se cultiver, apprendre et se divertir", //TODO
  popupPomote4: "Vivez une expérience immersive en illimité et sans publicité", //TODO
  redeemSignup: "Regístrese para aprovechar la oferta",
  redeemArgTitle1: "Miles de horas de escucha",
  redeemArgParagraph1:
    "Episodios inéditos de tus podcasts, documentales y audiolibros",
  redeemArgTitle2: "Contenidos para niños",
  redeemArgParagraph2: "Historias adecuadas para cada edad en un perfil seguro",
  redeemArgTitle3: "Más de 100 creaciones originales",
  redeemArgParagraph3: "Con sonido 3D y un reparto excepcional",
  redeemArgTitle4: "Apoya a tus podcasters favoritos",
  redeemArgParagraph4:
    "El 70% de tu suscripción se dona a los creadores de contenidos",
  buyashare: "INVEST ON THIS CONTENT",
  learnMore: "More…",
};
export default phrases;
