import ProfileType from "../interfaces/ProfileType";
import UserType from "../interfaces/UserType";

const getHomeService = async (
  user: UserType,
  lng: string,
  profile?: ProfileType
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: user.token,
    },
  };

  return await fetch(
    profile && profile._id
      ? `${process.env.REACT_APP_API}/${lng}/user/${user._id}/profile/${profile._id}/home`
      : `${process.env.REACT_APP_API}/${lng}/home`,
    requestOptions
  );
};
const getUniversService = async (
  lng: string,
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  };

  return await fetch(
    `${process.env.REACT_APP_API}/${lng}/universe/`,
    requestOptions
  );
};
const getCategoriesServiceById = async (
  user: UserType,
  lng: string,
  categoriesId: string
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",

    },
  };

  return await fetch(
    `${process.env.REACT_APP_API}/${lng}/category/${categoriesId}`,
    requestOptions
  );
};
const getUniversServiceById = async (
  user: UserType,
  lng: string,
  universeId: string
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",

    },
  };

  return await fetch(
    `${process.env.REACT_APP_API}/${lng}/category/${universeId}`,
    requestOptions
  );
};
export { getHomeService, getUniversService, getCategoriesServiceById, getUniversServiceById };
