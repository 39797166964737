import React, { ReactElement } from "react";
import HomeHighlightCategoryCard from "./HomeHighlightCategoryCard";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation, Mousewheel, Controller, Lazy } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import {
  LazyLoadComponent,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import HomeMainCategorieType from "../interfaces/components/HomeMainCategorieType";

SwiperCore.use([Navigation, Mousewheel, Controller, Lazy]);

function HomeHighlightCategory({
  scrollPosition,
  each,
  universToShow,
}: HomeMainCategorieType): ReactElement {
  return (
    <LazyLoadComponent
      threshold={0}
      scrollPosition={scrollPosition}
      placeholder={
        <div className="home-resume">
          <p className="resume-title">{each.title}</p>
          <Swiper
            lazy
            slidesPerView={1.4}
            spaceBetween={0}
            mousewheel={{ forceToAxis: true }}
            breakpoints={{
              "300": {
                slidesPerView: 1.4,
              },
              "600": {
                slidesPerView: 2.2,
              },
              "1000": {
                slidesPerView: 3.4,
              },
              "1700": {
                slidesPerView: 4.4,
              },
            }}
            className="home-current-slider"
            freeMode={true}
            navigation={true}
          >
            {Array.from(Array(3), (e, i) => {
              return (
                <SwiperSlide key={i}>
                  <HomeHighlightCategoryCard index={i} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      }
    >
      <div className="home-resume">
        <p className="resume-title">{each.title}</p>
        <Swiper
          lazy
          slidesPerView={1.4}
          spaceBetween={0}
          mousewheel={{ forceToAxis: true }}
          breakpoints={{
            "300": {
              slidesPerView: 1.4,
            },
            "600": {
              slidesPerView: 2.2,
            },
            "1000": {
              slidesPerView: 3.4,
            },
            "1700": {
              slidesPerView: 4.4,
            },
          }}
          className="home-current-slider"
          freeMode={true}
          navigation={true}
        >
          {each.seriesList.map((eachSeries, index) => {
            return (
              <SwiperSlide key={index}>
                <HomeHighlightCategoryCard
                  each={eachSeries}
                  category={each}
                  universeToShow={universToShow}
                  index={index}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </LazyLoadComponent>
  );
}

export default trackWindowScroll(HomeHighlightCategory);
