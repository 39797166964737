import {
  CardElementComponent,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import StoreType from "../interfaces/StoreType";
import { ProductStripeType } from "../interfaces/UserType";
import {
  getCoupon,
  getUser,
  subscribe,
} from "../services/UserService";
import { language } from "../stores/18nStore";
import EventStore from "../stores/AmplitudeStore";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";
import { updatePrice } from "../utils/UpdatePrice";
import { UseSeriesLanguage } from "./UseSeriesLanguage";

export const UsePopUpPayment = (
  selectedOffer: {
    index: number;
    offer: ProductStripeType;
  },
  isMultipleOffers: boolean,
  setUpdatedUser: React.Dispatch<any>,
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  id: string,
  CardElement: CardElementComponent
): {
  isPaymentLoading: boolean;
  isCouponLoading: boolean;
  priceText: string | undefined;
  couponTextResponse: string | undefined;
  applyCoupon: (url?) => void;
  couponValue: string;
  setCouponValue: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void | React.ReactText>;
  couponErrorText: undefined | string;
  isCouponValidated: boolean;
  stripe: Stripe | null;
  elements: StripeElements | null;
} => {
  const { user, currentEpisode, context, profile } = useSelector(
    (state: StoreType) => ({
      ...state.UserReducer,
      ...state.PlayerReducer,
      ...state.ProfileReducer,
    })
  );
  const {
    stripeViewSuccessScreen,
    stripeCouponApplied,
    stripeSubscriptionPurchased,
    stripeCouponTyped,
    errorStripeCouponDoesntMatchWithProduct,
    errorStripeCouponMistyped,
  } = EventStore();
  const { seriesLanguage } = UseSeriesLanguage();
  const [offer, setOffer] = useState<ProductStripeType | undefined>();
  const [couponValue, setCouponValue] = useState<string>("");
  const [couponId, setCouponId] = useState<string | undefined>();
  const [priceText, setPriceText] = useState<string | undefined>();
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const [couponTextResponse, setCouponTextResponse] = useState<
    string | undefined
  >();
  const [couponErrorText, setCouponErrorText] = useState<string | undefined>();
  const [isCouponLoading, setIsCouponLoading] = useState<boolean>(false);
  const [isCouponValidated, setIsCouponValidated] = useState<boolean>(false);

  useEffect(() => {
    if (user && user._id && user.subscriptionData) {
      if (isMultipleOffers) {
        for (let i = 0; i < Object.keys(user.subscriptionData).length; i++) {
          for (
            let j = 0;
            j <
            user.subscriptionData[Object.keys(user.subscriptionData)[i]].length;
            j++
          ) {
            if (
              user.subscriptionData[Object.keys(user.subscriptionData)[i]][j]
                .id === id
            ) {
              setOffer(
                user.subscriptionData[Object.keys(user.subscriptionData)[i]][j]
              );
            }
          }
        }
      } else {
        setOffer(user.subscriptionData!.productsStripe[0] as ProductStripeType);
      }
    }
  }, [isMultipleOffers, user, id]);
  useEffect(() => {
    if (offer) {
      setPriceText(
        offer.amount +
          " " +
          offer.currency +
          "/" +
          (offer.interval === "month"
            ? language("month")
            : language("yearUnit"))
      );
    }
  }, [offer]);
  const applyCoupon = (url: string | undefined) => {
    const definitiveCoupon =
      typeof url === "string" ? url.slice() : couponValue.slice();
    stripeCouponTyped(
      selectedOffer.offer.id,
      selectedOffer.offer.amount,
      selectedOffer.offer.interval,
      definitiveCoupon,
      undefined,
      context ? context : undefined,
      currentEpisode &&
        currentEpisode.episode &&
        currentEpisode.episode.info &&
        seriesLanguage
        ? currentEpisode.episode.info[seriesLanguage].title
        : undefined,
      currentEpisode && currentEpisode.episode
        ? currentEpisode.episode._id
        : undefined,
      currentEpisode && currentEpisode.episode && seriesLanguage
        ? currentEpisode.episode.season
        : undefined,
      currentEpisode && currentEpisode.series
        ? currentEpisode.series._id
        : undefined,
      currentEpisode &&
        currentEpisode.series &&
        currentEpisode.series.info &&
        seriesLanguage
        ? currentEpisode.series.info[seriesLanguage].title
        : undefined,
      profile ? profile.isChild : undefined
    );
    setIsCouponLoading(true);
    setCouponValue("");
    getCoupon(definitiveCoupon, user, id)
      .then((res) => {
        setCouponId(definitiveCoupon);
        setIsCouponLoading(false);
        setIsCouponValidated(true);
        const updatedCoupon = updatePrice(selectedOffer.offer, res.data.coupon);
        setPriceText(updatedCoupon!.priceText);
        setCouponTextResponse(updatedCoupon.couponText);
        toast(
          <p className="green">{language("couponSuccess")}</p>,
          notificationsParam
        );
        stripeCouponApplied(
          selectedOffer.offer.id,
          selectedOffer.offer.amount,
          updatedCoupon && updatedCoupon.priceText
            ? updatedCoupon.priceText
            : selectedOffer.offer.amount,
          selectedOffer.offer.interval,
          definitiveCoupon,
          undefined,
          context ? context : undefined,
          currentEpisode &&
            currentEpisode.episode &&
            currentEpisode.episode.info &&
            seriesLanguage
            ? currentEpisode.episode.info[seriesLanguage].title
            : undefined,
          currentEpisode && currentEpisode.episode
            ? currentEpisode.episode._id
            : undefined,
          currentEpisode && currentEpisode.episode && seriesLanguage
            ? currentEpisode.episode.season
            : undefined,
          currentEpisode && currentEpisode.series
            ? currentEpisode.series._id
            : undefined,
          currentEpisode &&
            currentEpisode.series &&
            currentEpisode.series.info &&
            seriesLanguage
            ? currentEpisode.series.info[seriesLanguage].title
            : undefined,
          profile ? profile.isChild : undefined
        );
      })

      .catch((err) => {
        if (
          err.response.status === 409 &&
          err.response.data === "This code doesn't apply to this product."
        ) {
          errorStripeCouponDoesntMatchWithProduct(
            selectedOffer.offer.id,
            selectedOffer.offer.amount,
            selectedOffer.offer.interval,

            definitiveCoupon,
            undefined,
            context ? context : undefined,
            currentEpisode &&
              currentEpisode.episode &&
              currentEpisode.episode.info &&
              seriesLanguage
              ? currentEpisode.episode.info[seriesLanguage].title
              : undefined,
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode._id
              : undefined,
            currentEpisode && currentEpisode.episode && seriesLanguage
              ? currentEpisode.episode.season
              : undefined,
            currentEpisode && currentEpisode.series
              ? currentEpisode.series._id
              : undefined,
            currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.info &&
              seriesLanguage
              ? currentEpisode.series.info[seriesLanguage].title
              : undefined,
            profile ? profile.isChild : undefined
          );
          setCouponId(undefined);
          setIsCouponValidated(false);
          setPriceText(
            offer!.amount +
              " " +
              offer!.currency +
              "/" +
              (offer!.interval === "month"
                ? language("month")
                : language("yearUnit"))
          );
          setIsCouponLoading(false);
          setCouponErrorText(language("couponConflict"));
          return toast(language("couponConflictToast"), notificationsParam);
        } else {
          errorStripeCouponMistyped(
            selectedOffer.offer.id,
            selectedOffer.offer.amount,
            selectedOffer.offer.interval,

            definitiveCoupon,
            undefined,
            context ? context : undefined,
            currentEpisode &&
              currentEpisode.episode &&
              currentEpisode.episode.info &&
              seriesLanguage
              ? currentEpisode.episode.info[seriesLanguage].title
              : undefined,
            currentEpisode && currentEpisode.episode
              ? currentEpisode.episode._id
              : undefined,
            currentEpisode && currentEpisode.episode && seriesLanguage
              ? currentEpisode.episode.season
              : undefined,
            currentEpisode && currentEpisode.series
              ? currentEpisode.series._id
              : undefined,
            currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.info &&
              seriesLanguage
              ? currentEpisode.series.info[seriesLanguage].title
              : undefined,
            profile ? profile.isChild : undefined
          );
          setCouponErrorText(undefined);
          setCouponId(undefined);
          setIsCouponValidated(false);
          setCouponTextResponse(undefined);
          setPriceText(
            offer!.amount +
              " " +
              offer!.currency +
              "/" +
              (offer!.interval === "month"
                ? language("month")
                : language("yearUnit"))
          );
          setIsCouponLoading(false);
          return toast(language("couponFail"), notificationsParam);
        }
      });
  };

  const stripe = useStripe();
  const elements = useElements();

  const send3dSecureResponse = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: JSON.parse(localStorage.getItem("user")!)!.token,
      },
    };
    return await fetch(
      `${process.env.REACT_APP_API}/user/${
        JSON.parse(localStorage.getItem("user")!)!._id
      }/refreshToken`,
      requestOptions
    );
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setIsPaymentLoading(true);
    const cardElement = elements!.getElement(CardElement);
    try {
      const subscribtionPending = await subscribe(
        selectedOffer.offer.id,
        {
          _id: JSON.parse(localStorage.getItem("user")!)!._id,
          token: JSON.parse(localStorage.getItem("user")!)!.token,
        },
        undefined,
        undefined,
        couponId ? couponId : undefined
      );
      if (subscribtionPending.status === 502) {
        setIsPaymentLoading(false);
        return toast(language("codeError"), {
          ...notificationsParam,
          toastId: "unique",
        });
      }
      const tokenRes: {
        stripe_subscription_status: string;
        stripe_subscription_id: string;
        stripe_intent_type: string;
        stripe_client_secret: string;
      } = await subscribtionPending.json();
      if (tokenRes && tokenRes.stripe_intent_type === "payment_intent") {
        const { paymentIntent, error } = await stripe!.confirmCardPayment(
          tokenRes.stripe_client_secret,
          {
            payment_method: {
              card: cardElement!,
            },
            setup_future_usage: "off_session",
          }
        );
        if (error) {
          console.log(error.message);
          setIsPaymentLoading(false);
          return toast(error.message, {
            ...notificationsParam,
            toastId: "unique",
          });
        } else if (paymentIntent) {
          const finalizePayment = async () => {
            const responsePending = await send3dSecureResponse();
            const { token }: { token: string } = await responsePending.json();
            const res = await getUser(user._id, token);
            const userData: any = await res.json();
            if (
              userData.subscription === null ||
              userData.subscription === undefined ||
              userData.subscription === 0
            ) {
              finalizePayment();
              return;
            } else {
              setUpdatedUser({ ...userData, token: token });
              localStorage.setItem(
                "user",
                JSON.stringify({ ...userData, token: token })
              );
              stripeSubscriptionPurchased(
                "Default offers",
                selectedOffer.offer.id,
                offer ? offer.amount : selectedOffer.offer.amount,
                offer ? offer.interval : selectedOffer.offer.interval,
                undefined,
                context ? context : undefined,
                currentEpisode &&
                  currentEpisode.episode &&
                  currentEpisode.episode.info &&
                  seriesLanguage
                  ? currentEpisode.episode.info[seriesLanguage].title
                  : undefined,
                currentEpisode && currentEpisode.episode
                  ? currentEpisode.episode._id
                  : undefined,
                currentEpisode && currentEpisode.episode && seriesLanguage
                  ? currentEpisode.episode.season
                  : undefined,
                currentEpisode && currentEpisode.series
                  ? currentEpisode.series._id
                  : undefined,
                currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.info &&
                  seriesLanguage
                  ? currentEpisode.series.info[seriesLanguage].title
                  : undefined,
                profile ? profile.isChild : undefined,
                couponId ? couponId : undefined
              );
              stripeViewSuccessScreen(
                selectedOffer.offer.id,
                selectedOffer.offer.amount,
                selectedOffer.offer.interval,
                couponId ? couponId : selectedOffer.offer.id,
                undefined,
                priceText ? priceText : selectedOffer.offer.amount,
                context ? context : undefined,
                currentEpisode &&
                  currentEpisode.episode &&
                  currentEpisode.episode.info &&
                  seriesLanguage
                  ? currentEpisode.episode.info[seriesLanguage].title
                  : undefined,
                currentEpisode && currentEpisode.episode
                  ? currentEpisode.episode._id
                  : undefined,
                currentEpisode && currentEpisode.episode && seriesLanguage
                  ? currentEpisode.episode.season
                  : undefined,
                currentEpisode && currentEpisode.series
                  ? currentEpisode.series._id
                  : undefined,
                currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.info &&
                  seriesLanguage
                  ? currentEpisode.series.info[seriesLanguage].title
                  : undefined,
                profile ? profile.isChild : undefined
              );
  
              setIsSuccess(true);
              return setIsPaymentLoading(false);
            }
          };

          finalizePayment();
        }
      } else if (tokenRes && tokenRes.stripe_intent_type === "setup_intent") {
        const { setupIntent, error } = await stripe!.confirmCardSetup(
          tokenRes.stripe_client_secret,
          {
            payment_method: {
              card: cardElement!,
            },
          }
        );
        if (error) {
          setIsPaymentLoading(false);
          return toast(error.message, {
            ...notificationsParam,
            toastId: "unique",
          });
        } else if (setupIntent) {
          const finalizePayment = async () => {
            const responsePending = await send3dSecureResponse();
            const { token }: { token: string } = await responsePending.json();
            const res = await getUser(user._id, token);
            const userData: any = await res.json();
            if (
              userData.subscription === null ||
              userData.subscription === undefined ||
              userData.subscription === 0
            ) {
              finalizePayment();
              return;
            } else {
              setUpdatedUser({ ...userData, token });
              localStorage.setItem(
                "user",
                JSON.stringify({ ...userData, token })
              );
              stripeSubscriptionPurchased(
                "Default offers",
                selectedOffer.offer.id,
                offer ? offer.amount : selectedOffer.offer.amount,
                offer ? offer.interval : selectedOffer.offer.interval,
                undefined,
                context ? context : undefined,
                currentEpisode &&
                  currentEpisode.episode &&
                  currentEpisode.episode.info &&
                  seriesLanguage
                  ? currentEpisode.episode.info[seriesLanguage].title
                  : undefined,
                currentEpisode && currentEpisode.episode
                  ? currentEpisode.episode._id
                  : undefined,
                currentEpisode && currentEpisode.episode && seriesLanguage
                  ? currentEpisode.episode.season
                  : undefined,
                currentEpisode && currentEpisode.series
                  ? currentEpisode.series._id
                  : undefined,
                currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.info &&
                  seriesLanguage
                  ? currentEpisode.series.info[seriesLanguage].title
                  : undefined,
                profile ? profile.isChild : undefined,
                couponId ? couponId : undefined
              );
              stripeViewSuccessScreen(
                selectedOffer.offer.id,
                selectedOffer.offer.amount,
                selectedOffer.offer.interval,
                couponId ? couponId : selectedOffer.offer.id,
                undefined,
                priceText ? priceText : selectedOffer.offer.amount,
                context ? context : undefined,
                currentEpisode &&
                  currentEpisode.episode &&
                  currentEpisode.episode.info &&
                  seriesLanguage
                  ? currentEpisode.episode.info[seriesLanguage].title
                  : undefined,
                currentEpisode && currentEpisode.episode
                  ? currentEpisode.episode._id
                  : undefined,
                currentEpisode && currentEpisode.episode && seriesLanguage
                  ? currentEpisode.episode.season
                  : undefined,
                currentEpisode && currentEpisode.series
                  ? currentEpisode.series._id
                  : undefined,
                currentEpisode &&
                  currentEpisode.series &&
                  currentEpisode.series.info &&
                  seriesLanguage
                  ? currentEpisode.series.info[seriesLanguage].title
                  : undefined,
                profile ? profile.isChild : undefined
              );
              setIsSuccess(true);
              return setIsPaymentLoading(false);
            }
          };
          finalizePayment();
        }
      }
    } catch (e) {
      console.log(e);
      setIsPaymentLoading(false);
      return toast(language("codeError"), {
        ...notificationsParam,
        toastId: "unique",
      });
    }
  };
  return {
    priceText,
    isPaymentLoading,
    couponTextResponse,
    isCouponLoading,
    couponValue,
    setCouponValue,
    applyCoupon,
    handleSubmit,
    couponErrorText,
    isCouponValidated,
    stripe,
    elements,
  };
};
