import React, { ReactElement } from "react";
import SeriesType from "../interfaces/SeriesType";
import { ProductStripeType } from "../interfaces/UserType";
import { language } from "../stores/18nStore";

interface Props {
  showPayment: Function;
  offerSelected: Function;
  firstOffer: ProductStripeType | undefined;
  secondOffer: ProductStripeType | undefined;
  selectedOffer: { index: number; offer: ProductStripeType } | undefined;
  seriesLanguage: string;
  seriesContext?: SeriesType | undefined;
  isNewSession?: true;
}

export default function PopUpOffers({
  showPayment,
  offerSelected,
  firstOffer,
  secondOffer,
  selectedOffer,
  seriesLanguage,
  seriesContext,
  isNewSession,
}: Props): ReactElement {
  return (
    <>
      <div className="pop-up-header" id="offers">
        <img
          className={seriesContext ? "header-image-pop-up" : "logo"}
          src={
            seriesContext
              ? seriesContext.titleImage
              : "/image/whiteSybelLogo.svg"
          }
          alt=""
        />
        <div className="offer-sentence-container">
          <p className="offer-sentence">{language("popupArguments1")}</p>
          <p className="offer-sentence">{language("popupArguments2")}</p>
          <p className="offer-sentence">{language("popupArguments3")}</p>
          <p className="offer-sentence">{language("popupArguments4")}</p>
        </div>
        <p
          style={{
            marginTop: isNewSession ? "0px" : undefined,
            marginBottom: isNewSession ? "30px" : undefined,
          }}
        >
          {seriesContext &&
          selectedOffer &&
          selectedOffer.offer.trial_period_days &&
          selectedOffer.offer.trial_period_days !== 0 &&
          !isNewSession
            ? `${language("seriesContextPopUpTitle")} ${
                seriesContext.info[seriesLanguage].title
              }`
            : !isNewSession &&
              seriesContext &&
              selectedOffer &&
              (!selectedOffer.offer.trial_period_days ||
                selectedOffer.offer.trial_period_days !== 0)
            ? `${language("seriesContextPopUpTitleNoTrial")} ${
                seriesContext.info[seriesLanguage].title
              }`
            : language("popUpTitle")}
        </p>
{/*         <p style={{marginTop: isNewSession ? "0px" : undefined, marginBottom: isNewSession ? "30px" : undefined}}>
 {language("popupPomote1")}
 {language("popupPomote2")}
 {language("popupPomote3")}
 {language("popupPomote4")}
        </p> */}
      </div>
      <div style={{ marginTop: "15px" }} className="pop-up-offers-container">
        {firstOffer && (
          <div
            style={{
              border:
                selectedOffer &&
                selectedOffer.offer &&
                selectedOffer.offer.id === firstOffer.id
                  ? " 2px solid #FF8475"
                  : "2px solid #A5A5A5",
              opacity:
                selectedOffer &&
                selectedOffer.offer &&
                selectedOffer.offer.id === firstOffer.id
                  ? 1
                  : 0.4,
            }}
            className="pop-up-card"
            onClick={() => offerSelected(0, firstOffer)}
          >
            {/*                 <p
                  className="offers-special-banner"
                  style={{
                    backgroundColor:
                      selectedOffer &&
                      selectedOffer.offer &&
                      selectedOffer.offer.id === firstOffer.id
                        ? "#F23568"
                        : "#A5A5A5",
                  }}
                >
                  {language("summerOffer")}
                  <span>{` (-${Math.floor(
                    100 - (firstOffer.amount / (secondOffer!.amount * 12)) * 100
                  )}%)`}</span>
                </p> */}
            <p className="offer-title">{language("yearly")}</p>
            <p className="offer-price">
              {language("defaultYearPrice", {
                price: `${firstOffer.amount.toFixed(2)} ${firstOffer.currency}`,
              })}
            </p>
            {/*             {firstOffer.trial_period_days &&
            firstOffer.trial_period_days !== 0 ? (
              <p className="offer-trial">
                {language("YearlyOfferUnitaryPrice", {
                  price: firstOffer.amount,
                })}
              </p>
            ) : null} */}
          </div>
        )}
        {secondOffer && (
          <div
            style={{
              border:
                selectedOffer &&
                selectedOffer.offer &&
                selectedOffer.offer.id === secondOffer.id
                  ? " 2px solid #FF8475"
                  : "2px solid #A5A5A5",
              opacity:
                selectedOffer &&
                selectedOffer.offer &&
                selectedOffer.offer.id === secondOffer.id
                  ? 1
                  : 0.4,
            }}
            className="pop-up-card"
            onClick={() => offerSelected(1, secondOffer)}
          >
            <p className="offer-title">{language("monthly")}</p>
            <p className="offer-price">
              {language("defaultMonthPrice", {
                price: `${secondOffer.amount.toFixed(2)} ${
                  secondOffer.currency
                }`,
              })}
            </p>
            {secondOffer.trial_period_days &&
            secondOffer.trial_period_days !== 0 ? (
              <p className="offer-trial">
                {secondOffer.trial_period_days}{" "}
                {language("trialPremium", {
                  count: secondOffer.trial_period_days,
                })}
              </p>
            ) : null}
          </div>
        )}
      </div>
      <button
        className="activate-payment"
        type="submit"
        onClick={(e) => showPayment(e)}
      >
        <p>
          {selectedOffer &&
          selectedOffer.offer &&
          selectedOffer.offer.trial_period_days &&
          selectedOffer.offer.trial_period_days !== 0
            ? language("beginTrialPremium", {
                count: selectedOffer.offer.trial_period_days,
              })
            : language("continue")}
        </p>
      </button>
    </>
  );
}
