import { useState } from "react";
import { GoogleLoginResponse } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Action, Dispatch } from "redux";
import { LoginFacebookResponse } from "../interfaces/FaceBookLoginType";
import { Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import UserType from "../interfaces/UserType";
import { oauthSignInService, signUpService } from "../services/AuthService";
import { addPromoService } from "../services/UserService";
import { language } from "../stores/18nStore";
import EventStore from "../stores/AmplitudeStore";
import authFormValidator from "../utils/AuthFormValidator";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";

export default function UseSocialConnect(renault?: boolean): {
  isGoogleLoading: boolean;
  isFacebookLoading: boolean;
  signWithFacebook: () => void;
  signWithGoogle: (
    res: GoogleLoginResponse | any
  ) => Promise<string | undefined | any>;
} {
  const { nextUrl } = useSelector((state: StoreType) => ({
    ...state.RouterReducer,
  }));
  const history = useHistory();
  const {
    userSignIn,
    userSignUp,
    enterDiscoverCoupon,
    redeemDiscoverCouponError,
  } = EventStore();
  const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(false);
  const [isFacebookLoading, setIsFacebookLoading] = useState<boolean>(false);
  const dispatch: Dispatch<Action> = useDispatch();

  const appId = process.env.REACT_APP_FB_APP_ID;
  const toastId = "toastUnique";

  const checkCoupon = async (user: UserType) => {
    console.log("je rentre");
    
    enterDiscoverCoupon(JSON.parse(sessionStorage.getItem("redeemCode")!));
    const { error } = authFormValidator(
      JSON.parse(sessionStorage.getItem("redeemCode")!)
    );
    if (!error) {
      try {
        const parsedCode = JSON.parse(sessionStorage.getItem("redeemCode")!);
        const couponPending = await addPromoService(parsedCode, user);
        if (couponPending.status === 409) {
          redeemDiscoverCouponError(
            JSON.parse(sessionStorage.getItem("redeemCode")!),
            language("couponAlreadySubscribed")
          );
          dispatch({ type: Case.AUTH_IS_NOT_LOADING });
          return toast(language("couponAlreadySubscribed"), {
            ...notificationsParam,
            toastId,
          });
        } else if (couponPending.status === 400) {
          redeemDiscoverCouponError(
            JSON.parse(sessionStorage.getItem("redeemCode")!),
            language("couponFail")
          );
          dispatch({ type: Case.AUTH_IS_NOT_LOADING });
          return toast(language("couponFail"), {
            ...notificationsParam,
            toastId,
          });
        } else {
          const couponData: {
            user: UserType;
            token: string;
          } = await couponPending.json();
          dispatch({
            type: Case.UPDATE_USER,
            payload: { user: { ...couponData.user, token: couponData.token } },
          });
          window.scrollTo(0, 0);
          history.push("/redeem-success", {
            fromRedeem: true,
            noNav: true,
          });
        }
      } catch (e) {
        dispatch({ type: Case.AUTH_IS_NOT_LOADING });
        console.log(e);
        redeemDiscoverCouponError(
          JSON.parse(sessionStorage.getItem("redeemCode")!),
          JSON.stringify(e)
        );
        toast(language("codeError"), { ...notificationsParam, toastId });
      }
    } else {
      redeemDiscoverCouponError(sessionStorage.getItem("redeemCode")!, error);
      toast(language(error), { ...notificationsParam, toastId });
    }
  };

  const setFbAsyncInit = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v9.0",
      });
    };
  };
  const loadSdkAsynchronously = () => {
    ((d, s, id) => {
      const element: any = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode!.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };
  const createFbRoot = () => {
    let fbRoot = document.getElementById("fb-root");
    if (!fbRoot) {
      fbRoot = document.createElement("div");
      fbRoot.id = "fb-root";
      document.body.appendChild(fbRoot);
    }
  };
  const asyncSignInWithFacebookService = async (
    response: LoginFacebookResponse
  ) => {
    setIsFacebookLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        partner: { renault },
        accessToken: response.authResponse.accessToken,
        facebookId: response.authResponse.userID,
        expirationDate:
          new Date().getTime() / 1000 + response.authResponse.expiresIn,
      }),
    };
    try {
      const dataPending = await fetch(
        `${process.env.REACT_APP_API}/auth/signup`,
        requestOptions
      );
      const facebookData:
        | {
            user: {
              _id: string;
              coupons: [];
              email: string;
              emailVerified: boolean;
              existed: boolean;
              token: string;
            };
          }
        | any = await dataPending.json();
      if (facebookData.user) {
        localStorage.setItem("user", JSON.stringify(facebookData.user));
      }
      if (
        localStorage.getItem("redirectUri") &&
        localStorage.getItem("state") &&
        localStorage.getItem("clientId")
      ) {
        const redirectUri = localStorage.getItem("redirectUri")!.slice();
        const state = localStorage.getItem("state")!.slice();
        const clientId = localStorage.getItem("clientId")!.slice();
        try {
          const tokenPending = await oauthSignInService(
            facebookData.user,
            clientId
          );
          const { token }: { token: string } = await tokenPending.json();
          localStorage.clear();
          sessionStorage.clear();
          return (window.location.href = `${redirectUri}#access_token=${token}&token_type=Bearer&state=${state}`);
        } catch (e) {
          console.log(e);
        }
      } else {
        if (facebookData.alreadySubscribed) {
          setIsFacebookLoading(false);
          sessionStorage.removeItem("renault");
          return toast(
            language("paymentHasAnAccountParagraphe"),
            notificationsParam
          );
        }
        if (facebookData.user.existed) {
          dispatch({
            type: Case.UPDATE_USER,
            payload: { user: facebookData.user },
          });
          userSignIn("Facebook", nextUrl ? nextUrl : "Default");
          localStorage.setItem("sy_signup_type", "Facebook");
          window.analytics.group(facebookData.user._id, {
            email: facebookData.user.email,
          });
          window.analytics.track("Signed In", {
            subscription: !!facebookData.user.subscription
              ? facebookData.user.subscribtion
              : 0,
            signup_type: "Facebook",
            email: facebookData.user.email,
            platform: "Web",
          });
          if (!!sessionStorage.getItem("redeemCode")) {
            checkCoupon(facebookData.user);
            return history.push("/redeem-success", {
              noNav: true,
            });
          }
          history.push(
            renault
              ? "/renault-activated"
              : nextUrl
              ? nextUrl
              : "/choose-profile"
          );
        } else {
          dispatch({
            type: Case.UPDATE_USER,
            payload: { user: facebookData.user },
          });
          localStorage.setItem("sy_signup_type", "Facebook");
          window.analytics.group(facebookData.user._id, {
            email: facebookData.user.email,
          });
          window.analytics.track("Signed Up", {
            subscription: !!facebookData.user.subscription
              ? facebookData.user.subscribtion
              : 0,
            signup_type: "Facebook",
            email: facebookData.user.email,
            platform: "Web",
          });
          userSignUp("Facebook", nextUrl ? nextUrl : "Default");
          if (!!sessionStorage.getItem("redeemCode")) {
            checkCoupon(facebookData.user);
            return history.push("/redeem-success", {
              noNav: true,
            });
          }
          history.push(renault ? "/renault-activated" : "/setup-profile", {
            nextUrl,
            isLegit: true,
            noNav: true,
          });
        }
      }
      setIsFacebookLoading(false);
    } catch (e) {
      console.log(e);
      setIsFacebookLoading(false);
      toast(language("codeError"), notificationsParam);
    }
  };
  const signWithFacebook = () => {
    setFbAsyncInit();
    loadSdkAsynchronously();
    createFbRoot();
    window.FB.login(
      async function (response: LoginFacebookResponse) {
        return await asyncSignInWithFacebookService(response);
      },
      { scope: "email,user_birthday,user_gender" }
    );
  };
  const signWithGoogle = async (res: GoogleLoginResponse | any) => {
    setIsGoogleLoading(true);
    if (res.tokenId) {
      try {
        const googlePending = await signUpService(
          {
            googleToken: res.tokenId,
            platform: "WEB",
          },
          renault ? true : undefined
        );
        const googleData:
          | {
              user: {
                _id: string;
                coupons: [];
                email: string;
                emailVerified: boolean;
                existed: boolean;
                token: string;
              };
            }
          | any = await googlePending.json();
        if (googleData.user) {
          localStorage.setItem("user", JSON.stringify(googleData.user));
        }
        if (
          localStorage.getItem("redirectUri") &&
          localStorage.getItem("state") &&
          localStorage.getItem("clientId")
        ) {
          const redirectUri = localStorage.getItem("redirectUri")!.slice();
          const state = localStorage.getItem("state")!.slice();
          const clientId = localStorage.getItem("clientId")!.slice();
          try {
            const tokenPending = await oauthSignInService(
              googleData.user,
              clientId
            );
            const { token }: { token: string } = await tokenPending.json();
            localStorage.clear();
            sessionStorage.clear();
            return (window.location.href = `${redirectUri}#access_token=${token}&token_type=Bearer&state=${state}`);
          } catch (e) {
            console.log(e);
          }
        } else {
          if (googleData.alreadySubscribed) {
            setIsGoogleLoading(false);
            sessionStorage.removeItem("renault");
            return toast(
              language("paymentHasAnAccountParagraphe"),
              notificationsParam
            );
          } else if (googleData.user.existed) {
            dispatch({
              type: Case.UPDATE_USER,
              payload: { user: googleData.user },
            });
            userSignIn("Google", nextUrl ? nextUrl : "Default");
            localStorage.setItem("sy_signup_type", "Google");
            window.analytics.group(googleData.user._id, {
              email: googleData.user.email,
            });
            window.analytics.track("Signed In", {
              subscription: !!googleData.user.subscription
                ? googleData.user.subscribtion
                : 0,
              signup_type: "Google",
              email: googleData.user.email,
              platform: "Web",
            });
            if (!!sessionStorage.getItem("redeemCode")) {
              checkCoupon(googleData.user);
              return history.push("/redeem-success", {
                noNav: true,
              });
            }
            history.push(
              renault
                ? "/renault-activated"
                : nextUrl
                ? nextUrl
                : "/choose-profile"
            );
          } else {
            dispatch({
              type: Case.UPDATE_USER,
              payload: { user: googleData.user },
            });
            localStorage.setItem("sy_signup_type", "Google");
            window.analytics.group(googleData.user._id, {
              email: googleData.user.email,
            });

            window.analytics.track("Signed Up", {
              subscription: !!googleData.user.subscription
                ? googleData.user.subscribtion
                : 0,
              signup_type: "Google",
              email: googleData.user.email,
              platform: "Web",
            });
            userSignUp("Google", nextUrl ? nextUrl : "Default");
            if (!!sessionStorage.getItem("redeemCode")) {
              checkCoupon(googleData.user);
              return history.push("/redeem-success", {
                noNav: true,
              });
            }
            history.push(renault ? "/renault-activated" : "/setup-profile", {
              nextUrl,
              isLegit: true,
              noNav: true,
            });
          }
        }
        setIsGoogleLoading(false);
      } catch (e) {
        console.log(e);

        setIsGoogleLoading(false);
        toast(language("codeError"), notificationsParam);
      }
    } else {
      setIsGoogleLoading(false);
      toast(language("codeError"), notificationsParam);
    }
  };

  return {
    isGoogleLoading,
    isFacebookLoading,
    signWithFacebook,
    signWithGoogle,
  };
}
