import React, { ReactElement, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { Link, useLocation } from "react-router-dom";
import "../styles/burger.scss";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import EventStore from "../stores/AmplitudeStore";
import { language } from "../stores/18nStore";
import HomeProfileDeployed from "./HomeProfileDeployed";

interface Props {
  setUniversToShow: React.Dispatch<React.SetStateAction<string | undefined>>;
  universToShow: string | undefined;
  setSearch: (value: React.SetStateAction<string>) => void;
}
export default function HomeNavBarBurger({
  setSearch,
  universToShow,
  setUniversToShow,
}: Props): ReactElement {
  const { profile, universes } = useSelector((state: StoreType) => ({
    ...state.ProfileReducer,
    ...state.PlayerReducer,
  }));
  const location = useLocation();
  const { seriesLanguage } = UseSeriesLanguage();
  const { openMyList, openHome, openUniverse } = EventStore();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Menu
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      isOpen={isOpen}
      itemListElement="div"
      className="burger"
      styles={{ bmMenuWrap: { height: undefined } }}
      bodyClassName={"overflow"}
      customCrossIcon={
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              opacity="0.6"
              d="M40 20C40 8.9543 31.0457 -1.35705e-06 20 -8.74228e-07C8.9543 -3.91405e-07 -1.35705e-06 8.95431 -8.74228e-07 20C-3.91405e-07 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20Z"
              fill="black"
            />
            <path
              d="M25.4821 12.8229L19.9781 18.3109L14.5061 12.8389C14.2738 12.6567 13.9828 12.5659 13.6881 12.5837C13.3933 12.6015 13.1153 12.7265 12.9065 12.9353C12.6978 13.1441 12.5727 13.4221 12.5549 13.7168C12.5372 14.0115 12.6279 14.3026 12.8101 14.5349L18.2821 20.0069L12.8421 25.4469C12.6599 25.6792 12.5692 25.9703 12.5869 26.265C12.6047 26.5597 12.7298 26.8377 12.9386 27.0465C13.1473 27.2553 13.4253 27.3803 13.72 27.3981C14.0148 27.4159 14.3058 27.3251 14.5381 27.1429L19.9781 21.7029L25.4501 27.1749C25.679 27.362 25.9684 27.4586 26.2637 27.4466C26.5591 27.4346 26.8397 27.3148 27.0526 27.1098C27.2655 26.9048 27.3959 26.6288 27.419 26.3342C27.4421 26.0395 27.3564 25.7466 27.1781 25.5109L21.7061 20.0389L27.1861 14.5589C27.3001 14.447 27.391 14.3137 27.4534 14.1668C27.5159 14.0198 27.5489 13.8619 27.5504 13.7022C27.5518 13.5425 27.5219 13.384 27.4621 13.2359C27.4024 13.0878 27.314 12.9529 27.2021 12.8389C27.0902 12.7249 26.957 12.6341 26.81 12.5716C26.663 12.5091 26.5052 12.4761 26.3454 12.4747C26.1857 12.4732 26.0273 12.5031 25.8792 12.5629C25.731 12.6226 25.5961 12.711 25.4821 12.8229Z"
              fill="#EDEFEF"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="40" height="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
    >
      <a
        onClick={() => setIsOpen(false)}
        className="discover-app"
        href="https://app.adjust.com/uatq2wh"
      >
        {language("discoverApp")}
      </a>
      <div className="burger-top">
        <HomeProfileDeployed
          universToShow={universToShow}
          setUniversToShow={setUniversToShow}
          burger
        />
        <div className="burger-universe-warpper">
          <div className="burger-universe">
            <Link
              onClick={() => {
                setIsOpen(false);
                openHome(
                  "NavBar Actions",
                  location.pathname,
                  universes && universToShow
                    ? universes[
                        universes.findIndex(
                          (each) => each.headerCategoryId === universToShow
                        )
                      ].title
                    : "Home",

                  seriesLanguage
                );
                setUniversToShow(undefined);
                setSearch("");
              }}
              to="/home"
              style={
                !universToShow && location.pathname === "/home"
                  ? { fontWeight: "bold" }
                  : {}
              }
            >
              {language("home")}
            </Link>

            <Link
              onClick={() => {
                setIsOpen(false);
                setSearch("");
                openMyList(
                  location.pathname,
                  universes && universToShow
                    ? universes[
                        universes.findIndex(
                          (each) => each.headerCategoryId === universToShow
                        )
                      ].title
                    : "Home",
                  seriesLanguage
                );
              }}
              to="/my-list"
              style={
                location.pathname === "/my-list" ? { fontWeight: "bold" } : {}
              }
            >
              {language("likeList")}
            </Link>
            {universes && profile && !profile.isChild
              ? universes.map((each, index) => (
                  <Link
                    to={{
                      pathname: "/home",
                      state: { headerCategoryId: each.headerCategoryId },
                    }}
                    className="universe-nav-link"
                    key={index}
                    onClick={() => {
                      setIsOpen(false);
                      setSearch("");
                      openUniverse(
                        universToShow && universes
                          ? universes[
                              universes.findIndex(
                                (each) =>
                                  each.headerCategoryId === universToShow
                              )
                            ].title
                          : "Home",
                        location.pathname,
                        seriesLanguage
                      );
                      setUniversToShow(each.headerCategoryId);
                    }}
                    style={
                      each.headerCategoryId === universToShow
                        ? { fontWeight: "bold" }
                        : {}
                    }
                  >
                    {each.title}
                  </Link>
                ))
              : null}
          </div>
        </div>
      </div>
    </Menu>
  );
}
