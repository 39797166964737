import React, { ReactElement } from "react";
import { createUseStyles } from "react-jss";

interface Props {
  progressBarSize: number;
}

export default function SeriesEpisodesProgressBar({
  progressBarSize,
}: Props): ReactElement {
  const useStyles = createUseStyles({
    inProgressBar: {
      width: "100%",
      height: "3px",
      position: "absolute",
      bottom: "0",
      left: "0",
      zIndex: 10000,
      background: "rgba(242, 53, 103, 0.213)",
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        background: "linear-gradient(90.91deg, #f23568 0%, #ff8475 100%)",
        width: (progressBarSize: Props) => `${progressBarSize}%`,
        height: "100%",
        display: "block",
        transition: "width .2s ease-in",
      },
    },
  });
  const classes = useStyles(progressBarSize);
  return (
    <div>
      <div className={classes.inProgressBar}></div>
    </div>
  );
}
