import React, { Dispatch, ReactElement, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Action, Case } from "../interfaces/ReducerCase";
import { editProfile } from "../services/ProfileService";
import SeriesHeaderProgressBar from "./SeriesHeaderProgressBar";
import { createUseStyles } from "react-jss";
import { seriesHeaderStyle } from "../utils/DynamicStyle";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import { UsePlay } from "../ressources/UsePlay";
import EventStore from "../stores/AmplitudeStore";
import { useLocation } from "react-router";
import SeriesLocationTypes from "../interfaces/SeriesLocationTypes";
interface Props {
  id: string;
  toastId: string;
  isLiked: boolean;
  setIsLiked: Function;
  seasonIndex: number;
}

export default function SeriesHeader({
  id,
  toastId,
  isLiked,
  setIsLiked,
  seasonIndex,
}: Props): ReactElement {
  const { series, profile, user, episodes, seriesHistory, profiles, uuid } =
    useSelector((state: StoreType) => ({
      ...state.UserReducer,
      ...state.ProfileReducer,
      ...state.SeriesReducer,
    }));
  const { seriesLanguage } = UseSeriesLanguage();
  const useStyles = createUseStyles({
    "series-header": {
      ...seriesHeaderStyle.seriesHeader,
      "&::before": {
        ...seriesHeaderStyle.before,
        backgroundImage: (url: string | undefined) =>
          url ? `url(${url})` : 'url("/image/coverImagePlaceholder.svg")',
      },
      "&::after": {
        ...seriesHeaderStyle.after,
      },
    },
    "@media (max-width: 1084px)": {
      "series-header": {
        ...seriesHeaderStyle.seriesHeaderQueries,
      },
    },
  });
  const location = useLocation<SeriesLocationTypes>();
  const { bookmark, unbookmark, clickSeeMoreSeriesDescription, playSample } =
    EventStore();
  const classes = useStyles(
    series[id!] &&
      series[id!].info &&
      series[id!].info[seriesLanguage] &&
      series[id!].info[seriesLanguage].coverImage
  );
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const dispatch: Dispatch<Action> = useDispatch();

  const addToLikeList = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const isLikedTemp = isLiked;
    if (!isLikedTemp) {
      bookmark(
        "Series Header Button",
        id,
        profile!.isChild,
        series[id].info[seriesLanguage].title,
        series[id].isChild,
        series[id].emotion ? series[id].emotion!.join() : undefined,
        undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentUniverse
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.universeId
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentPosition
          : undefined
      );
      window.analytics.track("Content Bookmarked", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        session_id: uuid,
        platform: "Web",
        series_id: id,
        content_name: series[id].info[seriesLanguage].title,
        content_length: series[id].length,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        channel_id:
          series[id] && series[id].channel
            ? series[id].channel!._id
            : undefined,
        channel_name:
          series[id] && series[id].channel
            ? series[id].channel!.name
            : undefined,
        channel_name_code:
          series[id] && series[id].channel
            ? series[id].channel!.nameCode
            : undefined,
        channel_umbrella_id:
          series[id] && series[id].channel
            ? series[id].channel!._id
            : undefined,
        channel_umbrella_name:
          series[id] && series[id].channel
            ? series[id].channel!.name
            : undefined,
        channel_umbrella_name_code:
          series[id] && series[id].channel
            ? series[id].channel!.umbrella.nameCode
            : undefined,
        has_rss_feed:
          series[id] &&
          series[id].info[seriesLanguage] &&
          series[id].info[seriesLanguage].rssFeed
            ? true
            : false,
        recomm_id:
          location &&
          location.state &&
          location.state.contentEventDetails?.recommId &&
          location.state.contentEventDetails &&
          location.state.contentEventDetails?.recommId,
      });
    } else {
      unbookmark(
        "Series Header Button",
        id,
        profile!.isChild,
        series[id].info[seriesLanguage].title,
        series[id].isChild,
        series[id].emotion ? series[id].emotion!.join() : undefined,
        undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentUniverse
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.universeId
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentPosition
          : undefined
      );
      window.analytics.track("Content Unbookmarked", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        session_id: uuid,
        platform: "Web",
        series_id: id,
        content_name: series[id].info[seriesLanguage].title,
        content_length: series[id].length,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        channel_id:
          series[id] && series[id].channel
            ? series[id].channel!._id
            : undefined,
        channel_name:
          series[id] && series[id].channel
            ? series[id].channel!.name
            : undefined,
        channel_name_code:
          series[id] && series[id].channel
            ? series[id].channel!.nameCode
            : undefined,
        channel_umbrella_id:
          series[id] && series[id].channel
            ? series[id].channel!._id
            : undefined,
        channel_umbrella_name:
          series[id] && series[id].channel
            ? series[id].channel!.name
            : undefined,
        channel_umbrella_name_code:
          series[id] && series[id].channel
            ? series[id].channel!.umbrella.nameCode
            : undefined,
        has_rss_feed:
          series[id] &&
          series[id].info[seriesLanguage] &&
          series[id].info[seriesLanguage].rssFeed
            ? true
            : false,
        recomm_id:
          location &&
          location.state &&
          location.state.contentEventDetails?.recommId &&
          location.state.contentEventDetails &&
          location.state.contentEventDetails?.recommId,
      });
    }
    setIsLiked(!isLiked);
    try {
      const profilePending = await editProfile(
        profile!,
        user,
        isLikedTemp
          ? profiles[
              profiles.findIndex((each) => each._id === profile!._id)
            ].likeList
              .slice()
              .filter((each) => each !== id)
          : [
              ...profiles[
                profiles.findIndex((each) => each._id === profile!._id)
              ].likeList.slice(),
              id,
            ]
      );
      const profileUpdated = await profilePending.json();
      dispatch({
        type: Case.UPDATE_PROFILES,
        payload: { profile: profileUpdated },
      });
    } catch (e) {
      setIsLiked(!isLiked);
    }
  };

  const [copy, setCopy] = useState({
    value: `${window.location.host}/series/${series[id]._id}`,
    copied: false,
  });
  useEffect(() => {
    if (copy.copied) {
      toast(<p className="green">{language("seriesCopy")}</p>, {
        ...notificationsParam,
        toastId,
      });
    }
  }, [copy, toastId]);
  const { play } = UsePlay();
  const playEpisodeHeader = () => {
    if (
      (episodes &&
        episodes[id] &&
        episodes[id][seasonIndex] &&
        episodes[id][seasonIndex].length === 0) ||
      (episodes &&
        episodes[id] &&
        episodes[id][seasonIndex] &&
        !episodes[id][seasonIndex][0].info[seriesLanguage])
    ) {
      return;
    } else if (
      seriesLanguage &&
      seriesHistory &&
      seriesHistory[id] &&
      seriesHistory[id].episode &&
      episodes &&
      episodes[id] &&
      episodes[id][seriesHistory[id].episode.season]
    ) {
      play(
        id,
        episodes[id][seriesHistory![id].episode.season][
          episodes[id][seriesHistory![id].episode.season].findIndex(
            (each) => each._id === seriesHistory![id].episode._id
          )
        ],
        "Series",
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentUniverse
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.universeId
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.categoryType
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentPosition
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentCategory
          : undefined,
        Math.round(seriesHistory![id].progress),
        Math.round(
          seriesHistory![id].progress / seriesHistory![id].episode.length
        ),
        location &&
          location.state &&
          location.state.contentEventDetails?.recommId &&
          location.state.contentEventDetails &&
          location.state.contentEventDetails?.recommId
      );
    } else if (seriesLanguage) {
      play(
        id,
        episodes[id][seasonIndex].filter(
          (each) => new Date(each.publicationDate) <= new Date()
        )[0],
        "Series",
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentUniverse
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.universeId
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.categoryType
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentPosition
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentCategory
          : undefined,
        0,
        0,
        location &&
          location.state &&
          location.state.contentEventDetails?.recommId &&
          location.state.contentEventDetails &&
          location.state.contentEventDetails?.recommId
      );
    }
  };
  const playExtract = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch({ type: Case.PLAYER_IS_LOADING });
    dispatch({ type: Case.REINIT_IS_SEEKED_TO_HISTORY });
    if (seriesLanguage) {
      playSample(
        series[id].isChild,
        "Series",
        series[id].emotion ? series[id].emotion!.join() : "",
        series[id]._id,
        series[id].info[seriesLanguage].title,
        profile!.isChild,
        undefined,
        "Home Header Carousel",
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.categoryPosition
          : undefined,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentUniverse
          : "Main Homepage"
      );
      dispatch({
        type: Case.SET_CURRENT_EPISODE,
        payload: {
          series: series[id],
          episodes: series[id].chronological
            ? episodes[id][seasonIndex].filter(
                (each) => new Date(each.publicationDate) <= new Date()
              )
            : episodes[id][seasonIndex].filter(
                (each) => new Date(each.publicationDate) <= new Date()
              ),
          lng: seriesLanguage,
          sample: series[id].info[seriesLanguage].sample,
          uuid,
        },
      });
      window.analytics.track("Audio Content Started", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        platform: "Web",
        session_id: uuid,
        series_id: id,
        episode_id: "trailer",
        content_name: series[id].info[seriesLanguage].title,
        content_length: Math.round(series[id].length),
        position: 0,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        chronological: series[id].chronological,
        classification: series[id].classification,
        emotion: series[id].emotion,
        format: series[id].format,
        is_unit: series[id].isUnit,
        publication_date: series[id].publicationDate,
        seasons_list: series[id].seasonsList,
        header_mention: series[id].info[seriesLanguage].headerMention,
        context: "Series",
        channel_id:
          series[id] && series[id].channel
            ? series[id].channel!._id
            : undefined,
        channel_name:
          series[id] && series[id].channel
            ? series[id].channel!.name
            : undefined,
        channel_name_code:
          series[id] && series[id].channel
            ? series[id].channel!.nameCode
            : undefined,
        channel_umbrella_id:
          series[id] && series[id].channel
            ? series[id].channel!._id
            : undefined,
        channel_umbrella_name:
          series[id] && series[id].channel
            ? series[id].channel!.name
            : undefined,
        channel_umbrella_name_code:
          series[id] && series[id].channel
            ? series[id].channel!.umbrella.nameCode
            : undefined,
        has_rss_feed:
          series[id] &&
          series[id].info[seriesLanguage] &&
          series[id].info[seriesLanguage].rssFeed
            ? true
            : false,

        child_content: series[id] ? series[id].isChild : undefined,
        genre: series[id] ? series[id].genre : undefined,
      });
    }
    dispatch({ type: Case.PLAYER_IS_NOT_LOADING });
  };
  const [mintedSerie, setMintedSerie] = useState(undefined);
  const [fractions, setFractions] = useState(undefined);
  useEffect(() => {
    const getPodcastMinted = async (token, seriesId) => {
      try {
        const mintedSeriesParams = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ seriesId }),
        };
        const mintedSeriesPending = await fetch(
          "https://api.sybel.io/firebase/flatpass?function=getPodcastsMinted",
          mintedSeriesParams
        );
        const { data } = await mintedSeriesPending.json();
        if (data.podcasts.length === 1) {
          setMintedSerie(data.podcasts[0]);
        }
      } catch (error) {}
    };

    getPodcastMinted(user.token, id);
  }, [id, user]);

  return (
    <div className={`series-header ${classes["series-header"]}`}>
      <div className="series-header-dark"></div>
      <div className="series-header-left-side">
        <div className="series-header-left-side-title">
          <h1>{seriesLanguage && series[id].info[seriesLanguage].title}</h1>
          {episodes &&
            episodes[id] &&
            episodes[id][seasonIndex] &&
            episodes[id][seasonIndex].filter(
              (each) => new Date(each.publicationDate) <= new Date()
            ).length > 0 && (
              <div className="series-length">
                <img src="/image/clock.svg" alt="" />
                <p>
                  {new Date(series[id!].length * 1000)
                    .toISOString()
                    .substr(11, 5)}
                </p>
              </div>
            )}
          {series[id!].classification &&
            series[id!].classification !== "Tous publics" && (
              <p className="classification">{series[id!].classification}</p>
            )}
        </div>
        <div
          className="series-header-mentions"
          style={isShowMore ? { marginTop: "14px" } : {}}
        >
          {seriesLanguage &&
            series[id!].info[seriesLanguage].headerMention &&
            series[id!].info[seriesLanguage].headerMention
              .trim()
              .split(";")
              .map((each, index) => {
                return <p key={index}>{each.trim()}</p>;
              })}
        </div>
        <div
          className="description"
          style={isShowMore ? { marginTop: "14px" } : {}}
        >
          <p>
            {isShowMore ? (
              <>
                {seriesLanguage &&
                  series[id!].info[seriesLanguage].details
                    .split("\n")
                    .map(function (item, index) {
                      return item === "" ? null : (
                        <span key={index}>
                          {index !== 0 ? <br /> : null}
                          {item}
                        </span>
                      );
                    })}
                {seriesLanguage &&
                  series[id!].info[seriesLanguage].details.length > 240 && (
                    <strong onClick={() => setIsShowMore(false)}>
                      {language("seeless")}
                    </strong>
                  )}
              </>
            ) : (
              <>
                {seriesLanguage &&
                  series[id!].info[seriesLanguage].details
                    .substr(0, 240)
                    .split("\n")
                    .map(function (item, index) {
                      return item === "" ? null : (
                        <span key={index}>
                          {index !== 0 ? <br /> : null}
                          {item}
                        </span>
                      );
                    })}
                {seriesLanguage &&
                  series[id!].info[seriesLanguage].details.length > 240 && (
                    <strong
                      onClick={() => {
                        clickSeeMoreSeriesDescription();
                        setIsShowMore(true);
                      }}
                    >
                      {language("seemore")}
                    </strong>
                  )}
              </>
            )}
          </p>
        </div>
        {seriesHistory &&
          seriesHistory[id] &&
          episodes[id] &&
          episodes[id][seriesHistory[id].episode.season] &&
          ((episodes && !episodes[id]) ||
            (episodes &&
              episodes[id] &&
              episodes[id][seasonIndex] &&
              episodes[id][seasonIndex].length === 0) ||
            (episodes &&
              episodes[id] &&
              episodes[id][seasonIndex] &&
              episodes[id][seasonIndex].filter(
                (each) => new Date(each.publicationDate) <= new Date()
              ).length > 0)) &&
          episodes[id][seriesHistory![id].episode.season][
            episodes[id][seriesHistory![id].episode.season].findIndex(
              (each) => each._id === seriesHistory![id].episode._id
            )
          ] && (
            <div
              className="progress-header-series-container"
              style={isShowMore ? { marginTop: "14px" } : {}}
            >
              <div className="progress-header-series">
                <p>
                  {language("shortSeason")}
                  {seriesHistory[id].episode.season} -{" "}
                  {seriesLanguage &&
                    episodes[id][seriesHistory![id].episode.season][
                      episodes[id][seriesHistory![id].episode.season].findIndex(
                        (each) => each._id === seriesHistory![id].episode._id
                      )
                    ].info[seriesLanguage].title}
                </p>
                <small>
                  {language("remaining")}{" "}
                  {Math.floor(
                    (seriesHistory[id].episode.length -
                      seriesHistory[id].progress) /
                      60
                  )}{" "}
                  {language("min")}
                </small>
              </div>
              <SeriesHeaderProgressBar
                progressBarSize={
                  (seriesHistory[id].progress * 100) /
                  seriesHistory[id].episode.length
                }
              />
            </div>
          )}
        <div
          className="header-button"
          style={isShowMore ? { marginTop: "14px" } : {}}
        >
          <button
            onClick={playEpisodeHeader}
            style={
              (episodes && !episodes[id]) ||
              (episodes &&
                episodes[id] &&
                episodes[id][seasonIndex] &&
                episodes[id][seasonIndex].length === 0) ||
              (episodes &&
                episodes[id] &&
                episodes[id][seasonIndex] &&
                episodes[id][seasonIndex].filter(
                  (each) => new Date(each.publicationDate) <= new Date()
                ).length === 0) ||
              (episodes &&
                seriesLanguage &&
                episodes[id] &&
                episodes[id][seasonIndex] &&
                episodes[id][seasonIndex].filter(
                  (each) => each.info[seriesLanguage]
                ).length === 0)
                ? { background: "#343434", color: "#A5A5A5", cursor: "default" }
                : {}
            }
          >
            {(episodes && !episodes[id]) ||
            (episodes &&
              episodes[id] &&
              episodes[id][seasonIndex] &&
              episodes[id][seasonIndex].length === 0) ||
            (episodes &&
              episodes[id] &&
              episodes[id][seasonIndex] &&
              episodes[id][seasonIndex].filter(
                (each) => new Date(each.publicationDate) <= new Date()
              ).length === 0) ||
            (episodes &&
              episodes[id] &&
              episodes[id][seasonIndex] &&
              seriesLanguage &&
              episodes[id][seasonIndex].filter(
                (each) => each.info[seriesLanguage]
              ).length === 0) ? null : (
              <img src="/image/playExtract.svg" alt="" />
            )}
            {(episodes && !episodes[id]) ||
            (episodes &&
              episodes[id] &&
              episodes[id][seasonIndex] &&
              episodes[id][seasonIndex].length === 0) ||
            (episodes &&
              episodes[id] &&
              episodes[id][seasonIndex] &&
              episodes[id][seasonIndex].filter(
                (each) => new Date(each.publicationDate) <= new Date()
              ).length === 0) ||
            (episodes &&
              episodes[id] &&
              episodes[id][seasonIndex] &&
              seriesLanguage &&
              episodes[id][seasonIndex].filter(
                (each) => each.info[seriesLanguage]
              ).length === 0)
              ? language("comingSoon")
              : seriesHistory &&
                seriesHistory[id] &&
                seriesHistory[id].episode._id
              ? language("resume")
              : language("listen")}
          </button>
          <CopyToClipboard
            text={copy.value}
            onCopy={() => {
              window.analytics.track("Content Shared", {
                subscription: !!localStorage.getItem("user")
                  ? JSON.parse(localStorage.getItem("user")!).subscription
                  : 0,
                session_id: uuid,
                series_id: id,
                content_name: seriesLanguage
                  ? series[id].info[seriesLanguage].title
                  : undefined,
                content_length: !!series[id].length
                  ? series[id].length
                  : undefined,
                platform: "Web",
                signup_type: localStorage.getItem("sy_signup_type")
                  ? localStorage.getItem("sy_signup_type")
                  : undefined,
                channel_id:
                  series[id] && series[id].channel
                    ? series[id].channel!._id
                    : undefined,
                channel_name:
                  series[id] && series[id].channel
                    ? series[id].channel!.name
                    : undefined,
                channel_name_code:
                  series[id] && series[id].channel
                    ? series[id].channel!.nameCode
                    : undefined,
                channel_umbrella_id:
                  series[id] && series[id].channel
                    ? series[id].channel!._id
                    : undefined,
                channel_umbrella_name:
                  series[id] && series[id].channel
                    ? series[id].channel!.name
                    : undefined,
                channel_umbrella_name_code:
                  series[id] && series[id].channel
                    ? series[id].channel!.umbrella.nameCode
                    : undefined,
                has_rss_feed:
                  series[id] &&
                  series[id].info[seriesLanguage] &&
                  series[id].info[seriesLanguage].rssFeed
                    ? true
                    : false,
              });
              setCopy({
                value: `${window.location.host}/series/${id}`,
                copied: true,
              });
            }}
          >
            <div className="action">
              <img src="/image/share.svg" alt="" />
            </div>
          </CopyToClipboard>

          <div
            onClick={(e) => addToLikeList(e)}
            className="action"
            style={{
              border: isLiked ? "2px solid #F23568" : "2px solid #343434",
            }}
          >
            <img
              src={isLiked ? "/image/heartLiked.svg" : "/image/heart.svg"}
              alt=""
            />
          </div>
          {seriesLanguage && series[id!].info[seriesLanguage].sample && (
            <button
              className="series-descirption-header"
              onClick={(e) => playExtract(e)}
            >
              <div className="launchExtract">
                <img src="/image/playExtract.svg" alt="" />
                <p>{language("seriesPagePlay")}</p>
              </div>
            </button>
          )}
        </div>
        {mintedSerie && (
          <div>
            <a
              style={{ position: "relative" }}
              target="_blank"
              rel="noopener noreferrer"
              className="nft-link"
              href={
                "https://app.sybel.io/item-details/" +
                id +
                "?token=" +
                user.token +
                "&email=" +
                user.email +
                "&user_id=" +
                user._id
              }
            >
              {language("buyashare")}
              <a
                style={{
                  color: "#fff",
                  position: "absolute",
                  right: 0,
                  bottom: "-30px",
                }}
                target="_blank"
                rel="noopener noreferrer"
                href={"https://sybel.io/"}
              >
                {language("learnMore")}
              </a>
            </a>
          </div>
        )}
      </div>
      <div className="series-header-right-side">
        <LazyLoadImage
          src={
            seriesLanguage && series[id!].info[seriesLanguage].coverImage
              ? series[id!].info[seriesLanguage].coverImage
              : "/image/coverImagePlaceholder.svg"
          }
          placeholderSrc="/image/coverImagePlaceholder.svg"
          loading={"lazy"}
          className="cover-image"
          alt=""
          unselectable="on"
        />
        <LazyLoadImage
          loading={"lazy"}
          src={
            series[id!].titleImage &&
            series[id!].info &&
            series[id!].info[seriesLanguage]
              ? series[id!].info[seriesLanguage].titleImage
              : ""
          }
          className="header-title-image"
          alt=""
          unselectable="on"
        />
      </div>
    </div>
  );
}
