import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import EpisodeHistoryType from "../interfaces/EpisodeHistoryType";
import ProfileType from "../interfaces/ProfileType";
import { Action, Case } from "../interfaces/ReducerCase";
import SeriesHistoryType from "../interfaces/SeriesHistoryType";
import StoreType from "../interfaces/StoreType";
import { deleteProfileService } from "../services/ProfileService";
import {
  getEpisodesHistoryService,
  getSeriesHistoryService,
} from "../services/SeriesService";
import { language } from "../stores/18nStore";
import EventStore from "../stores/AmplitudeStore";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";
import { UseSeriesLanguage } from "./UseSeriesLanguage";

export default function UseProfile(onHome?: true): {
  chooseProfile: (
    profileSelected: ProfileType,
    fromHome?: boolean | undefined
  ) => void;
  deleteProfile: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    profile: ProfileType
  ) => Promise<React.ReactText | undefined>;
  editProfile: (profile: ProfileType) => void;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
} {
  const {
    user,
    profile,
    nextUrl,
    userContentLanguage,
    uuid,
    volume,
    currentEpisode,
  } = useSelector((state: StoreType) => ({
    ...state.UserReducer,
    ...state.ProfileReducer,
    ...state.RouterReducer,
    ...state.PlayerReducer,
  }));
  const { chooseProfileEvent } = EventStore();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const history = useHistory();
  const dispatch: Dispatch<Action> = useDispatch();
  const { seriesLanguage } = UseSeriesLanguage();
  useEffect(() => {
    if (
      sessionStorage.getItem("profile") &&
      sessionStorage.getItem("profile") === "undefined"
    ) {
      sessionStorage.removeItem("profile");
      history.push("/choose-profile");
    }
  }, [history]);

  const getHistory = async (selectedProfile: ProfileType) => {
    const ac = new AbortController();
    const { signal } = ac;
    try {
      const episodehistoryPending = await getEpisodesHistoryService(
        JSON.parse(localStorage.getItem("user")!),
        selectedProfile,
        userContentLanguage,
        signal
      );
      const episodehistoryData: EpisodeHistoryType[] =
        await episodehistoryPending.json();
      let episodereorganizedHistory: any = {};
      episodehistoryData.map((each) => {
        return (episodereorganizedHistory = {
          ...episodereorganizedHistory,
          [each.seriesId]: {
            ...episodereorganizedHistory[each.seriesId],
            [each.episodeId]: each,
          },
        });
      });
      const historyPending = await getSeriesHistoryService(
        JSON.parse(localStorage.getItem("user")!),
        selectedProfile,
        userContentLanguage,
        signal
      );
      const historyData: SeriesHistoryType[] = await historyPending.json();
      let reorganizedHistory: any = {};
      historyData.map((each) => {
        return (reorganizedHistory = {
          ...reorganizedHistory,
          [each.series._id]: each,
          _id: each.series._id,
        });
      });
      dispatch({
        type: Case.PUSH_SERIES_HISTORY,
        payload: {
          seriesHistory: reorganizedHistory,
        },
      });
      dispatch({
        type: Case.PUSH_EPISODES_HISTORY,
        payload: {
          seriesHistory: reorganizedHistory,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  const chooseProfile = (profileSelected: ProfileType, fromHome?: boolean) => {
    if (profile && fromHome && profileSelected._id === profile._id) {
      return;
    } else {
      getHistory(profileSelected);
      chooseProfileEvent(
        fromHome ? "Profiles List NavBar Actions" : "New Session",
        profileSelected.isChild,
        profileSelected.isMain
      );
      dispatch({
        type: Case.REINIT_HOME,
      });
      if (profile && profile._id && profile._id !== profileSelected._id) {
        dispatch({ type: Case.SET_CURRENT_EPISODE_UNDEFINED });
        if (currentEpisode && currentEpisode.series) {
          window.analytics.track("Audio Content Interupted", {
            subscription: !!localStorage.getItem("user")
              ? JSON.parse(localStorage.getItem("user")!).subscription
              : 0,
            content_type:
              currentEpisode && currentEpisode.sample ? "Trailer" : "Episode",
            series_id:
              currentEpisode && currentEpisode.series
                ? currentEpisode.series._id
                : undefined,
            episode_id:
              currentEpisode && currentEpisode.episode
                ? currentEpisode.episode._id
                : undefined,
            content_length: currentEpisode && currentEpisode.series.length,
            platform: "Web",
            session_id: uuid,
            sound: volume,
            signup_type: localStorage.getItem("sy_signup_type")
              ? localStorage.getItem("sy_signup_type")
              : undefined,
            context: "Choose Profile",
            episode_name:
              currentEpisode && currentEpisode.episode
                ? currentEpisode.episode.info[seriesLanguage].title
                : undefined,
            channel_id:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel._id
                : undefined,
            channel_name:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel.name
                : undefined,
            channel_name_code:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel.nameCode
                : undefined,
            channel_umbrella_id:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel.umbrella._id
                : undefined,
            channel_umbrella_name:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel.umbrella.name
                : undefined,
            channel_umbrella_name_code:
              currentEpisode &&
              currentEpisode.series &&
              currentEpisode.series.channel
                ? currentEpisode.series.channel.umbrella.nameCode
                : undefined,
                has_rss_feed:
                currentEpisode &&
                currentEpisode.series &&
                currentEpisode.series.info[seriesLanguage] &&
                currentEpisode.series.info[seriesLanguage].rssFeed
                  ? true
                  : false,
                  content_name:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.info[seriesLanguage].title
                      : undefined,
                  episode_length:
                    currentEpisode && currentEpisode.episode
                      ? currentEpisode.episode.length
                      : undefined,
                  season:
                    currentEpisode && currentEpisode.episode
                      ? currentEpisode.episode.season
                      : undefined,
                  chronological:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.chronological
                      : undefined,
                  classification:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.classification
                      : undefined,
                  emotion:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.emotion
                      : undefined,
                  format:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.format
                      : undefined,
                  is_unit:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.isUnit
                      : undefined,
                  publication_date:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.publicationDate
                      : undefined,
                  seasons_list:
                    currentEpisode && currentEpisode.series
                      ? currentEpisode.series.seasonsList
                      : undefined,
                  header_mention:
                    currentEpisode && currentEpisode.series && seriesLanguage
                      ? currentEpisode.series.info[seriesLanguage].headerMention
                      : undefined,
                        child_content:
                        currentEpisode && currentEpisode.series
                          ? currentEpisode.series.isChild
                          : undefined,
                      episode_index: currentEpisode && currentEpisode.episode
                        ? currentEpisode.episode.index
                        : undefined,
                      genre:
                        currentEpisode && currentEpisode.series
                          ? currentEpisode.series.genre
                          : undefined,
                      total_length: currentEpisode && currentEpisode.episode
                        ? currentEpisode.episode.length
                        : undefined,
                  
          });
        }
        dispatch({
          type: Case.REINIT_SERIES_HISTORY,
        });
        dispatch({
          type: Case.REINIT_EPISODE_HISTORY,
        });
      }
      sessionStorage.setItem("profile", JSON.stringify(profileSelected));
      dispatch({
        type: Case.SET_PROFILE,
        payload: { profile: profileSelected },
      });
      if (!fromHome) {
        history.push(nextUrl ? nextUrl : "/home");
        dispatch({ type: Case.SET_NEXT_URL_DEFAULT });
        return sessionStorage.removeItem("nextUrl");
      }
    }
  };

  const editProfile = (profile: ProfileType) => {
    dispatch({
      type: Case.SET_CHOOSE_PROFILE_EDIT_VALUE,
      payload: {
        birthday: profile.birthday ? new Date(profile.birthday) : undefined,
        pseudo: profile.name,
        gender: profile.gender,
      },
    });
    window.scrollTo(0, 0);
    history.push("/choose-profile/edit", {
      isEditMode: true,
      isChild: profile.isChild,
      profile,
    });
  };

  const deleteProfile = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    profile: ProfileType
  ) => {
    e.stopPropagation();
    try {
      dispatch({
        type: Case.DELETE_PROFILES,
        payload: { profile },
      });
      setIsEditMode(false);
      const deleteProfilePending = await deleteProfileService(profile, user);
      await deleteProfilePending.json();
    } catch (e) {
      return toast(language("codeError"), notificationsParam);
    }
  };
  return {
    chooseProfile,
    deleteProfile,
    editProfile,
    isEditMode,
    setIsEditMode,
  };
}
