import React, { ReactElement, useState } from "react";
import { language } from "../stores/18nStore";
import Loader from "./Loader";
import ReactPixel from "react-facebook-pixel";
import HomeBillboardRightSideType from "../interfaces/components/HomeBillboardRightSideType";
import { Link } from "react-router-dom";

export default function HomeBillboardRightSide({
  episodes,
  isExtractLoading,
  seriesLanguage,
  each,
  categoryPosition,
  universes,
  universToShow,
  playExtract,
}: HomeBillboardRightSideType): ReactElement {
  const [isShowMoreState, setIsShowMoreState] = useState<boolean>(false);
  const showingMore = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    isShowMore: boolean
  ) => {
    e.stopPropagation();
    setIsShowMoreState(isShowMore);
  };
  return (
    <div className="billboard-right-side">
      <Link
        to={{
          pathname: `/series/${each.seriesList[0]._id}`,
          state: {
            contentEventDetails: {
              id: each.seriesList[0]._id,
              contentContext: "Explorer",
              categoryType: "Main Billboard",
              contentCategory: each.title,
              categoryPosition: categoryPosition,
              universeId: universToShow ? universToShow : undefined,
              contentUniverse:
                universes && universToShow
                  ? universes.filter(
                      (each) => each.headerCategoryId === universToShow
                    )[0].title
                  : "Main Homepage",
            },
          },
        }}
        className="billboard-title"
        onClick={() => {
          ReactPixel.track("ViewCategory", {
            content_type: "product",
            content_category: "Billboard",
            content_ids: each._id,
            content_name: null,
            univers_affichage:
              universes && universToShow
                ? universes.filter(
                    (each) => each.headerCategoryId === universToShow
                  )[0].title
                : "Main Homepage",
            page_type: "Boost",
          });
        }}
      >
        {each.seriesList[0].info[seriesLanguage].title}
      </Link>
      <div className="series-header-mentions">
        {each.seriesList[0].info[seriesLanguage].headerMention &&
          each.seriesList[0].info[seriesLanguage].headerMention
            .trim()
            .split(";")
            .map((each, index) => {
              return <p key={index}>{each.trim()}</p>;
            })}
      </div>
      <p className="billboard-description">
        {each.seriesList[0].info[seriesLanguage].details}
      </p>
      <p className="billboard-description-mobile">
        {isShowMoreState
          ? each.seriesList[0].info[seriesLanguage].details
          : each.seriesList[0].info[seriesLanguage].details.slice(0, 300)}
        <strong onClick={(e) => showingMore(e, !isShowMoreState)}>
          {isShowMoreState ? language("seeless") : language("seemore")}
        </strong>
      </p>
      <div className="billboard-cta">
        {each &&
          episodes &&
          each.seriesList[0].info &&
          each.seriesList[0].info[seriesLanguage] &&
          each.seriesList[0].info[seriesLanguage].sample && (
            <button
              className="billboard-extract"
              onClick={() =>
                playExtract(
                  each.seriesList[0],
                  episodes,
                  "Explorer",
                  each.title,
                  "Billboard",
                  categoryPosition,
                  universToShow
                )
              }
            >
              {isExtractLoading ? (
                <Loader customeClassName="loader" />
              ) : (
                <div className="launchExtract">
                  <img src="/image/playExtract.svg" alt="" />
                  <p>{language("seriesPagePlay")}</p>
                </div>
              )}
            </button>
          )}
        <Link
          to={{
            pathname: `/series/${each.seriesList[0]._id}`,
            state: {
              contentEventDetails: {
                id: each.seriesList[0]._id,
                contentContext: "Explorer",
                categoryType: "Main Billboard",
                contentCategory: each.title,
                categoryPosition: categoryPosition,
                universeId: universToShow ? universToShow : undefined,
                contentUniverse:
                  universes && universToShow
                    ? universes.filter(
                        (each) => each.headerCategoryId === universToShow
                      )[0].title
                    : "Main Homepage",
              },
            },
          }}
          style={{
            margin:
              each &&
              each.seriesList[0].info &&
              each.seriesList[0].info[seriesLanguage] &&
              each.seriesList[0].info[seriesLanguage].sample
                ? ""
                : "0px",
          }}
          className="billboard-openseries"
          onClick={() => {
            ReactPixel.track("ViewCategory", {
              content_type: "product",
              content_category: "Billboard",
              content_ids: each._id,
              content_name: null,
              univers_affichage:
                universes && universToShow
                  ? universes.filter(
                      (each) => each.headerCategoryId === universToShow
                    )[0].title
                  : "Main Homepage",
              page_type: "Boost",
            });
          }}
        >
          <div className="billboard-series">
            <p>{language("billboardDiscover")}</p>
          </div>
        </Link>
      </div>
    </div>
  );
}
