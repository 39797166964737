import { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action, Case } from "../interfaces/ReducerCase";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import { ProductStripeType } from "../interfaces/UserType";
import ReactPixel from "react-facebook-pixel";
import EventStore from "../stores/AmplitudeStore";
import { UseSeriesLanguage } from "./UseSeriesLanguage";
import { getUser } from "../services/UserService";
import { language } from "../stores/18nStore";
import { toast } from "react-toastify";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";
import amplitude from "amplitude-js";

export const UsePopUp = (
  dismissPopUp: (isSubscribed?: boolean | undefined) => void,
  seriesContext: SeriesType | undefined,
  isNewSession?: true
): {
  offerSelected: (index: number, product: ProductStripeType) => void;
  showPayment: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isSuccess: boolean;
  setIsSuccess: Dispatch<React.SetStateAction<boolean>>;
  resumeOnSubscribed: (button: string) => void;
  isPayment: boolean;
  setIsPayment: Dispatch<React.SetStateAction<boolean>>;
  selectedOffer:
    | {
        index: number;
        offer: ProductStripeType;
      }
    | undefined;
  setUpdatedUser: Dispatch<any>;
  isMultipleOffers: boolean;
  firstOffer: ProductStripeType | undefined;
  secondOffer: ProductStripeType | undefined;
  isInit: boolean;
} => {
  const {
    user,
    profile,
    profiles,
    context,
    popUpContext,
    currentEpisode,
    uuid,
  } = useSelector((state: StoreType) => ({
    ...state.UserReducer,
    ...state.ProfileReducer,
    ...state.PlayerReducer,
  }));
  const { stripeSelectPlan, stripePlanSelected, play } = EventStore();
  const [isMultipleOffers, setIsMultipleOffers] = useState<boolean>(false);
  const [firstOffer, setFirstOffer] = useState<ProductStripeType | undefined>(
    undefined
  );
  const [secondOffer, setSecondOffer] = useState<ProductStripeType | undefined>(
    undefined
  );
  const [selectedOffer, setSelectedOffer] = useState<
    | {
        index: number;
        offer: ProductStripeType;
      }
    | undefined
  >(undefined);
  const [isPayment, setIsPayment] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [updatedUser, setUpdatedUser] = useState<any>();
  const dispatch: Dispatch<Action> = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const [isFetchingUser, setIsFetchingUser] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      setIsFetchingUser(true);
      try {
        const res = await getUser(
          JSON.parse(localStorage.getItem("user")!)._id,
          JSON.parse(localStorage.getItem("user")!).token
        );
        const userData: any = await res.json();
        stableDispatch({ type: Case.UPDATE_USER, payload: { user: userData } });
        setIsFetchingUser(false);
      } catch (error: any) {
        setIsFetchingUser(false);
        return toast(language("codeError"), notificationsParam);
      }
    };
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")!).token &&
      JSON.parse(localStorage.getItem("user")!)._id
    ) {
      fetchUser();
    }
  }, [stableDispatch]);
  useEffect(() => {
    if (isSubscribed && !isNewSession) {
      dismissPopUp(true);
    }
  }, [isSubscribed, dismissPopUp, isNewSession]);
  const resumeOnSubscribed = (button: string) => {
    const refetchEpisode = currentEpisode;
    localStorage.setItem("user", JSON.stringify(updatedUser));
    setIsSubscribed(true);
    dispatch({ type: Case.UPDATE_USER, payload: { user: updatedUser } });
    dispatch({ type: Case.SET_CURRENT_EPISODE_UNDEFINED });
    if (refetchEpisode && refetchEpisode.episode) {
      dispatch({
        type: Case.SET_CURRENT_EPISODE,
        payload: { ...refetchEpisode, uuid },
      });
      window.analytics.track("Audio Content Started", {
        subscription: !!localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")!).subscription
          : 0,
        platform: "Web",
        session_id: uuid,
        series_id: refetchEpisode.series._id,
        episode_id: refetchEpisode.episode._id,
        content_name: refetchEpisode.series.info[seriesLanguage].title,
        episode_length: refetchEpisode.episode.length,
        content_length: Math.round(refetchEpisode.series.length),
        season: refetchEpisode.episode.season,
        position: 0,
        signup_type: localStorage.getItem("sy_signup_type")
          ? localStorage.getItem("sy_signup_type")
          : undefined,
        chronological: refetchEpisode.series.chronological,
        classification: refetchEpisode.series.classification,
        emotion: refetchEpisode.series.emotion,
        format: refetchEpisode.series.format,
        is_unit: refetchEpisode.series.isUnit,
        publication_date: refetchEpisode.series.publicationDate,
        seasons_list: refetchEpisode.series.seasonsList,
        header_mention:
          refetchEpisode.series.info[seriesLanguage].headerMention,
        context: "Explorer",
        episode_name: refetchEpisode.episode
          ? refetchEpisode.episode.info[seriesLanguage].title
          : undefined,
        channel_id:
          refetchEpisode &&
          refetchEpisode.series &&
          refetchEpisode.series.channel
            ? refetchEpisode.series.channel!._id
            : undefined,
        channel_name:
          refetchEpisode &&
          refetchEpisode.series &&
          refetchEpisode.series.channel
            ? refetchEpisode.series.channel!.name
            : undefined,
        channel_name_code:
          refetchEpisode &&
          refetchEpisode.series &&
          refetchEpisode.series.channel
            ? refetchEpisode.series.channel!.nameCode
            : undefined,
        channel_umbrella_id:
          refetchEpisode &&
          refetchEpisode.series &&
          refetchEpisode.series.channel
            ? refetchEpisode.series.channel!.umbrella._id
            : undefined,
        channel_umbrella_name:
          refetchEpisode &&
          refetchEpisode.series &&
          refetchEpisode.series.channel
            ? refetchEpisode.series.channel!.umbrella.name
            : undefined,
        channel_umbrella_name_code:
          refetchEpisode &&
          refetchEpisode.series &&
          refetchEpisode.series.channel
            ? refetchEpisode.series.channel!.umbrella.nameCode
            : undefined,
        has_rss_feed:
          refetchEpisode &&
          refetchEpisode.series &&
          refetchEpisode.series.info &&
          refetchEpisode.series.info[seriesLanguage] &&
          refetchEpisode.series.info[seriesLanguage].rssFeed
            ? true
            : false,
        child_content:
          refetchEpisode && refetchEpisode.series
            ? refetchEpisode.series.isChild
            : undefined,
        episode_index:
          refetchEpisode && refetchEpisode.episode
            ? refetchEpisode.episode.index
            : undefined,
        genre:
          refetchEpisode && refetchEpisode.series
            ? refetchEpisode.series.genre
            : undefined,
        total_length:
          refetchEpisode && refetchEpisode.episode
            ? refetchEpisode.episode.length
            : undefined,
      });
      return play(
        refetchEpisode.series.isChild,
        "Play From Payment Success",
        refetchEpisode.series.emotion
          ? refetchEpisode.series.emotion.join()
          : undefined,
        refetchEpisode.episode.info[seriesLanguage].title,
        refetchEpisode.episode._id,
        refetchEpisode.episode.season,
        refetchEpisode.series._id,
        refetchEpisode.series.info[seriesLanguage].title,
        profile!.isChild,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        refetchEpisode.series.mainImageId,
        0,
        0,
        Math.round(refetchEpisode.episode.length),
        Math.round(refetchEpisode.series.length)
      );
    }
  };
  useEffect(() => {
    if (!seriesContext && updatedUser) {
      stableDispatch({
        type: Case.UPDATE_USER,
        payload: { user: updatedUser },
      });
      localStorage.setItem("user", JSON.stringify(updatedUser));
    }
  }, [updatedUser, seriesContext, stableDispatch]);
  useEffect(() => {
    if (!profile && !!profiles && profiles.length > 0) {
      stableDispatch({
        type: Case.SET_PROFILE,
        payload: { profile: profiles[0] },
      });
      sessionStorage.setItem("profile", JSON.stringify(profiles[0]));
    }
    if (user && user._id && user.token) {
      if (
        !isFetchingUser &&
        user.subscriptionData &&
        user.subscriptionData.productsStripe &&
        user.subscriptionData.productsStripe.length > 1
      ) {
        setIsMultipleOffers(true);
      } else {
        setIsMultipleOffers(false);
      }
    }
  }, [user, profile, stableDispatch, profiles, isFetchingUser]);

  useEffect(() => {
    if (isMultipleOffers) {
      setFirstOffer(
        (user.subscriptionData!.productsStripe as ProductStripeType[]).find(
          (item: ProductStripeType) => item.interval === "year"
        ) as ProductStripeType
      );
      setSelectedOffer({
        index: 0,
        offer: (
          user.subscriptionData!.productsStripe as ProductStripeType[]
        ).find(
          (item: ProductStripeType) => item.interval === "year"
        ) as ProductStripeType,
      });
      setSecondOffer(
        (user.subscriptionData!.productsStripe as ProductStripeType[]).find(
          (item: ProductStripeType) => item.interval === "month"
        ) as ProductStripeType
      );
      setIsInit(true);
    } else if (user && user.subscriptionData && !isFetchingUser) {
      setSelectedOffer({
        index: 0,
        offer: (user.subscriptionData.productsStripe as ProductStripeType[])[0],
      });
      setIsInit(true);
    }
  }, [isMultipleOffers, user, isFetchingUser]);
  const { seriesLanguage } = UseSeriesLanguage();
  const offerSelected = (index: number, product: ProductStripeType) => {
    stripeSelectPlan(
      popUpContext ? popUpContext : "Direct to url",
      "Default offers",
      product.id,
      product.amount,
      product.interval,
      undefined,
      context ? context : undefined,
      currentEpisode &&
        currentEpisode.episode &&
        currentEpisode.episode.info &&
        seriesLanguage
        ? currentEpisode.episode.info[seriesLanguage].title
        : undefined,
      currentEpisode && currentEpisode.episode
        ? currentEpisode.episode._id
        : undefined,
      currentEpisode && currentEpisode.episode && seriesLanguage
        ? currentEpisode.episode.season
        : undefined,
      currentEpisode && currentEpisode.series
        ? currentEpisode.series._id
        : undefined,
      currentEpisode &&
        currentEpisode.series &&
        currentEpisode.series.info &&
        seriesLanguage
        ? currentEpisode.series.info[seriesLanguage].title
        : undefined,
      profile ? profile.isChild : undefined
    );
    window.analytics.track("Offer Plan Selected", {
      subscription: !!localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).subscription
        : 0,
      platform: "Web",
      session_id: uuid,
      signup_type: localStorage.getItem("sy_signup_type")
        ? localStorage.getItem("sy_signup_type")
        : undefined,
      context: context ? context : undefined,
      presented_offers: "Default offers",
      plan_id: product.id,
      plan_price: product.amount,
      plan_period: product.interval,
      content_context: popUpContext ? popUpContext : "Direct to url",
      content_episode_name:
        currentEpisode &&
        currentEpisode.episode &&
        currentEpisode.episode.info &&
        seriesLanguage
          ? currentEpisode.episode.info[seriesLanguage].title
          : undefined,
      content_episode_id:
        currentEpisode && currentEpisode.episode
          ? currentEpisode.episode._id
          : undefined,
      content_episode_season:
        currentEpisode && currentEpisode.episode && seriesLanguage
          ? currentEpisode.episode.season
          : undefined,
      series_id:
        currentEpisode && currentEpisode.series
          ? currentEpisode.series._id
          : undefined,
      content_name:
        currentEpisode &&
        currentEpisode.series &&
        currentEpisode.series.info &&
        seriesLanguage
          ? currentEpisode.series.info[seriesLanguage].title
          : undefined,
      channel_id:
        currentEpisode && currentEpisode.series && currentEpisode.series.channel
          ? currentEpisode.series.channel._id
          : undefined,
      channel_name:
        currentEpisode && currentEpisode.series && currentEpisode.series.channel
          ? currentEpisode.series.channel.name
          : undefined,
      channel_name_code:
        currentEpisode && currentEpisode.series && currentEpisode.series.channel
          ? currentEpisode.series.channel.nameCode
          : undefined,
      channel_umbrella_id:
        currentEpisode && currentEpisode.series && currentEpisode.series.channel
          ? currentEpisode.series.channel.umbrella._id
          : undefined,
      channel_umbrella_name:
        currentEpisode && currentEpisode.series && currentEpisode.series.channel
          ? currentEpisode.series.channel.umbrella.name
          : undefined,
      channel_umbrella_name_code:
        currentEpisode && currentEpisode.series && currentEpisode.series.channel
          ? currentEpisode.series.channel.umbrella.nameCode
          : undefined,
      has_rss_feed:
        currentEpisode &&
        currentEpisode.series &&
        currentEpisode.series.info &&
        currentEpisode.series.info[seriesLanguage] &&
        currentEpisode.series.info[seriesLanguage].rssFeed
          ? true
          : false,
    });
    setSelectedOffer({ offer: product, index });
  };
  const showPayment = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const offer = selectedOffer!.index === 0 ? firstOffer : secondOffer;
    if (isNewSession) {
      amplitude
        .getInstance()
        .logEvent("Click Premium Button", { Context: "Pop Up" });
    }
    if (offer) {
      stripePlanSelected(
        popUpContext ? popUpContext : "Direct to url",
        "Default offers",
        offer!.id,
        offer!.amount,
        offer!.interval,
        undefined,
        context ? context : undefined,
        currentEpisode &&
          currentEpisode.episode &&
          currentEpisode.episode.info &&
          seriesLanguage
          ? currentEpisode.episode.info[seriesLanguage].title
          : undefined,
        currentEpisode && currentEpisode.episode
          ? currentEpisode.episode._id
          : undefined,
        currentEpisode && currentEpisode.episode && seriesLanguage
          ? currentEpisode.episode.season
          : undefined,
        currentEpisode && currentEpisode.series
          ? currentEpisode.series._id
          : undefined,
        currentEpisode &&
          currentEpisode.series &&
          currentEpisode.series.info &&
          seriesLanguage
          ? currentEpisode.series.info[seriesLanguage].title
          : undefined,
        profile ? profile.isChild : undefined
      );
      ReactPixel.track("AddToCart", {
        order_id: offer.id,
        subscription_type: `${offer.amount} ${offer.interval} ${offer.currency}`,
        Trial:
          offer.trial_period_days && offer.trial_period_days > 0 ? true : false,
        numero_trial:
          offer.trial_period_days && offer.trial_period_days > 0
            ? offer.trial_period_days
            : null,
      });
    }
    if (offer) {
      setIsPayment(true);
    }
  };

  return {
    offerSelected,
    showPayment,
    isSuccess,
    setIsSuccess,
    resumeOnSubscribed,
    isPayment,
    setIsPayment,
    selectedOffer,
    setUpdatedUser,
    isMultipleOffers,
    firstOffer,
    secondOffer,
    isInit,
  };
};
