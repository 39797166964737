import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";

interface Props {
  refChild: React.RefObject<HTMLDivElement>;
  setContentLanguage: (language: string) => void;
}

export default function PreferencesSettingsContentLanguage({
  refChild,
  setContentLanguage,
}: Props): ReactElement {
  const { userContentLanguage } = useSelector((state: StoreType) => ({
    ...state.UserReducer,
  }));
  return (
    <div ref={refChild}>
      <p
        style={
          userContentLanguage && userContentLanguage === "fra"
            ? { backgroundColor: "#f23568", color: "#fff" }
            : {}
        }
        onClick={() => setContentLanguage("fra")}
      >
        {language("fr")}
      </p>
      <p
        style={
          userContentLanguage && userContentLanguage === "esp"
            ? { backgroundColor: "#f23568", color: "#fff" }
            : {}
        }
        onClick={() => setContentLanguage("esp")}
      >
        {language("es")}
      </p>
      <p
        style={
          userContentLanguage && userContentLanguage === "usa"
            ? { backgroundColor: "#f23568", color: "#fff" }
            : {}
        }
        onClick={() => setContentLanguage("usa")}
      >
        {language("en")}
      </p>
    </div>
  );
}
