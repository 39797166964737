import i18next from "i18next";
import React, { Fragment, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { language } from "../stores/18nStore";
import Input from "./Input";
import StoreType from "../interfaces/StoreType";
import { Case } from "../interfaces/ReducerCase";
import SubmitButtonConditions from "./SubmitButtonConditions";
import EventStore from "../stores/AmplitudeStore";
import UseEmailVerification from "../ressources/UseEmailVerification";

export default function SignUpForm({ signUp }): ReactElement {
  const dispatch = useDispatch();
  const { authIsLoading, signUpValue, authSignUpInputs, newsletter } =
    useSelector((state: StoreType) => ({
      ...state.AuthReducer,
      ...state.RouterReducer,
    }));
  const { viewSignUpForm } = EventStore();
  useEffect(() => {
    viewSignUpForm(
      sessionStorage.getItem("nextUrl")
        ? JSON.stringify(sessionStorage.getItem("nextUrl"))
        : "Default"
    );
  }, [viewSignUpForm]);

  useEffect(() => {
    window.analytics.page(`Viewed Sign-Up Page`, undefined, {
      next: sessionStorage.getItem("nextUrl")
        ? JSON.stringify(sessionStorage.getItem("nextUrl"))
        : "Default",
    });
  }, []);

  const { emailError } = UseEmailVerification(signUpValue.email);

  return (
    <form
      name="signup"
      onSubmit={(e) => {
        e.preventDefault();
        return signUp();
      }}
      autoComplete="off"
    >
      {authSignUpInputs.map((eachInput, index: number) => {
        return (
          <Fragment key={index}>
            <Input
              label={eachInput.label}
              htmlFor={eachInput.htmlFor}
              type={eachInput.type}
              key={index}
              autocomplete={eachInput.autocomplete}
              errorStyle={
                (eachInput.label === language("email") &&
                  emailError &&
                  emailError.error) ||
                (eachInput.label === language("confirmEmail") &&
                  signUpValue.emailconfirmation.length > 0 &&
                  signUpValue.emailconfirmation !== signUpValue.email)
                  ? true
                  : eachInput.errorStyle
              }
              dispatchType={Case.SET_SIGNUP_VALUE}
            />
          </Fragment>
        );
      })}
      <div
        className="sendEmailAutorization"
        onClick={() => dispatch({ type: Case.ALLOW_NEWSLETTER })}
        aria-checked={newsletter}
        role="checkbox"
      >
        <img
          src={newsletter ? "/image/checked.svg" : "/image/unchecked.svg"}
          alt="newsletter checkbox"
        />
        <p>{language("newsletter")}</p>
      </div>
      <p className="cgu">
        {language("authCgu")}
        <a href={`https://www.sybel.co/${i18next.language.substr(0, 2)}/cgu/`}>
          {language("cgu")}
        </a>
      </p>
      <SubmitButtonConditions
        loading={authIsLoading}
        cta="signup"
        className="sign-up-btn"
        style={{ color: "#fff" }}
        disabledStyle={{ background: "#343434", color: "#A5A5A5" }}
        value={signUpValue}
      />
    </form>
  );
}
