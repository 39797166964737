import React, { ReactElement, useState } from "react";
import Slider from "rc-slider";
import { Action, Case } from "../interfaces/ReducerCase";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

interface Props {
  setVolume: React.Dispatch<React.SetStateAction<number>>;
  volume: number;
}

export default function PlayerVolume({
  setVolume,
  volume,
}: Props): ReactElement {
  const [prevVolume, setPrevVolume] = useState<number>(0);
  const dispatch: Dispatch<Action> = useDispatch();

  const mute = () => {
    setPrevVolume(volume);
    setVolume(0);
    return dispatch({
      type: Case.SET_VOLUME,
      payload: {
        volume: 0,
      },
    });
  };
  return (
    <div
      className="volume-container"
    >
      <div className="volume-hover">
        <Slider
          step={0.01}
          min={0}
          max={1}
          onChange={(e) => setVolume(e)}
          onAfterChange={(e) =>
            dispatch({
              type: Case.SET_VOLUME,
              payload: {
                volume: e,
              },
            })
          }
          value={volume}
          className="volume-slider"
          vertical
        />
        <div
          className="volume-icon"
          onClick={() => {
            if (volume === 0) {
              dispatch({
                type: Case.SET_VOLUME,
                payload: {
                  volume: prevVolume,
                },
              });
              return setVolume(prevVolume);
            } else {
              return mute();
            }
          }}
        >
          {volume === 0 ? (
            <img src="/image/speakerMute.svg" alt="" />
          ) : (
            <img src="/image/speaker.svg" alt="" />
          )}
        </div>
      </div>
      <div
        className="volume-icon"
        onClick={() => {
          if (volume === 0) {
            setVolume(0.8);
            return dispatch({
              type: Case.SET_VOLUME,
              payload: {
                volume: 0.8,
              },
            });
          } else {
            setVolume(0.8);
            return dispatch({
              type: Case.SET_VOLUME,
              payload: {
                volume: 0,
              },
            });
          }
        }}
      >
        {volume === 0 ? (
          <img src="/image/speakerMute.svg" alt="" />
        ) : (
          <img src="/image/speaker.svg" alt="" />
        )}
      </div>
    </div>
  );
}
