import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import SignUpSocialConnect from "./SignUpSocialConnect";
import EventStore from "../stores/AmplitudeStore";
import UseSignInSignUp from "../ressources/UseSignInSignUp";

interface Props {
  signIn: () => Promise<void | React.ReactText | NodeJS.Timeout>;
  signUp: Function;
  isAppleLoading: boolean;
  renault?: boolean;
}
export default function SignUpSignIn({
  signIn,
  signUp,
  isAppleLoading,
  renault,
}: Props): ReactElement {
  const { signinMode } = useSelector((state: StoreType) => ({
    ...state.AuthReducer,
  }));

  const { switchToSignIn, switchToSignUp } = EventStore();
  const { isUrlLoaded } = UseSignInSignUp();
  return (
    <div
      className="sign-up-container"
      style={
        renault
          ? {
              backdropFilter: " blur(10px)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              padding: "20px",
              borderRadius: "22px",
            }
          : undefined
      }
    >
      <img
        className="sign-up-logo"
        src="/image/logo/whitelogo.svg"
        alt="Sybel logo"
      />
      <h1>{language("discoverTitle")}</h1>
      {renault && !signinMode && (
        <h1>Inscrivez-vous pour profiter de l’offre partenaire</h1>
      )}
      {renault && signinMode && (
        <h1>Connectez-vous pour profiter de l’offre partenaire</h1>
      )}
      <SignUpSocialConnect isAppleLoading={isAppleLoading} renault={renault} />
      <h2>
        {!!sessionStorage.getItem("redeemCode") && signinMode
          ? "Pour profiter de l’offre. Connectez-vous"
          : !!sessionStorage.getItem('redeemCode') && !signinMode
          ? "Pour profiter de l’offre. Inscrivez-vous"
          : signinMode
          ? language("emailLogin")
          : language("emailSignup")}
      </h2>
      {signinMode && isUrlLoaded ? (
        <SignInForm signIn={signIn} />
      ) : (
        <SignUpForm signUp={signUp} />
      )}
      {signinMode && isUrlLoaded ? (
        <p className="not-has-an-account">
          {language("notAlreadyAnAccount")}
          <Link
            onClick={() => switchToSignUp()}
            to={renault ? "/signup?renault=true" 
                : "/signup"}
          >
            {language("notAlreadyAnAccountLink")}
          </Link>
        </p>
      ) : (
        <small>
          {language("alreadyHasAnAccount")}
          <Link
            onClick={() => switchToSignIn()}
            to={renault ? "/signin?renault=true"
                : "/signin"}
          >
            {language("alreadyHasAnAccountLink")}
          </Link>
        </small>
      )}
    </div>
  );
}

