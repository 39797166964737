import React, { ReactElement } from "react";
import { language } from "../stores/18nStore";
import Loader from "./Loader";

interface Props {
  onClick: Function;
  cta: string;
  loading?: boolean;
  customClassName?: string;
  noClassName?: true;
  disabled?: boolean
}

export default function ButtonOnClick({
  onClick,
  loading,
  cta,
  customClassName,
  noClassName,
  disabled
}: Props): ReactElement {
  return (
    <button
      className={
        noClassName
          ? undefined
          : customClassName
          ? `${customClassName}`
          : "finish-profile-button"
      }
      onClick={(e) => onClick(e)}
      disabled={loading || disabled}
    >
      {loading ? <Loader /> : language(cta)}
    </button>
  );
}
