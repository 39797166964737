import { ToastOptions } from "react-toastify";

export const notificationsParam: ToastOptions = {
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: false,
  position: "bottom-center",
  progressClassName: "progressbar-sybel-notification",
  className: "main-sybel-notification",
  bodyClassName: "main-body-sybel-notification",
  closeButton: false,
  role: "alert",
  draggablePercent: 35
};
