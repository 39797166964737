import React, { Dispatch, ReactElement, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import EpisodeType from "../interfaces/EpisodeType";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import { language } from "../stores/18nStore";
import SeriesEpisodesProgressBar from "./SeriesEpisodesProgressBar";
import { UseEpisodeStatus } from "../ressources/UseEpisodeStatus";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import EventStore from "../stores/AmplitudeStore";
import ReactPlayer from "react-player";
import { getSeriesService } from "../services/SeriesService";
import SeriesType from "../interfaces/SeriesType";

interface Props {
  each: EpisodeType;
  playerRef: React.RefObject<ReactPlayer>;
  resetScrollEffect({ element }: {
    element: any;
}): void
refOfParent: React.RefObject<HTMLDivElement>
}

export default function PlayerEpisodesCard({
  each,
  playerRef,
  resetScrollEffect, refOfParent
}: Props): ReactElement {
  const { currentEpisode, profile, uuid, userContentLanguage, user } =
    useSelector((state: StoreType) => ({
      ...state.PlayerReducer,
      ...state.ProfileReducer,
      ...state.UserReducer,
    }));
  const { isInProgress, isListened, progressBarSize } = UseEpisodeStatus(
    currentEpisode!.series._id,
    each
  );
  const dispatch: Dispatch<Action> = useDispatch();
  const { seriesLanguage } = UseSeriesLanguage();
  const { play, pause } = EventStore();
  const stableDispatch = useCallback(dispatch, [dispatch]);

  const getSeries = async (id: string) => {
    const ac = new AbortController();
    const { signal } = ac;
    try {
      const seriesPending = await getSeriesService(
        id,
        user,
        userContentLanguage,
        signal
      );
      const seriesData: SeriesType = await seriesPending.json();
      if (
        !seriesData ||
        !seriesData.info[seriesLanguage!] ||
        (seriesData.isChild === false && profile && profile.isChild === true)
      ) {
        return;
      } else {
        stableDispatch({
          type: Case.PUSH_SERIES,
          payload: { series: seriesData },
        });
        return seriesData;
      }
    } catch (e) {
      return;
    }
  };

  const playlistSetEpisode = async (each: EpisodeType) => {
    dispatch({ type: Case.PLAYER_IS_LOADING });
    if (
      currentEpisode &&
      currentEpisode.episode &&
      !currentEpisode.sample &&
      playerRef &&
      playerRef.current
    ) {
      pause(
        currentEpisode.series.isChild,
        "Play Other Content",
        currentEpisode.series.emotion
          ? currentEpisode.series.emotion.join()
          : undefined,
        currentEpisode.episode!.info[seriesLanguage].title,
        currentEpisode.episode._id,
        currentEpisode.episode.season,
        currentEpisode.series._id,
        currentEpisode.series.info[seriesLanguage].title,
        profile!.isChild,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        currentEpisode.series.mainImageId,
        Math.round(playerRef.current.getCurrentTime()),
        Math.round(
          (playerRef.current.getCurrentTime() / currentEpisode.episode.length +
            Number.EPSILON) *
            1000
        ) / 1000,
        currentEpisode.episode.length,
        currentEpisode.series.length,
        currentEpisode.episode.info[seriesLanguage].title
      );
    }
    dispatch({
      type: Case.SET_CONTEXT,
      payload: { context: "Player" },
    });
    dispatch({ type: Case.REINIT_IS_SEEKED_TO_HISTORY });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: user.token,
      },
    };
    const rest = currentEpisode!.episodes.slice(
      currentEpisode!.episodes.findIndex(
        (eachEpiode) => each._id === eachEpiode._id
      )
    );
    let restTimstamp: number = 0;
    rest.map((each) => (restTimstamp += each.length));
    if (restTimstamp < 3600) {
      const playlistPending = await fetch(
        `${process.env.REACT_APP_API}/${userContentLanguage}/series/${each.seriesId}/next?profileId=${profile?._id}&duration=108000&episodeId=${each._id}`,
        requestOptions
      );
      const playlist = await playlistPending.json();
      Promise.all(
        playlist.map(async (eachEpisode) => {
          const episodePending = await fetch(
            `${process.env.REACT_APP_API}/${userContentLanguage}/series/${eachEpisode.seriesId}/episode/${eachEpisode._id}/`,
            requestOptions
          );
          const episode = await episodePending.json();
          return episode;
        })
      ).then(async (playlist: any) => {
        if (each.seriesId !== currentEpisode!.series._id) {
          const seriesRes = await getSeries(each.seriesId);
          dispatch({
            type: Case.SET_CURRENT_EPISODE,
            payload: {
              series: seriesRes,
              episodes: playlist,
              episode: each,
              lng: currentEpisode!.lng,
              uuid,
            },
          });
          resetScrollEffect({ element: refOfParent.current })
        } else {
          dispatch({
            type: Case.SET_CURRENT_EPISODE,
            payload: {
              series: currentEpisode!.series,
              episodes: playlist,
              episode: each,
              lng: currentEpisode!.lng,
              uuid,
            },
          });
          resetScrollEffect({ element: refOfParent.current })
        }
      });
    } else {
      if (each.seriesId !== currentEpisode!.series._id) {
        const seriesRes = await getSeries(each.seriesId);
        dispatch({
          type: Case.SET_CURRENT_EPISODE,
          payload: {
            series: seriesRes,
            episodes: currentEpisode!.episodes,
            episode: each,
            lng: currentEpisode!.lng,
            uuid,
          },
        });
        resetScrollEffect({ element: refOfParent.current })
      } else {
        dispatch({
          type: Case.SET_CURRENT_EPISODE,
          payload: {
            series: currentEpisode!.series,
            episodes: currentEpisode!.episodes,
            episode: each,
            lng: currentEpisode!.lng,
            uuid,
          },
        });
        resetScrollEffect({ element: refOfParent.current })
      }
    }
    window.analytics.track("Audio Content Started", {
      subscription: !!localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).subscription
        : 0,
      platform: "Web",
      session_id: uuid,
      series_id: currentEpisode!.series._id,
      episode_id: each._id,
      content_name: currentEpisode!.series.info[seriesLanguage].title,
      episode_length: each.length,
      chronological: currentEpisode!.series.chronological,
      classification: currentEpisode!.series.classification,
      emotion: currentEpisode!.series.emotion,
      format: currentEpisode!.series.format,
      is_unit: currentEpisode!.series.isUnit,
      publication_date: currentEpisode!.series.publicationDate,
      seasons_list: currentEpisode!.series.seasonsList,
      header_mention: currentEpisode!.series.info[seriesLanguage].headerMention,
      content_length: Math.round(currentEpisode!.series.length),
      season: each.season,
      position: 0,
      context: "Series",
      signup_type: localStorage.getItem("sy_signup_type")
        ? localStorage.getItem("sy_signup_type")
        : undefined,
        episode_name: currentEpisode && currentEpisode.episode ? currentEpisode.episode.info[seriesLanguage].title :undefined,
        has_rss_feed:
        currentEpisode &&
        currentEpisode.series &&
        currentEpisode.series.info[seriesLanguage] &&
        currentEpisode.series.info[seriesLanguage].rssFeed
          ? true
          : false,
          child_content:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.isChild
            : undefined,
        episode_index:each.index,
        genre:
          currentEpisode && currentEpisode.series
            ? currentEpisode.series.genre
            : undefined,
        total_length:each.length,
    });
    if (currentEpisode) {
      play(
        currentEpisode.series.isChild,
        "Player Episodes List",
        currentEpisode.series.emotion
          ? currentEpisode.series.emotion.join()
          : undefined,
        each.info[seriesLanguage].title,
        each._id,
        each.season,
        currentEpisode.series._id,
        currentEpisode.series.info[seriesLanguage].title,
        profile!.isChild,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        currentEpisode.series.mainImageId,
        0,
        0,
        Math.round(each.length),
        Math.round(currentEpisode.series.length)
      );
    }
  };
  return (
    <div
      className={
        isListened ? "each-episode-player listened" : "each-episode-player"
      }
      onClick={() => playlistSetEpisode(each)}
    >
      <div
        className="episode-cover-hover"
        style={{
          backgroundImage: each.image
            ? `url(${each.image})`
            : 'url("/image/episodeCoverImagePlaceholder.svg")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {isInProgress && (
          <SeriesEpisodesProgressBar progressBarSize={progressBarSize} />
        )}
        {isListened && (
          <img
            className="listened-episode-button"
            src="/image/listened.svg"
            alt=""
          />
        )}
      </div>
      <div className="episode-info-hover">
        <p
          className="title-episode-hover"
          style={{
            color:
              each._id ===
              (currentEpisode &&
                currentEpisode.episode &&
                currentEpisode.episode._id)
                ? "#f23568"
                : "#fff",
          }}
        >
          {currentEpisode &&
            currentEpisode.lng &&
            each.info &&
            each.info[currentEpisode.lng] &&
            each.info[currentEpisode.lng].title}
        </p>
        <p className="season-each-episode-hover">
          {language("shortSeason")}
          {each.season}
        </p>
      </div>
      <div className="episode-duration-hover">
        <p>
          {each.length && each.length < 3600
            ? `${new Date(each.length * 1000).toISOString().substr(14, 5)}`
            : each.length && each.length >= 3600
            ? `${new Date(each.length * 1000).toISOString().substr(11, 8)}`
            : "--:--"}
        </p>
      </div>
    </div>
  );
}
