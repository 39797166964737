import React, { ReactElement } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ProfileType from "../interfaces/ProfileType";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import UseProfile from "../ressources/UseProfile";
import { UseSeriesLanguage } from "../ressources/UseSeriesLanguage";
import { language } from "../stores/18nStore";
import EventStore from "../stores/AmplitudeStore";
import amplitude from "amplitude-js";
import { ProductStripeType } from "../interfaces/UserType";

interface Props {
  setUniversToShow: React.Dispatch<React.SetStateAction<string | undefined>>;
  universToShow: string | undefined;
  burger?: true;
  setSearch?: (value: React.SetStateAction<string>) => void;
  setSearchSeries?: React.Dispatch<
    React.SetStateAction<SeriesType[] | undefined>
  >;
}
export default function HomeProfileDeployed({
  setUniversToShow,
  universToShow,
  burger,
  setSearch,
  setSearchSeries,
}: Props): ReactElement {
  const { profile, profiles, universes, user } = useSelector(
    (state: StoreType) => ({
      ...state.ProfileReducer,
      ...state.UserReducer,
    })
  );
  const { openSettings } = EventStore();
  const { chooseProfile } = UseProfile(true);
  const location = useLocation();
  const homeNavChooseProfile = (
    each: ProfileType,
    fromHome?: boolean | undefined
  ) => {
    chooseProfile(each, fromHome);
    setUniversToShow(undefined);
  };
  
  const { seriesLanguage } = UseSeriesLanguage();
  return (
    <div className={burger ? "burger-profile-deployed" : "profile-deployed"}>
      {user && (user.subscription === null || user.subscription === 0) && (
        <Link
          className="nav-profile-premium-deployed"
          to={
            user.subscription === 0 &&
            user.subscriptionExpirationDate &&
            new Date(user.subscriptionExpirationDate) < new Date()
              ? "/offers"
              : `/payment/${
                  (
                    user.subscriptionData!.productsStripe[
                      user.subscriptionData!.productsStripe.findIndex(
                        (eachProduct) =>
                          eachProduct.trial_period_days ===
                          Math.max.apply(
                            Math,
                            user.subscriptionData!.productsStripe.map(
                              (o) => o.trial_period_days
                            )
                          )
                      )
                    ] as ProductStripeType
                  ).id
                }`
          }
          onClick={() =>
            amplitude
              .getInstance()
              .logEvent("Click Premium Button", { Context: "Menu" })
          }
        >
          {user.subscription === 0 &&
          user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) < new Date()
            ? language("subscriptionInsert").toUpperCase()
            : language("popUpNewSessionCta").toUpperCase()}
        </Link>
      )}
      {!burger && (
        <img src="/image/arrowIcon.svg" className="arrow-deployed" alt="" />
      )}
      {!burger ? (
        profiles.map((each, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                if (setSearch && setSearchSeries) {
                  setSearch("");
                  setSearchSeries(undefined);
                }
                homeNavChooseProfile(each, true);
              }}
              className="home-profile-content"
            >
              <p
                style={
                  each._id === profile!._id
                    ? { color: "#fff", fontWeight: "bold" }
                    : { color: "#A5A5A5" }
                }
              >
                {each.name.length <= 12
                  ? each.name
                  : `${each.name.slice(0, 12)}...`}
              </p>
              <div
                className={
                  each._id === profile!._id
                    ? "main-profile-image-home"
                    : "second-profile-image-home"
                }
              >
                <LazyLoadImage
                  style={each._id === profile!._id ? { opacity: 1 } : {}}
                  className="profile-picture"
                  placeholderSrc="/image/profilePlaceholder.svg"
                  src={
                    each.image ? each.image : "/image/profilePlaceholder.svg"
                  }
                  alt=""
                />
              </div>
            </div>
          );
        })
      ) : burger && profile ? (
        <div
          onClick={() => {
            homeNavChooseProfile(profile, true);
          }}
          className="home-profile-content"
          style={{ justifyContent: "flex-start", padding: "11px 0px" }}
        >
          <div className={"main-profile-image-home"}>
            <LazyLoadImage
              style={{ opacity: 1 }}
              className="profile-picture"
              placeholderSrc="/image/profilePlaceholder.svg"
              src={
                profile.image ? profile.image : "/image/profilePlaceholder.svg"
              }
              alt=""
            />
          </div>
          <p
            style={{
              width: "auto",
              color: "#fff",
              fontWeight: "bold",
              alignItems: "flex-start",
              flexDirection: "column",
              marginLeft: "11px",
            }}
          >
            {profile.name.length <= 12
              ? profile.name
              : `${profile.name.slice(0, 12)}...`}
            <Link
              style={{ margin: 0, fontWeight: 300 }}
              onClick={() => window.scrollTo(0, 0)}
              className="navbar-settings"
              to={{
                pathname: "/choose-profile",
                state: { fromHome: true },
              }}
            >
              {language("profileManagment")}
            </Link>
          </p>
        </div>
      ) : null}
      {!burger && (
        <Link
          onClick={() => window.scrollTo(0, 0)}
          className="navbar-settings"
          to={{
            pathname: "/choose-profile",
            state: { fromHome: true },
          }}
        >
          {language("profileManagment")}
        </Link>
      )}
      <Link
        onClick={() =>
          openSettings(
            location.pathname,
            universes && universToShow
              ? universes[
                  universes.findIndex(
                    (each) => each.headerCategoryId === universToShow
                  )
                ].title
              : "Home",
            seriesLanguage
          )
        }
        className="navbar-settings"
        to="/settings"
      >
        {language("settingsScreenTitle")}
      </Link>
      {user && (user.subscription === null || user.subscription === 0) && (
        <Link
          className="nav-profile-premium-deployed-bg"
          to={
            user.subscription === 0 &&
            user.subscriptionExpirationDate &&
            new Date(user.subscriptionExpirationDate) < new Date()
              ? "/offers"
              : `/payment/${
                  (
                    user.subscriptionData!.productsStripe[
                      user.subscriptionData!.productsStripe.findIndex(
                        (eachProduct) =>
                          eachProduct.trial_period_days ===
                          Math.max.apply(
                            Math,
                            user.subscriptionData!.productsStripe.map(
                              (o) => o.trial_period_days
                            )
                          )
                      )
                    ] as ProductStripeType
                  ).id
                }`
          }
          onClick={() =>
            amplitude
              .getInstance()
              .logEvent("Click Premium Button", { Context: "Menu" })
          }
        >
          {user.subscription === 0 &&
          user.subscriptionExpirationDate &&
          new Date(user.subscriptionExpirationDate) < new Date()
            ? language("subscriptionInsert").toUpperCase()
            : language("popUpNewSessionCta").toUpperCase()}
        </Link>
      )}
      {burger && (
        <hr
          style={{
            backgroundColor: "#343434",
            height: 1,
            width: "100%",
            border: "none",
          }}
        />
      )}
    </div>
  );
}
