import React, { ReactElement, useRef } from "react";
import ContentLoader from "react-content-loader";
import { createUseStyles } from "react-jss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SeriesType from "../interfaces/SeriesType";
interface Props {
  each: SeriesType;
  toastId: string;
  seriesLanguage: string;
  changeSeries: Function;
  index: number;
}

export default function SimilarSeriesCard({
  each,
  seriesLanguage,
  changeSeries,
  index,
}: Props): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const useStyles = createUseStyles({
    bgImage: {
      backgroundImage: (props: { url: string | undefined }) =>
        `url(${props.url})`,
      backgroundSize: "contain",
    },
  });
  const classes = useStyles({
    url: each.info[seriesLanguage].coverImage,
  });
  return (
    <div
      className={`each-similar ${classes.bgImage}`}
      onClick={() => changeSeries(each._id, index)}
    >
      <img className="play-epidode-button" src="/image/playLogo.svg" alt="" />
      {each.info[seriesLanguage].titleImage && (
        <LazyLoadImage
          src={each.info[seriesLanguage].titleImage}
          placeholder={
            <ContentLoader
              animate={true}
              speed={1.5}
              width="120"
              height="120"
              viewBox="0 0 120 120"
              backgroundColor="#343434"
              foregroundColor="#454545"
            >
              <rect width="120" height="120" rx="8" />
            </ContentLoader>
          }
          className="title-image"
          alt=""
        />
      )}
      <div className="series-header-mentions" ref={ref}>
        {each.info &&
        each.info[seriesLanguage] &&
        each.info[seriesLanguage].headerMention &&
        each.info[seriesLanguage].headerMention.length <= 34
          ? each.info[seriesLanguage].headerMention
              .trim()
              .split(";")
              .map((each, index) => {
                return <p key={index}>{each.trim()}</p>;
              })
          : each.info &&
            each.info[seriesLanguage] &&
            each.info[seriesLanguage].headerMention &&
            each.info[seriesLanguage].headerMention.length > 34
          ? each.info[seriesLanguage].headerMention
              .trim()
              .split(";")
              .splice(0, 2)
              .map((eachHeaderMentions, index) => {
                return <p key={index}>{eachHeaderMentions.trim()}</p>;
              })
          : ""}
      </div>
    </div>
  );
}
