import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { language } from "../stores/18nStore";
import Input from "./Input";
import { Link } from "react-router-dom";
import StoreType from "../interfaces/StoreType";
import SubmitButtonConditions from "./SubmitButtonConditions";
import EventStore from "../stores/AmplitudeStore";
import UseEmailVerification from "../ressources/UseEmailVerification";

interface Props{
    signIn: () => Promise<void | React.ReactText | NodeJS.Timeout>,
    isBlank?: true
}

export default function SignInForm(
{  signIn,
  isBlank} : Props
) {
  const { authIsLoading, signInValue, authSignInInputs } = useSelector(
    (state: StoreType) => ({
      ...state.AuthReducer,
      ...state.UserReducer,
      ...state.RouterReducer,
    })
  );
  const { viewSignInForm } = EventStore();
  useEffect(() => {    
    viewSignInForm(
      sessionStorage.getItem("nextUrl")
        ? JSON.stringify(sessionStorage.getItem("nextUrl"))
        : "Default"
    );
  }, [viewSignInForm]);
  
  useEffect(() => {
    window.analytics.page(`Viewed Sign-In Page`, undefined, { next:  sessionStorage.getItem("nextUrl")
    ? JSON.stringify(sessionStorage.getItem("nextUrl"))
    : "Default" });
  }, [])
   
  const { emailError } = UseEmailVerification(signInValue.email);

  return (
    <form
      name="signin"
      onSubmit={(e) => {
        e.preventDefault();
        return signIn();
      }}
      autoComplete="off"
    >
      {authSignInInputs.map((eachInput, index: number) => {
        return (
          <Fragment key={index}>
            <Input
              label={eachInput.label}
              htmlFor={eachInput.htmlFor}
              type={eachInput.type}
              autocomplete={eachInput.autocomplete}
              errorStyle={
                eachInput.label === language("email") &&
                  emailError &&
                  emailError.error ? true : eachInput.errorStyle}
              dispatchType="SET_SIGNIN_VALUE"
            />
          </Fragment>
        );
      })}
      <p className="forgoted-password">
        {language("forgotedPassword")}
        {isBlank ? (
          <Link
            to="/forgot-password"
            target="_blank"
            rel="noopener noreferrer"
          >
            {language("forgotedPasswordLink")}
          </Link>
        ) : (
          <Link to="/forgot-password">
            {language("forgotedPasswordLink")}
          </Link>
        )}
      </p>
      <SubmitButtonConditions
        loading={authIsLoading}
        cta="signin"
        className="sign-up-btn"
        style={{ color: "#fff" }}
        disabledStyle={{ background: "#343434", color: "#A5A5A5" }}
        value={signInValue}
      />
    </form>
  );
}
