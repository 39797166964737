import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import SeriesLocationTypes from "../interfaces/SeriesLocationTypes";
import SeriesType from "../interfaces/SeriesType";
import StoreType from "../interfaces/StoreType";
import EventStore from "../stores/AmplitudeStore";

interface Props {
  id: string;
  seriesLanguage: string;
  fetchedSeries: SeriesType | undefined;
  lastNavigation: "Similar Series Tab" | "Episodes List Tab" | "Details Tab";
}

export default function SeriesDetails({
  id,
  seriesLanguage,
  fetchedSeries,
  lastNavigation,
}: Props): ReactElement {
  const { series } = useSelector((state: StoreType) => ({
    ...state.SeriesReducer,
  }));
  const location = useLocation<SeriesLocationTypes>();
  const { openSeriesPageDetailsTab } = EventStore();
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("profile")!) && fetchedSeries && seriesLanguage) {
      return openSeriesPageDetailsTab(
        location &&
          location.state &&
          location.state.isSimilar &&
          location.state.isSimilar.id === fetchedSeries._id
          ? "Similar Series"
          : "Series Deeplink",
        fetchedSeries._id,
        JSON.parse(localStorage.getItem("profile")!).isChild,
        fetchedSeries.info[seriesLanguage].title,
        fetchedSeries.isChild,
        fetchedSeries.emotion ? fetchedSeries.emotion!.join() : undefined,
        lastNavigation,
        fetchedSeries.mainImageId,
        location && location.state && location.state.contentEventDetails
          ? location.state.contentEventDetails.contentPosition
          : undefined
      );
    }
  }, [
    location,
    fetchedSeries,
    seriesLanguage,
    lastNavigation,
    openSeriesPageDetailsTab,
  ]);

  return (
    <div
      className="detail-content"
      dangerouslySetInnerHTML={{
        __html: series[id!].info[seriesLanguage].credits,
      }}
    />
  );
}
