import i18next from "i18next";
import { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Action, Case } from "../interfaces/ReducerCase";
import StoreType from "../interfaces/StoreType";
import { updateUserService } from "../services/UserService";
import { language } from "../stores/18nStore";
import { notificationsParam } from "../utils/ReactNotificationStaticOptions";

export const UsePreferenceSettings = (
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setIsSecondComponentVisible: React.Dispatch<React.SetStateAction<boolean>>
): {
  setNewsletter: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  setContentLanguage: (language: string) => void;
  setInterfaceLanguage: (language: string) => void;
  triggerLanguages: (
    state: boolean,
    trueState: Function,
    falseState: Function
  ) => void;
} => {
  const { user } = useSelector((state: StoreType) => ({
    ...state.UserReducer,
  }));
  const dispatch: Dispatch<Action> = useDispatch();
  const setNewsletter = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch({
      type: Case.UPDATE_USER,
      payload: { user: { offersEmail: e.target.checked } },
    });
    try {
      const res = await updateUserService(
        { offersEmail: e.target.checked },
        user.token,
        user._id
      );
      await res.json();
    } catch (error) {
      dispatch({
        type: Case.UPDATE_USER,
        payload: { user: { offersEmail: !e.target.checked } },
      });
      toast(language("codeError"), { ...notificationsParam });
    }
  };
  const setInterfaceLanguage = (language: string) => {
    setIsComponentVisible(false);
    i18next.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
    return window.location.reload();
  };
  const setContentLanguage = (language: string) => {
    setIsSecondComponentVisible(false);
    dispatch({
      type: Case.SET_CONTENT_LANGUAGE,
      payload: { language },
    });
    localStorage.setItem("contentLanguage", language);
    return window.location.reload();
  };
  const triggerLanguages = (
    state: boolean,
    trueState: Function,
    falseState: Function
  ) => {
    trueState(!state);
    falseState(false);
  };
  return {
    setNewsletter,
    setContentLanguage,
    setInterfaceLanguage,
    triggerLanguages,
  };
};
