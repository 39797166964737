import React, { ReactElement } from "react";
import { createUseStyles } from "react-jss";

interface Props {
  progressBarSize: number;
}

export default function SeriesHeaderProgressBar({
  progressBarSize,
}: Props): ReactElement {
  const useStyles = createUseStyles({
    progressBar: {
      width: "100%",
      height: "3px",
      position: "relative",
      zIndex: 10000,
      background: "rgba(242, 53, 103, 0.213)",
      marginTop: "8px",
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        background: "rgba(242, 53, 103)",
        width: (progressBarSize: Props) => `${progressBarSize}%`,
        height: "100%",
        display: "block",
        transition: "width .2s ease-in",
      },
    },
  });
  const classes = useStyles(progressBarSize);
  return <div className={classes.progressBar}></div>;
}
